<template>
    <div class="col-12 col-md-6 order-2 order-md-1">
        <div class=" card p-1">
            <h4 class="border-bottom pb-05 mb-1"><i class="feather icon-settings"></i> {{ __('supplier.headers.admin.supplier_type') }}</h4>
            <p class="text-muted font-small-3"> {{ __('supplier.form.paragraph.supplier_type') }}</p>


            <div class="form-group">
                <div class="d-flex">
                    <div>
                        <label for="supplier-type-1">{{ __('supplier.field.text.supplier_types') }}</label>
                        <input class="form-control" type="text" name="type[supplierType1]" id="supplier-type-1"
                               @change="updateDisplayList(2)">

                        <span class="help-block"></span>
                    </div>
                    <div class="ml-05">
                        <label for="display-type-2">{{ __('supplier.how_to_display') }}</label>
                        <select name="type[displayType1]" id="display-type-1" class="form-control" v-model="display_type_1">
                            <option v-for="display in display_list_1" :value="display" :key="display">{{display}}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex mt-2">

                    <div class="vs-checkbox-con vs-checkbox-primary">
                        <input type="checkbox" value="1" name="type[paidUpgrade1]"
                               id="paid-upgrade-1" @click="enable_paid(1)" v-model="this.paid_upgrade_1">
                        <span class="vs-checkbox">
                                                    <span class="vs-checkbox--check"><i
                                                        class="vs-icon feather icon-check"></i></span>
                                                    </span>
                        {{ __('supplier.paid_upgrade') }}
                    </div>
                    <div class="ml-2">
                        <input type="number" :value="this.type_cost_1" name="type[typeCost1]" id="type-cost-1" placeholder="$ enter price" disabled/>
                    </div>
                </div>

            </div>
            <div class="form-group">
                <div class="d-flex">
                    <div>
                        <label for="supplier-type-2">{{ __('supplier.field.text.supplier_types') }}</label>
                        <input class="form-control" type="text" name="type[supplierType2]" id="supplier-type-2"
                               @change="updateDisplayList(1)">

                        <span class="help-block"></span>
                    </div>
                    <div class="ml-05">
                        <label for="display-type-2">{{ __('supplier.how_to_display') }}</label>
                        <select name="type[displayType2]" id="display-type-2" class="form-control" v-model="this.display_type_2">
                            <option v-for="display in display_list_2" :value="display" :key="display">{{display}}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex mt-2">

                    <div class="vs-checkbox-con vs-checkbox-primary">
                        <input type="checkbox" value="1" name="type[paidUpgrade2]" @click="enable_paid(2)"
                               id="paid-upgrade-2" v-model="this.paid_upgrade_2">
                        <span class="vs-checkbox">
                                                    <span class="vs-checkbox--check"><i
                                                        class="vs-icon feather icon-check"></i></span>
                                                    </span>
                        {{ __('supplier.paid_upgrade') }}
                    </div>
                    <div class="ml-2">
                        <input type="number" :value="this.type_cost_2" name="type[typeCost2]" id="type-cost-2" placeholder="$ enter price" disabled />
                    </div>
                </div>

            </div>
            <div class="form-group text-right mb-0">
                <button class="btn btn-success" type="submit">
                    {{ __('supplier.form.buttons.save') }} <i class="fa fa-check-circle"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SupplierTypes",
        props: ['data'],
        data() {
            return {
                display_list_1: [this.__('supplier.within_supplier'), this.__('supplier.seperate_menu')],
                display_list_2: [this.__('supplier.within_supplier'), this.__('supplier.seperate_menu')],
                display_type_1: this.data !== '' ? this.data.displayType1 : '',
                display_type_2: this.data !== '' ? this.data.displayType2 : '',
                paid_upgrade_1: this.data !== '' ? this.data.paidUpgrade1 : '',
                paid_upgrade_2: this.data !== '' ? this.data.paidUpgrade2 : '',
                type_cost_1: this.data !== '' ? this.data.typeCost1 : '',
                type_cost_2: this.data !== '' ? this.data.typeCost2 : '',
                supplier_type_1: this.data !== '' ? this.data.supplierType1 : '',
                supplier_type_2: this.data !== '' ? this.data.supplierType2 : '',
            }
        },
        methods:{
            updateDisplayList(num)
            {
                if (num == 2)
                {
                    let display = $('#supplier-type-1').val();
                    if (display !== '')
                    {
                        this.display_list_2.push('Show within '+display+' type');
                    }

                }

                if (num == 1)
                {
                    let display = $('#supplier-type-2').val();
                    if (display !== '')
                    {
                        this.display_list_1.push('Show within ' + display + ' type');
                    }
                }
            },
            enable_paid(num)
            {
                    if($('#paid-upgrade-'+num).prop("checked") == true)
                    {
                        $('#type-cost-'+num).prop('disabled', false);
                    }
                    else if($('#paid-upgrade-'+num).prop("checked") == false)
                    {
                        $('#type-cost-'+num).prop('disabled', true);
                    }
            },
            updateSupplierType()
            {
                $('#supplier-type-1').val(this.supplier_type_1);
                $('#supplier-type-2').val(this.supplier_type_2)
            }
        },
        mounted() {

            this.enable_paid(1);
            this.enable_paid(2);
            this.updateSupplierType();
            this.updateDisplayList(1);
            this.updateDisplayList(2);
        }
    }
</script>

<style scoped>

</style>
