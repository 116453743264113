<template>
    <div class="p-05">
        <div class="dropdown">
            <a class="dropdown-toggle" id="socialShareButton" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-share-alt"></i> {{ __('globals.share')}}
            </a>
            <div class="dropdown-menu mb-0 pb-0" aria-labelledby="socialShareButton">
                <ul>
                    <li class="mt-05 mb-1 d-inline-block" style="margin-left: -20px;">
                        <a :href="this.social.facebook" class="social-button"
                           style="color: #3b5998;">
                            <span class="fa fa-facebook" aria-hidden="true"></span> {{ __('globals.facebook')}}
                        </a>
                    </li>
                    <li class="mb-1 d-inline-block" style="margin-left: -20px;">
                        <a :href="this.social.twitter" class="social-button">
                                            <span class="fa fa-twitter" style="color: #55acee;"
                                                  aria-hidden="true"></span> {{ __('globals.twitter')}}
                        </a>
                    </li>
                    <li class=" d-inline-block" style="margin-left: -20px;">
                        <a :href="this.social.linkedin" class="social-button">
                                            <span class="fa fa-linkedin" style="color: #007bb6;"
                                                  aria-hidden="true"></span> {{ __('globals.linkedin')}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SocialShare",
    props: ['social'],
    methods: {
    }
}
</script>

<style scoped>

</style>
