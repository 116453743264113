<template>
    <div>
        <button class="btn btn-outline-danger btn-sm" @click.prevent="removeFollower()">{{ __('group.form.buttons.remove') }}</button>
    </div>
</template>

<script>
    export default {
        props: ['type', 'slug', 'member'],
        name: "ManageFollowers",
        data() {
            return {
                submitting: false,
                member_successfully_removed_message: this.__('globals.member_successfully_removed')
            }
        },
        methods: {
            removeFollower(){
                if(this.submitting){
                  return false;
                }
                this.submitting = true;
                if (confirm(this.__('group.remove_user_paragraph') + ' ' + this.member.first_name +' '+this.member.last_name +'?')) {
                    axios
                        .delete(this.endpoint())
                        .then((response) => {
                            let flash_data = {
                                'message': this.member_successfully_removed_message,
                                'level': 'success'
                            }
                            flash(flash_data);
                            this.$parent.$emit('removeMember', this.member.id);
                            this.submitting = false;
                        })
                        .catch(() => {
                           let flash_data = {
                              'message': 'An Error Occurred',
                              'level': 'danger'
                           }
                          flash(flash_data);
                          this.submitting = false;
                        });
                } else {
                    this.submitting = false;
                }
            },
            endpoint(){
                if(this.type === 'group'){
                    return `/member/${this.type}/${this.slug}/remove-member/${this.member.username}`;
                }
                return `/member/${this.type}/${this.slug}/remove-follower/${this.member.username}`;
            }
        },
    }
</script>

<style scoped>

</style>
