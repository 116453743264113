<template>
    <div>
        <div class="card p-05" v-if="this.shared_user">
            <div class="d-flex justify-content-start mb-05 ">
                <div>
                    <img class="round"
                         :src="this.shared_user !== null && this.shared_user.user.avatar !== null ? this.shared_user.user.avatar.url :  '/images/avatar.png'"
                         alt="avatar"
                         height="30"
                         width="30"/>
                </div>
                <div class="p-0 justify-content-start">
                    <div class="user-name text-bold-600">
                        <div class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle dropdown-user-link"
                               href="#"
                               data-toggle="dropdown"
                               v-text="this.shared_user.user.first_name+' '+this.shared_user.user.last_name">
                            </a>
                            <span v-for="badge in this.shared_user.user.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name">
                    </span>
                            <span class="text-bold-400">shared a webinar</span>
                        </div>
                    </div>
                    <div style="margin-top: -3px; line-height: 1;"><span v-text="this.shared_user.user.position"
                                                                         class="font-small-2 text-light"></span><span
                        class="font-small-2 text-light"> at </span><span v-text="this.shared_user.user.organisation"
                                                                         class="font-small-2 text-light"></span></div>
                </div>
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                    <span v-text="ago(this.data.created_at)" class="font-small-1 text-light "
                          style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="editPost"><i
                            class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                    </span>
                            <span
                                v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                            class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="editing" class="form-group pr-1 pl-1">
                <textarea class="form-control mb-1" v-model="body"></textarea>
                <button class="btn btn-sm btn-primary" @click.prevent="updateNewsfeed">{{ __('globals.update') }}</button>
                <button class="btn btn-sm btn-link" @click.prevent="editing = false">{{ __('globals.cancel') }}</button>
            </div>
            <div class=" p-05" v-else-if="body !== null">
                <div class="breakAll pb-1" v-linkified
                     v-html="this.$options.filters.nl2br(body, true)">

                </div>


            </div>
            <div class="d-flex justify-content-center">
                <div class="card border-light w-100">
                    <div class="d-flex justify-content-start">
                        <div>
                            <div v-if="this.shared_user.user !== null && this.shared_user.supplier_user.length === 0">
                                <post-owner-info :data="data"
                                                 :isServiceAdmin="isServiceAdmin"
                                                 :user_id="user_id"
                                />
                            </div>

                            <div v-else-if="this.shared_user.supplier_user.length > 0">
                                <div class="d-flex justify-content-start ">
                                    <div class="p-05">
                                        <img class="round"
                                             :src="this.shared_user.supplier_user.length > 0 && this.shared_user.supplier_user[0].supplier_avatar !== null ? this.shared_user.supplier_user[0].supplier_avatar.url :  '/images/avatar.png'"
                                             alt="avatar"
                                             height="30"
                                             width="30"/>
                                    </div>
                                    <div class="p-05 justify-content-start">
                                        <div class="user-name text-bold-600 text-dark">
                                            <div class="nav-item">
                                                <a class="dropdown-toggle dropdown-user-link"
                                                   :href="'/member/supplier/'+this.shared_user.supplier_user[0].slug"
                                                   data-toggle="dropdown"
                                                   v-text="this.shared_user.supplier_user[0].title">
                                                </a>
                                            </div>
                                        </div>
                                        <div style="margin-top: -5px;"><span class="font-small-2 text-primary">@{{ this.shared_user.supplier_user[0].slug}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                        <span v-text="ago(this.data.module.created_at)" class="font-small-1 text-light "
                                              style="margin-top: 4px;"></span>

                        </div>
                    </div>
                    <div class="card-content">
                        <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                            <img class=" img-fluid"
                                 :src="data.module.cover === null ? '/images/cover.jpg' : data.module.cover.url">
                        </div>
                        <div class="card-body p-0">
                            <div class="d-flex">
                                <div
                                    class="mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                        <span class="h3"><a :href="'/member/zoom-webinar/'+data.module.zoom_id" v-text="data.module.title"
                                            style="color:inherit;"></a></span>


                                </div>

                                <div class="ml-auto mt-0 ml-1 mr-1 mb-0 pt-1">
                                    <div
                                        class="badge badge-pill bg-gradient-secondary"
                                        style=" padding: 0.2rem 0.5rem;">
                                        <i class="feather icon-video"></i> {{ __('webinar.title_singular') }}
                                    </div>
                                </div>
                            </div>


                            <div class="d-flex ml-1 mr-1 mt-0">
                                <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span
                                class="h6 primary" v-text="showDateTime"></span>

                            </div>
                            <div class=" m-05 pt-1 pl-05 pr-0 border-top">
                                <div class="breakAll mb-0" v-linkified
                                     v-html="data.module.description.substring(0,550)"></div>
                                <div><a :href="'/member/zoom-webinar/'+data.module.zoom_id" class="font-small-3">{{ __('globals.read_more') }} <i
                                    class="feather icon-chevron-right"></i></a></div>

                            </div>
                        </div>
                        <div class="card-body border-top ml-05 mr-05 p-0">
                            <div class="d-flex justify-content-between">
                                <div class=" p-05">
                                    <like :post="data.module" type="post"></like>
                                </div>
                                <div class="p-05 font-small-3 text-bold-700"><a
                                    @click.prevent="showComments(data.id)"><i
                                    class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  :module="data.type"
                  :slug="data.module.id"
                  :members="members"
        ></comments>
    </div>
</template>

<script>

import Share from '../../../mixins/newsfeed/Share'
import DateTime from '../../../mixins/newsfeed/DateTime'
import Webinar from '../../../mixins/newsfeed/ZoomWebinar'

export default {
    props: ['members'],
    name: "SharedWebinar",
    data() {
        return {
            shared_user: null
        }
    },
    mixins: [
        Share, DateTime, Webinar
    ],
    mounted() {
        this.getSharedPost()
    }
}
</script>

<style scoped>

</style>
