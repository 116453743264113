<template>
    <div>
        <div class="card p-1">
            <span class="font-small-2 text-bold-700 mb-05 text-left">{{ __('question.headers.member.invite_users') }}</span>
            <button @click.prevent="triggerUserInviteModal" type="button" class="btn btn-primary">
                    {{ __('question.form.buttons.invite_users') }}
            </button>
        </div>
        <div v-if="show_invite_modal">
            <InviteUsersModal :module_id="this.module_id" @close-modal="triggerUserInviteModal"></InviteUsersModal>
        </div>
    </div>
</template>

<script>

import InviteUsersModal from "./InviteUsersModal.vue";
    export default {
        name: 'InviteUsers',
        components: {
            InviteUsersModal
        },
        props: ['module_id'],
        data(){
          return {
              show_invite_modal: false,
          }
        },
        methods: {
            triggerUserInviteModal(){
                this.show_invite_modal = !this.show_invite_modal;
            },
        }
    }
</script>
