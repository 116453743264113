import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)

export default {
    name: "Product",
    props: ['product'],
    components: {},
    data() {
        return {
            rating: null,
        }
    },
    methods: {
        average_rating(){
            let ratings_array = [];
            if(this.product.ratings.length > 0){
                this.product.ratings.forEach(rating => {
                    ratings_array.push(rating.rating);
                })
            }
            let sum = ratings_array.reduce((a, b) => a + b, 0);
            this.rating = Math.ceil((sum / ratings_array.length) || 0);
        },
        editPost(e) {
            e.stopPropagation();
            this.editing = true;
        },
        changeImage(id, url) {
            $('.product-image-' + id).attr("src", url);
        },
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/product/' + this.product.slug);
                if(window.location.href.includes('/member/product')){
                    document.location.href='/member';
                }
                this.$parent.$emit('deleted', this.product.id);
            }
        },
        report() {
            $('#report_id').val(this.product.id);
            $('#report_type').val('App\\Models\\Product');
            $('#post_title').text(this.product.title);
            $('#reportModal').modal('toggle');
        },
    },
    beforeMount() {
    }
}
