<template>
    <div>
        <div class="card" style="width: 100%">
            <div class="card-content">
                <div class="card-body">
                    <div class="font-large-2" v-text="job.title"></div>
                    <div class="border-bottom">
                        <div class="">
                            <p class="primary font-medium-3 mb-05">
                                <i class="fa fa-briefcase"></i> {{ job.employment_type }}
                            </p>
                            <p class=" font-small-3 text-muted"><i class="fa fa-map-marker"></i> {{
                                job.position_location }}</p>
                        </div>
                        <div class="pt-1 pb-1 border-bottom breakAll">
                            <span class="breakAll image-resize" v-html="job.position_description"></span>
                        </div>
                        <div class="pt-1 pb-1 border-bottom">
                            <strong> {{ __('job.field.label.position_salary') }}:</strong> {{ job.position_salary }}
                        </div>


                        <div class="d-flex align-items-center border-bottom pt-1 pb-1">
                            <div>
                                <div class="pb-1"><strong>{{ __('job.company_name') }}:</strong><br> {{ job.organisation_name }}</div>
                                <div><strong>{{ __('job.company_description') }}:</strong><br>
                                    <span class="breakAll" v-html="job.organisation_description"></span>
                                </div>
                                <div class="pt-1"><strong>{{ __('job.company_size') }}:</strong> {{ job.organisation_size }}</div>
                            </div>
                        </div>
                        <div class="d-flex mt-1">
                            <button data-toggle="modal" data-target="#registerModal"
                               class="btn btn-primary waves-effect waves-light form-control">{{
                                __('job.form.links.apply_for_position')
                                }}</button>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between border-top pl-05 pr-05">
                    <div class="p-05">
                        <i class="fa fa-thumbs-o-up" data-toggle="modal" data-target="#registerModal"></i>
                    </div>
                    <div class="p-05">
                        <span data-toggle="modal" data-target="#registerModal">
                            <i class="fa fa-comment-o"></i> {{ __('globals.comment') }}
                        </span>
                    </div>
                    <div class="p-05">
                        <div class="dropdown">
                            <a class="dropdown-toggle" id="socialShareButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-share-alt"></i> {{ __('globals.share') }}
                            </a>
                            <div class="dropdown-menu" aria-labelledby="socialShareButton">
                                <span v-html="social"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment-timezone";
    import linkify from 'vue-linkify'

    Vue.directive('linkified', linkify)

    export default {
        name: "Job",
        props: ['data', 'social'],
        data() {
            return {
                job: this.data,
            }
        },

        computed: {

            ago() {
                return moment(this.data.created_at).format('DD-MM-YY');
            }
        }
    }
</script>

