<template>
    <div>
        <div class="input-group pb-2">

            <input class="form-control round" type="text" name="keyword" id="keyword" v-model="keyword" v-on:input="debounceInput" :placeholder="__('search.type_to_search')">

        </div>
        <div class="card" v-for="(friend, index) in friends" :key="index">
            <div class="d-flex">
                <div class="p-1">
                    <img class="round" :src="friend.avatar !== null ? friend.avatar.url : '/images/avatar.png'"
                         alt="avatar"
                         height="40"
                         width="40"/>
                </div>

                <div class="pl-0 pt-1 pr-1 pb-1">
                    <div>
                        <a :href="'/friend/profiles/'+friend.username" style="color:inherit;" class="text-bold-600">
                            {{ friend.first_name }} {{ friend.last_name }}
                        </a>

                        <img v-if="friend.roles[0].title === 'Admin'"
                             src="/images/badges/admin.png" alt="user role badge" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" title="Administrator" />

                        <img v-for="badge in friend.badges"
                            :src="badge.image" alt="user custom badge" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="badge.name" />

                        <img v-if="friend.user_badge_id !== null && friend.user_badge_image != null"
                            :src="friend.user_badge_image.url" alt="user plan badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="friend.user_badge_name" />
                    </div>

                    <span class="font-small-3 text-muted">{{ friend.organisation }} | {{ friend.position }}</span>
                </div>

                <div class="ml-auto p-1 mobile-hide text-center d-flex align-items-center">
                    <button class="btn-sm btn-outline-light" @click.prevent="goProfile(friend.username)">
                        {{ __('group.form.buttons.view_profile') }} <i class="feather icon-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>

        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results">{{ __('globals.no_contacts_found') }}</div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
    name: "ContactInfiniteScroll",
    components: {InfiniteLoading},
    props: ['user_id'],
    data() {
        return {
            friends: [],
            keyword: '',
            page: 1,
            user: window.App.user,
            infiniteId: +new Date(),
        }
    },
    methods: {
        infiniteHandler($state) {
            let vm = this;
            axios
                .get(this.getUrl())
                .then((response) => {
                    if (response.data.data.length > 0) {
                        $.each(response.data.data, function (key, value) {
                            vm.friends.push(value);
                        });
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getUrl() {
            return `/member/profiles/${this.user.username}/get-contacts?user_id=${this.user_id}&keyword=${this.keyword}&page=${this.page}`;
        },
        debounceInput: _.debounce(function (e) {
            this.keyword = e.target.value;
            this.friends = [];
            this.page = 1;
            this.infiniteHandler();
        }, 500),
        goProfile(username){
            window.location.href = "/member/profiles/"+username;
        },
    },

}
</script>

<style scoped>

</style>
