<template>
    <div class="d-flex justify-content-between" style="margin-bottom: 5px;">
        <div class="mr-05">
            <div v-if="user_data !== null">
                <img class="round" :src="(user_data !== null && user_data.avatar !== null) ? user_data.avatar.url : '/images/avatar.png'"
                     alt="avatar"
                     height="30"
                     width="30"/>
                </div>
        </div>

        <div class="p-0 justify-content-start">
            <div class="user-name text-bold-600 text-dark">
                <div class="dropdown dropdown-user nav-item">
                    <a class="dropdown-toggle dropdown-user-link" :href="postOwnerLink" data-toggle="dropdown" v-text="postOwnerName" @click="show_profile = true"></a>
                    <div v-if="show_profile" class="dropdown-menu dropdown-menu-center">
                        <profile :data="user_data"></profile>
                    </div>
                    <span v-if="user_data.roles[0].title === 'Admin'">
                        <img src="/images/badges/admin.png" alt="user role badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" title="Administrator">
                    </span>
                    <span v-for="badge in user_data.badges">
                        <img :src="badge.image" alt="user custom badge" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="badge.name">
                    </span>
                    <span v-if="user_data.user_badge_id !== null && user_data.user_badge_image != null">
                        <img :src="user_data.user_badge_image.url" alt="user plan badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="user_data.user_badge_name">
                    </span>
                </div>
            </div>
            <div  style="margin-top: -3px; line-height: 1;" class="mr-2">
                <span v-text="user_data.position" class="font-small-2 text-light"></span>
                <span class="font-small-2 text-light"> {{ __('globals.at') }} </span> <span v-text="user_data.organisation" class="font-small-2 text-light"></span>
            </div>
        </div>
    </div>
</template>

<script>
import Profile from "./Profile";
import moment from "moment-timezone";

export default {
    name: "CommentOwnerInfo",
    props: ['user_data'],
    components: {Profile},
    computed: {
        postOwnerName: function () {
            return this.user_data.first_name+' '+this.user_data.last_name

        },
        postOwnerLink: function () {
            return '#'
        },
    },
    data(){
        return {
            show_profile: false
        }
    }

}
</script>
