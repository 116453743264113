<template>
    <div></div>
</template>

<script>
    import Comments from '../components/comment/Comments.vue';
    import Follow from '../components/Follow.vue';

    export default {
        components: { Comments, Follow },
        name: 'Post'
    }
</script>
