<template>
    <div class="card pl-1 pr-1 pb-0 pt-2 h-100">
        <h4 class="border-bottom pb-05 mb-1">Register Stats</h4>

        <div v-if="data_updated">
            <apexchart width="100%" height="250" type="pie" :options="options" :series="series"></apexchart>
        </div>

    </div>
</template>

<script>
   // import {Apexcharts} from "../../../vendors/js/charts/apexcharts";
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: "RegisterStats",
        components: {VueApexCharts},
        props: ['data'],
        data() {
            return {
                search_from: null,
                search_to: null,
                data_updated: false,
                options: {
                    labels: [],
                    legend: {
                        position: 'bottom'
                    },
                },
                series: [],
            }
        },
        beforeMount() {
            this.options.labels = Object.keys(this.data);
            this.series = Object.values(this.data);
            this.data_updated = true;
        },
    }
</script>

<style scoped>

</style>
