<template>
    <div>
        <div v-if="sidebar === true ? 'class=\'col-12 m-auto\'' : 'class=\'col-12 col-md-5 m-auto\''">
            <div class="card">
                <a href="#"
                   class="btn btn-outline-white text-white btn-default waves-effect waves-light position-absolute m-05">
                    <i class="feather icon-arrow-left"></i> {{ __('meeting.form.buttons.back') }}
                </a>
                <div class="position-absolute float-right" style="right: 0.5rem; top: 0.5rem; v-if" v-if="has_edit_permission === true">
                    <a :href="'/member/zoom/'+data.module.slug+'/edit'"
                       class="btn btn-dark waves-effect waves-light">{{ __('meeting.form.buttons.edit') }} <i class="fa fa-wrench"></i>
                    </a>
                </div>
                <div class="shadow">

                    <img class="card-img-top img-fluid"
                         :src="data.module.media.length > 0 ? data.module.media[0].url : '/images/cover.jpg'"
                         style="object-fit: cover;">

                </div>
                <div class="card-content" v-if="data.module.description !== null">
                    <div class="card-body pt-05 pb-05">
                        <div class="d-flex">
                            <div>
                                <h1 class="font-large-1" v-text="data.module.title"></h1>
                                <h2 class="primary font-medium-3 pb-2"><span v-text="showDateTime"></span></h2>
                            </div>
                            <div class="ml-auto pt-1">
                                <div
                                    class=" badge badge-pill bg-gradient-primary"
                                    style=" padding: 0.2rem 0.5rem;">
                                    <i class="feather icon-video"></i> {{ __('meeting.title_singular') }}
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-12 col-md-8">
                                <h5 class="border-bottom pb-05 mb-1"><i class="feather icon-book-open"></i> {{ __('meeting.headers.admin.event_details') }}
                                </h5>

                                <div class="d-flex justify-content-start">
                                    <p><span class="user-name text-bold-600"><i
                                        class="fa fa-clock"></i> {{ __('meeting.fields.text.timezone') }}: </span><br>{{ data.module.timezone }}
                                    </p>
                                </div>
                                <p><span class="user-name text-bold-600"><i
                                    class="fas fa-file-alt"></i> {{ __('meeting.field.text.description') }}: </span><br>
                                    <span class="breakAll image-resize" v-html="data.module.description"></span>
                                </p>
                            </div>
                            <div class="col-12 col-md-4">
                                <h5 class="border-bottom pb-05 mb-1"><i class="feather icon-user-plus"></i> {{ __('meeting.headers.admin.event_organiser') }}
                                </h5>
                                <p><span class="user-name text-bold-600"><i
                                    class="fa fa-building"></i> {{ __('meeting.fields.text.organisation_name') }}: </span><br>{{ data.module.organisation_name }}
                                </p>
                                <p><span class="user-name text-bold-600"><i
                                    class="fa fa-user"></i> {{ __('globals.name') }}: </span><br>{{ data.module.contact_name }}</p>
                                <p>
                            <span class="user-name text-bold-600"><i
                                class="fa fa-phone"></i> {{ __('globals.phone') }}: </span><br>{{ data.module.contact_phone }}
                                </p>
                                <p>
                            <span class="user-name text-bold-600"><i
                                class="fa fa-envelope"></i> {{ __('globals.email') }}: </span><br>{{ data.module.contact_email }}
                                </p>
                            </div>
                        </div>

                        <div v-if="data.module.user_registered === false" class="d-flex pr-05 align-items-center border-top pt-1">
                           <div v-if="data.module.event_price !== null && data.module.event_price !== 0">
                                <div v-if="this.price_setting === 'plan-price'">
                                    <div class="text-bold-700">
                                        <span>
                                            <strong>{{ __('globals.form.paragraph.your_price') }}
                                                {{ data.module.event_price | currency(site_tax) }}
                                            </strong>
                                        </span>
                                    </div>
                                </div>
                                <div v-else-if="price_setting === 'one-price' && data.module.event_price !== null">
                                    <span>
                                        <strong>{{ __('globals.form.paragraph.your_price') }}
                                            {{ data.module.event_price | currency(site_tax) }}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                            <div class="ml-auto">
                                <span v-if="data.module.event_price  !==  null && data.module.event_price !== 0">

                                    <div class="">
                                        <a :href="'/member/zoom/'+data.module.zoom_id+'/add_to_cart'"
                                                               class="download-button btn btn-primary waves-effect waves-light form-control"><i
                                                                class="fa fa-dollar"></i> {{ __('meeting.form.buttons.buy_now') }} </a>
                                    </div>
                                </span>
                                <span v-if="data.module.event_price === 0 || data.module.event_price === null">
                                            <a :href="'/member/zoom/'+data.module.zoom_id+'/register'"
                                               class="download-button btn btn-primary waves-effect waves-light form-control"><i
                                                class="fa fa-calendar"></i> {{ __('meeting.form.buttons.register_now') }} </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="d-flex border-top justify-content-between">
                    <div class="font-small-3 text-bold-700">
                        <likes :id="this.data.module.zoom_id" :likes-count="this.data.likesCount" :is-liked="this.data.isLiked" :liked-by-users="this.data.userLikes" :type="this.data.type"></likes>
                    </div>
                    <div class="font-small-3 text-bold-700">
                            <a class="p-05 d-block" @click.prevent="commentsShow = !commentsShow"><i
                               class="fa fa-comment-o"></i>
                                <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                                <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                            </a>
                    </div>
                    <div class="font-small-3 text-bold-700">
                        <social-share :id="this.data.id" :social="this.data.module.socialShare" @shareModal="triggerShareModal"></social-share>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="sidebar === false">
            <div class="mr-auto ml-1 mb-1">
                <button class="btn btn-outline-danger" @click.prevent="report">{{ __('globals.report') }} </button>
            </div>
        </div>

        <div v-if="commentsShow">
            <comments :newsfeed_uuid="this.data.uuid" :id="this.data.module.zoom_id" :type="this.data.type" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <share-modal :original_newsfeed_id="this.data.id" :module="this.data" type="zoom" @closeModal="share_newsfeed = false" ></share-modal>
        </div>
    </div>
</template>

<script>

import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)
import Share from '../../mixins/newsfeed/Share'
import ZoomMeeting from "../../mixins/newsfeed/ZoomMeeting";

import Likes from "../newsfeed/components/Likes.vue";
import Comments from "../newsfeed/components/Comments.vue";
import ShareModal from "../newsfeed/components/ShareModal.vue";

export default {
    name: "Zoom",
    props: ['data', 'section', 'has_edit_permission'],
    components: {Likes, Comments, ShareModal},
    mixins: [
        Share, ZoomMeeting
    ],
    data() {
        return {
            commentsShow: false,
            commentsCount: this.data.commentsCount,
            share_newsfeed: false,
            sidebar: this.section === 'sidebar',
        }
    },
    methods: {
        updateCommentsCount(count){
                this.commentsCount = count;
        },
        triggerShareModal(){
                this.share_newsfeed = !this.share_newsfeed;
        }
    },
    computed: {
    },
    mounted() {
    }
}
</script>
