<template>
    <div>

      <div class="container">
        <div class="row">
          <div class="col-6">
            <h4>Behavioural Triggered Emails</h4>
            <p>When a user performs a specific action they will be sent an email.</p>
          </div>
          <div class="col-6">
            <div class="w-100 mb-1">
              <div class="d-flex align-items-center">
                <div class="ml-auto mb-05">
                  <a class="btn btn-success ml-05" href="/admin/email-behavioural-trigger-create"><i class="feather icon-plus"></i>Create Behavioural Trigger Email</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div class="panel-body card p-1">

            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Enabled</th>
                    <th scope="col">Event</th>
                    <th scope="col">Filter</th>
                    <th scope="col">Delay Mins</th>
                    <th scope="col">Last Sent</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="behavioural_trigger in available_behavioural_triggers">
                        <th scope="row">{{ behavioural_trigger.name ? behavioural_trigger.name : 'empty' }}</th>
                        <td>{{ behavioural_trigger.enabled ? 'Yes' : 'No' }}</td>
                        <td>{{ behavioural_trigger.trigger && behavioural_trigger.trigger.name ? behavioural_trigger.trigger.name : 'empty' }}</td>
                        <td>{{ behavioural_trigger.filter && behavioural_trigger.filter.name ? behavioural_trigger.filter.name : 'empty'}}</td>
                        <td>{{ behavioural_trigger.delay }}</td>
                        <td>{{ !behavioural_trigger.last_executed ? 'Never' : behavioural_trigger.last_executed }}</td>
                        <td>
                            <a :href="'/admin/email-behavioural-trigger-edit/' + behavioural_trigger.id">
                                <button type="button" class="btn-sm btn-outline-warning">EDIT</button>
                            </a>
                            <button type="button" class="btn-sm btn-outline-danger" @click.prevent="deleteBehaviouralTrigger(behavioural_trigger.id)">DELETE</button>
                        </td>
                    </tr>

                    <tr v-if="!available_behavioural_triggers || available_behavioural_triggers.length === 0">
                        <th>-- No Behavioural Triggers --</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>



<script>
export default {
    name: "EmailMarketingBehaviouralTriggerIndex",

    data() {
        return {
            available_behavioural_triggers: [],
        }
    },
    methods: {

        init(){
            axios.post('/admin/email-behavioural-trigger-index-init')
            .then(response => {
                this.available_behavioural_triggers = response.data.data.available_behavioural_triggers;
                console.log(this.available_behavioural_triggers)
            });
        },

        deleteBehaviouralTrigger(id){
            if(confirm('Are you sure you want to delete this marketing email?')){
                axios.post('/admin/email-behavioural-trigger-index-delete', {
                    'email_behavioural_trigger_id': id,
                })
                .then(response => {
                    this.init();
                });
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>
