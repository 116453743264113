<template>

    <span class="pt-1">
        <h4 class="border-bottom pb-05 mb-1"><i class="feather icon-tag"></i> {{ __('event.tickets.headers.event_tickets')}}</h4>

            <p>{{ __('event.tickets.sell') }}</p>
            <details v-for="(ticket,i) in tickets"
                     :key="i">
                <summary>
                    Ticket: {{ ticket.title }}
                </summary>
                <div class="form-group">
                    <label class="required" for="ticket_title">Ticket title</label>
                    <input class="form-control" v-model="ticket.title" type="text" name="ticket_title[]" id="ticket_title" required>
                </div>
                <div class="form-group">
                    <label class="required" for="ticket_price">{{ __('event.tickets.fields.text.ticket_price') }}</label>
                    <input class="form-control"
                           type="number"
                           name="ticket_price[]"
                           id="ticket_price"
                           min="0"
                           oninput="validity.valid||(value='');"
                           v-model="ticket.price" required>
                </div>

                <div class="form-group">
                    <label for="ticket_description" class="required">Ticket Description</label>
                    <textarea class="form-control" name="ticket_description[]"
                              id="ticket_description" v-model="ticket.description" required></textarea>
                </div>

                <div class="form-group">
                    <label class="required" for="ticket_qty">Ticket Qty</label>
                    <input class="form-control"
                           step="1"
                           type="number"
                           name="ticket_qty[]"
                           min="0"
                           oninput="validity.valid||(value='');"
                           id="ticket_qty"
                           v-model="ticket.qty" required>
                </div>

                <div v-if="ticket.id">
                    <input type="hidden" name="ticket_id[]" :value="ticket.id">
                </div>


                <a href="#" class="btn btn-danger" @click="removeTicket(i, ticket.id ? ticket.id : 0)">{{ __('event.tickets.form.links.remove_ticket') }}</a>
            </details>

            <div>
                <a href="#" class="btn btn-primary" @click="addNewTicket">{{ __('event.tickets.form.buttons.add_ticket')}}</a>
            </div>

        <h4 class="border-bottom pb-05 mb-1 pt-2"><i class="feather icon-link"></i> {{ __('event.link_external_site')}}</h4>
            <div class="form-group">
                <label for="contact_link">{{ __('event.link_external_site_paragraph')}}</label>
                <input type="text" class="form-control" name="contact_link" id="contact_link" :value="event !== undefined ? event.contact_link : ''">
                <div class="help-block text-muted font-small-1">{{ __('event.link_external_site_helper')}}</div>
            </div>
        <div class="d-flex pt-2 pb-1">
            <div class="mr-auto form-group pb-0 mb-0">
                <button @click="scrollToTop" class="btn btn-primary ml-auto js-btn-prev" type="button"
                        title="Create Account"><i
                    class="fa fa-chevron-left"></i> {{ __('globals_previous_step')}}
                </button>
            </div>
            <div class="form-group pb-0 mb-0">
                <button @click="scrollToTop" class="btn btn-primary ml-auto js-btn-next" type="button"
                        title="Create Account">{{ __('globals.next_step')}} <i
                    class="fa fa-chevron-right"></i></button>
            </div>
        </div>
    </span>
</template>

<script>

    export default {
        props: ['data'],
        data() {
            return {
                ticket: {
                    title: '',
                    price: '',
                    description: '',
                    qty: ''
                },
                tickets: this.data !== undefined ? this.data.tickets : [],
                title: '',
                event: this.data,
            }

        },
        mounted() {
            if (this.data !== undefined){
                // convert price as price/100 , when fetching from db
                this.data.tickets.map( function(ticket){
                    return ticket.price = ticket.price/100;
                });
            }

        },
        methods: {
            scrollToTop: function() {
                window.scrollTo(0,0);
            },
            addNewTicket: function () {
                this.tickets.push({
                    title: '',
                    price: '',
                    description: '',
                    qty: ''
                })
            },
            removeTicket: function (index, ticket_id) {
                if (ticket_id !== 0) {
                    if (confirm('Are you sure')) {
                        axios.delete('/member/tickets/' + ticket_id)
                        this.tickets.splice(index, 1);
                    }
                }
            },
        }
    }

</script>

<style scoped>
    details {
        display: block;
        margin: 10px 0;
        border: 1px solid #ccc;
        padding: 1rem;
        border-radius: .5rem;
    }

    summary {
        display: block;
        background: #CCCCCC;
        color: white;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 5px;
        cursor: pointer;
        font-weight: bold;
    }

    summary::-webkit-details-marker {
        color: #FF0000;
        background: #FFFFFF;
    }

    details[open] summary::-webkit-details-marker {
        color: #0000FF;
        background: #00FFFF;
    }

    summary::-webkit-details-marker {
        display: none
    }

    summary:after {
        content: "+";
        color: #FFFFFF;
        float: left;
        font-size: 1.5em;
        font-weight: bold;
        margin: -5px 5px 0 0;
        padding: 0;
        text-align: center;
        width: 20px;
    }

    details[open] summary:after {
        content: "-";
        color: #FFFFFF
    }
</style>
