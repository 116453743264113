<template>
    <div>
        <div class="card-content">
                <div :id="'gallery-'+this.data.id" class="card-img-top img-fluid">
                    <img class=" img-fluid"
                         :src="this.data.event_image === null ? '/images/cover.jpg' : this.data.event_image.url">
                </div>
                <div class="card-body p-0">

                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                            <span class="h3"><a :href="'/event/'+this.data.slug" v-text="this.data.title"
                                                style="color:inherit;"></a></span>


                    </div>
                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 align-items-center justify-content-start">
                            <span v-if="this.data.event_privacy === 1"
                                  class="badge badge-pill bg-gradient-secondary font-small-2 text-bold-700 mb-05 mr-05"
                                  style="padding: 0.2rem 0.5rem;"><i class="feather icon-award"></i> {{ __('globals.premium_only') }}</span>
                        <span v-if="this.data.sold_out == 1"
                              class="badge font-small-2 text-bold-700 badge-pill badge-danger mb-05"
                              style="padding: 0.2rem 0.5rem;"><i class="feather icon-alert-circle"></i> {{ __('event.sold_out') }}</span>
                    </div>
                    <div class="d-flex ml-1 mr-1 mt-0">
                        <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span
                        class="h6 primary" v-text="this.data.event_start_end_date"></span>

                    </div>
                    <div class="d-flex ml-1 mt-0 mr-1 align-items-center justify-content-start text-light font-small-2">
                        <i class="fa fa-map-marker"></i> <span class="pl-05" v-text="this.data.location"></span>

                    </div>
                    <div class=" m-05 pt-1 pl-1 pr-1 border-top">
                        <div class="breakAll mb-0 image-resize" v-linkified
                             v-html="this.data.event_description"></div>
                        <div><a :href="'/event/'+this.data.slug" class="font-small-3">{{ __('globals.read_more') }} <i
                            class="feather icon-chevron-right"></i></a></div>

                    </div>


                    <div v-if="this.data.tickets.length > 0" class="d-flex ml-05 mr-05  mb-0 pb-05 pt-05 border-top align-items-center justify-content-start">
                        <div  v-if="this.data.price_setting === 'plan-price'">{{ __('globals.from') }} {{ this.prices_array[this.lowest_plan_price_id] | currency(site_tax) }} {{ __('globals.on_the') }} {{ this.plans[this.lowest_plan_price_id] }} {{ __('globals.plan') }} </div>
                        <div v-else-if="this.data.price_setting === 'one-price'">{{ this.data.event_price | currency(site_tax) }}</div>
                    </div>
                    <div class="ml-05 mr-05  mb-0 pb-05 pt-05 align-items-center justify-content-start">
                        <div class="d-flex justify-content-end" v-if="this.data.event_privacy === 0">
                            <div>
                                <a :href="'/event/'+this.data.slug"
                                   class="btn btn-sm btn-flat-primary text-primary text-bold-700 waves-effect waves-light">{{ __('globals.view_event_page') }} <i
                                        class="feather icon-chevron-right"></i>
                                </a>
                            </div>
                            <div v-if="this.data.contact_link != null" class="ml-05">
                                <a :href="this.data.contact_link" style="color: #fff;" target="_blank"
                                   class="btn btn-sm btn-primary waves-effect waves-light">{{ __('event.form.buttons.view_event_site') }} <i
                                    class="feather icon-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment-timezone";
    export default {
        name: "GuestNewsfeedEvent",
        props: ['data', 'plans'],
        data() {
            return {
                prices_array: [],
                site_tax: window.App.site_tax
            }
        },
        computed: {
            },
            methods: {
                init() {
                    if (this.data.price_setting === 'one-price' && this.data.tickets.length > 0) {
                        let ticketsWithPrice = this.data.tickets.filter(function(ticket){
                            return ticket.price !== null
                        })
                        return ticketsWithPrice.length > 0
                    }

                    if (this.data.price_setting === 'plan-price' && this.data.tickets.length > 0) {
                        this.data.tickets.forEach(ticket => {
                            ticket.ticket_plan_prices.forEach(plan_price => {
                                if(this.prices_array[plan_price.plan_id] == undefined){
                                     this.prices_array[plan_price.plan_id] = plan_price.price;
                                } else {
                                     if(this.prices_array[plan_price.plan_id] > plan_price.price){
                                         this.prices_array[plan_price.plan_id] = plan_price.price;
                                     }
                                }
                            })
                        })
                        let keysSorted = Object.keys(this.prices_array).sort((a,b) => {return this.prices_array[a]-this.prices_array[b]})
                        this.lowest_plan_price_id = keysSorted[0];
                    }
                },
            },
            beforeMount() {
                this.init();
            }
    }
</script>

<style scoped>

</style>
