<template>
    <div id="child-comment">
        <div v-for="(comment, index) in items"  :key="comment.id">
            <child-comment :data="comment" @deleted="remove(index)"></child-comment>
        </div>
        <infinite-loading @infinite="infiniteHandler">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
        </infinite-loading>
        <new-child-comment :comment_id="comment_id" :type="this.parent_type" @created="add"></new-child-comment>
    </div>
</template>

<script>
    import ChildComment from './ChildComment';
    import NewChildComment from './NewChildComment';
    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        props:['comment_id', 'parent_type'],
        components: { ChildComment, NewChildComment, InfiniteLoading},
        name: 'ChildComments',
        data() {
            return {
                items: [],
                type: 'comment',
                are_you_sure_message: this.__('globals.are_you_sure'),
                user: window.App.user,
                page: 1,
            }
        },
        methods : {
            infiniteHandler($state){
                this.init($state);
            },
            init($state){
                let vm = this;
                axios
                    .get('/member/comment/'+this.comment_id+'/comments'+"?page="+this.page)
                    .then(response => {
                        if(response.data.data.length >0) {
                            $.each(response.data.data, function (key, value) {
                                 if(!Array.isArray(value)) {
                                     vm.items.push(value);
                                 }
                            });
                            $state.loaded();
                        } else {
                            this.$emit('length', this.items.length);
                            $state.complete();
                        }
                    });
                vm.page++;
            },
            add(comment) {
                this.items.push(comment);
                this.$emit('repliesLength', this.items.length);
            },
            remove(index) {
                if (confirm(this.are_you_sure_message)){
                    this.items.splice(index, 1);
                    this.$emit('repliesLength', this.items.length);
                }
            }
        },
        beforeMount(){
            this.init()
        }
    }
</script>

<style scoped>

</style>
