<template>
    <div class="row">
        <div class="col-12">
            <div class="card bg-gradient-success p-1">
                <div class="row align-items-center">
                    <div class="col-12 col-md-3">
                        <img src="/images/trybz.png" height="60" alt="Logo">
                    </div>
                    <div class="col-12 col-md-7">
                        <h4 class="text-white mb-0">{{ __('advertisement.headers.like') }}</h4>
                        <p class="mb-0 font-small-3">{{ __('advertisement.headers.like_paragraph') }}</p>
                    </div>
                    <div class="col-12 col-md-2">
                        <a href="https://trybz.com.au" target="_blank">
                            <button class="btn btn-white float-right">{{ __('globals.find_out_more') }}</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>
