<template>

    <div class="col-xl-4 col-md-6 col-sm-12 profile-card-2">
        <div class="card shadow-none" style="width: 240px; border: none;" >
            <div class="card-header mx-auto pb-0">
                <div class="row m-0">
                    <div class="col-sm-12 text-center">
                        <div class="avatar avatar-xl">
                            <img class="img-fluid"
                                 :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                                 alt="">
                        </div>
                        <a :href="'/member/profiles/'+data.module.owner.username">
                            <h4>
                                <span v-text="data.module.owner.first_name+' '+data.module.owner.last_name"></span>
                                <img
                                    v-if="data.module.owner.roles.hasOwnProperty('title') && data.module.owner.roles[0].title === 'Admin'"
                                    src="/images/badges/admin.png"
                                    style="margin-top: -5px;" data-toggle="tooltip" data-placement="bottom"
                                    title="Administrator">
                            </h4>
                        </a>
                    </div>
                    <div class="col-sm-12 text-center">
                        <div style="margin-top: -5px;"><span v-text="data.module.owner.position" class="font-small-2 text-light"></span>
                            <span class="font-small-2 text-light"> at </span><span v-text="data.module.owner.organisation" class="font-small-2 text-light"></span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card-content">
                <div class="card-body text-center mx-auto p-0">

                    <div class="d-flex justify-content-between mt-2">

                        <div class="followers">
                            <p class="font-weight-bold font-medium-2 mb-0" v-text="followers"></p>
                            <span class="">{{ __('globals.contacts') }}</span>
                        </div>
                        <div>
                          <follow :data="data.module.owner"></follow>

                        </div>
                    </div>
                    <div class="mt-05">
                    <a :href="'/member/profiles/'+data.module.owner.username">
                        <button class="btn btn-sm btn-outline-light mt-1">{{ __('globals.view_profile_button') }} <i
                            class="feather icon-chevron-right"></i></button>
                    </a>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Profile",
        props: ['data'],
        data() {
            return {
                followers: 0,
                following: 0,
                followingOwner: '',
                username: this.data.module.owner.username
            }
        },
        methods: {
            showFollowers() {
                axios
                    .get('/member/user/followers/' + this.username)
                    .then(response => {
                        this.followers = response.data.followers;
                    });
            },
            showFollowing() {
                axios
                    .get('/member/user/following/' + this.username)
                    .then(response => {
                        this.following = response.data.following;
                    });
            },
        },
        beforeMount() {
            this.showFollowers();
            this.showFollowing();
        }
    }
</script>

<style scoped>

</style>
