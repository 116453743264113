<template>
    <div class="p-1 m-1 border shadow rounded col-10" id="new-crud">
        <form @submit="checkForm">
            <div class="text-danger" v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </div>
            <div class="row">
                <div class="form-group col-4">
                    <label for="sectionName">Name Of Section</label>
                    <input type="text" class="form-control" v-model="newSection.section_name" id="sectionName">
                </div>
                <div class="form-group col-4">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" v-model="newSection.title" id="title">
                </div>
                <div class="form-group col-4">
                    <label for="singularTitle">Singular Title</label>
                    <input type="text" class="form-control" v-model="newSection.title_singular" id="singularTitle">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <a
                        @click="addNewFields()"
                        class="btn btn-success waves-effect waves-light text-white"><i class="feather icon-plus"></i>
                        Add New Fields
                    </a>
                    <div class="new-fields">
                        <div v-for="(field, key) in newSection.fields" :key="key" class="d-flex">
                            <div class="form-group mr-1 align-self-center">
                                <label for="newFieldname">Field Name</label>
                                <input type="text" class="form-control" v-model="field.fld" id="newFieldname">
                            </div>
                            <div class="form-group mr-1 align-self-center">
                                <label for="newFieldText">Field Text</label>
                                <input type="text" class="form-control"
                                       v-model="field.fld_text"
                                       id="newFieldText">
                            </div>
                            <div class="form-group flex-fill mr-1 align-self-center">
                                <label for="newFieldHelper">Field Helper Text</label>
                                <input type="text" class="form-control"
                                       v-model="field.fld_helper"
                                       id="newFieldHelper">
                            </div>
                            <div class="form-group pb-05 align-self-end">
                                <div class="pl-05 action-btns " style="width: 60px;">
                                    <a @click="removeField(key)" class="btn-sm btn-danger text-white">Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <button type="submit" class="mt-05 btn btn-primary">Save</button>
            <a
                @click="addNewFields()"
                class="btn mt-05 btn-success waves-effect waves-light text-white"><i class="feather icon-plus"></i>
                Add New Fields
            </a>
        </form>
    </div>
</template>

<script>
    export default {
        name: 'NewCrudLanguage',
        props: ['data'],
        data() {
            return {
                errors: [],
                newField: {
                    fld : null,
                    fld_text: null,
                    fld_helper: null,
                },
                newSection : {
                    section_name: null,
                    title: null,
                    title_singular: null,
                    fields: [
                        {
                            fld : null,
                            fld_text: null,
                            fld_helper: null,
                        }
                    ]
                }
            }
        },
        mounted() {
          if (this.data) {
              this.newSection = this.data;
          }
        },
        methods: {
            checkForm: function (e) {
                e.preventDefault();
                this.errors = [];
                if (!this.newSection.title) {
                    this.errors.push('Title is required.');
                }
                if (!this.newSection.title_singular) {
                    this.errors.push('Title singular required.');
                }
                if (!this.newSection.section_name) {
                    this.errors.push('Section name is required.');
                }

                if (this.errors.length === 0) {
                    this.saveNewCrud();
                }

            },
            saveNewCrud() {
                axios.post('/admin/language-cruds', {
                    crud : this.newSection
                }).then(response => {
                    if (response.data) {
                        alert(response.data.message);
                        this.$parent.fetchCrudLanguages();
                    }
                });
            },
            addNewFields() {
                this.newSection.fields.push({
                    fld : null,
                    fld_text: null,
                    fld_helper: null,
                });
            },
            removeField(key) {
                this.newSection.fields.splice(key, 1);
            }
        }

    }
</script>
