<template>
    <div>
        <div class="card">
            <div v-if="data.newsfeed_type === 'share'">

                <!-- This is for shared Newsfeed -->
                <div class="d-flex justify-content-between border-bottom">
                    <div class="flex-grow-1">
                        <newsfeed-owner-info
                                :user_data="data.owner"
                                :supplier_data="null"
                                :shared="true"
                                :type="data.type"
                                :time="data.published_at">
                        </newsfeed-owner-info>
                    </div>

                    <div class="btn-group mt-05 mr-05">
                        <div class="dropdown">
                                <button class="btn-sm btn-outline-light dropdown-toggle " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                                    <i class="fa fa-chevron-circle-down mr-0"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                                    <span >
                                        <a class="dropdown-item" href="#" @click.prevent="editPost">
                                            <i class="feather icon-settings"></i>{{ __('post.form.buttons.edit_post') }}
                                        </a>
                                    </span>
                                    <span>
                                        <a class="dropdown-item" href="#" @click.prevent="deleteNewsfeed">
                                            <i class="feather icon-trash-2"></i>{{ __('post.form.buttons.delete_post') }}
                                        </a>
                                    </span>
                                    <a class="dropdown-item danger" @click.prevent="reportNewsfeed">
                                            <i class="feather icon-alert-triangle"></i>{{ __('post.form.buttons.report_post') }}
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>

                <div class="mb-05">
                    <div class="pl-05 pr-05 mt-05">
                        <div v-if="editing" class="form-group pr-05 pl-05">
                            <div class="mb-05 mx-05">
                                <div class="card my-0">
                                    <editor
                                        v-model="shared_text"
                                        api-key="no-api-key"
                                        :init='{
                                            skin: "trybz-member",
                                            skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                            content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                            external_plugins: {
                                                customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                                loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                            },
                                            valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                            plugins: "link quickbars autolink lists customtags loadingbar",
                                            link_context_toolbar: true,
                                            quickbars_selection_toolbar: "bold italic link bullist",
                                            quickbars_image_toolbar: false,
                                            quickbars_insert_toolbar: false,
                                            link_assume_external_targets: "https",
                                            link_target_list: false,
                                            link_title: false,
                                            toolbar: false,
                                            branding: false,
                                            elementpath: false,
                                            menubar: false,
                                            file_picker_types: "",
                                            height: 100,
                                            content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                            relative_urls : false,
                                            remove_script_host : false,
                                        }'
                                    />
                                </div>
                            </div>
                            <button class="btn btn-sm btn-primary" @click.prevent="updateSharedNewsfeed">{{ __('globals.update') }}</button>
                            <button class="btn btn-sm btn-link" @click.prevent="cancelEditing">{{ __('globals.cancel') }}</button>
                        </div>
                    </div>
                    <div v-if="editing === false" class="breakAll mt-1 mr-1 ml-1" v-linkified v-html="body">
                    </div>
                </div>
            </div>

            <div class="card mb-1 mr-1 ml-1">
                <div v-if="data.original_newsfeed !== null && data.original_newsfeed.owner !== null">
                     <div class="d-flex justify-content-start border-bottom pr-05">
                        <newsfeed-owner-info
                            :user_data="data.original_newsfeed.owner"
                            :profile_data="data.original_newsfeed.profile"
                            :supplier_data="data.original_newsfeed.supplier"
                            :shared="false"
                            :time="data.original_newsfeed.published_at"
                        />
                    </div>

                    <div v-if="data.original_newsfeed.module !== null">
                        <div v-if="data.module_type === 'App\\Models\\Post'">
                            <newsfeed-shared-post :data="data.original_newsfeed.module" />
                        </div>

                        <div v-if="data.module_type === 'App\\Models\\Video'">
                            <newsfeed-shared-video :data="data.original_newsfeed.module" />
                        </div>

                        <div v-if="data.module_type === 'App\\Models\\Resource'">
                            <newsfeed-shared-resource :data="data.original_newsfeed.module" />
                        </div>

                        <div v-if="data.module_type === 'App\\Models\\Event'">
                            <newsfeed-shared-event :data="data.original_newsfeed.module" :plans="plans" />
                        </div>

                        <div v-if="data.module_type === 'App\\Models\\ZoomWebinar'">
                            <newsfeed-shared-zoom-webinar :data="data.original_newsfeed.module" :plans="plans" />
                        </div>

                        <div v-if="data.module_type === 'App\\Models\\Zoom'">
                            <newsfeed-shared-zoom :data="data.original_newsfeed.module" />
                        </div>

                        <div v-if="data.module_type === 'App\\Models\\Job'">
                            <newsfeed-shared-job :data="data.original_newsfeed.module" />
                        </div>

                        <div v-if="data.module_type === 'App\\Models\\Product'">
                            <newsfeed-shared-product :data="data.original_newsfeed.module" />
                        </div>
                    </div>

                    <div v-else>
                        <div class="text-center m-1 p-1 bg-outline-gray">{{ __('newsfeed.deleted_post') }}</div>
                    </div>

                    <!-- This is for the group or supplier of the original newsfeed if it exists -->
                        <div class="p-05" v-if="data.original_newsfeed.group != null">
                            <a :href="'/member/group/'+data.original_newsfeed.group.slug">
                                <div class="card text-white mb-0 border-0">
                                    <img class="card-img img-fluid" style="height: 3rem;" :src="data.original_newsfeed.group.group_cover == null ? '/images/cover.jpg' : data.original_newsfeed.group.group_cover.url">
                                    <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                        <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                            {{ data.original_newsfeed.group.title }}</p>
                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-05" v-if="data.original_newsfeed.supplier != null">
                            <a :href="'/member/supplier/'+data.original_newsfeed.supplier.slug">
                                <div class="card text-white mb-0 border-0">
                                    <img class="card-img img-fluid" style="height: 3rem;"
                                         :src="data.original_newsfeed.supplier.supplier_cover_image == null ? '/images/cover.jpg' : data.original_newsfeed.supplier.supplier_cover_image.url">
                                    <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                        <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ data.original_newsfeed.supplier.title }}</p>
                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                                    </div>
                                </div>
                            </a>
                        </div>
                </div>
                <div v-else>
                    <div class="text-center m-1 p-1 bg-outline-gray">{{ __('newsfeed.deleted_owner') }}</div>
                </div>
            </div>

            <div v-if="data.newsfeed_type === 'share'" >
                <!-- Details of the group and supplier where the newsfeed has been shared -->
                <div class="pl-1 pr-1 pb-05" v-if="data.group !== null && section !== 'group'">
                    <a :href="'/member/group/'+data.group.slug">
                        <div class="card text-white mb-0 border-0">
                            <img class="card-img img-fluid" style="height: 3rem;" :src="data.group.group_cover == null ? '/images/cover.jpg' : data.group.group_cover.url">
                            <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                        {{ data.group.title }}</p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="pl-1 pr-1 pb-05" v-if="data.supplier !== null && section !== 'supplier'">
                    <a :href="'/member/supplier/'+data.supplier.slug">
                        <div class="card text-white mb-0 border-0">
                            <img class="card-img img-fluid" style="height: 3rem;" :src="data.supplier.supplier_cover_image == null ? '/images/cover.jpg' : data.supplier.supplier_cover_image.url">
                            <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                    {{ data.supplier.title }}</p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div v-if="!(data.module_type === 'App\\Models\\Stack\\Question') && data.original_newsfeed !== null && data.original_newsfeed.module !== null" class="d-flex justify-content-between border-top pl-05 pr-05">
                <div class="font-small-3 text-bold-700">
                     <likes :id="data.uuid" :likes-count="data.likesCount" :is-liked="data.isLiked" :liked-by-users="data.userLikes" type="newsfeed"></likes>
                </div>
                <div class="font-small-3 text-bold-700">
                    <a class="d-block p-05 comment-button" @click.prevent="commentsShow = !commentsShow"><i
                       class="fa fa-comment-o"></i>
                        <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                        <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                    </a>
                </div>
                <div class="font-small-3 text-bold-700" v-if="data.module_type !== 'App\\Models\\Stack\\Question'">
                    <social-share :id="data.id" :social="data.module.socialShare" @shareModal="triggerShareModal"></social-share>
                </div>
            </div>
        </div>
        <div v-if="commentsShow">
            <comments :newsfeed_uuid="data.uuid" type="newsfeed" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <newsfeed-share-modal :original_newsfeed_id="data.original_newsfeed_id" :data="data.original_newsfeed" :type="this.data.type" @closeModal="share_newsfeed = false" ></newsfeed-share-modal>
        </div>
    </div>
</template>

<script>

    import Comments from '../components/Comments';
    import SharedFunctions from "../../../mixins/newsfeed/SharedFunctions";
    import NewsfeedShareModal from "../components/NewsfeedShareModal.vue";

    export default {
        name: "SharedNewsfeed",
        props: ['data', 'section', 'social', 'plans'],
        components: {Comments, NewsfeedShareModal},
        mixins: [
            SharedFunctions
        ],
        data(){
            return {
            }
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
