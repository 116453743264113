<template>
    <div>
        <video ref="videoPlayer" class="video-js" @error="handleVideoError"></video>
    </div>
</template>

<script>
    import videojs from 'video.js';

    export default {
        name: "VideoPlayer",
        props: {
            options: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        data() {
            return {
                player: null
            }
        },
        mounted() {
            this.initVideoPlayer();
        },
        methods: {
            initVideoPlayer() {
                this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {});
                this.player.on('error', this.handleVideoError);
            },
            handleVideoError() {
                this.player.src({ type: "video/mp4", src: "/videos/pending.mp4" });
            },
        },
        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        }
    }
</script>
