<template>
    <div :class="'d-flex justify-content-end p-05 '+ displayStatus" style="width: 100%">
        <div class="pr-05">
            <button class="btn btn-success waves-effect waves-light white" @click.prevent="acceptInvite()">
                <i class="fas fa-sign-in-alt"></i> Accept Invite
            </button>
        </div>
        <div class="pr-05">
            <button class="btn btn-danger waves-effect waves-light white" @click.prevent="rejectInvite()">
                <i class="fas fa-times"></i> Reject Invite
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AcceptRejectInvite",
    props: ['type', 'slug'],
    data() {
        return {
            user: window.App.user,
            displayStatus: '',
        }
    },
    methods: {
        acceptInvite() {
            axios
                .post(`/member/${this.type}/${this.slug}/accept/${this.user.username}`)
                .then((response) => {
                    let flash_data = {
                        'message': response.data.message,
                        'level': response.data.status
                    }
                    flash(flash_data);
                })
                .catch((error) => {
                    let flash_data = {
                        'message': 'An Error Occurred',
                        'level': 'danger'
                    }
                    flash(flash_data);
                })
            this.displayStatus = 'hidden';
        },
        rejectInvite() {
            axios
                .delete(`/member/${this.type}/${this.slug}/reject/${this.user.username}`)
                .then((response) => {
                    let flash_data = {
                        'message': response.data.message,
                        'level': response.data.status
                    }
                    flash(flash_data);
                })
                .catch((error) => {
                    let flash_data = {
                        'message': 'An Error Occurred',
                        'level': 'danger'
                    }
                    flash(flash_data);
                })
            this.displayStatus = 'hidden';
        }
    },
}
</script>

<style scoped>

</style>
