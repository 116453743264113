<template>
    <div class="modal fade" ref="shareModal" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                         <h5 class="modal-title" id="exampleModalLabel">{{ __('post.share_post') }}</h5>
                        <button type="button" class="close" @click.prevent="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body form-group">
                        <div class="mb-05">
                            <label for="comment_body" class="required d-block">{{ __('globals.enter_comment') }}</label>
                            <div id="loader" class="card p-1">
                                <div class="spinner-border spinner-border-sm text-secondary m-auto" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div id="editor" class="card my-0 d-none">
                                <div>
                                    <editor
                                        v-model="comment"
                                        api-key="no-api-key"
                                        :init='{
                                            skin: "trybz-member",
                                            skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                            content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                            external_plugins: {
                                                customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                                loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                            },
                                            valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                            plugins: "link quickbars autolink lists customtags loadingbar autoresize",
                                            link_context_toolbar: true,
                                            quickbars_selection_toolbar: "bold italic link bullist",
                                            quickbars_image_toolbar: false,
                                            quickbars_insert_toolbar: false,
                                            link_assume_external_targets: "https",
                                            link_target_list: false,
                                            link_title: false,
                                            toolbar: false,
                                            branding: false,
                                            elementpath: false,
                                            menubar: false,
                                            max_height: 300,
                                            file_picker_types: "",
                                            content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                            relative_urls : false,
                                            remove_script_host : false,
                                            init_instance_callback: this.loader,
                                        }'
                                    />
                                </div>
                            </div>
                            <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.comment != null">
                                <i class="feather icon-x-circle"></i> {{this.errors.comment[0]}}
                            </div>
                        </div>
                         <div class="mb-05 w-100">
                             <label class="required">{{ __('globals.select_share') }}</label>
                             <select :id='"service"+this._uid' class="form-control" v-model="service">
                                 <option value="profile" selected>{{ __('globals.your_newsfeed') }}</option>
                                 <optgroup :label="__('globals.share_group')">
                                    <option :value="'group_'+group.id" v-for="group in groups_followed">{{group.title}}</option>
                                 </optgroup>
                                 <optgroup :label="__('globals.share_supplier')">
                                    <option :value="'supplier_'+supplier.id" v-for="supplier in suppliers_followed">{{supplier.title}}</option>
                                 </optgroup>
                             </select>
                             <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.service != null">
                                <i class="feather icon-x-circle"></i> {{this.errors.service[0]}}
                             </div>
                        </div>
                        <div class="card border-light" style="pointer-events: none;">
                            <div class="border-bottom pr-05">
                                <newsfeed-owner-info
                                   :user_data="this.module.owner"
                                   :profile_data="null"
                                   :supplier_data="this.module.supplier !== null ? this.module.supplier : null"
                                   :shared="false"
                                   :time="this.module.published_at">
                                </newsfeed-owner-info>
                            </div>
                            <div v-if="this.type === 'event'">
                                <newsfeed-shared-event :data="this.module"></newsfeed-shared-event>
                            </div>
                            <div v-else-if="this.type === 'job'">
                                <newsfeed-shared-job :data="this.module"></newsfeed-shared-job>
                            </div>
                            <div v-else-if="this.type === 'post'">
                                <newsfeed-shared-post :data="this.module"></newsfeed-shared-post>
                            </div>
                            <div v-else-if="this.type === 'product'">
                                <newsfeed-shared-product :data="this.module"></newsfeed-shared-product>
                            </div>
                            <div v-else-if="this.type === 'resource'">
                                <newsfeed-shared-resource :data="this.module"></newsfeed-shared-resource>
                            </div>
                            <div v-else-if="this.type === 'video'">
                                <newsfeed-shared-video :data="this.module"></newsfeed-shared-video>
                            </div>
                            <div v-else-if="this.type === 'zoom'">
                                <newsfeed-shared-zoom :data="this.module"></newsfeed-shared-zoom>
                            </div>
                             <div v-else-if="this.type === 'webinar'">
                                <newsfeed-shared-zoom-webinar :data="this.module"></newsfeed-shared-zoom-webinar>
                            </div>

                            <div class="pl-1 pr-1 pb-05" v-if="this.module.group != null">
                                <a :href="'/member/group/'+this.module.group.slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;" :src="this.module.group.group_cover == null ? '/images/cover.jpg' : this.module.group.group_cover.url">
                                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ this.module.group.title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="pl-1 pr-1 pb-05" v-if="this.module.supplier != null">
                                <a :href="'/member/supplier/'+this.module.supplier.slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;" :src="this.module.supplier.supplier_cover_image == null ? '/images/cover.jpg' : this.module.supplier.supplier_cover_image.url">
                                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ this.module.supplier.title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="close">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevevnt="shareNewsfeed">{{ __('globals.share') }}</button>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import TomSelect from 'tom-select';
    import NewsfeedOwnerInfo from "./NewsfeedOwnerInfo.vue";

    export default {
        name: 'ShareModal',
        components: {
            'editor': Editor,
            TomSelect,
            NewsfeedOwnerInfo,
        },
        props: ['original_newsfeed_id', 'module', 'type'],
        data(){
          return {
            comment: '',
            service: 'profile',
            groups_followed: window.App.groupsFollowed,
            suppliers_followed: window.App.suppliersFollowed,
            errors: {
                'comment': null,
                'service': null
            },
            images: [],
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: this.type === 'post' ? this.module.video_link : '',
                        type: 'video/mp4'
                    }
                ]
            }
          }
        },
        mounted() {
            $('#shareModal').modal('show');
            $('#shareModal').on('shown.bs.modal', function() {
                $(document).off('focusin.modal');
            });

            new TomSelect('#service'+this._uid,
                {
                }
            );
        },
        methods: {
             init(){
                 if(this.type === 'post') {
                     let mime_type = ['image/gif', 'image/jpeg', 'image/png'];
                     if (this.module.media && this.module.media.length > 0) {
                         for (let i = 0; i < this.module.media.length; i++) {
                             if (this.module.media[i] !== undefined) {
                                 if (mime_type.includes(this.module.media[i].mime_type)) {
                                     this.images.push(window.App.siteUrl + '/storage/' + this.module.media[i].id + '/' + this.module.media[i].file_name);
                                 }
                             }
                         }
                     }
                 }
            },
            errorsReset(){
                this.errors = {
                    'comment': null,
                    'service': null
                };
            },
            shareNewsfeed() {
                axios.post('/member/newsfeed/share', {
                    body: this.comment,
                    feed: this.service,
                    id: this.original_newsfeed_id,
                })
                    .then(response => {
                        if (this.service === 'profile') {
                            document.location.href = '/member'
                        } else if (this.service.includes('group')) {
                            document.location.href = '/member/group/' + response.data.slug
                        } else if (this.service.includes('supplier')) {
                            document.location.href = '/member/supplier/' +response.data.slug
                        } else {
                            document.location.href = '/member'
                        }
                    });
            },
            close() {
                this.$emit('closeModal');
                $('#shareModal').modal('hide');
            },
            loader(){
                  $('#editor').removeClass('d-none');
                  $('#loader').addClass('d-none');
            },
        },
        beforeMount() {
            this.init()
        }
    }
</script>
