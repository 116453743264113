import moment from "moment-timezone";

export default {
    methods: {
        ago(created_at) {
            return moment(created_at).format('DD-MM-YY');
        },
    }

}
