<template>

    <div class="card" style="padding-bottom: 50px; min-height: 162px;">
        <div class=" shadow">
            <a :href="'/member/group/'+group.slug">
                <img class="img-fluid card-img-top " :src="group.cover !== null ? group.cover.url :  '/images/cover.jpg'" style="object-fit: cover;">
            </a>
        </div>
        <div class="card-content">

            <div class="row pl-1 pr-1">

                <div class="col-12 pt-05">
                    <div class="card-title">
                        <a :href="'/member/group/'+group.slug"><h6 class="mb-0 text-bold-700"> {{ group.title }}</h6></a>

                        <span class="badge-md badge-pill badge-light text-white font-small-1 " style="padding: 0.3rem 0.5rem" v-if="group.privacy === '1'">  <i
                            class="fa fa-shield-alt" data-toggle="tooltip" data-placement="bottom"
                            title="Private Group"></i> {{ __('globals.private') }} </span>

                    </div>
                </div>
            </div>
            <div class="d-flex card-bottom mt-1 d-flex font-small-3 align-items-center justify-content-between">
                <div class="text-light font-small-2 ">
                    {{ group.member_count }} {{ __('globals.members') }}
                </div>
                <div>
                    <join-service
                        type="group"
                        :slug="group.slug"
                        :status="group.is_member"
                        :ask_question="group.leave_question"
                        :leave_question="group.leave_question_text"
                        button_size="small">
                    </join-service>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "GroupCard",
    props: ['group'],
    data() {
        return {
            user: window.App.user,
        }
    }
}
</script>

<style scoped>

</style>
