<template>
        <div>
                <div v-for="(newsfeed, index) in newsfeeds" v-if="newsfeed.module !== null" :key="newsfeed.id">
                    <span v-if="newsfeed.module_type === 'App\\Models\\Video'">
                        <videopost :data="newsfeed"
                                   :members="members"
                                   :administrator="administrator"
                                   @deleted="remove(index)"
                                   :groups_follow="groups_follow"
                                   :suppliers_follow="suppliers_follow" />
                    </span>
                    <span v-if="newsfeed.module_type === 'App\\Models\\Resource'">
                        <resourcepost :data="newsfeed"
                                   :members="members"
                                   :administrator="administrator"
                                   @deleted="remove(index)"
                                   :groups_follow="groups_follow"
                                   :suppliers_follow="suppliers_follow" />
                    </span>
                    <span v-if="newsfeed.module_type === 'App\\Models\\Event'">
                        <event :data="newsfeed"  :members="members" :subscription="subscription" :administrator="administrator" @deleted="remove(index)"
                               :groups_follow="groups_follow" :suppliers_follow="suppliers_follow"
                        />
                    </span>
                    <span v-if="newsfeed.module_type === 'App\\Models\\ZoomWebinar'">
                        <webinar :data="newsfeed"  :members="members" :subscription="subscription" :administrator="administrator" @deleted="remove(index)"
                               :groups_follow="groups_follow" :suppliers_follow="suppliers_follow"
                        />
                    </span>
                    <span v-if="newsfeed.module_type === 'App\\Models\\Zoom'">
                        <meeting :data="newsfeed"  :members="members" :subscription="subscription" :administrator="administrator" @deleted="remove(index)"
                                 :groups_follow="groups_follow" :suppliers_follow="suppliers_follow"
                        />
                    </span>

                    <span v-if="newsfeed.module_type === 'App\\Models\\Post'">
                        <post :data="newsfeed" :members="members" :administrator="administrator" @deleted="remove(index)"
                              :groups_follow="groups_follow" :suppliers_follow="suppliers_follow" :section="section" :questions_enabled="questions_enabled" @sortpost="sortposts()" />
                    </span>

                   <span v-if="newsfeed.module_type === 'App\\Models\\Job'">
                        <job :data="newsfeed" :members="members" :administrator="administrator" @deleted="remove(index)"
                             :groups_follow="groups_follow" :suppliers_follow="suppliers_follow"
                        />
                    </span>
                   <span v-if="newsfeed.module_type === 'App\\Models\\Product'">
                        <product :data="newsfeed" :members="members" :administrator="administrator" @deleted="remove(index)"
                                 :groups_follow="groups_follow" :suppliers_follow="suppliers_follow"/>
                    </span>

                    <span v-if="newsfeed.module_type === 'App\\Models\\Stack\\Question'">
                        <question :data="newsfeed" :members="members" :administrator="administrator" @deleted="remove(index)" />
                    </span>
                </div>
                <infinite-loading @infinite="infiniteHandler">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>


            <div v-if="newsfeeds.length === 0">
                <div class="card bg-outline-gray">
                    <div class="card-header">
                        <h4 class="card-title gray">{{ __('globals.no_posts_header') }}</h4>

                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li class=""><a data-action="close"><i class="feather icon-x"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">
                        <span class="gray">{{ __('globals.no_post_content') }}</span>
                        <div>
                            <a href="/member/help">
                                <button class="btn btn-outline-light mt-1">
                                    {{ __('globals.help_button') }}
                                </button>
                            </a>
                        </div>
                        <div class="ml-auto">
                            <img src="/images/annie.png" width=50px style="float:right; margin-top: -40px;">
                        </div>
                    </div>
                </div>
            </div>
            <span v-text="newsfeeds.links"></span>
        </div>

</template>

<script>
    import Event from './post/Event.vue';
    import Webinar from './post/ZoomWebinar.vue';
    import Meeting from './post/Zoom.vue';
    import Video from './post/Video.vue';
    import Job from './post/Job.vue';
    import Product from './post/Product.vue';
    import Post from './post/Post.vue';
    import Question from './post/Question.vue';
    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        name: "InfiniteScroll",
        components: {Event, Webinar, Meeting, Job, Product, Post, Video, Question, InfiniteLoading},
        props: ['data', 'members', 'section', 'subscription', 'type', 'administrator', 'groups_follow', 'suppliers_follow', 'questions_enabled'],
        data() {
            return {
                newsfeeds: [],
                page: 1,
            }
        },
        methods: {
            infiniteHandler($state){
                if (this.section === 'group'){
                    this.loadGroupFeed($state);
                }
                else if (this.section === 'supplier'){
                    this.loadSupplierFeed($state);
                }
                else if (this.section === 'profile'){
                    this.loadProfileFeed($state);
                }
                else {
                    this.loadHomePageFeed($state);
                }
            },
            loadGroupFeed($state){
                let vm = this;
                axios
                    .get("/member/load-posts/group/"+this.data.slug+"?page="+this.page)
                    .then(response => {
                        if (response.data.data.length > 0){
                            $.each(response.data.data, function(key, value){
                                vm.newsfeeds.push(value);
                            });
                            $state.loaded();
                        }
                        else {
                            $state.complete();
                        }
                    });
                vm.page++;

            },
            loadSupplierFeed($state){
                let vm = this;
                axios
                    .get("/member/load-posts/"+this.type+"/"+this.data.slug+"?page="+this.page)
                    .then(response => {
                        if (response.data.data.length > 0){
                            $.each(response.data.data, function(key, newsFeeds){
                                vm.newsfeeds.push(newsFeeds);
                            });
                            $state.loaded();
                        }
                        else {
                            $state.complete();
                        }
                    });
                vm.page++;
            },
            loadHomePageFeed($state){
                let vm = this;
                axios
                    .get("/member/load-posts?page="+this.page)
                    .then(response => {
                        if (response.data.data.length > 0){
                            $.each(response.data.data, function(key, value){
                                vm.newsfeeds.push(value);
                            });
                            $state.loaded();
                        }
                        else {
                            $state.complete();
                        }
                    });
                vm.page++;
            },
            loadProfileFeed($state){
                let vm = this;
                axios
                    .get("/member/load-posts/profile/"+this.data.username+"?page="+this.page)
                    .then(response => {
                        if (response.data.data.length > 0){
                            $.each(response.data.data, function(key, value){
                                vm.newsfeeds.push(value);
                            });
                            $state.loaded();
                        }
                        else {
                            $state.complete();
                        }
                    });
                vm.page++;
            },
            remove(index) {
                this.newsfeeds.splice(index, 1);
            },
            sortposts: function() {
                return this.newsfeeds.sort((a,b) => b.pin_to_top - a.pin_to_top || new Date(b.published_at) -  new Date(a.published_at))
            },
        },
    }
</script>

<style scoped>

</style>
