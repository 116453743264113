<template>
    <div class="d-flex align-items-top">
        <img class="round shadow"
             :src="data.owner.avatar != null ? data.owner.avatar.url : '/images/avatar.png'"
             alt="avatar"
             height="25"
             width="25"/>
        <div class="card mb-1 d-inline-block ml-05" style="width: 90%;">
            <div class="d-flex align-items-center border-bottom  pb-05 m-05">

                <div class="pr-1">

                    <a :href="'/member/profiles/'+data.owner.username"
                       v-text="data.owner.first_name+' '+data.owner.last_name" class="text-bold-600 dark">
                        <span class="text-bold-500 dark"> {{ __('globals.wrote') }}: </span>
                    </a>

                </div>

                <div class="ml-auto">
                    <span v-text="ago" class="text-muted font-small-2"></span>
                    <button  v-if="canUpdate" class="btn-sm btn-flat-danger" style="border:none;" @click="destroy"><i class="fa fa-trash-o"></i></button>
                </div>
            </div>

            <div class="ml-1 mr-1 mb-1 mt-05">
                <span :class="'rating-static rating-'+data.rating * 10"></span>
                <div>
                    {{ rating }}
                </div>


            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment-timezone';

    export default {
        name: "Rating",
        props: ['data'],
        data() {
            return {
                editing: false,
                rating: this.data.body,
                id: '',
                rating_deleted_message: this.__('globals.rating_deleted'),
            };
        },
        computed: {
            canUpdate() {
                return this.authorize(user => this.data.author_id == user.id)
            },
            ago() {
                return moment(this.data.created_at).format('DD-MM-YY');
            }
        },
        methods: {
            destroy() {
                axios
                    .delete('/member/rating/' + this.data.id)
                    .then(response => {
                        if (response.data.error != null){
                            let flash_data = {
                                'message': response.data.error,
                                'level': 'danger'
                            }
                            flash(flash_data);
                        }
                        else {
                            this.$emit('deleted', this.data.id);
                            let flash_data = {
                                'message': this.rating_deleted_message,
                                'level': 'success'
                            }
                            flash(flash_data);
                        }
                    })
            }
        }
    }
</script>
