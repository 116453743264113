<template>
    <div >
        <div v-bind:class="'card '+ pinStyle">
            <post-owner-info :data="data"
                             :isServiceAdmin="isServiceAdmin"
                             :user_id="user_id"
            ></post-owner-info>
            <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                <span v-text="ago(data.published_at)" class="font-small-1 text-light "
                      style="margin-top: 4px;"></span>
                <span  v-if="isPin === true">
                <i class="fas fa-thumbtack text-primary mt-05 ml-05" style="font-size:0.7em;"></i>
                    </span>
                <div class="dropdown">
                    <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">

                             <i class="fa fa-chevron-circle-down mr-0" title="Pinned Post"></i>


                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                        <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || administrator === true">
                            <a class="dropdown-item" href="#" @click.prevent="editPost">
                                <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                            </a>
                        </span>
                        <span v-if="((isServiceAdmin === true || user_id === data.module.owner.id || administrator === true) && hide_questions == false)">
                            <a class="dropdown-item danger" href="#" @click.prevent="triggerQuestionModal">
                                <i class="feather icon-alert-triangle"></i>Make Question
                            </a>
                        </span>
                        <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || administrator === true">
                            <a class="dropdown-item" href="#" @click.prevent="destroy">
                                <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                            </a>
                        </span>
                        <span v-if="(isServiceAdmin === true || administrator === true) && isPin === false">
                            <a class="dropdown-item" href="#" @click.prevent="pin">
                                <i class="fas fa-thumbtack" style="font-size: 1.0em; margin-bottom: 10px;"></i>{{ __('post.form.links.pin_post') }}
                            </a>
                        </span>
                        <span v-if="(isServiceAdmin === true || administrator === true) && isPin === true">
                            <a class="dropdown-item" href="#" @click.prevent="unpin">
                                <i class="fas fa-thumbtack" style="font-size: 1.0em; margin-bottom: 10px;"></i>{{ __('post.form.links.unpin_post') }}
                            </a>
                        </span>
                        <a class="dropdown-item danger" @click.prevent="report">
                            <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                        </a>
                    </div>
                </div>
            </div>

            <div class=" mt-0 mb-0 border-top border-bottom">

                <!-- Home newsfeed edit existing post-->
                <div v-if="editing" class="form-group w-100 p-1">
<!--                <textarea class="form-control mb-1" v-model="post_text"></textarea>-->

                    <div class="card mb-1">
                        <editor
                            v-model="post_text"
                            api-key="no-api-key"
                            :init='{
                                skin: "trybz-member",
                                skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                external_plugins: {
                                    customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                    loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                },
                                valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                plugins: "link quickbars autolink lists customtags loadingbar",
                                link_context_toolbar: true,
                                quickbars_selection_toolbar: "bold italic link bullist",
                                quickbars_image_toolbar: false,
                                quickbars_insert_toolbar: false,
                                link_assume_external_targets: "https",
                                link_target_list: false,
                                link_title: false,
                                toolbar: false,
                                branding: false,
                                elementpath: false,
                                menubar: false,
                                file_picker_types: "",
                                height: 100,
                                content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                relative_urls : false,
                                remove_script_host : false,
                            }'
                        />
                    </div>

                    <button class="btn btn-sm btn-primary" @click.prevent="update">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click.prevent="editing = false">{{ __('globals.cancel') }}</button>
                </div>

                <div class=" pt-1 pb-1 col-12" v-if="post_text !== null && post_text !== ''">
                    <div v-if="thesection !== 'sidebar'">
<!--                        <div class="breakAll" v-linkified v-html="this.$options.filters.nl2br(post_text, true)"></div>-->
                        <div class="breakAll" v-linkified v-html="this.post_text"></div>
                    </div>
                    <div v-else>
<!--                        <div class="breakAll" v-linkified v-html="this.$options.filters.nl2br(post_text.substring(0,400), true)"></div>-->
<!--                        <div class="breakAll" v-linkified v-html="this.post_text.substring(0,400)"></div>-->
                        <div class="breakAll" v-linkified v-html="this.post_text"></div>
                    </div>

                </div>
                <div class="col-12 p-0">

                        <div class="text-center" v-if="data.module.video_link !== null">
                            <video-player :options="videoOptions"/>
                        </div>
                        <div class="video_container" v-else-if="data.module.video_external_url !== null">
                                <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="data.module.video_external_url"></iframe>
                            </div>
                    <div class="gallery-row mb-05" v-if="this.data.module.post_image.length > 1">
                        <div class="gallery-container">
                            <lightbox :items="images"></lightbox>
                        </div>
                    </div>

                    <div class="mb-05" v-else-if="this.data.module.post_image.length === 1">
                        <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                            <img class=" img-fluid"
                                 :src="this.data.module.post_image[0].url">
                        </div>
                    </div>

                    <div class="mr-05 ml-05 mb-05" v-if="data.module.post_file.length > 0">
                        <a @click.prevent="downloadFile(file.id, index)" v-for="(file, index) in data.module.post_file">
                            <div class="card bg-outline-light text-center mb-05">
                                <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                                    <strong class="w-100 text-center"><i class="feather icon-download"></i> {{ __('post.form.buttons.download_file') }} ...{{ file.file_name.slice(-20) }} </strong>
                                </div>
                            </div>
                        </a>
                    </div>

                     <div class="p-1" v-if="data.groups !== undefined && data.groups.length > 0">
                        <a :href="'/member/group/'+data.groups[0].slug">
                            <div class="card text-white mb-0">
                                <img class="card-img img-fluid" style="height: 3rem;"
                                     :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                <div
                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                        {{ data.groups[0].title }}</p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                        class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>

                    </div>
                    <div class="p-1" v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                        <a :href="'/member/supplier/'+data.suppliers[0].slug">
                            <div class="card text-white mb-0">
                                <img class="card-img img-fluid" style="height: 3rem;"
                                     :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                <div
                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{
                                            data.suppliers[0].title
                                        }} </p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                        class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="card-body ml-05 mr-05 p-0" v-if="thesection !== 'sidebar'">
                <div class="d-flex justify-content-between">
                    <div class=" p-05">
                        <like :post="data.module" type="post"></like>
                    </div>
                    <div class="p-05 font-small-3 text-bold-700" style="margin-top: 1px;"><a
                        @click.prevent="showComments(data.id)"><i
                        class="fa fa-comment-o"></i> Comment <span v-text="commentsCount"></span></a></div>
                    <social-share :id="data.id" :social="this.data.module.socialShare"/>
                </div>
            </div>
            <div v-else class="card-body ml-05 mr-05 p-05 w-100 text-right">
                <a :href="'/member/post/'+data.module.id" style="color:inherit"
                   class="btn-sm btn-flat-light text-bold-700">{{ __('globals.view_post') }} <i
                    class="feather icon-arrow-right"></i></a>
            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  :module="data.type"
                  :slug="data.module.id"
                  :members="members"
                  :administrator="administrator"
        ></comments>

        <div class="modal fade" :id="'shareModal'+data.id" :ref="'shareModal'+data.id" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ __('post.share_post') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="p-1 card border-light">
                            <div class="">
                                <label :for="'comment_body'+data.id">{{ __('globals.enter_comment') }}</label>
                                <textarea :name="'comment_body'+data.id" :id="'comment_body'+data.id" cols="30" rows="3"
                                          class="form-control"></textarea>
                            </div>
                            <div class="pb-1">
                                <label :for="'feed'+data.id">{{ __('globals.select_share') }}</label>
                                <select :name="'feed'+data.id" :id="'feed'+data.id" class="form-control">
                                    <option value="profile">{{ __('globals.your_newsfeed') }}</option>
                                    <optgroup :label="__('globals.share_group')" v-if="groups_follow !== undefined">
                                        <option :value="'group_'+group.id" v-for="group in groups_follow">{{
                                                group.title
                                            }}
                                        </option>
                                    </optgroup>
                                    <optgroup :label="__('globals.share_supplier')"
                                              v-if="suppliers_follow !== undefined">
                                        <option :value="'supplier_'+supplier.id"
                                                v-for="supplier in suppliers_follow">{{ supplier.title }}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>

                            <div class="p-1 card border-light">
                                <post-owner-info :data="data"
                                                 :isServiceAdmin="isServiceAdmin"
                                                 :user_id="user_id"
                                />
                                <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                        <span v-text="ago(data.published_at)" class="font-small-1 text-light "
                                              style="margin-top: 4px;"></span>
                                </div>

                                <div class=" mt-0 mb-0 border-top border-bottom">
                                    <div class=" pt-1 pb-1 col-12" v-if="post_text !== null && post_text !== ''">
<!--                                        <div class="breakAll" v-linkified v-html="this.$options.filters.nl2br(post_text.substring(0,400), true)"></div>-->
                                        <div class="breakAll" v-linkified v-html="this.post_text.substring(0,400)"></div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="text-center" v-if="data.module.video_link !== null">
                                            <video-player :options="videoOptions"/>
                                        </div>
                                        <div class="video_container" v-else-if="data.module.video_external_url !== null">
                                            <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                                    mozallowfullscreen allowfullscreen :src="data.module.video_external_url"></iframe>
                                        </div>
                                        <div class="gallery-row" v-if="images.length > 1">
                                            <div class="gallery-container">
                                                <lightbox :items="images"></lightbox>
                                            </div>
                                        </div>
                                        <div v-else-if="images.length === 1">
                                            <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                                                <img class=" img-fluid"
                                                     :src="images[0]">
                                            </div>
                                        </div>
                                        <div class="" v-if="data.groups !== undefined && data.groups.length > 0">
                                            <a :href="'/member/group/'+data.groups[0].slug">
                                                <div class="card text-white mb-0">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;">
                                                            <strong>Posted
                                                                in: </strong> {{ data.groups[0].title }}</p>
                                                        <button class="btn-sm btn-outline-white ml-auto">View Group
                                                            <i
                                                                class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class=""
                                             v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                                            <a :href="'/member/supplier/'+data.suppliers[0].slug">
                                                <div class="card text-white mb-0">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;">
                                                            <strong>{{ __('globals.posted_to') }} </strong> {{ data.suppliers[0].title }} </p>
                                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }}
                                                            <i
                                                                class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="" v-if="data.module.post_file.length > 0">
                                            <div class="card bg-outline-light text-center mb-0">
                                                <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                                                    <strong class="w-100 text-center"><i
                                                        class="feather icon-download"></i>
                                                        {{ __('post.form.buttons.download_file') }} </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevent="sharePost(data.id)">{{ __('globals.share') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="make_question">
            <question-modal :question_body="this.data.module.post_text" :module_id="this.data.module.id" type="post" :user_id="this.data.module.user_id" @close-modal="triggerQuestionModal" />
        </div>
    </div>
</template>

<script>
import Share from '../../../mixins/newsfeed/Share'
import Post from '../../../mixins/newsfeed/Post'
import DateTime from '../../../mixins/newsfeed/DateTime'
import Editor from '@tinymce/tinymce-vue'
import lightbox from "../lightbox/lightbox"
import "../lightbox/lightbox.css"
import QuestionModal from '../../post/QuestionModal';


export default {
    name: "NewsfeedPost",
    components: {
        lightbox,
        'editor': Editor,
        QuestionModal
    },
    props: ['groups_follow', 'suppliers_follow', 'administrator', 'section', 'questions_enabled'],
    data() {
        return {
            images: [],
            user: window.App.user,
            user_id: window.App.user.id,
            hide_questions: '',
            make_question: false,
            question_tags: '',
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: typeof this.data.module.video_link !== undefined ? this.data.module.video_link : '',
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    mixins: [
        Share, Post, DateTime
    ],
    methods: {
        init(){
            if(this.questions_enabled != undefined){
                if(this.questions_enabled['enable_global_questions'] === null){
                    this.hide_questions = true;
                } else {
                    this.hide_questions = false;
                }
            } else {
                this.hide_questions = false;
            }
        },
        triggerQuestionModal(){
            this.make_question = !this.make_question;
        }
    },
    beforeMount(){
        this.init();
    },

}
</script>

<style>

</style>
