\<template>
    <div class="col-12 m-auto">
        <div class="card">
            <div class="d-flex justify-content-start">
                <guest-post-owner-info
                            :user_data="this.data.owner"
                            :supplier_data="null"
                            :shared="false"
                            :time="this.data.created_at"
                />
            </div>

            <div class="card-content">
                <div class="card-body pt-05 pb-05">
                    <span class="breakAll" v-html="this.data.post_text"></span>
                </div>
            </div>
            <img class="img-fluid"
                 :src="this.data.media.length > 0 ? this.data.media[0].url : ''">
            <div v-if="!this.share" class="d-flex justify-content-between border-top pl-05 pr-05">
                <div class="p-05">
                    <i class="fa fa-thumbs-o-up" data-toggle="modal" data-target="#registerModal"></i>
                </div>
                <div class="p-05">
                    <span data-toggle="modal" data-target="#registerModal">
                        <i class="fa fa-comment-o"></i> {{ __('globals.comment') }}
                    </span>
                </div>
                <div class="p-05">
                    <div class="dropdown">
                        <a class="dropdown-toggle" id="socialShareButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-share-alt"></i> {{ __('globals.share') }}
                        </a>
                        <div class="dropdown-menu" aria-labelledby="socialShareButton">
                            <span v-html="this.social"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import linkify from 'vue-linkify';
    Vue.directive('linkified', linkify)

    export default {
        name: "Post",
        props: ['data', 'social', 'share'],
    }
</script>


