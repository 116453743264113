<template>
    <div style="display: inline-block" v-if="this.data.username !== this.user.username">
        <div class="d-flex pr-05" :class="'friend-actions-'+this._uid">
            <div class="spinner-border spinner-border-sm text-primary" role="status" v-if="isLoading">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
                <button :class="'btn btn-success waves-effect waves-light '+size" v-if="contactStatus === false" @click.prevent="follow()">
                    <span>
                        {{ __('globals.follow') }} {{ size === 'btn-sm' ? '' : data.first_name }}
                    </span>
                    <i class="feather icon-user-plus"></i>
                </button>

                <button :class="'btn btn-danger waves-effect waves-light '+size" v-if="contactStatus === true" @click.prevent="unfollow()">
                    <span>
                        {{ __('globals.unfollow') }} {{ size === 'btn-sm' ? '' : data.first_name }}
                    </span>
                    <i class="feather icon-user-minus "></i>
                </button>

                <button :class="'btn btn-primary waves-effect waves-light '+size" v-if="contactStatus === 'pendingRequest'" @click.prevent="cancelPending()">
                    <span>
                        {{ size === 'btn-sm' ? __('globals.pending') : __('globals.pending_contact_request') }}
                    </span>
                    <i class="feather icon-time"></i>
                </button>

                <template v-else-if="contactStatus === 'pendingAccept' && !this.hideButtons">
                    <button :class="'btn btn-success waves-effect waves-light '+size" @click.prevent="acceptRequest()">
                        <span>
                            {{ size === 'btn-sm' ? __('globals.accept') : __('globals.accept_contact_request') }}
                        </span>
                    </button>

                    <button :class="'btn btn-danger waves-effect waves-light '+size" @click.prevent="declineRequest()">
                        <span>
                            {{ size === 'btn-sm' ? __('globals.decline') : __('globals.decline_contact_request') }}
                        </span>
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'size'],
        name: "Follow",
        data() {
            return {
                user: window.App.user,
                isLoading: true,
                hideButtons: false,
                submitting: false,
                contactStatus: '',
            }
        },
        beforeMount() {
            if(!window.location.href.includes('/member/profiles/')){
                this.hideButtons = true;
            }
            this.isFollowing();
        },
        methods: {
            isFollowing() {
                axios
                    .get(`/member/user/${this.data.username}/is-contact`)
                    .then((response) => {
                        this.contactStatus = response.data.is_contact;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger')
                        this.isLoading = false;
                    });
            },
            follow(){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .post(`/member/user/${this.data.username}/follow`)
                    .then(response => {
                        this.contactStatus = 'pendingRequest';
                        this.flashResponse(response.data.message, 'success')
                        this.submitting = false;
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger')
                        this.submitting = false;
                    });
            },
            unfollow(){
                if(window.confirm(this.__('user.confirm.unfollow_user'))) {
                    if(this.submitting){
                        return false;
                    }
                    this.submitting = true;
                    axios
                        .delete(`/member/user/${this.data.username}/unfollow`)
                        .then(response => {
                            this.contactStatus = false;
                            this.flashResponse(response.data.message, 'danger')
                            this.submitting = false;
                        })
                        .catch((error) => {
                            this.flashResponse('An error occurred', 'danger')
                            this.submitting = false;
                        });
                } else {}
            },
            cancelPending(){
                if(window.confirm(this.__('user.confirm.cancel_pending'))) {
                    if(this.submitting){
                        return false;
                    }
                    this.submitting = true;
                    axios
                        .delete(`/member/user/${this.data.username}/cancel-request`)
                        .then(response => {
                            this.contactStatus = false;
                            this.flashResponse(response.data.message, 'success')
                            this.submitting = false;
                        })
                        .catch((error) => {
                            this.flashResponse('An error occurred', 'danger')
                            this.submitting = false;
                        });
                } else {}
            },
            acceptRequest(){
                if(this.submitting){
                     return false;
                }
                this.submitting = true;
                axios
                    .post(`/member/user/${this.data.username}/accept-request`)
                    .then(response => {
                        this.contactStatus = true;
                        this.flashResponse(response.data.message, 'success')
                        this.submitting = false;
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger')
                        this.submitting = false;
                    });
            },
            declineRequest(){
                if(window.confirm(this.__('user.confirm.decline_request'))) {
                    if(this.submitting){
                        return false;
                    }
                    this.submitting = true;
                    axios
                        .delete(`/member/user/${this.data.username}/decline-request`)
                        .then(response => {
                            this.contactStatus = false;
                            this.flashResponse(response.data.message, 'danger')
                            this.submitting = false;
                        })
                        .catch((error) => {
                            this.flashResponse('An error occurred', 'danger')
                            this.submitting = false;
                        });
                } else {}
            },
            flashResponse(message, status){
                let flash_data = {
                    'message': message,
                    'level': status
                }
                flash(flash_data);
            }
        },
    }
</script>

<style scoped>

</style>
