<template>
    <div>
        <!-- @if (sizeof($supplier_types) > 0)//-->
        <div class="form-group" v-if="this.supplier_types.length > 0">
            <label class="required" for="supplier_type" style="margin-top: 10px;">{{ __('globals.select_type') }}</label>
            <select class="form-control" name="supplier_type" id="supplier_type" @change="change_selector_type">
                <option value="supplier">{{ __('globals.supplier') }}</option>
                <option v-for="supplier_type in supplier_types" :value="supplier_type" :key="supplier_type">{{supplier_type}}</option>

            </select>

            <span class="help-block"></span>
        </div>


        <div class="card bg-gradient-primary p-2" v-if="show_price">
            {{ __('supplier.supplier_pay') }}
        </div>
    </div>

</template>

<script>
    export default {
        props: ['settings', 'supplier_types'],
        name: "SupplierTypesSelector",
        data() {
            return {
                show_price: false,
                price: '',
                type: ''
            }
        },
        methods: {
            change_selector_type()
            {
                this.type = $('#supplier_type').children("option:selected").val();

                if (this.type == this.settings[1].type.supplierType1)
                {
                    this.setShowPrice(this.settings[1].type.paidUpgrade1, this.settings[1].type.typeCost1);
                }

                if (this.type == this.settings[1].type.supplierType2)
                {
                    this.setShowPrice(this.settings[1].type.paidUpgrade2, this.settings[1].type.typeCost2);
                }
            },
            setShowPrice(paidUpgrade, cost)
            {
                if (paidUpgrade == '1')
                {
                    this.show_price = true;
                    this.price = cost;
                }
                else
                {
                    this.show_price = false;
                    this.price = '';
                }
            }
        },
        mounted() {
            this.change_selector_type();
        }
    }
</script>

<style scoped>

</style>
