<template>
    <div style="display: inline-block">
        <button class="btn btn-sm btn-primary no-border " style="border:none;" @click="follow" v-if="buttonsize === undefined">
            <span v-if="following === false">{{ __('globals.join') }} <i class="feather icon-user-plus"></i></span>
            <span v-if="following === true">{{ __('globals.leave') }} <i class="feather icon-log-out"></i></span>
            <span v-if="following === 'pending'">{{ __('globals.requested') }} <i class="feather icon-log-in"></i></span>
        </button>

        <button class="btn btn-primary no-border" style="border:none;" @click="follow" v-if="buttonsize === 'large'">
            <div v-if="following === false">{{ __('globals.join') }} <i class="feather icon-user-plus"></i></div>
            <div v-if="following === true">{{ __('globals.leave') }} <i class="feather icon-log-out"></i></div>
            <div v-if="following === 'pending'">{{ __('globals.requested') }} <i class="feather icon-log-in"></i></div>
        </button>

        <!-- Leave Group Response Modal -->
        <div class="modal fade" :id="'leave_response_'+this.group.id" tabindex="-1" role="dialog" aria-labelledby="leaveQuestionTitle"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{ __('globals.before_you_leave') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-left">{{ this.group.leave_question_text}}</p>
                        <div class="form-group">
                            <textarea class="form-control" name="response" :id="'response_'+this.group.id" cols="30" rows="5"></textarea>
                        </div>
                        <div :id="'thankyou_'+this.group.id" class="text-success text-left"></div>
                        <div class="ml-auto" :id="'buttons_'+this.group.id">
                            <button id="skip" class="btn btn-sml btn-danger" @click="closeModal">Skip</button><button id="submit_response" class="btn btn-sml btn-success ml-05" @click="storeResponse">{{ __('globals.submit_response') }}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'isfollowing', 'buttonsize'],
        name: "JoinGroup",
        data () {
            return {
                group: this.data,
                following: '',
                user: window.App.user,
                groupfollower: '',
                groupfollowing: '',
            }
        },
        methods: {
            isfollow() {
                axios
                    .get('/member/group/isfollow/'+this.group.slug)
                    .then(response => {
                        this.following = response.data.isFollowing;
                    });
            },
            follow() {
                if (this.following === true && this.group.leave_question === 1){
                    axios
                        .get('/member/group/'+this.group.slug+'/has_left_response')
                        .then(response => {
                            if (response.data === false){
                                $('#leave_response_'+this.group.id).modal('show');
                            }
                        });
                }

                axios
                    .post('/member/group/follow/'+this.group.slug)
                    .then(response => {
                        this.following = response.data.isFollowing;
                    });
            },
            storeResponse(){
                $('#buttons_'+this.group.id).css('display', 'none');
                axios
                    .post('/member/group/'+this.group.slug+'/leave_response', {response: document.getElementById('response_'+this.group.id).value});

                $('#thankyou_'+this.group.id).text('Thank you for your feedback');

                setTimeout(function(id){
                    $('#leave_response_'+id).modal('hide');
                }, 3000, this.group.id);

            },
            closeModal(){
                $('#leave_response_'+this.group.id).modal('hide');
            }
        },
        mounted() {
            this.isfollow();
        }
    }
</script>

<style scoped>

</style>
