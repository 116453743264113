<template>
    <div class='card mt-1 ml-3 mr-1 p-1 mb-3'>
    <span class="font-small-2 text-bold-600">Post your {{ __('comment.reply') }}:</span>

            <div class="card mb-1">
                <editor
                    v-model="comment_text"
                    api-key="no-api-key"
                    :init='{
                        skin: "trybz-member",
                        skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                        content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                        external_plugins: {
                            customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                            loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                        },
                        valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                        plugins: "link quickbars autolink lists customtags loadingbar",
                        link_context_toolbar: true,
                        quickbars_selection_toolbar: "bold italic link bullist",
                        quickbars_image_toolbar: false,
                        quickbars_insert_toolbar: false,
                        link_assume_external_targets: "https",
                        link_target_list: false,
                        link_title: false,
                        toolbar: false,
                        branding: false,
                        elementpath: false,
                        menubar: false,
                        file_picker_types: "",
                        height: 100,
                        content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                        relative_urls : false,
                        remove_script_host : false,
                    }'
                />
            </div>

            <div class="d-flex justify-content-end">
                <button id="comment_post_submit" type="submit"
                        :disabled="isActive"
                        class="btn btn-primary btn-doc-header waves-effect waves-light text-bold-700"
                        @click="addComment">{{ __('comment.post') }}<i class="feather icon-arrow-right"></i></button>
            </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'

    export default {
        name: "NewComment",
        components: {
            'editor': Editor
        },
        props: ['comment_id', 'type'],
        data() {
            return {
                isActive: false,
                comment_text: '',
                user: window.App.user,
                comment_posted_message: this.__('comment.comment_posted'),
                confirmed_user_message: this.__('comment.confirmed_user'),

            }
        },
        methods: {
            endpoint() {
                if (this.type === 'newsfeed'){ //|| this.type === 'zoom-webinar' || this.type === 'zoom' || this.type === 'newsfeed') {
                    return '/member/'+this.type+'/comment/'+this.comment_id+'/reply';
                } else {
                    return '/member/comment/'+this.comment_id+'/comments';
                }
            },
            addComment(){
                this.isActive = !this.isActive;
                if (this.user.confirmed === 0){
                    let data = {
                        'message': this.confirmed_user_message,
                        'level': 'danger'
                    }
                    flash(data);
                    this.isActive = !this.isActive;
                    return;
                }

                axios
                    .post(this.endpoint(), {comment_text: this.comment_text })
                    .then(response => {
                        this.comment_text = '';
                        let data = {
                            'message': this.comment_posted_message,
                            'level': 'success'
                        }
                        flash(data);
                        this.$emit('created', response.data);
                        this.isActive = !this.isActive;
                    });
            }
        }
    }
</script>

<style scoped>

</style>
