
import linkify from 'vue-linkify'
import Share from '../../mixins/newsfeed/Share'
import DateTime from '../../mixins/newsfeed/DateTime'

Vue.directive('linkified', linkify)
import SharedPostOwnerInfo from "../../components/post/share/PostOwnerInfo";
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import Likes from '../../components/newsfeed/components/Likes.vue'
import NewsfeedComments from '../../components/newsfeed/components/Comments.vue'

export default {
    name: "SharedFunctions",
    mixins: [
        DateTime
    ],
    props: ['section'],
    components: {SharedPostOwnerInfo, 'editor': Editor, Likes, NewsfeedComments},
    data() {
        return {
            editing: false,
            user_id: window.App.user.id,
            user_badge: window.App.user_badge,
            username: window.App.user.username,
            site_tax: window.App.site_tax,
            shared_text: this.data.body,
            isServiceAdmin: false,
            isAdmin: window.App.user.isAdmin,
            body: this.data.body,
            commentsCount: this.data.commentsCount,
            commentsShow: false,
            share_newsfeed: false,
        }
    },
    methods: {
        share() {
            axios.post('/member/newsfeed/share', {
                body: $('#comment_body'+this.data.id).val(),
                feed: $('#feed'+this.data.id).val(),
                id: this.data.original_newsfeed_id,
            })
                .then(response => {
                    if ($('#feed'+this.data.id).val() === 'profile') {
                        document.location.href = '/member'
                    }

                    if ($('#feed'+this.data.id).val().includes('group')) {
                        document.location.href = '/member/group/' + response.data.slug
                    }

                    if ($('#feed'+this.data.id).val().includes('supplier')) {
                        document.location.href = '/member/supplier/' +response.data.slug
                    }
                });
        },

        updateCommentsCount(count){
            this.commentsCount = count;
        },

        triggerShareModal(){
            this.share_newsfeed = !this.share_newsfeed;
        },

        editPost() {
            this.editing = true;
        },

        deleteNewsfeed() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/newsfeed/' + this.data.id);
                this.$emit('deleted', this.data.id);
                let data = {
                    'message': 'Post Deleted',
                    'level': 'danger'
                }
                flash(data);
                if(window.location.pathname.includes('/member/newsfeed/')){
                    window.location.href = '/member';
                }
            }
        },

        updateSharedNewsfeed() {
            axios.patch('/member/newsfeed/' + this.data.id, {
                body: this.shared_text
            }).then(()=>{this.body = this.shared_text});

            this.editing = false;

            let data = {
            'message': 'Post Updated',
            'level': 'success'
            }
            flash(data);
        },

        reportNewsfeed(){
            $('#report_id').val(this.data.id);
            $('#report_type').val('App\\Models\\Newsfeed');
            $('#post_title').text(this.body.replace(/<\/?[^>]+(>|$)/g, ""));
            $('#reportModal').modal('toggle');
        },

        cancelEditing(){
            this.editing = false;
            this.shared_text = this.data.body;
        },

        checkServiceAdmin() {
            if (this.data.groups !== undefined && this.data.groups.hasOwnProperty('admins')) {
                for (var i = 0; i < this.data.groups[0].admins.length; i++) {
                    if (this.data.groups[0].admins[i].id === this.user_id) {
                        this.isServiceAdmin = true;
                    }
                }

                if (this.data.groups[0].user_id === this.user_id) {
                    this.isServiceAdmin = true;
                }
            }
        },
    },
}
