<template>
    <div>
        <div class="form-group">
            <div class="d-flex">
                <div class="ml-2 mr-3">
                    <input class="form-check-input" type="radio" name="send_type" id="sendImmediately" value="sendImmediately"
                           @click="sendImmediately" v-model="send_type">
                    <label class="form-check-label" for="sendImmediately">
                        {{ __('email_marketing.send_email_immediately') }}
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="radio" name="send_type" id="sendDateTime" value="sendDateTime"
                           @click="showDateTimePicker"
                           v-model="send_type">
                    <label class="form-check-label" for="sendDateTime">
                        {{ __('email_marketing.send_email_date_time') }}
                    </label>
                </div>
                <div class="ml-3">
                    <input class="form-check-input" type="radio" name="send_type"
                           id="sendBehaviouralTrigger" value="sendBehaviouralTrigger" @click="SendBehaviouralOption"
                           v-model="send_type">
                    <label class="form-check-label" for="sendBehaviouralTrigger">
                        {{ __('email_marketing.send_email_triggers') }}
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="required" for="email_trigger_id">{{ __('email_marketing.audience') }}</label>
            <select class="form-control email_trigger" name="email_trigger_id[]" id="email_trigger_id" v-model="selected_triggers"  style="width: 1056px;" size="10">
                <option v-for="trigger in triggers" :value="trigger.id" :key="trigger.id">{{trigger.name}}</option>
            </select>
            <span class="help-block" role="alert"></span>
        </div>

        <div id="date_time_triggers" style="display: none">
            <div class="form-group" id="date_time">
                <label class="required" for="send_date_time">{{ __('email_marketing.select_date_time') }}</label>
                <input class="form-control datetime" type="text" name="send_date_time" id="send_date_time"
                       :value="this.emailMarketing.send_date_time">
                <span class="help-block" role="alert"></span>
            </div>
        </div>

        <div id="behaviouralTriggerOptions" style="display: none">

            <div class="form-group">
                <label class="required">{{ __('email_marketing.send_email_in') }}</label>
                <div class="d-flex">
                    <div>
                        <input class="form-control" type="number" name="send_in_number" step="1" id="send_in_number" v-model="send_in_number">
                    </div>
                    <div>
                        <select name="send_in_param" class="form-control" style="width: 100px" v-model="send_in_param">
                            <option value="minute">{{ __('email_marketing.minute') }}</option>
                            <option value="hour">{{ __('email_marketing.hour') }}</option>
                            <option value="day">{{ __('email_marketing.day') }}</option>
                            <option value="week">{{ __('email_marketing.week') }}</option>
                            <option value="month">{{ __('email_marketing.month') }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmailMarketing",
        props: ['data'],
        data() {
            return {
                triggers: [],
                selected_triggers: [],
                emailMarketing: this.data != undefined ? this.data : '',
                send_type: this.data != undefined ? this.data.send_type : '',
                send_in_param: this.data != undefined ? this.data.send_in_param : '',
                send_in_number: this.data != undefined ? this.data.send_in_number : ''
            }
        },
        methods: {
            showDateTimePicker(){
                let params = {
                    'behavioural': 0
                }
                axios.get('/admin/email-triggers/get-trigger-list', {params: params})
                    .then(response => {
                        this.triggers = response.data.triggers;
                    });

                $('#date_time_triggers').css('display', 'block');
                $('#behaviouralTriggerOptions').css('display', 'none');
                $('.email_trigger').attr("multiple", "multiple");

            },
            sendImmediately(){
                let params = {
                    'behavioural': 0
                }
                axios.get('/admin/email-triggers/get-trigger-list', {params: params})
                    .then(response => {
                        this.triggers = response.data.triggers;
                    });
                $('#date_time_triggers').css('display', 'none');
                $('#behaviouralTriggerOptions').css('display', 'none');
                $('.email_trigger').attr("multiple", "multiple");
            },
            SendBehaviouralOption(){
                let params = {
                    'behavioural': 1
                }
                axios.get('/admin/email-triggers/get-trigger-list', {params: params})
                    .then(response => {
                        this.triggers = response.data.triggers;
                    });

                $('#date_time_triggers').css('display', 'none');
                $('#behaviouralTriggerOptions').css('display', 'block');
                $('.email_trigger').removeAttr("multiple");
                //alert();
            }
        },
        mounted() {
            if (this.emailMarketing.send_type == 'sendBehaviouralTrigger'){
                this.selected_triggers = this.data !== undefined ? this.data.email_marketing_triggers[0].id : ''
                this.SendBehaviouralOption()
            }
            else if (this.emailMarketing.send_type == 'sendDateTime'){
                this.showDateTimePicker()
            }
            else {
                this.sendImmediately()
                this.send_type = 'sendImmediately'
            }
        }
    }
</script>

<style scoped>

</style>
