<template>
    <div id="mediaManagerModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mediaManagerModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-lg">

            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title"><i class="feather icon-file mr-05"></i>{{ __('media_manager.title') }}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <div class="col-md-8">

                            <div class="form-group">
                                <input class="form-control" name="search_term" id="search_term" placeholder="Enter a keyword to search" v-model="search_term" v-on:keyup="search"/>
                            </div>


                            <ul class="list-group overflow-y-scroll scroll" style="min-height: 500px; max-height: 500px">
                                <li class="list-group-item font-small-3">
                                    <div class="d-flex">
                                        <div class="width-50-per"><strong>{{ __('media_manager.file') }}</strong></div>
                                        <div class="width-30-per"><strong>{{ __('globals.type') }}</strong></div>
                                        <div class="width-20-per"></div>
                                    </div>
                                </li>

                                <li class="list-group-item font-small-3" v-for="file in mediafilelist" :key="file.id">
                                    <div class="d-flex">
                                        <div class="width-50-per"><a :href="file.media_file.url" target="_blank">{{ file.title }}</a></div>
                                        <div class="width-30-per">{{ fileType[file.media[0].mime_type] }}</div>
                                        <div class="text-right width-20-per ml-auto">
                                            <span class="badge badge-pill badge-success" :id="'link_copied'+file.id" style="display:none">Copied</span>
                                            <a href="#" @click.prevent="copylink(siteUrl+file.media_file.url, file.id)"><i style="font-size: 0.9em" class="feather icon-link" :title="__('media_manager.copy_link')"></i></a>
                                            <a href="#" @click.prevent="deleteMedia(file.id)"><i style="font-size: 0.9em" class="feather icon-trash-2" :title="__('media_manager.delete_media')"></i></a>

                                        </div>
                                    </div>
                                </li>


                            </ul>
                        </div>
                        <div class="col-md-4">

                            <h3><i class="feather icon-upload mr-05"></i>{{ __('media_manager.add_file') }}</h3>

                            <div class="form-group">
                                <label for="title">Title</label>
                                <input class="form-control" name="mediatitle" id="mediatitle" v-on:keyup="characterCheck" />
                                <div class="help-block text-muted font-small-1">
                                    {{ __('media_manager.num_chars_left') }} {{ maxCharacters }}
                                </div>
                            </div>

                            <input type="file" name="mediafile" id="mediafile">

                            <div class="help-block text-muted font-small-1" id="validation" style="display:none">
                                {{ __('media_manager.media_upload_para') }}
                            </div>
                            <div class="d-flex flex-row-reverse pt-1">
                                <button type="button" class="btn btn-success waves-effect waves-light" @click.prevent="save()">
                                    {{ __('globals.save') }} <i class="feather icon-check-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "MediaManager",

    data() {
        return {
            mediafilelist: [],
            search_term: '',
            siteUrl: window.App.siteUrl,
            maxCharacters: 40,
            fileType:
                {"text/csv": "CSV",
                "application/msword" : "Word DOC",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "Word DOC",
                "application/pdf" : "PDF",
                "application/vnd.ms-powerpoint" : "PowerPoint",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" : "PowerPoint",
                "text/plain" : "Text",
                "application/vnd.ms-excel" : "Excel Spreadsheet",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "Excel SpreadSheet",
                "application/zip" : "Zip File",
                "video/mp4" : "Video File"},

        }
    },
    methods: {
        characterCheck() {
            let mediatitle = $('input[name=mediatitle]').val();
            this.maxCharacters = 40 - mediatitle.length;
            if (this.maxCharacters === 0 || this.maxCharacters < 0){
                $('input[name=mediatitle]').val(mediatitle.slice(0, -1))
            }
        },
        save(){
            let file = $('input[name=mediafile]').val();
            let title = $('input[name=mediatitle]').val();

            if (file === '' || title ===''){
                $('#validation'+id).css('display', 'inline');
                setTimeout(function(){
                    $('#validation'+id).css('display', 'none');
                }, 5000);
            }
            else {
                axios.post('/media-manager', {
                    title: title,
                    file: file,
                })
                    .then(response => {
                        this.medialist()
                        $('input[name=mediatitle]').val('')
                        pondmediafile.removeFiles();
                    });
            }
        },
        deleteMedia(id){
            if(confirm("Confirm delete?")) {
                axios.delete('/media-manager/' + id)
                    .then(response => {
                        this.medialist()
                    });
            }
        },
        medialist(){
            axios.get('/media-manager')
                .then(response => {
                    this.mediafilelist = response.data
                });
        },
        search(){
            if (this.search_term !== '' && this.search_term.length > 3){
                axios.post('/media-manager/search', {
                    search_term: this.search_term,
                })
                    .then(response => {
                        this.mediafilelist = response.data

                    });
            }
            else {
                this.medialist();
            }

        },
        copylink(url, id) {
            navigator.clipboard.writeText(url);

            $('#link_copied'+id).css('display', 'inline');
            setTimeout(function(){
                $('#link_copied'+id).css('display', 'none');
            }, 1000);
        },

    },
    mounted(){
        this.medialist()
    },

}




</script>

<style scoped>
.scroll {
    height: 500px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
}

.scroll::-webkit-scrollbar {
    width: 6px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
</style>
