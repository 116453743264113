<template>
    <div>
        <div class="card-content">
            <span v-if="this.data.can_view_video === true">
                <div class="text-center" v-if="this.data.external_url === null">
                    <video-player :options="videoOptions" v-if="videoOptions.sources[0].src"/>
                </div>
                <div class="video_container" v-else>
                    <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="this.data.external_url"></iframe>
                </div>

            </span>
            <span v-else>
                <img class="img-fluid"
                     :src="this.data.cover == null ? this.data.thumbnail : this.data.cover.url">
            </span>

            <div class="card-body py-1 pl-1 pr-1">
                <div class="d-flex mt-0 mb-0 align-items-center justify-content-start">
                    <span class="h3"><a :href="'/member/video/'+this.data.slug" v-text="this.data.title" style="color:inherit;"></a></span>

                </div>

                <div>
                    <span class="font-small-3 text-bold-700 text-secondary video-view"><i class="feather icon-eye"></i> {{ this.data.views }} {{ __('globals.views') }}</span>
                    <span class="text-bold-700 text-secondary px-1">&bull;</span>
                    <span class="font-small-3 text-bold-700 text-secondary"><i class="feather icon-calendar"></i> {{ formatDate(this.data.created_at) }}</span>
                </div>

                <div class="pt-05 pb-05">
                    <div v-if="collapse_body" class="breakAll image-resize " v-linkified v-html="data.description"></div>
                    <div v-else :class="class_body+' image-resize '" ref="textBox" v-linkified v-html="data.description"></div>

                    <div v-if="enable_read_more">
                        <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                            {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                        </button>
                        <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                            {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="pt-1 pb-1 col-12 border-top" v-if="user.confirmed && data.can_view_video === false">
                <span v-if="data.price === null && this.lowest_plan_price !== null" class="font-small-2">
                    {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('plan.upgrade_plan_message') }}
                </span>
                <div v-else-if="data.price !== null" class="d-flex">
                        <span class="font-small-2">{{ __('globals.form.paragraph.your_price') }} {{ data.price | currency(site_tax) }} {{ __('globals.all_inclusive') }}</span>
                        <div class="ml-auto">
                            <a :href="'/member/video/'+data.slug+'/add_to_cart'" class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('video.form.links.pay_for_view') }} <i class="fa fa-tag"></i>
                            </a>
                        </div>
                </div>
            </div>
            <div id="progress" class="p-2" style="display: none"></div>
        </div>
    </div>
</template>

<script>

import VideoPlayer from "../../../VideoPlayer";

export default {
    name: "NewsfeedSharedVideo",
    props: ['data'],
    components: {VideoPlayer},
    data() {
        return {
            user: window.App.user,
            site_tax: window.App.site_tax,
            price_setting: window.App.price_setting,
            lowest_plan_name: null,
            lowest_plan_price: null,
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: typeof this.data.video_link !== undefined ? this.data.video_link : '',
                        type: 'video/mp4'
                    }
                ]
            },
            enable_read_more: false,
            collapse_body: false,
            class_body: 'breakAll',
        }
    },
    methods:{
        videoPrice(plan_prices) {
            if (this.price_setting === 'plan-price' && plan_prices.length > 0) {
                plan_prices.forEach(plan => {
                    if(plan.price !== null) {
                        // This is used to set the price based on lowest plan price if the user is not on a plan where price is available
                        if (this.lowest_plan_price === null) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        } else if (plan.price < this.lowest_plan_price) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        }
                    }
                })
            }
        },
        formatDate(date) {
            return moment(date).format('DD MMM YY');
        },
    },
    beforeMount() {
        this.videoPrice(this.data.video_plan_prices);
    },
    mounted(){
         if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>

<style>
.video_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
