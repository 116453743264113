<template>
    <div id="rating" style="display:none">
        <div v-for="(rating, index) in items"  :key="rating.id">
            <rating :data="rating" @deleted="remove(index)"></rating>
        </div>

        <new-rating v-if="enableRating" :data="type" :slug="slug" @created="add"></new-rating>
    </div>
</template>

<script>
    import Rating from './Rating';
    import NewRating from './NewRating';

    export default {
        props:['data', 'module', 'slug', 'count', 'enableRating'],
        components: { Rating, NewRating },
        name: 'Ratings',
        data() {
            return {
                items: this.data.ratings,
                type: this.module,
                user: window.App.user,
                are_you_sure_message: this.__('globals.are_you_sure'),
            }
        },
        methods : {
            add(rating) {
                this.items.push(rating);
            },
            remove(index) {
                if (confirm(this.are_you_sure_message)){
                    this.items.splice(index, 1);
                }
            }
        }
    }
</script>

<style scoped>

</style>
