<template>
    <div>
        <a href="#" @click.prevent="generateInvoice()"
           class="btn btn-outline-primary"><i class="feather icon-file-text"></i>
        </a>
    </div>
</template>

<script>
    export default {
        props: ['id'],
        name: "generateInvoice",
        data() {
            return {
                order_id: this.id,
                receipt_generated_successfully_message: this.__('globals.receipt_generated_successfully'),
            }
        },
        methods: {
            generateInvoice(id){
                axios.get('/admin/invoices/generate/'+this.order_id)
                .then(response => {
                    if (response.data.status == 200)
                    {
                        let flash_data = {
                            'message': this.receipt_generated_successfully_message,
                            'level': 'success'
                        }
                        flash(flash_data);
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
