<template>
    <div class="d-flex align-items-top ml-3 mr-1">
        <div class="card  mb-1 d-inline-block ml-05 w-100">
            <div class="d-flex border-bottom pb-05 m-05">

                <div class="pr-1 align-self-start">
                    <img class="round shadow"
                         :src="data.owner.avatar != null ? data.owner.avatar.url : '/images/avatar.png'"
                         alt="avatar"
                         height="25"
                         width="25"/>
                </div>
                <div class="pr-1">

                    <a :href="'/member/profiles/'+data.owner.username"
                       v-text="data.owner.first_name+' '+data.owner.last_name" class="text-bold-600 dark" >
                        <span class="text-bold-500 dark"> {{ __('comment.wrote') }}: </span>
                    </a>

                    <span v-for="badge in data.owner.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name">
                    </span>

                    <div style="margin-top: -5px;">
                        <span v-text="data.owner.position"
                                                                                                            class="font-small-2 text-light"></span><span
                        class="font-small-2 text-light"> {{ __('comment.at') }} </span>
                        <span v-text="data.owner.organisation" class="font-small-2 text-light"></span>
                    </div>

                </div>

                <div class="ml-auto" v-if="data.owner.id === this.user_id || administrator === true">
                  <span v-text="ago" class="text-light font-small-1 d-block"></span>
                    <button class="btn-sm btn-flat-dark float-right" style="border:none;" @click="editing = true"><i class="fa fa-edit"></i></button>
                    <button class="btn-sm btn-flat-danger float-right" style="border:none;" @click="destroy"><i class="fa fa-trash-o"></i></button>
                </div>
            </div>

            <div class="d-flex ml-1 mr-1 mb-1 mt-05">
                <div v-if="editing" class="form-group w-100 mb-0">

                    <div class="card mb-1 shared-post-reply">
                        <editor
                            v-model="comment"
                            api-key="no-api-key"
                            :init='{
                                skin: "trybz-member",
                                skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                external_plugins: {
                                    customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                    loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                },
                                valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                plugins: "link quickbars autolink lists customtags loadingbar",
                                link_context_toolbar: true,
                                quickbars_selection_toolbar: "bold italic link bullist",
                                quickbars_image_toolbar: false,
                                quickbars_insert_toolbar: false,
                                link_assume_external_targets: "https",
                                link_target_list: false,
                                link_title: false,
                                toolbar: false,
                                branding: false,
                                elementpath: false,
                                menubar: false,
                                file_picker_types: "",
                                height: 100,
                                content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                relative_urls : false,
                                remove_script_host : false,
                            }'
                        />
                    </div>

                    <button class="btn btn-sm btn-primary" @click="update">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click="editing = false">{{ __('globals.cancel') }}</button>
                </div>

                <div v-else v-html="comment" class="col-12">
                </div>
                <div class="p-05">

                </div>
            </div>

            <div class="card-body border-top ml-1 mr-1 pt-05 p-0 w-100">
                <div class="d-flex">
                    <div class="pb-05">
                        <like :post="data" type="comment" class="font-small-3 text-bold-700"></like>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment-timezone';
    // import linkify from 'vue-linkify';
    import Editor from '@tinymce/tinymce-vue'

    // Vue.directive('linkified', linkify)

    export default {
        props: ['data', 'administrator'],
        name: 'Comments',
        components: {
            'editor': Editor
        },
        data() {
            return {
                editing: false,
                //comment: this.data.comment_text.replace(/\n/g, "<br/>"),
                comment: this.data.comment_text,
                comment_updated_message: this.__('comment.comment_updated'),
                id: this.data.id,
                user_id: window.App.user.id,
            };
        },
        computed: {
            canUpdate() {
                return this.authorize(user => (this.data.user_id === user.id) || this.administrator === true)
            },
            ago() {
                return moment(this.data.created_at).format('DD-MM-YY');
            },
            commentsCount() {
                if (this.data.commentsCount > 0) {
                    return `(${this.data.commentsCount})`;
                }
            }
        },
        methods: {
            update() {
                axios.patch('/member/comment/' + this.data.id, {
                    comment_text: this.comment
                });

                this.editing = false;
                let data = {
                    'message': this.comment_updated_message,
                    'level': 'success'
                }
                flash(data);
            },
            destroy() {
                axios.delete('/member/comment/' + this.data.id)

                this.$emit('deleted', this.data.id);

            }
        }
    }
</script>

<style scoped>

</style>
