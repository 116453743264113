<template>

    <li class="dropdown dropdown-notification nav-item" v-if="notifications.length > 0">
        <a class="nav-link nav-link-label" style="color: #FFF;" href="#" data-toggle="dropdown" @click="iconClass = 'far fa-bell'">
            <i :class="iconClass" style="font-size: 1.5em"></i>
            <span class="badge badge-pill badge-primary badge-up" v-if="notificationCount > 0">
                {{ notificationCount }}
            </span>
        </a>
        <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right ">
            <li class="dropdown-menu-header">
                <div class="dropdown-header m-0 p-2">
                    <h3>
                        <span v-model="notificationCount"></span> {{ __('globals.new') }}
                    </h3>
                    <span class="grey darken-2">
                        {{ __('notifications.headers.member.notifications') }}
                    </span>
                </div>
            </li>
            <li>
                <div style="max-height: 23rem; overflow: hidden; overflow-y: scroll;">
                    <ul style="list-style:none;" class="p-0 notificationList">
                        <li class="media-list" v-for="(notification, index) in notifications" :key="index">
                            <a :href="notification.data.link+(notification.data.module === 'group' || notification.data.module === 'supplier' ? '?member-fill=1' : '')" target="_blank" @click="markAsRead(index)">
                                <div class="media d-flex align-items-center">
                                    <div class="media-left">
                                        <i class="feather icon-check-circle font-medium-5 primary"></i>
                                    </div>
                                    <div class="w-100" >
                                        <div class="media-body">
                                            <h6 class="primary media-heading mb-0" v-text="notification.data.heading"></h6>
                                            <small>
                                                <time class="media-meta text-muted mb-05" v-text="ago(notification.created_at)"></time>
                                            </small>
                                            <small class="notification-text d-block mt-05" v-linkified v-html="notification.data.message">
                                            </small>
                                        </div>
                                        <div class="d-flex mt-1" v-if="notification.data.contact_request === 'yes' && notification.type === 'App\\Notifications\\NewFollowUserNotification'">
                                            <button class="btn btn-success flex-fill mr-1" @click.prevent="acceptRequest(notification.data.username, index)">
                                                {{ __('globals.accept') }}
                                            </button>
                                            <button class="btn btn-danger flex-fill" @click.prevent="declineRequest(notification.data.username, index)">
                                                {{ __('globals.decline') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>

                </div>
            </li>
            <li class="dropdown-menu-footer"><a class="dropdown-item p-1 text-center" @click="markAllAsRead">{{ __('notifications.mark_all') }}</a></li>
        </ul>
    </li>
    <li class="dropdown dropdown-notification nav-item" v-else>
        <a class="nav-link nav-link-label" style="color: #FFF;" href="#" data-toggle="dropdown">
            <i :class="iconClass" style="font-size: 1.5em"></i>
        </a>
    </li>
</template>

<script>

import moment from 'moment-timezone';

export default {
    name: 'userNotifications',
    data() {
        return {
            notifications: [],
            notificationCount: 0,
            username: '',
            user: window.App.user,
            iconClass: 'far fa-bell',
            initialLoad: true,
        }
    },
    created() {
        window.Echo.private(`App.Models.User.${this.user.id}`).notification((notification) => {
            this.initialLoad = false;
            this.getNotifications();
        });
    },
    computed: {},
    mounted() {
        this.getNotifications();
    },
    methods: {
        getNotifications() {
            axios
                .get("/member/profiles/" + this.user.username + "/notifications")
                .then(response => {
                    if (response.data.length > 0) {
                        let beforeCount = this.notifications.length;
                        this.notifications = response.data;
                        if (! this.initialLoad && beforeCount < this.notifications.length) {
                            this.iconClass = 'fas fa-bell';
                        }
                        // if the length is more change the icon to fas fa-bell
                        response.data.forEach(i=>{
                            let elements = i.data.heading.split(" ");

                            if(elements[elements.length - 1] == "request"){
                                i.data["contact_request"] = 'yes';
                            } else {
                                i.data["contact_request"] = 'no';
                            }
                            if(i.data.link == undefined){
                                i.data['link'] = '';
                            }
                        })
                        this.notificationCount = response.data.length;
                    }
                });
        },
        ago(date) {
            return moment(date).fromNow();
        },
        markAsRead(index) {
            event.stopPropagation()
            axios
                .delete("/member/profiles/" + window.App.user.username + "/notifications/" + this.notifications[index].id)
                .then(() => {
                    this.notifications.splice(index, 1);
                    this.notificationCount--;
                })
                .catch(() => {
                    this.notifications.splice(index, 1);
                    this.notificationCount--;
                })
        },
        markAllAsRead() {
            axios
                .delete("/member/profiles/" + window.App.user.username + "/notifications/")
                .then(() => {
                    this.notifications = [];
                })
                .catch(() => {})
        },
        acceptRequest(username, index){
            if(this.submitting){
                 return false;
            }
            this.submitting = true;
            axios
                .post(`/member/user/${username}/accept-request`)
                .then(response => {
                    this.contactStatus = true;
                    this.flashMessage(response.data.message, 'success')
                    this.submitting = false;
                })
                .catch((error) => {
                    this.flashMessage('An error occurred', 'danger')
                    this.submitting = false;
                });
        },
        declineRequest(username, index){
            if(window.confirm(this.__('user.confirm.decline_request'))) {
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .delete(`/member/user/${username}/decline-request`)
                    .then(response => {
                        this.contactStatus = false;
                        this.flashMessage(response.data.message, 'danger')
                        this.submitting = false;
                    })
                    .catch((error) => {
                        this.flashMessage('An error occurred', 'danger')
                        this.submitting = false;
                    });
            } else {}
        },
        flashMessage(message, status){
            let flash_data = {
                'message': message,
                'level': status
            }
            flash(flash_data);
        }
    }
}
</script>

<style scoped>
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #c7c5c5;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: grey;
    }

    .notificationList li {
        border-bottom: 1px solid #ededed;
    }

    .notificationList li:last-child {
      border-bottom: none;
    }
</style>
