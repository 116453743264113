<template>
    <div>
        <div class="d-flex justify-content-between border-bottom">
            <div class="flex-grow-1">
                <newsfeed-owner-info
                        :user_data="data.module.owner"
                        :profile_data="data.profile"
                        :supplier_data="data.supplier"
                        :shared="false"
                        :type="data.type"
                        :time="data.published_at">
                </newsfeed-owner-info>
            </div>
            <div class="btn-group mt-05 mr-05">
                <span  v-if="pin === true">
                    <i class="fas fa-thumbtack text-primary mt-05 ml-05" style="font-size:0.7em;"></i>
                </span>
                <div class="dropdown">
                    <button class="btn-sm btn-outline-light dropdown-toggle " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                        <i class="fa fa-chevron-circle-down mr-0" title="Pinned Post"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                        <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin">
                            <a class="dropdown-item" href="#" @click.prevent="editPost">
                                <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                            </a>
                        </span>
                        <span v-if="((isServiceAdmin === true || user_id === data.module.owner.id || isAdmin) && !hide_questions)">
                            <a class="dropdown-item danger" href="#" @click.prevent="triggerQuestionModal">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.make_question') }}
                            </a>
                        </span>
                        <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || (data.profile !== null && user_id === data.profile.id) || isAdmin">
                            <a class="dropdown-item" href="#" @click.prevent="destroy">
                                <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                            </a>
                        </span>
                        <span v-if="(isServiceAdmin === true || isAdmin) && isPinPost === false && data.section === section">
                            <a class="dropdown-item" href="#" @click.prevent="pinPost">
                                <i class="fas fa-thumbtack" style="font-size: 1.0em; margin-bottom: 10px;"></i>{{ __('post.form.links.pin_post') }}
                            </a>
                        </span>
                        <span v-if="(isServiceAdmin === true || isAdmin) && isPinPost === true && data.section === section">
                            <a class="dropdown-item" href="#" @click.prevent="unpin">
                                <i class="fas fa-thumbtack" style="font-size: 1.0em; margin-bottom: 10px;"></i>{{ __('post.form.links.unpin_post') }}
                            </a>
                        </span>
                        <a class="dropdown-item danger" @click.prevent="report">
                            <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class=" mt-0 mb-0">
            <!-- Home newsfeed edit existing post-->
            <div v-if="editing" class="form-group w-100 pt-1 pl-1 pr-1">
                <!-- <textarea class="form-control mb-1" v-model="post_text"></textarea>-->
                <div class="card mb-1">
                    <editor
                        v-model="post_text"
                        api-key="no-api-key"
                            :init='{
                                skin: "trybz-member",
                                skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                external_plugins: {
                                    customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                    loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                },
                                valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                plugins: "link quickbars autolink lists customtags loadingbar",
                                link_context_toolbar: true,
                                quickbars_selection_toolbar: "bold italic link bullist",
                                quickbars_image_toolbar: false,
                                quickbars_insert_toolbar: false,
                                link_assume_external_targets: "https",
                                link_target_list: false,
                                link_title: false,
                                toolbar: false,
                                branding: false,
                                elementpath: false,
                                menubar: false,
                                file_picker_types: "",
                                height: 100,
                                content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                relative_urls : false,
                                remove_script_host : false,
                            }'
                    />
                </div>
                <button class="btn btn-sm btn-primary" @click.prevent="update">{{ __('globals.update') }}</button>
                <button class="btn btn-sm btn-link" @click.prevent="editing = false">{{ __('globals.cancel') }}</button>
            </div>

            <div v-else>
                <div class="pt-1 col-12" v-if="body !== null && body !== ''">
                    <div v-if="collapse_body">
                        <div class="breakAll" v-linkified v-html="body"></div>
                    </div>
                    <div v-else>
                        <div v-bind:class="class_body" :ref="section === 'sidebar' ? 'textBoxSidebar' : 'textBox'" v-linkified v-html="body"></div>
                    </div>

                    <div v-if="enable_read_more">
                        <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                            {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                        </button>
                        <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                            {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                        </button>
                    </div>
                </div>
            </div>

                <div class="col-12 p-0">

                        <div class="text-center" v-if="data.module.video_link !== null">
                            <video-player :options="videoOptions"/>
                        </div>
                        <div class="video_container" v-else-if="data.module.video_external_url !== null">
                                <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="data.module.video_external_url"></iframe>
                            </div>
                    <div class="gallery-row mb-05" v-if="this.data.module.post_image.length > 1">
                        <div class="gallery-container">
                            <lightbox :items="images"></lightbox>
                        </div>
                    </div>

                    <div class="mb-05" v-else-if="this.data.module.post_image.length === 1">
                        <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                            <img class=" img-fluid"
                                 :src="this.data.module.post_image[0].url">
                        </div>
                    </div>

                    <div class="mr-05 ml-05 mb-05" v-if="data.module.post_file.length > 0">
                        <a @click.prevent="downloadFile(file.id, index)" v-for="(file, index) in data.module.post_file">
                            <div class="card bg-outline-light text-center mb-05">
                                <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                                    <strong class="w-100 text-center"><i class="feather icon-download"></i> {{ __('post.form.buttons.download_file') }} ...{{ file.file_name.slice(-20) }} </strong>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>

        <div v-if="make_question">
            <question-modal :question_body="data.module.post_text" :module_id="data.module.id" type="post" :user_id="data.module.user_id" @close-modal="triggerQuestionModal" />
        </div>
    </div>
</template>

<script>
import Share from '../../../../mixins/newsfeed/Share'
import Post from '../../../../mixins/newsfeed/Post'
import DateTime from '../../../../mixins/newsfeed/DateTime'
import Editor from '@tinymce/tinymce-vue'
import lightbox from "../../lightbox/lightbox"
import "../../lightbox/lightbox.css"
import QuestionModal from "../../../post/QuestionModal.vue";

export default {
    name: "NewsfeedPost",
    components: {
        lightbox,
        'editor': Editor,
        QuestionModal
    },
    props: ['data', 'pin', 'questions_enabled'],
    data() {
        return {
            hide_questions: false,
            make_question: false,
        }
    },
    mixins: [
        Post, DateTime, Share
    ],
    mounted(){
        if(this.section === 'sidebar' && this.$refs.textBoxSidebar.clientHeight >= 150){
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade-sidebar';
        } else if (this.section === 'home' && this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
    methods: {
        init(){
            if(this.questions_enabled !== undefined || null){
                if(this.questions_enabled.enable_global_questions === null){
                    this.hide_questions = true;
                } else {
                    this.hide_questions = false;
                }
            } else {
                this.hide_questions = false;
            }
        },
        triggerQuestionModal(){
            this.make_question = !this.make_question;
        }
    },
    beforeMount(){
        this.init();
    },
}
</script>

<style>
</style>
