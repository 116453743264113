<template>
    <div>
        <div class="card">

            <div class="d-flex">
                <div v-if="data.suppliers !== undefined && data.suppliers.length === 0">
                    <div class="d-flex justify-content-start mb-05">
                        <div class="p-05">
                            <img class="round"
                                 :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                                 alt="avatar"
                                 height="30"
                                 width="30"/>
                        </div>
                        <div class="p-0  pt-05 justify-content-start">
                            <div class="user-name text-bold-600">
                                <div class="dropdown dropdown-user nav-item">
                                    <a class="dropdown-toggle dropdown-user-link"
                                       href="#"
                                       data-toggle="dropdown"
                                       v-text="data.module.owner.first_name+' '+data.module.owner.last_name">
                                    </a>
                                    <span v-for="badge in data.module.owner.badges">
                                    <img :src="badge.image" style="margin-top: -3px;  height: 15px;"
                                         data-toggle="tooltip"
                                         data-placement="bottom"
                                         :title="badge.name">
                                </span>
                                    <span v-if="data.module.owner.user_badge_id !== null">
                            <img :src="data.module.owner.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                                 data-toggle="tooltip"
                                 data-placement="bottom"
                                 :title="data.module.owner.user_badge_name">
                        </span>
                                    <div class="dropdown-menu dropdown-menu-center">
                                        <profile :data="data"></profile>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: -3px; line-height: 1;"><span v-text="data.module.owner.position"
                                                                                 class="font-small-2 text-light"></span><span
                                class="font-small-2 text-light"> at </span><span v-text="data.module.owner.organisation"
                                                                                 class="font-small-2 text-light"></span>
                            </div>
                        </div>
                        <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                            <span v-text="ago(data.module.created_at)" class="font-small-1 text-light "
                                                  style="margin-top: 4px;"></span>
                            <div class="dropdown">
                                <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        style="border:none">
                                    <i class="fa fa-chevron-circle-down mr-0"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                                    <div v-if="canUpdate || isAdmin === true">
                                        <a class="dropdown-item"
                                           :href="'/member/zoom-webinar/'+data.module.zoom_id+'/edit'">
                                            <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                        </a>
                                        <a class="dropdown-item" href="#" @click="destroy">
                                            <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                        </a>
                                    </div>
                                    <a class="dropdown-item danger" @click.prevent="report">
                                        <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="data.suppliers !== undefined && data.suppliers.length === 1">
                    <div class="d-flex justify-content-start mb-05">
                        <div class="p-05">
                            <img class="round"
                                 :src="data.suppliers[0].supplier_avatar !== null ? data.suppliers[0].supplier_avatar.url :  '/images/avatar.png'"
                                 alt="avatar"
                                 height="30"
                                 width="30"/>
                        </div>
                        <div class="p-0  pt-05 justify-content-start">
                            <div class="user-name text-bold-600 text-dark">
                                <a href="#" class="text-dark" v-text="data.suppliers[0].title"></a>
                            </div>
                            <div style="margin-top: -5px" class="font-small-2 text-primary">@{{
                                    data.suppliers[0].slug
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-content">
                <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                    <img class=" img-fluid"
                         :src="data.module.cover === null ? '/images/cover.jpg' : data.module.cover.url">
                </div>
                <div class="card-body p-0">

                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                            <span class="h3"><a :href="'/member/zoom-webinar/'+data.module.zoom_id"
                                                v-text="data.module.title"
                                                style="color:inherit;"></a></span>

                        <div class="ml-auto">
                            <div
                                class="badge badge-pill bg-gradient-secondary"
                                style=" padding: 0.2rem 0.5rem;">
                                <i class="feather icon-video"></i> {{ __('webinar.title_singular') }}
                            </div>
                        </div>
                    </div>

                    <div class="d-flex ml-1 mr-1 mt-0">
                        <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span
                        class="h6 primary" v-text="showDateTime"></span>

                    </div>

                    <div class=" pt-1 pl-1 pr-1 border-top">
                        <div class="breakAll mb-0" v-linkified
                             v-html="data.module.description.substring(0,550)"></div>
                        <div><a :href="'/member/zoom-webinar/'+data.module.zoom_id" class="font-small-3">{{ __('globals.read_more') }} <i
                            class="feather icon-chevron-right"></i></a></div>

                    </div>

                    <div class="d-flex border-top" v-if="data.module.event_price > 0">
                        <div
                            class="d-flex ml-05 mr-05  mb-0 pb-05 pt-05 border-top align-items-center justify-content-start">
                            <span class="font-small-2 ml-05"> {{ data.module.event_price | currency(site_tax) }} </span>
                        </div>

                        <div class="ml-auto p-1">
                            <a :href="'/member/zoom-webinar/'+data.module.zoom_id+'/add_to_cart'"
                               class="btn btn-sm btn-primary waves-effect waves-light">
                                <i class="fa fa-dollar"></i> {{ __('globals.buy_now') }}
                            </a>
                        </div>
                    </div>
                    <div class="d-flex border-top" v-if="data.module.event_price === 0">
                        <div class="ml-auto p-1">
                            <a :href="'/member/zoom-webinar/'+data.module.zoom_id+'/register'"
                               class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('webinar.form.buttons.register_now') }}
                            </a>

                        </div>
                    </div>


                    <div class="ml-05 mr-05" v-if="data.groups !== undefined && data.groups.length > 0">
                        <a :href="'/member/group/'+data.groups[0].slug">
                            <div class="card text-white">
                                <img class="card-img img-fluid" style="height: 3rem;"
                                     :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                <div
                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                        {{ data.groups[0].title }}</p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                        class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="ml-05 mr-05" v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                        <a :href="'/member/'+data.suppliers[0].type+'/'+data.suppliers[0].slug">
                            <div class="card text-white">
                                <img class="card-img img-fluid" style="height: 3rem;"
                                     :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                <div
                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{
                                            data.suppliers[0].title
                                        }}</p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                        class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>

                    </div>
                    <div class="d-flex justify-content-between border-top ml-05 mr-05">
                        <div class="p-05">
                            <like :post="data.module" type="zoom-webinar"></like>
                        </div>
                        <div class="p-05" style="margin-top: 1px;">
                            <a
                                @click.prevent="showComments(data.id)" class="font-small-3 text-bold-700">
                                <i class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span>
                            </a>
                        </div>
                        <social-share :id="data.id" :social="this.data.module.socialShare"/>
                    </div>

                </div>
            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  module="zoom-webinar"
                  :slug="data.module.zoom_id"
                  :members="members"
        ></comments>

        <div class="modal fade" :id="'shareModal'+data.id" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ __('webinar.headers.admin.share_webinar') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="p-1 card border-light">
                            <div class="">
                                <label :for="'comment_body'+data.id">{{ __('globals.enter_comment') }}</label>
                                <textarea :name="'comment_body'+data.id" :id="'comment_body'+data.id" cols="30" rows="3"
                                          class="form-control"></textarea>
                            </div>
                            <div class="pb-1">
                                <label :for="'feed'+data.id">{{ __('globals.select_share') }}</label>
                                <select :name="'feed'+data.id" :id="'feed'+data.id" class="form-control">
                                    <option value="profile">{{ __('globals.your_newsfeed') }}</option>
                                    <optgroup :label="__('globals.share_group')" v-if="groups_follow !== undefined">
                                        <option :value="'group_'+group.id" v-for="group in groups_follow">{{
                                                group.title
                                            }}
                                        </option>
                                    </optgroup>
                                    <optgroup :label="__('globals.share_supplier')"
                                              v-if="suppliers_follow !== undefined">
                                        <option :value="'supplier_'+supplier.id"
                                                v-for="supplier in suppliers_follow">{{ supplier.title }}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="card">

                                <div class="d-flex">
                                    <div v-if="data.suppliers !== undefined && data.suppliers.length === 0">
                                        <div class="d-flex justify-content-start mb-05">
                                            <div class="p-05">
                                                <img class="round"
                                                     :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                                                     alt="avatar"
                                                     height="30"
                                                     width="30"/>
                                            </div>
                                            <div class="p-0  pt-05 justify-content-start">
                                                <div class="user-name text-bold-600">
                                                    <div class="dropdown dropdown-user nav-item">
                                                        <a class="dropdown-toggle dropdown-user-link"
                                                           href="#"
                                                           data-toggle="dropdown"
                                                           v-text="data.module.owner.first_name+' '+data.module.owner.last_name">
                                                        </a>
                                                        <span v-for="badge in data.module.owner.badges">
                                    <img :src="badge.image" style="margin-top: -3px;  height: 15px;"
                                         data-toggle="tooltip"
                                         data-placement="bottom"
                                         :title="badge.name">
                                </span>
                                                        <span v-if="data.module.owner.user_badge_id !== null">
                            <img :src="data.module.owner.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                                 data-toggle="tooltip"
                                 data-placement="bottom"
                                 :title="data.module.owner.user_badge_name">
                        </span>
                                                        <div class="dropdown-menu dropdown-menu-center">
                                                            <profile :data="data"></profile>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="margin-top: -3px; line-height: 1;"><span
                                                    v-text="data.module.owner.position"
                                                    class="font-small-2 text-light"></span><span
                                                    class="font-small-2 text-light"> at </span><span
                                                    v-text="data.module.owner.organisation"
                                                    class="font-small-2 text-light"></span>
                                                </div>
                                            </div>
                                            <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                            <span v-text="ago(data.module.created_at)" class="font-small-1 text-light "
                                                  style="margin-top: 4px;"></span>
                                                <div class="dropdown">
                                                    <button class="btn-sm btn-outline-light dropdown-toggle "
                                                            type="button"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false"
                                                            style="border:none">
                                                        <i class="fa fa-chevron-circle-down mr-0"></i>
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                                                        <div v-if="canUpdate || isAdmin === true">
                                                            <a class="dropdown-item"
                                                               :href="'/member/zoom-webinar/'+data.module.zoom_id+'/edit'">
                                                                <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                                            </a>
                                                            <a class="dropdown-item" href="#" @click="destroy">
                                                                <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                                            </a>
                                                        </div>
                                                        <a class="dropdown-item danger" @click.prevent="report">
                                                            <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="data.suppliers !== undefined && data.suppliers.length === 1">
                                        <div class="d-flex justify-content-start mb-05">
                                            <div class="p-05">
                                                <img class="round"
                                                     :src="data.suppliers[0].supplier_avatar !== null ? data.suppliers[0].supplier_avatar.url :  '/images/avatar.png'"
                                                     alt="avatar"
                                                     height="30"
                                                     width="30"/>
                                            </div>
                                            <div class="p-0  pt-05 justify-content-start">
                                                <div class="user-name text-bold-600 text-dark">
                                                    <a href="#" class="text-dark" v-text="data.suppliers[0].title"></a>
                                                </div>
                                                <div style="margin-top: -5px" class="font-small-2 text-primary">
                                                    @{{ data.suppliers[0].slug }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                                        <img class=" img-fluid"
                                             :src="data.module.cover === null ? '/images/cover.jpg' : data.module.cover.url">
                                    </div>
                                    <div class="card-body p-0">

                                        <div
                                            class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                            <span class="h3"><a :href="'/member/zoom-webinar/'+data.module.zoom_id"
                                                v-text="data.module.title"
                                                style="color:inherit;"></a></span>

                                            <div class="ml-auto">
                                                <div
                                                    class="badge badge-pill bg-gradient-secondary"
                                                    style=" padding: 0.2rem 0.5rem;">
                                                    <i class="feather icon-video"></i> {{ __('webinar.title_singular') }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex ml-1 mr-1 mt-0">
                                            <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span
                                            class="h6 primary" v-text="showDateTime"></span>

                                        </div>

                                        <div class=" pt-1 pl-1 pr-1 border-top">
                                            <div class="breakAll mb-0" v-linkified
                                                 v-html="data.module.description.substring(0,550)"></div>
                                            <div><a :href="'/member/zoom-webinar/'+data.module.zoom_id"
                                                    class="font-small-3">{{ __('globals.read_more') }} <i
                                                class="feather icon-chevron-right"></i></a></div>

                                        </div>

                                        <div class="ml-05 mr-05"
                                             v-if="data.groups !== undefined && data.groups.length > 0">
                                            <a :href="'/member/group/'+data.groups[0].slug">
                                                <div class="card text-white">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                            {{ data.groups[0].title }}</p>
                                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                                            class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="ml-05 mr-05"
                                             v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                                            <a :href="'/member/'+data.suppliers[0].type+'/'+data.suppliers[0].slug">
                                                <div class="card text-white">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{
                                                                data.suppliers[0].title
                                                            }}</p>
                                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                                            class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevent="sharePost(data.id)">{{ __('globals.share') }}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import linkify from 'vue-linkify'
import moment from "moment-timezone";
import Share from '../../../mixins/newsfeed/Share'
import DateTime from '../../../mixins/newsfeed/DateTime'
import Profile from '../../../components/post/Profile'

Vue.directive('linkified', linkify)

export default {
    name: "NewsfeedZoomWebinar",
    props: ['data', 'section', 'subscriptionname', 'registered', 'low_price', 'price_title', 'members', 'groups_follow', 'suppliers_follow',],
    components: {Profile},
    data() {
        return {
            zoom: this.data,
            user_id: window.App.user.id,
            user: window.App.user,
            sidebar: this.section === 'sidebar',
            price: '',
            social: '',
            plan_prices: '',
            is_registered: this.registered,
            site_tax: window.App.site_tax,
            price_setting: window.App.price_setting
        }
    },
    mixins: [
        Share, DateTime
    ],
    methods: {
        showComments(id) {
            $('#comment-' + id).toggle();
        },
        getSocialLinks() {
            axios.get('/member/zoom-webinar/' + this.data.module.zoom_id + '/social')
                .then(response => {
                    this.social = response.data;
                });
        },
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/zoom-webinar/' + this.data.module.id);
                this.$emit('deleted', this.data.module.id);
            }
        },
        report() {
            $('#report_id').val(this.data.zoom_id);
            $('#report_type').val('App\\Models\\ZoomWebinar');
            $('#post_title').text(this.data.module.title.slice(0, 50));
            $('#reportModal').modal('toggle');
        }
    },
    computed: {
        postOwnerLink: function () {
            return (this.data.post_as === 'supplier')
                ? `/member/supplier/${this.data.news_feed.newsfeed_supplier[0].slug}`
                : '#'
        },
        postOwnerName: function () {
            return (this.data.post_as === 'supplier')
                ? this.data.news_feed.newsfeed_supplier[0].title
                : this.data.module.owner.first_name + ' ' + this.data.module.owner.last_name
        },
        canUpdate() {
            return this.authorize(user => this.data.user_id === user.id)
        },

        commentsCount() {
            if (this.data.module.comments.length) {
                return `(${this.data.module.comments.length})`;
            }
        },
        showDateTime() {
            return moment(this.data.module.webinar_at).format('LLL');
        },
    },
    mounted() {
        axios
            .get('/member/zoom-webinar/' + this.data.module.zoom_id + '/get_all_price_plans')
            .then(response => {
                this.plan_prices = response.data.plan_prices;
            });

        this.getSocialLinks();
    }
}
</script>
