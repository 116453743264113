<template>
    <div :class="'d-flex justify-content-start w-100'+ (time !== null ? ' border-bottom' : '')">
        <div class="p-05">
            <div v-if="supplier_data != null && supplier_data.pivot.post_as === 'supplier'">
                 <img class="round" :src="supplier_data.supplier_avatar !== null ? supplier_data.supplier_avatar.url :  '/images/avatar.png'"
                 alt="avatar"
                 height="40"
                 width="40"/>
            </div>
            <div v-else>
                <img class="round" :src="user_data !== null ? user_data.avatar.url :  '/images/avatar.png'"
                     alt="avatar"
                     height="40"
                     width="40"/>
            </div>
        </div>
        <div class="p-0 pt-05 justify-content-start">
            <div class="user-name text-bold-600 text-dark">
                <div class="dropdown dropdown-user nav-item" v-if="supplier_data != null && supplier_data.pivot.post_as === 'supplier'">
                    <a class="text-dark" :href="postOwnerLink" v-text="postOwnerName">
                    </a>
                </div>
                 <div class="nav-item" v-else>
                    <div class="nav-item">
                        <span v-text="postOwnerName"></span>
                        <span>
                            <img v-if="user_data.roles[0].title === 'Admin'" src="/images/badges/admin.png" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" title="Administrator"/>

                            <img v-for="badge in user_data.badges" :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="badge.name"/>

                            <img v-if="user_data.user_badge_id !== null && user_data.user_badge_image != null" :src="user_data.user_badge_image.url" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="user_data.user_badge_name"/>
                        </span>
                        <span class="text-bold-400" v-if="shared === true" >{{ __('globals.shared') }} a {{ type }}</span>
                     </div>
                </div>
            </div>
            <div  style="margin-top: -5px;" v-if="supplier_data != null && supplier_data.pivot.post_as === 'supplier'">
                <span v-text="'@'+supplier_data.slug" class="font-small-2 text-primary"></span>
            </div>
            <div  style="margin-top: 3px; line-height: 1;" class="mr-2"  v-else>
                 <div style="margin-top: -5px;" class="user-name text-bold-600">
                     <span v-text="user_data.position" class="font-small-2 text-muted"></span>
                     <span class="font-small-2 text-muted"> |</span> <span v-text="user_data.organisation" class="font-small-2 text-muted"></span>
                 </div>
            </div>
        </div>

        <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;" v-if="time !== null">
            <span v-text="ago" class="font-small-1 text-light" style="margin-top: 4px;">
            </span>
        </div>
    </div>
</template>

<script>

import moment from 'moment-timezone';

export default {
    name: "GuestPostOwnerInfo",
    props: ['user_data', 'supplier_data', 'shared', 'type', 'time'],
    computed: {
        postOwnerName: function () {
            return (this.supplier_data != null && this.supplier_data.pivot.post_as === 'supplier')
                ? this.supplier_data.title
                : this.user_data.first_name+' '+this.user_data.last_name

        },
        postOwnerLink: function () {
            return (this.supplier_data != null && this.supplier_data.pivot.post_as === 'supplier')
                ? `/supplier/${this.supplier_data.slug}`
                : '#'
        },
        ago() {
            return moment(this.time).format('DD-MM-YY');
        },
    },
}
</script>
