<template>
    <div>
        <div class="card-content">
            <img :class="'img-fluid product-image'" v-if="this.data.product_image.length > 0"
                     :src="this.data.product_image[0].url">
            <div class=" mt-1 ml-1 mr-1 mb-05">
                    <span class="h3"><a :href="'/member/product/'+this.data.slug"
                                        v-text="this.data.title" class="text-dark"></a></span>
            </div>

            <div class="mt-05 ml-1 mr-1" v-if="average_rating !== null && average_rating !== 0">
                <span :class="'rating-static rating-'+average_rating * 10">  </span>
            </div>

            <div class="pt-05 pl-1 pr-1 pb-05">
                        <div v-if="collapse_body" class="breakAll image-resize postarea" v-linkified v-html="data.product_description"></div>
                        <div v-else :class="class_body+' image-resize postarea'" ref="textBox" v-linkified v-html="data.product_description"></div>

                        <div v-if="enable_read_more">
                             <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                            </button>
                            <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>
            </div>

            <div class="text-right mb-05 mr-1 mr-1">
                <a :href="'/member/product/'+this.data.slug" class="font-small-3 ml-1">{{ __('globals.read_more') }} <i
                    class="feather icon-chevron-right"></i>
                </a>
            </div>

            <div class="d-flex p-05 justify-content-between align-items-center border-top">
                <div v-if="this.data.price !== null">
                    {{ __('globals.form.paragraph.your_price') }} {{ this.data.price | currency(site_tax) }}
                </div>
                 <span v-else-if="this.lowest_plan_price !== null" class="font-small-2 pl-05 pr-05">
                     {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('plan.upgrade_plan_message') }}
                 </span>
                <div class="ml-auto">
                    <a :href="'/member/product/'+this.data.slug"
                           class="btn btn-sm btn-flat-primary text-bold-700 ">View
                            {{ __('globals.view_product') }} <i class="feather icon-chevron-right"></i></a>
                    <a :href="'/member/product/'+this.data.slug" v-if="this.data.external_link !== null">
                            <button class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('globals.more_info') }} <i class="feather icon-chevron-right"></i>
                            </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "NewsfeedSharedProduct",
    props: ['data'],
    data() {
        return {
            prices_array: [],
            average_rating: null,
            site_tax: window.App.site_tax,
            lowest_plan_name: null,
            lowest_plan_price: null,
            price_setting: window.App.price_setting,
            enable_read_more: false,
            collapse_body: false,
            class_body: 'breakAll',
        }
    },
    methods: {
        init() {
            let ratings_array = [];
            if(this.data.ratings.length > 0){
                this.data.ratings.forEach(rating => {
                    ratings_array.push(rating.rating);
                })
            }
            let sum = ratings_array.reduce((a, b) => a + b, 0);
            this.average_rating = Math.ceil((sum / ratings_array.length) || 0);
        },
        productPrice(plan_prices) {
            if (this.price_setting === 'plan-price' && plan_prices.length > 0) {
                plan_prices.forEach(plan => {
                    if(plan.price !== null) {
                        // This is used to set the price based on lowest plan price if the user is not on a plan where price is available
                        if (this.lowest_plan_price === null) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        } else if (plan.price < this.lowest_plan_price) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        }
                    }
                })
            }
        },
    },
    mounted(){
        if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
    beforeMount() {
        this.init();
        this.productPrice(this.data.product_plan_prices);
    }
}
</script>
