<template>
    <div class="modal fade" id="reportModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="">
                        <i class="feather icon-briefcase"></i>
                        {{ __('report.report_details') }}
                    </h4>
                    <button type="button" class="close" @click.prevent="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">

                    <template v-if="report !== null">

                        <p>{{ __('report.reported_by') }}: {{ report.user.full_name }}</p>
                        <p>{{ __('report.reported_date') }}: {{ timeFormat(report.updated_at) }}</p>
                        <p>{{ __('report.comment_by') }} {{ report.user.first_name }}: {{ report.comment }}</p>

                        <p>{{ __('report.reported_content') }}:</p>

                        <div :class="'card border-light '+ class_body" style="pointer-events: none;" ref="newsfeedCard">

                            <template v-if="report.module !== null && report.module.owner !== null">
                                <div class="border-bottom pr-05">
                                    <newsfeed-owner-info
                                       :user_data="report.module.owner"
                                       :profile_data="null"
                                       :supplier_data="null"
                                       :shared="false"
                                       :time="report.module.created_at ? report.module.created_at : report.module.updated_at">
                                    </newsfeed-owner-info>
                                </div>

                                <div v-if="report.reportable_type === 'App\\Models\\Post'">
                                    <newsfeed-shared-post :data="report.module"></newsfeed-shared-post>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Resource'">
                                    <newsfeed-shared-resource :data="report.module"></newsfeed-shared-resource>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Product'">
                                    <newsfeed-shared-product :data="report.module"></newsfeed-shared-product>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Video'">
                                    <newsfeed-shared-video :data="report.module"></newsfeed-shared-video>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Event'">
                                    <newsfeed-shared-event :data="report.module"></newsfeed-shared-event>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Job'">
                                    <newsfeed-shared-job :data="report.module"></newsfeed-shared-job>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Stack\\Question'" class="p-1">
                                    <p class="font-medium-3 text-dark text-bold-700">
                                       {{ report.module.title }}
                                    </p>
                                    <p v-html="report.module.body"></p>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Newsfeed'" class="p-1">
                                    <p v-html="report.module.body"></p>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Stack\\QuestionResponse'" class="p-1">
                                    <p v-html="report.module.answer"></p>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Stack\\Comment'" class="p-1">
                                    <p v-html="report.module.reply"></p>
                                </div>

                                <div v-else-if="report.reportable_type === 'App\\Models\\Comment'" class="p-1">
                                    <p v-html="report.module.comment_text"></p>
                                </div>
                            </template>

                            <div class="text-center m-1 p-1 bg-outline-gray" v-else>
                                <div v-if="report.module === null">
                                    {{ __('newsfeed.deleted_post') }}
                                </div>
                                <div v-else-if="report.module.owner === null">
                                    {{ __('newsfeed.deleted_owner') }}
                                </div>
                            </div>
                        </div>

                        <div class="text-right" v-if="post_link !== '#'">
                            <a :href="post_link" style="color:inherit" class="btn-sm btn-flat-light text-bold-700">
                                {{ __('globals.view') }} <i class="feather icon-arrow-right"></i>
                            </a>
                        </div>
                    </template>

                    <div class="text-center" v-else>
                        <span class="spinner-border spinner-border-md text-primary"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment-timezone";

    export default {
        props: ['report_id'],
        name: "ReportModal",
        data() {
            return {
                report: null,
                post_link: '#',
                class_body: '',
            }
        },
        methods: {
            getReport(){
                axios   .get('/admin/report/'+this.report_id)
                        .then((response) => {
                            this.report = response.data.data;
                            this.postLink(this.report);
                            this.adjustHeight();
                        })
                        .catch(error => {
                        })
            },
            close() {
                $('#reportModal').modal('hide');
            },
            timeFormat(time) {
                return moment(time).format('D MMM, YYYY');
            },
            postLink(report){
                switch(report.reportable_type){
                    case 'App\\Models\\Post':
                        return this.post_link = '/member/post/'+report.module.id;
                    case 'App\\Models\\Stack\\Question':
                        return this.post_link = '/member/question/'+report.module.slug;
                    case 'App\\Models\\Newsfeed':
                        return this.post_link = '/member/newsfeed/'+report.module.uuid;
                    case 'App\\Models\\Resource':
                        return this.post_link = '/member/'+report.module.type+'/'+report.module.slug;
                    case 'App\\Models\\Video':
                        return this.post_link = '/member/video/'+report.module.slug;
                    case 'App\\Models\\Job':
                        return this.post_link = '/member/job/'+report.module.slug;
                    case 'App\\Models\\Product':
                        return this.post_link = '/member/product/'+report.module.slug;
                    case 'App\\Models\\Event':
                        return this.post_link = '/member/event/'+report.module.slug;
                    default:
                        return null;
                }
            },
            adjustHeight(){
                this.$nextTick(() => {
                    if(this.$refs.newsfeedCard.clientHeight >= 200) {
                        this.class_body = 'overflow-fade';
                    }
                });
            }
        },
        mounted() {
            let vm = this;
            $('#reportModal').modal('show');
            $('#reportModal').on('hidden.bs.modal', function() {
                vm.$emit('closeModal');
            });
        },
        beforeMount() {
            this.getReport();
        }
    }
</script>

<style>

</style>
