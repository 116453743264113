<template>
    <div>
        <search :categories="this.category" :types="this.types" :filters="this.search_filters"
                v-on:searchType="changeFilter($event)"></search>
        <div v-if="this.user.user_categories.length === 0 && this.search_filters.default === 'user'">
            <div class="alert alert-danger" role="alert">
                {{ __('search.paragraph.no_interests') }} <a :href="'/member/profiles/'+this.user.username+'/edit'">{{ __('globals.click_here') }}</a>
            </div>
        </div>
        <div class="row match-height pl-05 pr-05 mt-2 pb-1">
            <div class="col-4 col-md-3 col-lg-2 pl-05 pr-05" v-for="(product, index) in products" :key="infiniteId+index">
                <products-infinite-scroll-card :product="product" :service_settings="service_settings" />
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results"> {{ __('globals.no_products_found') }}</div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Search from '../Search'

export default {
    name: "ProductInfiniteScroll",
    components: {InfiniteLoading, Search},
    props: ['category', 'types', 'filters', 'service_settings'],
    data() {
        return {
            products: [],
            page: 1,
            user: window.App.user,
            confirmed_user: window.App.user.confirmed,
            search: this.filters.default,
            infiniteId: +new Date(),
            categoryId: '',
            keyword: '',
            search_filters: this.filters,
            site_tax: window.App.site_tax,
            price_setting: window.App.price_setting,
        }
    },
    methods: {
        infiniteHandler($state) {
            let vm = this;
            axios
                .get(this.getUrl())
                .then(response => {
                    if (response.data.data.length > 0) {
                        $.each(response.data.data, function (key, value) {
                            vm.products.push(value);
                        });
                        this.matchHeight();
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getUrl() {
            if (this.search === "my") {
                return "/member/product/my?page=" + this.page;
            }

            if (this.search === "category") {
                return "/member/product/search/" + this.search + "?categoryId=" + this.categoryId + "&page=" + this.page;
            }

            if (this.search === "type") {
                return "/member/product/search/" + this.search + "?typeId=" + this.typeId + "&page=" + this.page;
            }

            if (this.search === "keyword") {
                return "/member/product/search/" + this.search + "?keyword=" + this.keyword + "&page=" + this.page;
            }

            return "/member/product/search/" + this.search + "?page=" + this.page;
        },
        changeFilter(args) {
            if (args.type === "category") {
                this.categoryId = args.categoryId
            }

            if (args.type === "keyword") {
                this.keyword = args.keyword
            }

            if (args.type === "type") {
                this.typeId = args.typeId
            }

            this.search = args.type;
            this.page = 1;
            this.products = [];
            this.infiniteId += 1;
        },
        matchHeight() {
          let iteration = 0;
          let intervalId = setInterval(function () {
            if (iteration < 10) {
              $(".row.match-height").each(function () {
                $(this).find(".card").not(".card .card").matchHeight(); // Not .card .card prevents collapsible cards from taking height
              });
              iteration++;
            } else {
              clearInterval(intervalId);
            }
          }, 100);
        }
    },
    mounted() {
        if (this.user.user_categories.length === 0 && this.search_filters.default !== 'my'){
            this.search = "all";
        }

        if (this.search_filters.default === 'my') {
            this.search = "my";
        }
    }
}
</script>
