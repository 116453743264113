<template>

    <div class="form-group">
        <label for="postal_country"><slot name="country_label"></slot></label>
        <select class="form-control" :name="this.cname" :id="this.cname" v-model="selected_country" @change="get_states">
            <option>{{ __('globals.select') }} <slot name="country_label"></slot></option>
            <option v-for="country in countries" :value="country" :key="country">{{country}}</option>
        </select>
        <span class="help-block" role="alert"></span>
        <span class="help-block text-muted font-small-1"></span>
    </div>

<!--        <div class="col-12 col-md-6">-->
<!--            <div class="form-group">-->
<!--                <label for="postal_state"><slot name="state_label"></slot></label>-->
<!--                <select class="form-control" :name="this.sname" :id="this.sname" v-model="selected_state">-->
<!--                    <option>{{ __('globals.select') }} <slot name="state_label"></slot></option>-->
<!--                    <option v-for="state in states" :value="state" :key="state">{{state}}</option>-->
<!--                </select>-->
<!--                <span class="help-block" role="alert"></span>-->
<!--                <span class="help-block text-muted font-small-1"></span>-->
<!--            </div>-->
<!--        </div>-->
</template>

<script>
    export default {
        props: ['country', 'cname'],
        // props: ['country', 'state', 'cname', 'sname'],
        name: "CountryStatePicker",
        data() {
            return {
                selected_country: this.country,
                // selected_state: this.state,
                countries: [],
                // states: [],
                name_country: this.cname,
                // name_state: this.sname
            }
        },
        mounted() {
            this.get_countries();
            // if (this.selected_country !== '')
            // {
            //     this.get_states()
            // }
        },
        methods: {
            // get_states()
            // {
            //     let vm = this;
            //     axios.get('/member/profile/get-states/'+this.selected_country)
            //         .then(response => {
            //             this.states = [];
            //             $.each(response.data.states, function(key, value)
            //             {
            //                 vm.states.push(value);
            //             });
            //         });
            // },
            get_countries()
            {
                let vm = this;
                axios.get('/member/profile/get-countries')
                    .then(response => {
                        response.data.countries.sort();
                        $.each(response.data.countries, function(key, value)
                        {
                            vm.countries.push(value);
                        });
                    });
            }

        }
    }
</script>

<style scoped>

</style>
