
import VideoPlayer from "../../components/VideoPlayer";
import linkify from 'vue-linkify'
import Share from '../../mixins/newsfeed/Share'
import DateTime from '../../mixins/newsfeed/DateTime'

Vue.directive('linkified', linkify)
import PostOwnerInfo from '../../components/post/PostOwnerInfo'

export default {
    name: "Post",
    mixins: [
        Share, DateTime
    ],
    props: ['post', 'section'],
    components: {PostOwnerInfo, VideoPlayer},
    data() {

        // When uploading a post video a 'trybz_pending_video' placeholder is added then replaced when the upload completes.
        let vid_src = '';
        if(this.post.video_link !== undefined){
            if(this.post.video_link && this.post.video_link.includes('trybz_pending_video')){
                // vid_src = window.location.origin + '/videos/pending.mp4';
                vid_src = '/videos/pending.mp4';
            } else {
                vid_src = this.post.video_link;
            }
        }

        return {
            question: false,
            post_text: this.post.post_text,
            body: this.post.post_text,
            images: [],
            question_title: '',
            question_text: '',
            thesection: this.data.section,
            isPinPost: this.data.pin_to_top === 1,
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: vid_src,
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    methods: {
        questionPost() {
            this.question = true;
            this.question_text = this.post_text;
        },
        editPost() {
            this.editing = true;
        },
        destroy() {
            if (confirm('Are you sure you wish to delete this post?')) {
                axios.delete('/member/post/' + this.post.id);
                if(window.location.href.includes('/member/post')){
                    document.location.href='/member';
                }
                if(this.section === 'sidebar'){
                    location.reload();
                } else {
                    this.$parent.$emit('deleted', this.post.id);
                }
            }
        },
        unpin() {
            if (confirm('Are you sure you wish to unpin this post')) {
                axios.post('/member/post/unpin/' + this.post.id);
                this.$parent.$emit('unpin', this.post.id);
                this.isPinPost =  false;
                this.data.pin_to_top = null
                this.data.pin_unlimited = null
                this.$parent.$emit('sortpost')
            }
        },
        pinPost() {
            if (confirm('Are you sure you wish to pin this post')) {
                axios.post('/member/post/pin/' + this.post.id);
                this.isPinPost = true;
                this.data.pin_to_top = 1
                this.data.pin_unlimited = 1
                this.$parent.$emit('sortposts')
            }
        },
        update() {
            axios.patch('/member/post/' + this.post.id, {
                post_text: this.post_text
            })

            this.body = this.post_text;

            this.editing = false;

            let data = {
                'message': 'Post Updated',
                'level': 'success'
            }
            flash(data);
        },
        downloadFile(file_id, index) {
            this.$http.get('/member/post/' + this.post.id + '/download-file/'+file_id, {responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], {type: this.post.post_file.mime_type})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = this.post.post_file[index].file_name
                    link.click()
                })
        },
        report() {
            $('#report_id').val(this.post.id);
            $('#report_type').val('App\\Models\\Post');
            $('#post_title').text(this.post.post_text);
            $('#reportModal').modal('toggle');
        },
    },
    beforeMount() {
        let mime_type = ['image/gif', 'image/jpeg', 'image/png'];
        if (this.post.media !== undefined && this.post.media.length > 0) {
            for (let i = 0; i < this.post.media.length; i++) {
                if (this.post.media[i] !== undefined) {
                    if (mime_type.includes(this.post.media[i].mime_type)) {
                        this.images.push(window.App.siteUrl+'/storage/' + this.post.media[i].id + '/' + this.post.media[i].file_name);
                    }
                }
            }
        }

        this.checkServiceAdmin()
    },
}
