<template>
    <div>
        <div v-if="data.newsfeed_type === 'post' && data.module != null">
            <newsfeed-resource :data="data"
                            :groups_follow="groups_follow"
                            :suppliers_follow="suppliers_follow"
                            :section="section"
                            :administrator="administrator"
                            :members="members"/>
        </div>

        <div v-if="data.newsfeed_type === 'share' && data.module != null">
            <newsfeed-share-resource  :data="data"
                                      :administrator="administrator"/>
        </div>
        <!-- To Do add code for deleted resource new feature
        <div v-if="data.newsfeed_type === 'share' && data.module == null">
            <newsfeed-deleted-resource  :data="data"
                                        :administrator="administrator"/>
        </div>
        -->
    </div>
</template>

<script>
import Share from '../../mixins/newsfeed/Share'

export default {
    name: "ResourcePost",
    mixins: [
        Share
    ],
    props: ['data', 'members', 'administrator', 'section', 'groups_follow', 'suppliers_follow'],
}

</script>
