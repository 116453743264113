<template>
    <div>
        <search :categories="this.category" :filters="this.search_filters" v-on:searchType="changeFilter($event)"></search>
        <div v-if="this.user.user_categories.length === 0 && this.search_filters.default === 'user'">
            <div class="alert alert-danger" role="alert">
                {{ __('search.paragraph.no_interests') }} <a :href="'/member/profiles/'+this.user.username+'/edit'">{{ __('globals.click_here') }}</a>
            </div>
        </div>
        <div class="row match-height pl-05 pr-05">
            <div class="col-md-3 col-sm-4 col-6 p-05" v-for="(group, index) in groups" :key="index" v-if="groups.length > 0">
                <group-card :group="group"></group-card>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more" class="text-light"> </div>
            <div slot="no-results">{{ __('globals.no_groups_header') }}</div>
        </infinite-loading>
    </div>


</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import moment from "moment-timezone";
import Search from '../Search'
import GroupCard from './cards/Group';

export default {
    name: "GroupInfiniteScroll",
    components: {InfiniteLoading, Search, GroupCard},
    props: ['category', 'filters'],
    data() {
        return {
            groups: [],
            page: 1,
            user: window.App.user,
            search: this.filters.default,
            infiniteId: +new Date(),
            categoryId: '',
            keyword: '',
            search_filters: this.filters,
        }
    },
    methods: {
        infiniteHandler($state) {
            let vm = this;
            axios
                .get(this.getUrl())
                .then(response => {
                    if (response.data.data.length > 0) {
                        $.each(response.data.data, function (key, value) {
                            vm.groups.push(value);
                        });
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getUrl(){
            if (this.search === "my") {
                return "/member/group/my?page=" + this.page ;
            }

            if (this.search === "category"){
                return "/member/group/search/"+this.search+"?categoryId="+this.categoryId+"&page=" + this.page;
            }

            if (this.search === "keyword"){
                return "/member/group/search/"+this.search+"?keyword="+this.keyword+"&page=" + this.page;
            }

            return "/member/group/search/"+this.search+"?page=" + this.page;
        },
        changeFilter(args){

            if (args.type === "category")
            {
                this.categoryId = args.categoryId
            }

            if (args.type === "keyword")
            {
                this.keyword = args.keyword
            }

            this.search = args.type;
            this.page = 1;
            this.groups = [];
            this.infiniteId += 1;
        }
    },
    mounted() {
        if(!this.filters.default === 'my'){
            if (this.user.user_categories.length === 0){
                this.search = "all";
            }
        };
    }
}
</script>

<style scoped>

</style>
