<template>
    <div class="container">
        <div class="col-lg-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="row ">
                        <div class="col-6 align-self-center"><h2>{{ __('report.reported_content') }}</h2>
                        </div>
                    </div>
                </div>
                <div class="panel-body mt-1">
                    <div class="w-100 border-bottom-primary mb-1">
                        <div class="d-flex align-items-center">
                            <div class="avatar bg-rgba-primary float-left">
                                <div class="avatar-content">
                                    <i class="feather icon-alert-triangle text-primary font-medium-3"></i>
                                </div>
                            </div>
                            <h4 class="mt-1">{{ __('report.all_reports') }}</h4>
                        </div>
                    </div>
                    <div class="panel-body mt-1 card p-2">
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <label>{{ __('table.show_amount') }}</label>
                                <select id="report_amount" class="form-control ml-05" v-model="reports_amount">
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>

                            <div class="d-flex align-items-center ml-auto">
                                <label>{{ __('table.keyword') }}: </label>
                                <input type="text" class="form-control ml-05" v-model="search_text" v-on:input="debounceInput"/>
                            </div>

                            <button class="btn btn-outline-primary ml-05" @click.prevent="getReports()">{{ __('globals.search') }}</button>
                        </div>

                        <table class="table table-bordered table-striped mt-1">
                            <thead>
                                <tr>
                                    <th scope="col" @click.prevent="changeActiveField('id')" style="width: 10%;">
                                        <div class="d-flex">
                                            <p>
                                                {{ __('table.id') }}
                                            </p>
                                            <p class="ml-auto">
                                                <i :class="'feather icon-arrow-up '+ (this.sort_field === 'id' && this.sort_order === 'asc' ? 'text-dark' : 'text-light')" style="margin-right: -6px;"></i>
                                                <i :class="'feather icon-arrow-down '+ (this.sort_field === 'id' && this.sort_order === 'desc' ? 'text-dark' : 'text-light')"></i>
                                            </p>
                                        </div>
                                    </th>
                                    <th scope="col" @click.prevent="changeActiveField('full_name')" style="width: 20%;">
                                        <div class="d-flex">
                                            <p>
                                                {{ __('report.reported_by') }}
                                            </p>
                                            <p class="ml-auto">
                                                <i :class="'feather icon-arrow-up '+ (this.sort_field === 'full_name' && this.sort_order === 'asc' ? 'text-dark' : 'text-light')" style="margin-right: -6px;"></i>
                                                <i :class="'feather icon-arrow-down '+ (this.sort_field === 'full_name' && this.sort_order === 'desc' ? 'text-dark' : 'text-light')"></i>
                                            </p>
                                        </div>
                                    </th>
                                    <th scope="col" @click.prevent="changeActiveField('comment')" style="width: 30%;">
                                        <div class="d-flex">
                                            <p>
                                              {{ __('table.comment') }}
                                            </p>
                                            <p class="ml-auto">
                                                <i :class="'feather icon-arrow-up '+ (this.sort_field === 'comment' && this.sort_order === 'asc' ? 'text-dark' : 'text-light')" style="margin-right: -6px;"></i>
                                                <i :class="'feather icon-arrow-down '+ (this.sort_field === 'comment' && this.sort_order === 'desc' ? 'text-dark' : 'text-light')"></i>
                                            </p>
                                        </div>
                                    </th>
                                    <th scope="col" @click.prevent="changeActiveField('reportable_type')" style="width: 14%;">
                                        <div class="d-flex">
                                            <p>
                                                {{ __('report.reported_type') }}
                                            </p>
                                            <p class="ml-auto">
                                                <i :class="'feather icon-arrow-up '+ (this.sort_field === 'reportable_type' && this.sort_order === 'asc' ? 'text-dark' : 'text-light')" style="margin-right: -6px;"></i>
                                                <i :class="'feather icon-arrow-down '+ (this.sort_field === 'reportable_type' && this.sort_order === 'desc' ? 'text-dark' : 'text-light')"></i>
                                            </p>
                                        </div>
                                    </th>
                                    <th scope="col" @click.prevent="changeActiveField('updated_at')" style="width: 14%;">
                                        <div class="d-flex">
                                            <p>
                                                {{ __('report.reported_date') }}
                                            </p>
                                            <p class="ml-auto">
                                                <i :class="'feather icon-arrow-up '+ (this.sort_field === 'updated_at' && this.sort_order === 'asc' ? 'text-dark' : 'text-light')" style="margin-right: -6px;"></i>
                                                <i :class="'feather icon-arrow-down '+ (this.sort_field === 'updated_at' && this.sort_order === 'desc' ? 'text-dark' : 'text-light')"></i>
                                            </p>
                                        </div>
                                    </th>
                                    <th scope="col" style="width: 12%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loading" class="text-center">
                                    <td colspan="6">
                                        <span class="spinner-border spinner-border-md text-primary"></span>
                                    </td>
                                </tr>
                                <template v-else>
                                    <tr v-for="(report, index) in reports" :key="index">
                                        <td>{{ report.id }}</td>
                                        <td>{{ report.full_name }}</td>
                                        <td>{{ report.comment }}</td>
                                        <td>{{ typeFormat(report.reportable_type) }}</td>
                                        <td>{{ timeFormat(report.updated_at) }}</td>
                                        <td>
                                            <button class="btn btn-sm btn-outline-primary" @click.prevent="viewDetailsModal(report.id)">
                                                {{ __('table.view') }}
                                            </button>
                                            <button class="btn btn-sm btn-outline-danger" @click.prevent="deleteReport(report.id, index)">
                                                {{ __('table.delete') }}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="reports.length === 0" class="text-center">
                                        <td colspan="6">
                                            {{ __('report.no_reports_yet') }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>

                        <div class="d-flex align-items-center">
                            <div>
                                <p v-if="!loading && reports.length > 0">
                                    <span v-if="this.total_reports === 1">
                                        {{ __('table.showing') }} 1 {{ __('globals.report') }}
                                    </span>
                                    <span v-else>
                                        {{ __('table.showing') }} {{ this.current_page === 1 ? this.current_page : 1 + (this.reports_amount * (this.current_page - 1)) }} {{ __('globals.to') }} {{ this.showing_reports }} {{ __('globals.of') }} {{ this.total_reports }} {{ __('globals.reports') }}
                                    </span>
                                </p>
                            </div>

                            <div class="ml-auto">
                                <ul class="pagination">
                                    <li class="page-item previous">
                                        <a href="#" class="page-link" @click="goPreviousPage()">{{ __('table.previous') }}</a>
                                    </li>
                                    <li :class="'page-item '+ (current_page === page ? 'active' : '')" v-for="page in total_pages">
                                        <a href="#" class="page-link" @click.prevent="changePage(page)">{{ page }}</a>
                                    </li>
                                    <li class="page-item next">
                                        <a href="#" class="page-link" @click.prevent="goNextPage()">{{ __('table.next') }}</a>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div v-if="view_details">
            <report-modal :report_id="this.report_id" @closeModal="closeDetailsModal"></report-modal>
        </div>
    </div>
</template>

<script>
    import moment from "moment-timezone";
    import ReportModal from "./ReportModal";

    export default {
        name: 'ReportsComponent',
        props: [],
        components: {ReportModal},
        data() {
            return {
                reports_amount: 15,
                search_text: '',
                reports: [],
                current_page: 1,
                total_pages: null,
                showing_reports: null,
                total_reports: null,
                loading: false,
                sort_field: 'id',
                sort_order: "desc",
                submitting: false,
                report_id: null,
                view_details: false,
            }
        },
        watch: {
            current_page(new_value, old_value){
                this.getReports();
            },
            reports_amount(new_value, old_value){
                this.current_page = 1;
                this.getReports();
            },
            sort_field(new_value, old_value){
                this.getReports();
            },
            sort_order(new_value, old_value){
                this.getReports();
            }
        },
        methods: {
            debounceInput: _.debounce(function (e) {
                this.current_page = 1;
                this.getReports();
            }, 500),
            getReports(){
                if(this.loading){
                    return false;
                }
                this.loading = true;

                 axios  .get('/admin/report?field='+this.sort_field+'&sortBy='+this.sort_order+'&comment='+this.search_text+'&count='+this.reports_amount+'&page='+this.current_page)
                        .then((response) => {
                            this.reports = response.data.data;
                            this.total_pages =  response.data.last_page;
                            this.showing_reports = response.data.to;
                            this.total_reports = response.data.total;
                            this.loading = false;
                        })
                        .catch(error => {
                            this.loading = false;
                        })
            },
            typeFormat(type){
                const stringsArray = type.split('\\');
                switch (stringsArray[stringsArray.length - 1]){
                    case "Newsfeed":
                        return "Shared Newsfeed";
                    case "QuestionResponse":
                        return "Question Answer"
                    default:
                        return stringsArray[stringsArray.length - 1];
                }
            },
            timeFormat(time) {
                return moment(time).format('D MMM, YYYY');
            },
            changePage(page){
                this.current_page = page;
            },
            goPreviousPage(){
                if(this.current_page > 1){
                    this.current_page--;
                }
            },
            goNextPage(){
                if(this.total_pages > this.current_page){
                    this.current_page++;
                }
            },
            changeActiveField(field){
                if(this.sort_field === field){
                    if(this.sort_order === 'asc'){
                        this.sort_order = 'desc';
                    } else {
                        this.sort_order = 'asc';
                    }
                } else {
                    this.sort_field = field;
                }
            },
            async deleteReport(id, index){
                if(confirm("Do you really want to delete this report?")){
                    if(this.submitting){
                        return false;
                    }
                    this.submitting = true;

                    await axios
                        .delete('/admin/report/'+id)
                        .then((response)=>{

                            this.reports.splice(index, 1);

                            let data = {
                                'message': 'Report Deleted',
                                'level': 'danger'
                            }
                            flash(data);
                            this.submitting = false;
                        })
                        .catch(error => {
                            this.submitting = false;
                        });
                }
            },
            viewDetailsModal(report_id){
                this.report_id = report_id;
                this.view_details = true;
            },
            closeDetailsModal(){
                this.view_details = false;
            },
        },
        mounted() {
            new TomSelect('#report_amount', {});
            this.getReports();
        },
        beforeMount() {

        }
    }
</script>


<style>

</style>
