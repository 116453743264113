<template>
    <div>
        <div v-if="data.newsfeed_type === 'post' && data.module.published === 1">
            <newsfeed-video :data="data"
                            :groups_follow="groups_follow"
                            :suppliers_follow="suppliers_follow"
                            :section="section"
                            :members="members"
                            :administrator="administrator" />
        </div>

        <div v-if="data.newsfeed_type === 'share'">
            <newsfeed-share-video  :data="data" :members="members" />
        </div>

    </div>
</template>

<script>
import Share from '../../mixins/newsfeed/Share'
import Video from "../../mixins/newsfeed/Video";
import DateTime from "../../mixins/newsfeed/DateTime";

export default {
    name: "VideoPost",
    mixins: [
        Share, Video, DateTime
    ],
    props: ['data', 'members', 'administrator', 'section', 'groups_follow', 'suppliers_follow'],
}

</script>
