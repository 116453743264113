<template>
        <div>
                <div v-for="(newsfeed, index) in newsfeeds" :key="newsfeed.id">

                    <span v-if="newsfeed.newsfeed_type === 'share'">
                        <member-shared-newsfeed :data="newsfeed" :section="section" @deleted="remove(index)"></member-shared-newsfeed>
                    </span>
                    <span v-else>
                        <member-newsfeed v-if="newsfeed.module !== null" :data="newsfeed" :section="section" :questions_enabled="questions_enabled" @deleted="remove(index)" @sortposts="sortposts()"></member-newsfeed>
                    </span>

                </div>
                <infinite-loading @infinite="infiniteHandler">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>


            <div v-if="newsfeeds.length === 0">
                <div class="card bg-outline-gray">
                    <div class="card-header">
                        <h4 class="card-title gray">{{ __('globals.no_posts_header') }}</h4>

                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <li class=""><a data-action="close"><i class="feather icon-x"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">
                        <span class="gray">{{ __('globals.no_post_content') }}</span>
                        <div>
                            <a href="/member/help">
                                <button class="btn btn-outline-light mt-1">
                                    {{ __('globals.help_button') }}
                                </button>
                            </a>
                        </div>
                        <div class="ml-auto">
                            <img src="/images/annie.png" width=50px style="float:right; margin-top: -40px;">
                        </div>
                    </div>
                </div>
            </div>
            <span v-text="newsfeeds.links"></span>
        </div>

</template>

<script>

    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        name: "NewInfiniteScroll",
        components: {InfiniteLoading},
        props: ['data', 'section', 'type', 'questions_enabled'],
        data() {
            return {
                newsfeeds: [],
                page: 1,
                endpoint: null,
            }
        },
        mounted() {
            if (this.section === 'group'){
                this.endpoint = "/member/load-posts/group/"+this.data.slug;
            }
            else if (this.section === 'supplier'){
                this.endpoint = "/member/load-posts/"+this.type+"/"+this.data.slug;
            }
            else if (this.section === 'profile'){
                this.endpoint = "/member/load-posts/profile/"+this.data.username;
            }
            else {
                this.endpoint = "/member/load-posts";
            }
        },
        methods: {
            infiniteHandler($state){
                this.loadFeed($state);
            },
             loadFeed($state){
                let vm = this;
                axios
                    .get(this.endpoint+"?page="+this.page)
                    .then(response => {
                        if (response.data.data.length > 0){
                            $.each(response.data.data, function(key, value){
                                if(!Array.isArray(value)) {
                                    vm.newsfeeds.push(value);
                                }
                            });
                            $state.loaded();
                        }
                        else {
                            $state.complete();
                        }
                    });
                vm.page++;
            },
            remove(index) {
                this.newsfeeds.splice(index, 1);
            },
            sortposts: function() {
                return this.newsfeeds.sort((a,b) => b.pin_to_top - a.pin_to_top || new Date(b.published_at) -  new Date(a.published_at))
            },
        },
    }
</script>

<style scoped>

</style>
