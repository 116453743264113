<template>
    <div  style="display: inline-block">
        <button class="btn btn-sm btn-primary no-border " style="border:none;" @click="follow" v-if="buttonsize === undefined">
            <span v-if="following === false">{{ __('globals.follow') }} <i class="feather icon-user-plus"></i></span>
            <span v-if="following === true">{{ __('globals.unfollow') }} <i class="feather icon-log-out"></i></span>
            <span v-if="following === 'pending'">{{ __('globals.requested') }} <i class="feather icon-log-in"></i></span>
        </button>

        <button class="btn btn-primary no-border " style="border:none;" @click="follow" v-if="buttonsize === 'large'">
            <span v-if="following === false">{{ __('globals.follow') }} <i class="feather icon-user-plus"></i></span>
            <span v-if="following === true">{{ __('globals.unfollow') }} <i class="feather icon-log-out"></i></span>
            <span v-if="following === 'pending'">{{ __('globals.requested') }} <i class="feather icon-log-in"></i></span>
        </button>
    </div>
</template>

<script>
    export default {
        props: ['data', 'isfollowing', 'buttonsize'],
        name: "JoinSupplier",
        data () {
            return {
                supplier: this.data,
                following: '',
                user: window.App.user,
                groupfollower: '',
                groupfollowing: ''
            }
        },
        methods: {
            isfollow() {
                axios
                    .get('/member/supplier/isfollow/'+this.supplier.slug)
                    .then(response => {
                        this.following = response.data.isFollowing;
                    });
            },
            follow() {
                axios
                    .post('/member/supplier/follow/'+this.supplier.slug)
                    .then(response => {
                        this.following = response.data.isFollowing;
                    });
            },
        },
        mounted() {
            this.isfollow();
        }
    }
</script>

<style scoped>

</style>
