<template>
        <div class="card">
            <div>
                <!-- This is for shared Newsfeed -->
                <guest-post-owner-info
                    :user_data="data.owner"
                    :supplier_data="data.supplier !== null ? data.supplier : null"
                    :shared="data.newsfeed_type === 'share' ? true : false"
                    :type="data.type"
                    :time="data.published_at"
                />
                <div v-if="data.body !== null">
                    <div class="breakAll mt-1 mr-1 ml-1" v-linkified v-html="data.body">
                    </div>
                </div>
            </div>

            <div :class="[data.newsfeed_type === 'share' ? 'card mr-1 ml-1 mb-1 mt-0' : '']">

                 <div class="d-flex justify-content-start" v-if="data.newsfeed_type === 'share'">
                    <guest-post-owner-info
                        :user_data="data.original_newsfeed.owner"
                        :supplier_data="data.original_newsfeed.supplier"
                        :shared="false"
                        :time="data.original_newsfeed.published_at"
                    />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Post'">
                    <guest-newsfeed-post :data="data.module" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Video'">
                    <guest-newsfeed-video :data="data.module" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Resource'">
                    <guest-newsfeed-resource :data="data.module" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Event'">
                    <guest-newsfeed-event :data="data.module" :plans="plans" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\ZoomWebinar'">
                    <guest-newsfeed-zoom-webinar :data="data.module" :plans="plans" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Zoom'">
                    <guest-newsfeed-zoom :data="data.module" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Job'">
                    <guest-newsfeed-job :data="data.module" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Product'">
                    <guest-newsfeed-product :data="data.module" />
                </div>

                <div v-if="data.module_type === 'App\\Models\\Stack\\Question'">
                    <guest-newsfeed-question :data="data.module" />
                </div>

                <!-- This is for the group or supplier of the original newsfeed if it exists -->
                <div v-if="data.original_newsfeed != null">
                    <div class="p-05" v-if="data.original_newsfeed.group != null">
                        <a :href="'/group/'+data.original_newsfeed.group.slug">
                            <div class="card text-white mb-0 border-0">
                                <img class="card-img img-fluid" style="height: 3rem;" :src="data.original_newsfeed.group.group_cover == null ? '/images/cover.jpg' : data.original_newsfeed.group.group_cover.url">
                                <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                        {{ data.original_newsfeed.group.title }}</p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="p-05" v-if="data.original_newsfeed.supplier != null">
                        <a :href="'/supplier/'+data.original_newsfeed.supplier.slug">
                            <div class="card text-white mb-0 border-0">
                                <img class="card-img img-fluid" style="height: 3rem;"
                                     :src="data.original_newsfeed.supplier.supplier_cover_image == null ? '/images/cover.jpg' : data.original_newsfeed.supplier.supplier_cover_image.url">
                                <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ data.original_newsfeed.supplier.title }}</p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="data.newsfeed_type === 'share'" >
                <!-- Details of the group and supplier where the newsfeed has been shared -->
                <div class="pl-1 pr-1 pb-05" v-if="data.group != null">
                    <a :href="'/group/'+data.group.slug">
                        <div class="card text-white mb-0 border-0">
                            <img class="card-img img-fluid" style="height: 3rem;" :src="data.group.group_cover == null ? '/images/cover.jpg' : data.group.group_cover.url">
                            <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                        {{ data.group.title }}</p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="pl-1 pr-1 pb-05" v-if="data.supplier != null">
                    <a :href="'/supplier/'+data.supplier.slug">
                        <div class="card text-white mb-0 border-0">
                            <img class="card-img img-fluid" style="height: 3rem;" :src="data.supplier.supplier_cover_image == null ? '/images/cover.jpg' : data.supplier.supplier_cover_image.url">
                            <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                    {{ data.supplier.title }}</p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div v-if="!(data.module_type === 'App\\Models\\Stack\\Question')" class="d-flex justify-content-between border-top p-05">
                <div class="font-small-3 text-bold-700" data-toggle="modal" data-target="#registerModal">
                    <i class="fa fa-thumbs-o-up"></i>
                    <span v-text="data.likesCount === 0 ? '' : data.likesCount"></span>
                </div>
                <div class="font-small-3 text-bold-700">
                    <span data-toggle="modal" data-target="#registerModal" class="comment-button">
                        <i class="fa fa-comment-o"></i>
                        <span v-if="data.commentsCount === 1"><span v-text="data.commentsCount"></span> {{ __('globals.comment') }}</span>
                        <span v-else><span v-text="data.commentsCount === 0 ? ' ': data.commentsCount"></span> {{ __('globals.comments') }}</span>
                    </span>
                </div>
                <div class="font-small-3 text-bold-700">
                    <div class="dropdown">
                        <a class="dropdown-toggle" id="socialShareButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-share-alt"></i> {{ __('globals.share') }}
                        </a>
                        <div class="dropdown-menu" aria-labelledby="socialShareButton">
                            <span v-html="data.newsfeed_type === 'share' ? data.original_newsfeed.module.social : data.module.social"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>



<script>

    export default {
        name: "GuestNewsfeed",
        props: ['data', 'social', 'guest_purchase_tickets', 'plans'],
        beforeMount() {
        }
    }
</script>

<style scoped>

</style>
