<template>
    <div class="col-12">
        <div class="row match-height" v-if="newsfeeds.length > 0">
            <div :class="newsfeeds.length === 1 ? 'col-12 col-md-12' :  'col-12 col-md-6'" v-for="(post, index) in newsfeeds" :key="post.id">
                <schedule-post-display :data="post" @deleted="remove(index)"></schedule-post-display>
            </div>
        </div>
        <div v-else>
            <div class="card bg-outline-gray ml-1">
                <div class="card-header">
                    <h4 class="card-title gray">{{ __('post.no_scheduled_posts') }}</h4>
                    <a data-action="close" class="heading-elements"><i class="feather icon-x"></i></a>
                </div>
                <div class="card-body">
                    <span class="gray">{{ __('post.form.paragraph.no_scheduled_post') }}</span>
                    <div>
                        <a href="/member/help">
                            <button class="btn btn-outline-light mt-1">
                                {{ __('globals.menu_help') }}
                            </button>
                        </a>
                    </div>
                    <div class="ml-auto">
                        <img src="/images/annie.png" width=50px style="float:right; margin-top: -40px;">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SchedulePostDisplay from './SchedulePostDisplay'

export default {
    name: "ScheduledPost",
    components: {SchedulePostDisplay},
    props: ['data'],
    data() {
        return {
            newsfeeds: this.data
        }
    },
    methods: {
        remove(index) {
            this.newsfeeds.splice(index, 1);
        }
    }

}
</script>
