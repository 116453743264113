<template>
    <div class="card p-1 mb-0">
        <div class="card-content pb-3" >
            <div class="d-flex">
                <div>
                    <a :href="'/member/job/'+job.slug"><h5 class="text-bold-600">{{ job.title }}</h5>
                        <span class="card-text primary text-bold-700 mb-05 d-block"><i class="feather icon-clock"></i> {{ job.employment_type }}</span>
                        <span class="font-small-2 text-light d-block"><i class="feather icon-briefcase"></i>  {{ job.organisation_name }}</span>
                        <span class="font-small-2 text-light d-block"><i class="feather icon-map-pin"></i> {{ job.position_location }}</span>
                    </a>

                </div>
                <div class="ml-auto pl-05">
                    <a :href="'/member/job/'+job.slug">
                        <img :src="job.organisation_logo !== null ? job.organisation_logo.url :  '/images/logo.png'" width="75" alt="Logo">
                    </a>
                </div>
            </div>
            <div class="card-content job-description font-small-4 mt-1" v-if="service_settings.description_display" v-html="description"></div>
        </div>
        <div class="mb-3" v-if="job.disabled === 1">
                <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2 ">
                    <i class="feather icon-alert-circle"></i> {{ __('job.job_inactive') }}</span>
        </div>
        <div class="card-bottom d-flex align-items-center">
            <div class="pt-1" v-if="job.hide_salary === 0">
                <span class="font-small-2 text-dark text-bold-700 d-block"> {{ job.custom_salary }}</span>
            </div>
            <div class="ml-auto pt-1 d-flex align-items-center">
                <a :href="'/member/job/'+job.slug">  <button class="btn-sm btn-flat-light" style="border:none;">{{ __('globals.view') }} <i class="feather icon-chevron-right"></i></button> </a>

                <a v-if="confirmed_user === true && job.external_link == null" :href="'/member/job-applies?apply='+job.slug" class="btn-sm btn-primary waves-effect waves-light" style="border:none">
                    {{ __('job.form.buttons.apply') }}
                    <i class="fas fa-user-check">
                    </i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card.Job",
    props: ['job', 'service_settings'],
    data() {
        return {
            user: window.App.user,
            site_tax: window.App.site_tax,
            confirmed_user: window.App.user.confirmed,
        }
    },
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.job.position_description.split(' ').length > 50 ? this.job.position_description.split(' ', 50).join(' ') + '...' : this.job.position_description) : '';
        }
    },
}
</script>

