<template>
    <div class="card" style="padding-bottom: 40px;" v-if="event.event_type === 'event'">

        <div class="shadow">
            <a :href="'/member/event/'+event.slug">
                <img class="card-img-top img-fluid " :src="event.event_image !== null ? event.event_image.url :  '/images/cover.jpg'" style="object-fit: cover;" alt="Cover image"/>
            </a>
        </div>

        <div class="card-content">
            <div class="card-body pt-05 p-1">
                <a :href="'/member/event/'+event.slug">
                    <h6 class="text-bold-700 mb-0 mt-05"> {{ event.title }}</h6>
                </a>

                <a :href="'/member/event/'+event.slug">
                    <p class="event-text primary font-small-1 mt-05 mb-05" style="line-height: 1.4em;">
                        <i class="feather icon-calendar"></i>
                        {{ event.event_start_end_date }}
                    </p>
                </a>

                <!-- <p class="font-small-3 mt-05" style="color: #2c2c2c;" v-html="event.event_description"></p> -->
                <div class="badge badge-pill " style="padding: 0.2rem 0.5rem;" v-if="event.event_privacy">
                    <i class="feather icon-award"></i> <slot name="premium_label"></slot>
                </div>
                <div class="badge badge-pill bg-gradient-warning" style="padding: 0.2rem 0.5rem;" v-if="event.access === 'Hybrid Event'">
                    <i class="feather icon-calendar"></i> <slot name="hybrid_label"></slot>
                </div>
                <div class="badge badge-pill bg-gradient-success" style=" padding: 0.2rem 0.5rem;" v-if="event.access === 'Online Event'">
                    <i class="feather icon-globe"></i> <slot name="online_label"></slot>
                </div>
                <div class="badge badge-pill bg-gradient-secondary" style=" padding: 0.2rem 0.5rem;" v-if="event.access === 'Offline Event'">
                    <i class="feather icon-user-plus"></i> <slot name="offline_label"></slot>
                </div>
                <div class="card-content event-description font-small-4 mt-1" v-if="service_settings.description_display" v-html="description"></div>
                <div class="card-bottom mt-1 d-flex font-small-3 align-items-center ">
                    <div class="badge badge-pill badge-danger font-small-2" style="padding: 0.2rem 0.5rem;" v-if="this.sold_out">
                        <i class="feather icon-alert-circle"></i> {{ __('event.sold_out') }}
                    </div>
                    <div v-else>
                        <span v-if="event.event_price !== 0 && event.event_price !== null" class="text-bold-700 font-small-2 text-light">
                            {{ __('globals.form.paragraph.your_price') }} {{ event.event_price | currency(site_tax)}}
                        </span>
                    </div>
                    <div v-if="event.disabled === 1">
                        <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2 ">
                        <i class="feather icon-alert-circle"></i> {{ __('event.event_inactive') }}</span>
                    </div>
                    <div class="ml-auto">
                        <a :href="'/member/event/'+event.slug">
                            <button class="btn-sm btn-flat-light" style="border:none;">
                                {{ __('globals.view') }} <i class="feather icon-chevron-right"></i>
                            </button>
                        </a>
                        <span v-if="this.can_buy_tickets && !this.sold_out">
                            <span>
                                <a :href="'/member/event/'+event.slug+'/tickets'" class="btn-sm btn-primary" style="border:none;">
                                    {{ __('event.form.buttons.buy_tickets	') }}
                                    <i class="fa fa-tag"></i>
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tickets from "../../../mixins/tickets";

export default {
    name: "EventCard",
    props: ['event', 'service_settings'],
    mixins: [
        Tickets
    ],
    data() {
        return {
            user: window.App.user,
            site_tax: window.App.site_tax,
        }
    },
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.event.event_description.split(' ').length > 50 ? this.event.event_description.split(' ', 50).join(' ') + '...' : this.event.event_description) : '';
        }
    },
    beforeMount() {
        this.ticketsPrice(this.event.tickets);
    }
}
</script>
