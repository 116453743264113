<template>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card card-default">
                <div class="pb-1 d-flex border-bottom card-header align-items-center p-0 m-1">
                    <h3 class="text-bold-700 mb-0"><i class="feather icon-send"></i> {{__('chat.inbox') }}</h3>
                    <a href="/member/chat" class="btn btn-primary">
                        <i class="fa fa-envelope-o"></i> {{ __('chat.compose_new_message')}}
                    </a>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="message in messages" :key="message.id" v-if="message.chat_type === 'single'">

<!--                            <a href="" style="color:#212529"> </a>-->
                            <div @click="goToChat(message)" class="d-flex align-items-center">
                                <div v-if="message.chat_type === 'single'">
                                    <img class="round"
                                         :src="message.second_party.user_image !== null ? message.second_party.user_image :  '/images/avatar.png'"
                                         alt="avatar"
                                         height="40"
                                         width="40"/>
                                </div>
                                <div v-else>
                                    <img class="round"
                                         src='/images/Group-icon.png'
                                         alt="avatar"
                                         height="40"
                                         width="40"/>
                                </div>
                                <div class="ml-05">
                                    <span class="h4">  {{ message.second_party.full_name }}</span>
                                    <div v-if="message.chat_type === 'single'" class="font-small-2 text-bold-700 text-light">{{ message.second_party.position }} |
                                        {{ message.second_party.organisation }}
                                    </div>

                                    <span class="text-muted mt-1 font-small-3 d-block"
                                          v-if="message.chat_type === 'single'"
                                          v-html="message.message">
                                    </span>
                                    <span class="text-muted mt-1 font-small-3 d-block" v-else
                                          v-html="message.message">
                                    </span>
                                    <!-- <i class="fas fa-circle float-right success" v-if="message.second_party.online" style="position: absolute; top: 15px; right: 15px;"></i> -->
                                </div>
                                <div class="pl-1 ml-auto font-small-3" v-text="showDate(message.created)">

                                </div>
                            </div>
                        </li>
                    </ul>

                    <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler">
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                    <div class="mt-1  mb-1 text-center text-bold-600 text-light" v-if="messages.length === 0">{{ __('chat.no_messages') }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
    name: 'Messages',
    components: {InfiniteLoading},
    data() {
        return {
            messages: [],
            page: 1,
            infiniteId: +new Date(),
        }
    },
    created() {
        //this.getMessages();
    },
    methods: {
        infiniteHandler($state) {
            this.getMessages($state)
        },
        showDate(date){
            return moment(date).format("MMMM Do YYYY, h:mm A");
        },
        goToChat(message) {
            if (message.chat_type === 'group') {
                window.location.href = '/member/chat?group=' + message.id
            } else {
                window.location.href = '/member/chat?friend=' + message.second_party.id;
            }
        },
        getMessages($state) {
            let vm = this;
            let url = "/member/messages/inbox";
            axios
                .get(url)
                .then(response => {
                    $.each(response.data, function (key, value) {
                        vm.messages.push(value);
                    });

                    $state.complete();
                });
            vm.page++;
        },
    }
}
</script>

<style scoped>
.list-group-item {
    cursor: pointer;
}
</style>
