<template>
    <div class="card">
        <div class="d-flex flex-row align-items-center">
            <div class="d-flex justify-content-start">
                <div class="p-1">
                    <img class="round"
                         :src="member.avatar !== null ? member.avatar.url :  '/images/avatar.png'"
                         alt="avatar" height="40" width="40"/>

                    <div v-if="contactStatus === true" data-toggle="tooltip" data-placement="bottom"
                         title="Already a contact" class="badge badge-pill badge-success"
                         style="border:none; position: absolute; top: 10px; left: 10px;padding-right: 0.4em; padding-left: 0.4em;">
                        <i class="feather icon-check"></i>
                    </div>
                </div>

                <div class="pl-0 pt-1 pr-1 pb-1">
                    <a :href="'/member/profiles/'+member.username" style="color:inherit;" class="text-bold-600">
                        {{member.first_name}} {{member.last_name}}
                    </a>

                    <span v-for="badge in member.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name" alt="badge image" />
                    </span>

                    <img :src="member.user_badge_image.url" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                         data-placement="bottom"
                         :title="member.user_badge_name" v-if="member.user_badge_name !== null && member.user_badge_icon !== null" alt="badge image" />

                    <div class="font-small-2 text-muted">{{member.position}} {{ member.organisation !== null ? 'at ' + member.organisation : '' }}</div>
                </div>
            </div>

            <div class="p-1 ml-auto" v-if="member.id !== user_id">
                <button class="btn btn-success waves-effect waves-light btn-sm" v-if="contactStatus === false" @click.prevent="follow()">{{ __('globals.follow') }}</button>
                <button class="btn btn-danger waves-effect waves-light btn-sm" v-else-if="contactStatus === true" @click.prevent="unfollow()">{{ __('globals.unfollow') }}</button>
                <button class="btn btn-primary waves-effect waves-light btn-sm" v-else-if="contactStatus === 'pendingRequest'" @click.prevent="cancelPending()">{{ __('globals.pending') }}</button>
                <div class="d-flex flex-row" v-else-if="contactStatus === 'pendingAccept'">
                    <button class="btn btn-success btn-sm" @click.prevent="acceptRequest()">{{ __('globals.accept') }}</button>
                    <button class="btn btn-danger btn-sm ml-05" @click.prevent="declineRequest()">{{ __('globals.decline') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MemberDirectoryCard",
    props: ['member', 'index'],
    components: {},
    data() {
        return {
            contactStatus: this.member.is_contact,
            user_id: window.App.user.id,
            submitting: false,
        }
    },
    methods: {
        follow(){
            if(this.submitting){
                return false;
            }
            this.submitting = true;
            axios
                .post(`/member/user/${this.member.username}/follow`)
                .then(response => {
                    this.contactStatus = 'pendingRequest';
                    this.flashResponse(response.data.message, 'success')
                    this.submitting = false;
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                    this.submitting = false;
                });
        },
        unfollow(){
            if(window.confirm(this.__('user.confirm.unfollow_user'))) {
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .delete(`/member/user/${this.member.username}/unfollow`)
                    .then(response => {
                        this.contactStatus = false;
                        this.flashResponse(response.data.message, 'danger')
                        this.submitting = false;
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger')
                        this.submitting = false;
                    });
            } else {}
        },
        cancelPending(){
            if(window.confirm(this.__('user.confirm.cancel_pending'))) {
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .delete(`/member/user/${this.member.username}/cancel-request`)
                    .then(response => {
                        this.contactStatus = false;
                        this.flashResponse(response.data.message, 'success')
                        this.submitting = false;
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger')
                        this.submitting = false;
                    });
            } else {}
        },
        acceptRequest(){
             if(this.submitting){
                 return false;
             }
            this.submitting = true;
            axios
                .post(`/member/user/${this.member.username}/accept-request`)
                .then(response => {
                    this.contactStatus = true;
                    this.flashResponse(response.data.message, 'success')
                    this.submitting = false;
                })
                .catch((error) => {
                    this.flashResponse('An error occurred', 'danger')
                    this.submitting = false;
                });
        },
        declineRequest(){
            if(window.confirm(this.__('user.confirm.decline_request'))) {
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .delete(`/member/user/${this.member.username}/decline-request`)
                    .then(response => {
                        this.contactStatus = false;
                        this.flashResponse(response.data.message, 'danger')
                        this.submitting = false;
                    })
                    .catch((error) => {
                        this.flashResponse('An error occurred', 'danger')
                        this.submitting = false;
                    });
            } else {}
        },
        flashResponse(message, status){
            let flash_data = {
                'message': message,
                'level': status
            }
            flash(flash_data);
        }
    },
}

</script>

<style>

</style>
