
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)
export default {
    name: "Resource",
    mixins: [],
    props: ['resource'],
    components: {},
    data() {
        return {
            rating: null,
        }
    },
    methods: {
        average_rating(){
            let ratings_array = [];
            if(this.resource.ratings.length > 0){
                this.resource.ratings.forEach(rating => {
                    ratings_array.push(rating.rating);
                })
            }
            let sum = ratings_array.reduce((a, b) => a + b, 0);
            this.rating = Math.ceil((sum / ratings_array.length) || 0);
        },
        editPost(e) {
            e.stopPropagation();
            this.editing = true;
        },
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/' + this.resource.type + '/' + this.resource.slug);
                if(window.location.href.includes('/member/'+this.resource.type)){
                    document.location.href='/member';
                }
                this.$parent.$emit('deleted', this.resource.slug);
            }
        },
        update() {
            axios.patch('/member/' + this.resource.type + '/' + this.resource.slug, {
                post_text: this.post_text
            })

            this.editing = false;

            let data = {
                'message': 'Post Updated',
                'level': 'success'
            }
            flash(data);
        },
        report() {
            $('#report_id').val(this.resource.id);
            $('#report_type').val('App\\Models\\Resource');
            $('#post_title').text(this.post_text);
            $('#reportModal').modal('toggle');
        },
        downloadFile(file_id, index) {
            this.$http.get('/member/' + this.resource.type + '/' + this.resource.id + '/download-file/'+file_id, {responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], {type: this.resource.post_file.mime_type})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = this.resource.post_file[index].file_name
                    link.click()
                })
        },
    },
    computed: {
    },
    beforeMount() {
    },
}
