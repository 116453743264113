import VideoPlayer from "../../components/VideoPlayer";
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)

export default {
    name: "Video",
    mixins: [],
    props: ['video', 'section'],
    components: {VideoPlayer},
    data() {
        return {
            images: [],
            price_setting: window.App.price_setting,
            thesection: this.section,
            videoOptions: {
                autoplay: false,
                controls: true,
                playbackRates: [0.5, 1, 1.5, 2],
                sources: [
                    {
                        src: typeof this.video.video_link !== undefined ? this.video.video_link : '',
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    methods: {
        editPost(e) {
            e.stopPropagation();
            this.editing = true;
        },
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/video/' + this.video.slug);
                if(window.location.href.includes('/member/video')){
                    document.location.href='/member';
                }
                this.$parent.$emit('deleted', this.video.id);
            }
        },
        report() {
            $('#report_id').val(this.video.id);
            $('#report_type').val('App\\Models\\Video');
            $('#post_title').text(this.video.title);
            $('#reportModal').modal('toggle');
        },
        videoPlanPrices(plan_prices) {
            if (this.price_setting === 'plan-price' && plan_prices.length > 0) {
                plan_prices.forEach(plan => {
                    if(plan.price !== null) {
                        // This is used to set the price based on lowest plan price if the user is not on a plan where price is available
                        if (this.lowest_plan_price === null) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        } else if (plan.price < this.lowest_plan_price) {
                            this.lowest_plan_price = plan.price;
                            this.lowest_plan_name = plan.plan.title;
                        }
                    }
                })
            }
        }
    },
    computed: {

    },
    beforeMount() {
    },
}
