<template>
    <div class="card card-default chat-box" style="min-height: 600px;" >
        <div class="card-header pt-1 pb-1 pl-0 pr-0 border-bottom ml-1 mr-1 align-items-center">
            <strong :class="{'text-danger':groupSession.block}">
                <div class="d-flex align-items-center">
                    <div class="">
                        <img class="round"
                             src="/images/avatar.png"
                             alt="avatar"
                             height="30"
                             width="30" /></div>
                    <div>  <span class="h4 pt-05 ml-05">
                        {{ groupSession.name }}
                        <span v-if="isTyping">{{ __('chat.is_typing') }} . . .</span></span>
                        <span v-if="groupSession.block">({{ __('chat.blocked') }})</span>
                    </div>
                </div>
            </strong>

            <!-- options -->

            <div class="mt-1 dropdown">
                <!-- Close button -->
                <a href="#" @click.prevent="leaveGroup"><button class="btn-sm btn-outline-danger">{{ __('chat.leave_group') }} </button></a>

                <a href="#" @click.prevent="clear">  <button class="btn-sm btn-outline-light"> {{ __('chat.clear_chat') }} </button></a>
                <a href="/member/messages"><button class="btn-sm btn-outline-primary">{{ __('chat.back_to_messages') }} </button></a>

            </div>
        </div>
        <div class="card-body" v-chat-scroll>
            <div class="mb-1 group-message-block card-text"
                 :class="{'text-success':chat.read_at != null}"
                v-for="chat in chats" :key="chat.id">
                <div class="d-flex pb-1 align-items-center"
                     :class="{'float-right':chat.type == 0}">
                        <img class="round mr-05"
                             :title="chat.sender.username"
                             :src="chat.sender.user_image !== null ? chat.sender.user_image :  '/images/avatar.png'"
                             alt="avatar"
                             height="30"
                             width="30" />
                    <div v-html="chat.message" class="text-message-bg" :class="{'text-success':chat.read_at != null}"></div>
                    <br>
                    <span style="font-size: 8px;">{{ chat.read_at }}</span>
                </div>
            </div>


        </div>
        <form class="card-footer p-2" @submit.prevent="send">
            <div class="form-group" style="position:relative;">
                <div class="d-flex">
                    <input type="text" class="input-message form-control" placeholder="Write your message here" :disabled="groupSession.block"
                           v-model="message">
                    <button class="btn btn-primary btn-send-message"><i class="feather icon-send"></i> {{ __('chat.send') }}</button>
                </div>

                <emoji-picker @emoji="append" :search="search">
                    <div
                        class="emoji-invoker"
                        slot="emoji-invoker"
                        slot-scope="{ events: { click: clickEvent } }"
                        @click.stop="clickEvent"
                    >
                        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                        </svg>
                    </div>
                    <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                        <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                            <div class="emoji-picker__search">
                                <input type="text" v-model="search" v-focus>
                            </div>
                            <div>
                                <div v-for="(emojiGroup, category) in emojis" :key="category">
                                    <h5>{{ category }}</h5>
                                    <div class="emojis">
                <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    @click="insert(emoji)"
                    :title="emojiName"
                >{{ emoji }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </emoji-picker>
                <vue-dropzone
                    @vdropzone-success="uploadSuccess"
                    v-if="showImageUpload" ref="chatImageDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                <vue-dropzone
                    @vdropzone-success="uploadSuccessFile"
                    v-if="showFileUpload" ref="chatFileDropzone" id="dropzoneFile" :options="dropzoneFileOptions"></vue-dropzone>

                <div id="show_options_chat">
                    <div class="d-flex" id="post_options_layer_chat">
                        <div class="pl-0">
                            <a @click="showImageUpload = !showImageUpload" id="upload_images" data-toggle="tooltip"
                               data-placement="top" data-original-title="Upload Image">
                                <i class="far fa-image" style="font-size: 1.5em; margin-bottom: 10px;"></i>
                            </a>

                            <a class="file-icon" @click="showFileUpload = !showFileUpload"
                               id="upload_file" data-toggle="tooltip"
                               data-placement="top" data-title="Attach File">
                                <i class="fas fa-paperclip" style="font-size: 1.5em; margin-bottom: 10px;"></i>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </form>

    </div>
</template>

<script>
    import EmojiPicker from 'vue-emoji-picker';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';

    export default {
        props: ['groupSession'],
        components: {
            EmojiPicker,
            vueDropzone: vue2Dropzone},
        data() {
            return {
                dropzoneOptions: {
                    url: `/member/group-session/${this.groupSession.id}/media`,
                    maxFiles: 1,
                    maxFilesize: 20, // MB
                    acceptedFiles: '.jpeg,.jpg,.png,.gif',
                    addRemoveLinks: true,
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD IMAGE",
                    headers: {
                        'X-CSRF-TOKEN': window.App.csrfToken
                    },
                },
                dropzoneFileOptions: {
                    url: `/member/group-session/${this.groupSession.id}/media`,
                    maxFiles: 1,
                    acceptedFiles: '.pdf,.xls,.xlsx,.docx,.doc,.ppt,.pptx,.mov,.avi,.mp3,.mp4,.csv,.txt,.zip,.rar',
                    maxFilesize: 500,
                    timeout: 36000000,
                    addRemoveLinks: true,
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD FILE",
                    headers: {
                        'X-CSRF-TOKEN': window.App.csrfToken
                    },
                },
                showFileUpload: false,
                showImageUpload: false,
                chats: [],
                message: null,
                open: true,
                isTyping: false,
                attachment: null,
                search: '',
            }
        },
        computed: {
            session() {
                return this.groupSession;
            },
            can(){
                return this.groupSession.blocked_by == auth.id;
            }
        },
        watch: {
            message(value) {
                if (value) {
                    Echo.private(`groups.${this.groupSession.id}`)
                        .whisper('typing', {
                            name: auth.first_name
                        })
                }
            }
        },
        methods: {
            leaveGroup() {
              if (confirm("Are you sure you want to leave the group?")) {
                  axios.delete(`/member/group-send/${this.groupSession.id}`, {
                  }).then(res => {
                      if (res.data.success) {
                          window.location.href = '/member/chat'
                      }
                  });
                }
            },
            hasImage( content ) {
                return content.match(`/<img/`);
            },
            uploadSuccess( file, response) {
                this.attachment = response.name;
                this.sendImage();
            },
            uploadSuccessFile( file, response) {
                this.attachment = response.name;
                this.sendFile();
            },
            append(emoji) {
                if( this.message!== null)
                {
                    this.message += emoji;
                }
                else {
                    this.message = emoji;
                }

            },
            send() {
                if (this.message){
                    axios.post(`/member/group-send/${this.groupSession.id}`, {
                        content: this.message,
                        chat_image: null,
                        to_user: null,
                    }).then(res => {
                        this.chats.push(res.data);
                    });
                    this.message = null;
                }
            },
            sendImage() {
                if (this.attachment){
                    axios.post(`/member/group-send/${this.groupSession.id}`, {
                        content: 'image',
                        chat_image: this.attachment,
                        to_user: null
                    }).then( (res) => {
                        this.chats.push(res.data);
                    });
                    this.attachment = null;
                    this.$refs.chatImageDropzone.removeAllFiles();
                    this.showImageUpload = false;

                }
            },
            sendFile() {
                if (this.attachment){
                    axios.post(`/member/group-send/${this.groupSession.id}`, {
                        content: 'file',
                        chat_file: this.attachment,
                        to_user: null
                    }).then( (res) => {
                        this.chats.push(res.data);
                    });
                    this.attachment = null;
                    this.$refs.chatFileDropzone.removeAllFiles();
                    this.showFileUpload = false;

                }
            },
            close() {
                this.$emit('close');
            },
            clear() {
                axios
                    .post(`/member/group-session/${this.groupSession.id}/clear`)
                    .then(res => (this.chats = []));
            },
            block() {
                /*
                // @todo: needs a column in group_session_users table

                this.groupSession.block = true;
                axios
                    .post(`/member/group-session/${this.groupSession.id}/block`)
                    .then(res => this.groupSession.blocked_by = auth.id)
            */
            },
            unblock() {
                this.groupSession.block = false
                axios
                    .post(`/member/group-session/${this.groupSession.id}/unblock`)
                    .then(res => this.groupSession.blocked_by = null)
            },
            getAllMessages() {
                let self = this;
                axios
                    .post(`/member/group-session/${this.groupSession.id}/chats`)
                    .then(res => self.chats = res.data);
            },
            read() {
                axios.post(`/member/group-session/${this.groupSession.id}/read`);
            }
        },
        created() {
            this.read();

            this.getAllMessages();

            Echo.private(`groups.${this.groupSession.id}`).listen("GroupMessageCreatedEvent", e => {
                //this.groupSession.open ? this.read() : "";
                this.chats.push(e);
            });

            Echo.private(`groups.${this.groupSession.id}`).listen("MessageReadEvent", e =>
                this.chats.forEach(
                    chat => (chat.id == e.chat.id ? (chat.read_at = e.chat.read_at) : ""))
            );

            Echo.private(`groups.${this.groupSession.id}`).listen(
                "BlockEvent",
                e => (this.groupSession.block = e.blocked)
            );

            Echo.private(`groups.${this.groupSession.id}`).listenForWhisper(
                "typing",
                e => {
                    this.isTyping = true
                    setTimeout(() => {
                        this.isTyping = false
                    }, 2000)
                }
            );

        }
    }
</script>

<style scoped>
    .group-message-block {
        width: 100%;
        clear: both;
    }

    .text-message-bg {
        background: #3399fe;
        color: #fff !important;
        PADDING: 5px 24px;
        border-radius: 0.5rem;
    }

    #show_options_chat {
        position: absolute;
        right: 7.5rem;
        top: 0.7rem;
    }

    #show_options_chat .fas, #show_options_chat .far {
        color: #b1c6d0;
    }

    .btn-send-message {
        border-radius: 0 0.42rem 0.42rem 0;
    }
    .input-message {
        padding-right: 6.5rem;
        border-radius: 0.42rem 0 0 0.42rem;
    }
    .chat-box {
        height: 400px;
    }

    .card-body {
        overflow-y: scroll
    }

    /* Emoji */
    .emoji-invoker {
        position: absolute;
        top: 0.5rem;
        right: 5.5rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s;
    }
    .emoji-invoker:hover {
        transform: scale(1.1);
    }
    .emoji-invoker > svg {
        fill: #b1c6d0;
    }

    .emoji-picker {
        z-index: 1;
        font-family: Montserrat;
        border: 1px solid #ccc;
        width: 100%;
        height: 20rem;
        overflow: scroll;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        background: #fff;
        box-shadow: 1px 1px 8px #c7dbe6;
    }
    .emoji-picker__search {
        display: flex;
    }
    .emoji-picker__search > input {
        flex: 1;
        border-radius: 10rem;
        border: 1px solid #ccc;
        padding: 0.5rem 1rem;
        outline: none;
    }
    .emoji-picker h5 {
        margin-bottom: 0;
        color: #b1b1b1;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: default;
    }
    .emoji-picker .emojis {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .emoji-picker .emojis:after {
        content: "";
        flex: auto;
    }
    .emoji-picker .emojis span {
        padding: 0.2rem;
        cursor: pointer;
        border-radius: 5px;
    }
    .emoji-picker .emojis span:hover {
        background: #ececec;
        cursor: pointer;
    }

</style>
