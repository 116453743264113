<template>
    <div class="col-xl-4 col-md-6 col-sm-12 profile-card-2">
        <div class="card shadow-none" style="width: 240px; border: none;" >
            <div class="card-header mx-auto pb-0">
                <div class="row m-0">
                    <div class="col-sm-12 text-center">
                        <div class="avatar avatar-xl">
                            <img class="img-fluid"
                                 :src="this.data.avatar !== null ? this.data.avatar.url :  '/images/avatar.png'"
                                 alt="Avatar Image" />
                        </div>
                        <a :href="'/member/profiles/'+this.data.username">
                            <h4>
                                <span v-text="this.data.first_name+' '+this.data.last_name"></span>
                                <img v-if="this.data.roles.hasOwnProperty('title') && this.data.roles[0].title === 'Admin'"
                                    src="/images/badges/admin.png"
                                    style="margin-top: -5px;" data-toggle="tooltip" data-placement="bottom"
                                    title="Administrator" alt="Admin Badge" />
                            </h4>
                        </a>
                    </div>
                    <div class="col-sm-12 text-center">
                        <div style="margin-top: -5px;">
                            <span v-text="this.data.position" class="font-small-2 text-light"></span>
                            <span class="font-small-2 text-light"> at </span>
                            <span v-text="this.data.organisation" class="font-small-2 text-light"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-content">
                <div class="card-body text-center mx-auto p-0">
                    <div class="d-flex justify-content-between mt-2 align-items-center">
                        <div class="followers">
                            <p class="font-weight-bold font-medium-2 mb-0" v-text="followers"></p>
                            <span class="">{{ __('globals.contacts') }}</span>
                        </div>
                        <div>
                          <follow :data="this.data" size="btn-sm"></follow>
                        </div>
                    </div>
                    <div class="mt-05">
                        <a :href="'/member/profiles/'+this.data.username">
                            <button class="btn btn-sm btn-outline-light mt-1">
                                {{ __('globals.view_profile_button') }} <i class="feather icon-chevron-right"></i>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Profile",
        props: ['data'],
        data() {
            return {
                followers: '',
            }
        },
        methods: {
            showFollowers() {
                axios
                    .get(`/member/user/${this.data.username}/contact-count`)
                    .then(response => {
                        this.followers = response.data.contact_count;
                    });
            },
        },
        beforeMount() {
            this.showFollowers();
        }
    }
</script>

<style scoped>

</style>
