<template>
    <div>
        <div class="card" v-if="this.shared_user">
            <div class="d-flex justify-content-start border-bottom">
                <shared-post-owner-info
                    :user_data="this.shared_user"
                    :supplier_data="[]"
                    :isServiceAdmin="isServiceAdmin"
                    :shared="true"
                    :type="this.data.type"
                    :user_id="user_id" />
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                    <span v-text="ago(this.data.created_at)" class="font-small-1 text-light " style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="editPost"><i
                            class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                    </span>
                            <span
                                v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="deleteNewsfeed"><i
                            class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pl-05 pr-05 mt-05">
                <div v-if="editing" class="form-group pr-05 pl-05">
                    <div class="mb-05 mx-05">
                        <div class="card my-0">
                            <editor
                                v-model="shared_text"
                                api-key="no-api-key"
                                :init='{
                                        skin: "trybz-member",
                                        skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                        content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                        external_plugins: {
                                            customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                            loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                        },
                                        valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                        plugins: "link quickbars autolink lists customtags loadingbar",
                                        link_context_toolbar: true,
                                        quickbars_selection_toolbar: "bold italic link bullist",
                                        quickbars_image_toolbar: false,
                                        quickbars_insert_toolbar: false,
                                        link_assume_external_targets: "https",
                                        link_target_list: false,
                                        link_title: false,
                                        toolbar: false,
                                        branding: false,
                                        elementpath: false,
                                        menubar: false,
                                        file_picker_types: "",
                                        height: 100,
                                        content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                        relative_urls : false,
                                        remove_script_host : false,
                                    }'
                            />
                        </div>
                    </div>
                    <button class="btn btn-sm btn-primary" @click.prevent="updateSharedNewsfeed">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click.prevent="cancelEditing">{{ __('globals.cancel') }}</button>
                </div>
                <div class="pt-05" v-else-if="body !== null">
                    <div class="breakAll pr-05 pl-05" v-linkified v-html="this.$options.filters.nl2br(body, true)">
                    </div>
                </div>

                <div class="d-flex justify-content-center pr-05 pl-05 pt-05">
                    <div class="card border-light w-100">
                        <div class="border-bottom">
                            <shared-post-owner-info
                                :user_data="this.data.module.owner"
                                :supplier_data="[]"
                                :isServiceAdmin="isServiceAdmin"
                                :shared="false"
                                type="resource"
                                :user_id="user_id"
                            />
                            <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                                <span v-text="ago(this.data.module.created_at)" class="font-small-1 text-light " style="margin-top: 3px;"></span>
                            </div>
                        </div>
                        <div class="bg-white text-white pt-2 pb-2 pl-2 pr-2 mb-0 d-flex justify-content-between">
                            <div>
                                <span class="font-medium-3 text-light">{{ __('job.new_position_open') }}:</span><br>


                                <a :href="'/member/job/'+data.module.slug" style="color: #000000"><span
                                    class="font-large-1 text-black text-bold-700"
                                    v-text="data.module.title"></span></a>
                                <div>
                                    <i class="feather icon-briefcase text bold-700" style="color: #3399fe !important"></i> <span
                                    style="color: #3399fe !important" class="text-bold-700"
                                    v-text="data.module.employment_type"></span>
                                </div>
                            </div>
                            <div v-if="data.module.organisation_logo !== null">
                                <img :src="data.module.organisation_logo.url"
                                     width="75">

                            </div>
                        </div>

                        <div class="card-content">
                            <div class="card-body p-0">

                            </div>
                            <div class="breakAll ml-1 mt-1 mr-1 mb-05 pb-1 border-bottom" style="color: black"
                                 v-html="data.module.position_description.substring(0, 300)"></div>
                            <div class="d-flex ml-1 mr-1 mt-05 mb-0 pb-05 border-bottom align-items-center justify-content-start">
                            <span v-if="data.module.hide_salary === 0" class="font-small-3 text-bold-700" style=" color: black">{{ __('job.salary') }}: {{data.module.custom_salary }}</span>
                                <div class="ml-auto" v-if="user.confirmed === true">
                                <span v-if=" data.module.isPositionClosed === false">
                                        <span class="ml-auto" v-if="data.module.external_link !== null">
                                            <a :href="data.module.external_link"
                                               target="_blank"
                                               class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.view_job_site') }} <i
                                                class="feather icon-chevron-right"></i></a>
                                        </span>
                                        <span v-else>
                                            <a :href="'/member/job-applies?apply='+data.module.slug"
                                               class="btn btn-primary waves-effect waves-light">{{ __('job.form.buttons.apply') }} <i
                                                class="fas fa-user-check"></i></a>
                                        </span>

                                </span>
                                    <div class="btn btn-sm btn-outline-danger p-05 rounded" v-else>
                                        <strong>{{ __('job.application_closed') }}</strong>
                                    </div>

                                </div>
                            </div>
                            <div class="d-flex justify-content-between ml-05 mr-05" style="color: black">
                                <div class="p-05">
                                    <like :post="data.module" type="job"></like>
                                </div>
                                <div class="p-05" style="margin-top: 1px;">
                                    <a href="javascript:void(0)"
                                       class="font-small-3 text-bold-700 text-dark"
                                       @click="showComments(data.id)">
                                        <i class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pl-1 pr-1 pb-05" v-if="data.groups !== undefined && data.groups.length > 0">
                <a :href="'/member/group/'+data.groups[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.groups[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pl-1 pr-1 pb-05" v-if="data.suppliers !== undefined && data.suppliers.length > 0">
                <a :href="'/member/supplier/'+data.suppliers[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.suppliers[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  :module="data.type"
                  :slug="data.module.slug"
                  :members="members"
        ></comments>
    </div>
</template>

<script>
import Job from '../../../mixins/newsfeed/Job'
import DateTime from '../../../mixins/newsfeed/DateTime'
import SharedFunctions from "../../../mixins/newsfeed/SharedFunctions";

export default {
    props: ['members'],
    name: "ShareJob",
    mixins: [
        SharedFunctions, Job, DateTime
    ],
    mounted () {
        this.getSharedPost()
    }
}
</script>
