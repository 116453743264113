<template>
    <div>
        <div class="card">
            <div style="display: flex; flex: 1 1 auto;" class="row">

                <div class="col-12 col-md-6">
                    <div class="img-square-wrapper">

                        <img :class="'card-img img-fluid m-05 product-image-'+product.id"
                             :src="product.product_image.length > 0 ? product.product_image[0].url : '/images/cover.png'" alt="Product Image" />

                        <span v-if="product.product_image.length > 1">
                            <span class="ml-05 mb-2" v-for="image in product.product_image">
                                <a @click.prevent="changeImage(product.id, image.url)">
                                    <img class="rounded" :src="image.url" height="50" width="50" alt="Product Image" />
                                </a>
                            </span>
                        </span>

                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="card-body p-1">
                        <h4 class="font-large-2" v-text="product.title"></h4>

                        <div class="mt-05 mb-05" v-if="product.average_rating !== null">
                            <span :class="'rating-static rating-'+product.average_rating * 10">  </span>
                        </div>

                    </div>
                    <div class="card-text p-1 border-bottom breakAll">
                        <span class="breakAll image-resize" v-html="product.product_description"></span>
                    </div>
                    <div class="d-flex p-1 align-items-center border-bottom">
                        <div class="ml-auto pr-1">
                            <a data-toggle="modal" data-target="#registerModal">
                                <button class="btn btn-primary waves-effect waves-light">
                                    {{ __('globals.buy_now') }} <i class="fa fa-tag"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                    <a href="#" data-toggle="modal" data-target="#registerModal" style="color: inherit;">
                        <post-owner-info :user_data="product.owner" :time="null"></post-owner-info>
                    </a>
                </div>

            </div>
            <div class="d-flex justify-content-between border-top pr-05 pl-05 mt-1">
                <div class="p-05">
                    <i class="fa fa-thumbs-o-up" data-toggle="modal" data-target="#registerModal"></i> {{ product.likes === 0 ? '' : product.likes }}
                </div>
                <div class="p-05">
                    <span data-toggle="modal" data-target="#registerModal">
                        <i class="fa fa-comment-o"></i> {{ product.comments === 0 ? '' : product.comments }} {{ product.comments === 1 ? __('globals.comment') : __('globals.comments') }}
                    </span>
                </div>
                <div>
                    <guest-social-share :social="product.social_share"></guest-social-share>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment-timezone";
    import linkify from 'vue-linkify';
    import PostOwnerInfo from "./PostOwnerInfo";

    Vue.directive('linkified', linkify)

    export default {
        name: "Product",
        props: ['product'],
        components: {PostOwnerInfo},
        data() {
            return {
                rating: null,
            }
        },
        computed: {
            ago() {
                return moment(this.product.published_at).format('DD-MM-YY');
            }
        },
        beforeMount() {
        },
        methods: {
            changeImage(id, url) {
                $('.product-image-' + id).attr("src", url);
            },
        }
    }
</script>


