<template>
    <div class="global-languages">
        <a
            @click="addNewLang()"
            class="btn btn-success waves-effect waves-light text-white"><i class="feather icon-plus"></i>
            Add New
        </a>
        <new-crud-language
            :data="null"
            v-if="addNewPage">
        </new-crud-language>

        <div v-if="loading">Loading..</div>
        <div v-for="(language, key) in filteredLanguages" :key="key" class="global-language pt-05 row">
            <div class="col-12 d-flex align-items-center">
                <a href="javascript:" class="form-control section-button btn btn-primary text-capitalize" @click="currentSection = key">{{key}}</a>
                <a @click="removeLanguage(key)" class="btn-sm btn-danger text-white ml-05">Delete</a>
            </div>
            <new-crud-language
                :data="language"
                v-if="currentSection === key">
            </new-crud-language>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'CrudLanguages',
        props: ['searchKeyword'],
        data() {
            return {
                loading: false,
                currentSection: null,
                addNewPage: false,
                languages: [],
            }
        },
        mounted() {
            this.fetchCrudLanguages();
        },
        computed: {
            filteredLanguages() {
                return this.languages;
            }
        },
        methods: {
            removeLanguage(key) {
                let self = this;
                if(confirm('Are you sure, you want to delete?')) {
                    // remove from database
                    axios.delete('/admin/language-cruds/' + key)
                        .then(response => {
                            if (response.data) {
                                self.fetchCrudLanguages();
                            }
                        });

                }
            },
            saveLanguage(language) {
                axios.post('/admin/language-cruds', {
                    key : language[0],
                    value: language[1]
                }).then(response => {
                    if (response.data) {
                        alert(response.data.message);
                    }
                });
            },
            addNewLang() {
                this.addNewPage = true;
            },
            fetchCrudLanguages() {
                this.loading = true;
                this.addNewPage = false;
                axios.get('/admin/language-cruds')
                    .then(response => {
                        if (response.data) {
                            this.loading = false;
                            this.languages = response.data;
                        }
                    });
            }
        }
    }
</script>
