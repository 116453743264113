module.exports = {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace) {
            let translation, translationNotFound = true

            // translation = window._translations[key];
            // return translation

            try {

                if(window.App.use_json_lang){

                    // Using JSON Lang
                    let json_lang = window._translations;
                    translation = json_lang[key]

                } else {

                    // Using PHP Lang
                    translation = key.split('.').reduce((t, i) => t[i] || null, window._translations[window._locale].php)
                }

                if (translation) {
                    translationNotFound = false
                }

            } catch (e) {
                translation = key
            }

            _.forEach(replace, (value, key) => {
                translation = translation.replace(':' + key, value)
            })

            return translation
        }
    },
}
