<template>
    <div>
            <div class="bg-white text-white pt-2 pb-2 pl-1 pr-1 mb-0 d-flex justify-content-between">
                <div>
                    <span class="font-medium-3 text-light">{{ __('job.new_position_open') }}:</span><br>
                    <a :href="'/member/job/'+this.data.slug" style="color: #000000"><span
                        class="h3"
                        v-text="this.data.title"></span></a>
                    <div>
                        <i class="feather icon-briefcase text bold-700" style="color: #3399fe !important"></i> <span
                        style="color: #3399fe !important" class="text-bold-700"
                        v-text="this.data.employment_type"></span>
                    </div>
                </div>
                <div v-if="this.data.organisation_logo !== null">
                    <img :src="this.data.organisation_logo.url"
                         width="75">
                </div>
            </div>

            <div class="card-content">
                 <div class="pt-1 pl-1 pr-1 pb-05 border-top">
                    <div v-if="collapse_body" class="breakAll image-resize textBox" v-linkified v-html="data.position_description"></div>
                    <div v-else :class="class_body+' image-resize textBox'" ref="textBox" v-linkified v-html="data.position_description"></div>

                    <div v-if="enable_read_more">
                        <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                            {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                        </button>
                        <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                            {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                        </button>
                    </div>
                </div>

                <div class="d-flex ml-1 mr-1 mt-05 mb-0 pb-05 align-items-center justify-content-start">
                    <span v-if="this.data.hide_salary === 0" class="font-small-3 text-bold-700" style=" color: black">{{ __('job.salary') }}: {{ this.data.custom_salary }}</span>
                    <div class="ml-auto" v-if="user.confirmed === true">
                        <span v-if=" this.data.isPositionClosed === false">
                            <span class="ml-auto" v-if="this.data.external_link !== null">
                                <a :href="this.data.external_link" target="_blank" class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.view_job_site') }} <i class="feather icon-chevron-right"></i>
                                </a>
                            </span>
                             <span v-else>
                                 <a :href="'/member/job-applies?apply='+this.data.slug"
                                       class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.apply') }} <i
                                        class="fas fa-user-check"></i></a>
                             </span>
                        </span>
                        <div class="btn btn-sm btn-outline-danger p-05 rounded ml-auto" v-else>
                            <strong>{{ __('job.headers.member.title_position_closed') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

export default {
    name: "NewsfeedSharedJob",
    props: ['data'],
    data(){
        return {
            user: window.App.user,
            enable_read_more: false,
            collapse_body: false,
            class_body: 'breakAll',
        }
    },
    mounted() {
        if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>

<style scoped>

</style>
