<template>
    <div>
        <div class="d-flex justify-content-between border-bottom">
            <div class="flex-grow-1">
                    <newsfeed-owner-info
                            :user_data="data.module.owner"
                            :supplier_data="data.supplier"
                            :shared="false"
                            :type="data.type"
                            :time="data.published_at">
                    </newsfeed-owner-info>
            </div>
            <div class="btn-group mt-05 mr-05">
                <div class="dropdown">
                                <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        style="border:none">
                                    <i class="fa fa-chevron-circle-down mr-0"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                                    <div v-if="canUpdate || isAdmin === true">
                                        <a class="dropdown-item"
                                           :href="'/member/zoom-webinar/'+data.module.zoom_id+'/edit'">
                                            <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                        </a>
                                        <a class="dropdown-item" href="#" @click="destroy">
                                            <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                        </a>
                                    </div>
                                    <a class="dropdown-item danger" @click.prevent="report">
                                        <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                                    </a>
                                </div>
                </div>
            </div>
        </div>
        <div class="card-content">
                <div id="gallery" class="card-img-top img-fluid">
                    <img class=" img-fluid"
                         :src="data.module.cover === null ? '/images/cover.jpg' : data.module.cover.url">
                </div>
                <div class="card-body p-0">

                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                            <span class="h3"><a :href="'/member/zoom-webinar/'+data.module.zoom_id"
                                                v-text="data.module.title"
                                                style="color:inherit;"></a></span>

                        <div class="ml-auto">
                            <div
                                class="badge badge-pill bg-gradient-secondary"
                                style=" padding: 0.2rem 0.5rem;">
                                <i class="feather icon-video"></i> {{ __('webinar.title_singular') }}
                            </div>
                        </div>
                    </div>

                    <div class="d-flex ml-1 mr-1 mt-0">
                        <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span
                        class="h6 primary" v-text="showDateTime"></span>

                    </div>

                    <div class=" pt-1 pl-1 pr-1 pb-05 border-top">
                             <div v-if="collapse_body" class="breakAll image-resize" v-linkified v-html="data.module.description"></div>
                             <div v-else :class="class_body" ref="textBox" v-linkified v-html="data.module.description"></div>
                             <div v-if="enable_read_more+ ' image-resize'">
                                 <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                     {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                                 </button>
                                 <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                     {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                                 </button>
                             </div>
                    </div>

                    <div v-if="data.module.user_registered === false">
                        <div class="d-flex border-top" v-if="data.module.event_price > 0">
                            <div
                                class="d-flex ml-05 mr-05  mb-0 pb-05 pt-05 align-items-center justify-content-start">
                                <span class="font-small-2 ml-05"> {{ data.module.event_price | currency(site_tax) }} </span>
                            </div>

                            <div class="ml-auto p-05">
                                <a :href="'/member/zoom-webinar/'+data.module.zoom_id+'/add_to_cart'"
                                   class="btn btn-sm btn-primary waves-effect waves-light">
                                    <i class="fa fa-dollar"></i> {{ __('globals.buy_now') }}
                                </a>
                            </div>
                        </div>
                        <div class="d-flex border-top" v-if="data.module.event_price === 0 || data.module.event_price  === null">
                            <div class="ml-auto p-05">
                                <a :href="'/member/zoom-webinar/'+data.module.zoom_id+'/register'"
                                   class="btn btn-sm btn-primary waves-effect waves-light">
                                    {{ __('webinar.form.buttons.register_now') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>

import linkify from 'vue-linkify'
import Share from '../../../../mixins/newsfeed/Share'
import ZoomWebinar from "../../../../mixins/newsfeed/ZoomWebinar";

Vue.directive('linkified', linkify)

export default {
    name: "NewsfeedZoomWebinar",
    props: ['data', 'section', 'registered',],
    components: {},
    data() {
        return {
            sidebar: this.section === 'sidebar',
        }
    },
    mixins: [
        Share, ZoomWebinar
    ],
    mounted(){
         if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>
