<template>
    <div>
        <h4 class="border-bottom pb-05 mb-1"> <i class="feather icon-users"></i> {{ __('group.title_followers') }}</h4>

        <p>{{ __('group.paragraphs.followers') }}</p>

        <input class="form-control round" type="text" name="search_term" id="search_term" v-model="search_term" v-on:input="debounceInput" :placeholder="__('group.form.placeholder.search')">

        <div style="max-height: 600px; overflow-y: auto;">
            <ul class="list-supplier m-1 p-0 " v-for="(member, index) in members" :key="index">
                <followers :type="type"
                           :slug="slug"
                           :member="member"
                           :admins="admin_list"
                           @removeMember="removeMember(index)"
                           @updateAdmins="updateAdmins"
                >
                </followers>
            </ul>

            <infinite-loading ref="infiniteLoadingMembers" :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"> {{ __('group.form.validation.no_more_followers') }}</div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>

import EventBus from '../EventBus'

export default {
    name: "MakeAdmin",
    props: ['type', 'slug', 'admins'],
    data() {
        return {
            members: [],
            page: 1,
            user: window.App.user,
            infiniteId: +new Date(),
            search_term: '',
            admin_list: this.admins
        }
    },
    methods: {
        infiniteHandler($state) {
            let vm = this;
            axios
                .get(this.getUrl())
                .then(response => {
                    let entries = Object.values(response.data.data)
                    if (entries.length > 0) {
                        $.each(entries, function (key, value) {
                            vm.members.push(value);
                        });
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        removeMember(index) {
            this.members.splice(index, 1);
        },
        getUrl() {
            if(this.type === 'group'){
              return `/member/${this.type}/${this.slug}/get-members?page=${this.page}&search_term=${this.search_term}`;
            }
            return `/member/${this.type}/${this.slug}/get-followers?page=${this.page}&search_term=${this.search_term}`;
        },
        debounceInput: _.debounce(function (e) {
            this.search_term = e.target.value;
            this.page = 1;
            this.members = [];
            this.infiniteId += 1;
        }, 500),
        updateAdmins(event){
            if(event.status === 'add'){
              this.admin_list.push(event.id);
            } else {
              this.admin_list = this.admin_list.filter(item => item !== event.id);
            }
        }
    },
    mounted() {
        let vm = this;
        // Used to manually trigger infinite loading
        this.$refs.infiniteLoadingMembers.$emit('infinite', this.$refs.infiniteLoadingMembers.stateChanger);

        // Linked to Pending Joins to append members
        EventBus.$on('addMember', function (payload) {
            vm.members.unshift(payload);
        });
    }
}
</script>

<style scoped>

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #c7c5c5;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: grey;
    }

</style>
