<template>
    <div>
        <div class="card" v-if="this.shared_user">
            <div class="d-flex justify-content-start border-bottom">
                <shared-post-owner-info
                    :user_data="this.shared_user"
                    :supplier_data="[]"
                    :isServiceAdmin="isServiceAdmin"
                    :shared="true"
                    :type="this.data.type"
                    :user_id="user_id" />
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                    <span v-text="ago(this.data.created_at)" class="font-small-1 text-light " style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                            <span v-if="isServiceAdmin === true || this.data.user_id ===  user_id || isAdmin === true">
                                <a class="dropdown-item" href="#" @click.prevent="editPost">
                                    <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                </a>
                            </span>
                            <span v-if="isServiceAdmin === true || this.data.user_id ===  user_id || isAdmin === true">
                                <a class="dropdown-item" href="#" @click.prevent="deleteNewsfeed">
                                    <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                </a>
                            </span>
                            <a class="dropdown-item danger" @click.prevent="reportNewsfeed">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pl-05 pr-05 mt-05">
                <div v-if="editing" class="form-group pr-05 pl-05">
                    <div class="mb-05 mx-05">
                        <div class="card my-0">
                            <editor
                                v-model="shared_text"
                                api-key="no-api-key"
                                :init='{
                                    skin: "trybz-member",
                                    skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                    content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                    external_plugins: {
                                        customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                        loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                    },
                                    valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                    plugins: "link quickbars autolink lists customtags loadingbar",
                                    link_context_toolbar: true,
                                    quickbars_selection_toolbar: "bold italic link bullist",
                                    quickbars_image_toolbar: false,
                                    quickbars_insert_toolbar: false,
                                    link_assume_external_targets: "https",
                                    link_target_list: false,
                                    link_title: false,
                                    toolbar: false,
                                    branding: false,
                                    elementpath: false,
                                    menubar: false,
                                    file_picker_types: "",
                                    height: 100,
                                    content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                    relative_urls : false,
                                    remove_script_host : false,
                                }'
                            />
                        </div>
                    </div>
                    <button class="btn btn-sm btn-primary" @click.prevent="updateSharedNewsfeed">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click.prevent="cancelEditing">{{ __('globals.cancel') }}</button>
                </div>

                <div class="pt-05" v-else-if="body !== null">
                    <div class="breakAll pr-05 pl-05" v-linkified v-html="this.$options.filters.nl2br(body, true)">
                    </div>
                </div>

                <div class="d-flex justify-content-center pr-05 pl-05 pt-05">
                    <div class="card border-light w-100">
                        <shared-post-owner-info
                                    :user_data="this.data.module.owner"
                                    :supplier_data="post_as_who === 'supplier' ? this.shared_supplier : []"
                                    :isServiceAdmin="isServiceAdmin"
                                    :shared="false"
                                    type="post"
                                    :user_id="user_id"
                        />

                        <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                            <span v-text="ago(this.data.module.created_at)" class="font-small-1 text-light " style="margin-top: 4px;"></span>
                        </div>
                        <div class=" mt-0 mb-0 border-top border-bottom">
                            <div class="breakAll pt-1 pb-05 col-12" v-linkified
                                 v-html="this.$options.filters.nl2br(data.module.post_text, true)"></div>
                            <div class="col-12 p-0">
                                <div v-if="data.module.video !== null">
                                    <video-player :options="videoOptions"/>
                                </div>
                                <div class="gallery-row" v-if="this.data.module.post_image.length > 1">
                                    <div class="gallery-container">
                                        <lightbox :items="images"></lightbox>
                                    </div>
                                </div>
                                <div v-else-if="this.data.module.post_image.length === 1">
                                    <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                                        <img class=" img-fluid" :src="this.data.module.post_image[0].url">
                                    </div>
                                </div>
                                 <div class="p-1" v-if="data.module.post_file.length > 0">
                                    <a @click.prevent="downloadFile(file.id, index)" v-for="(file, index) in data.module.post_file">
                                        <div class="card bg-outline-light text-center mb-05">
                                            <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                                                <strong class="text-center"><i class="feather icon-download"></i>
                                                    {{ __('post.form.buttons.download_file') }} ...{{ file.file_name.slice(-20) }} </strong>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-1" v-if="this.shared_group.length > 0">
                                    <a :href="'/member/group/'+this.shared_group[0].slug">
                                        <div class="card text-white mb-0 border-0">
                                            <img class="card-img img-fluid" style="height: 3rem;"
                                                 :src="this.shared_group[0].group_cover == null ? '/images/cover.jpg' : this.shared_group[0].group_cover.url">
                                            <div
                                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ this.shared_group[0].title }}</p>
                                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                                    class="feather icon-chevron-right"></i></button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-1" v-if="this.shared_supplier.length > 0">
                                    <a :href="'/member/supplier/'+this.shared_supplier[0].slug">
                                        <div class="card text-white mb-0 border-0">
                                            <img class="card-img img-fluid" style="height: 3rem;"
                                                 :src="this.shared_supplier[0].supplier_cover_image == null ? '/images/cover.jpg' : this.shared_supplier[0].supplier_cover_image.url">
                                            <div
                                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ this.shared_supplier[0].title }}</p>
                                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                                    class="feather icon-chevron-right"></i></button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           <div class="pl-1 pr-1 pb-05" v-if="data.groups !== undefined && data.groups.length > 0">
               <a :href="'/member/group/'+data.groups[0].slug">
                   <div class="card text-white mb-0 border-0">
                       <img class="card-img img-fluid" style="height: 3rem;"
                            :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                       <div
                           class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                           <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                               {{ data.groups[0].title }}</p>
                           <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                               class="feather icon-chevron-right"></i></button>
                       </div>
                   </div>
               </a>
           </div>
           <div class="pl-1 pr-1 pb-05" v-if="data.suppliers !== undefined && data.suppliers.length > 0">
               <a :href="'/member/supplier/'+data.suppliers[0].slug">
                   <div class="card text-white mb-0 border-0">
                       <img class="card-img img-fluid" style="height: 3rem;"
                            :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                       <div
                           class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                           <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                               {{ data.suppliers[0].title }}</p>
                           <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                               class="feather icon-chevron-right"></i></button>
                       </div>
                   </div>
               </a>
           </div>
           <div class="border-top pl-05 pr-05 pr-0 ml-0 mr-0" v-if="thesection !== 'sidebar'">
               <div class="d-flex justify-content-between">
                   <div class=" p-05">
                       <likes :id="this.data.uuid" :likes-count="this.data.likesCount"  :is-liked="this.data.isLiked" :liked-by-users="this.data.userLikes" type="newsfeed"></likes>
                   </div>
                   <div class="p-05 font-small-3 text-bold-700 mr-1"><a
                       @click.prevent="commentsShow = !commentsShow"><i
                       class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount !== 0 ? '('+commentsCount+')': ''"></span></a>
                   </div>
                   <div>
                       <social-share :id="data.id" :social="this.data.module.socialShare"/>
                   </div>
               </div>
           </div>
        </div>

        <div v-if="commentsShow">
            <newsfeed-comments :uuid="this.data.uuid" type="newsfeed" @length="updateCommentsCount"></newsfeed-comments>
        </div>

        <div class="modal fade" :id="'shareModal'+data.id" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
           <div class="modal-dialog" role="document">
               <div class="modal-content">
                   <div class="modal-header">
                       <h5 class="modal-title" id="exampleModalLabel">{{ __('post.share_post') }}</h5>
                       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                       </button>
                   </div>
                   <div class="modal-body">
                       <div class="p-1 card border-light">
                           <div class="">
                               <label :for="'comment_body'+data.id">{{ __('globals.enter_comment') }}</label>
                               <textarea :name="'comment_body'+data.id" :id="'comment_body'+data.id" cols="30" rows="3"
                                         class="form-control"></textarea>
                           </div>
                           <div class="pb-1">
                               <label :for="'feed'+data.id">{{ __('globals.select_share') }}</label>
                               <select :name="'feed'+data.id" :id="'feed'+data.id" class="form-control">
                                   <option value="profile">{{ __('globals.your_newsfeed') }}</option>
                                   <optgroup :label="__('globals.share_group')" v-if="groups_follow !== undefined">
                                       <option :value="'group_'+group.id" v-for="group in groups_follow">{{
                                               group.title
                                           }}
                                       </option>
                                   </optgroup>
                                   <optgroup :label="__('globals.share_supplier')"
                                             v-if="suppliers_follow !== undefined">
                                       <option :value="'supplier_'+supplier.id"
                                               v-for="supplier in suppliers_follow">{{ supplier.title }}
                                       </option>
                                   </optgroup>
                               </select>
                           </div>
                           <div class="p-1 card border-light">
                               <shared-post-owner-info
                                   :user_data="this.data.module.owner"
                                   :supplier_data="post_as_who === 'supplier' ? this.shared_supplier : []"
                                   :isServiceAdmin="isServiceAdmin"
                                   :shared="false"
                                   type="post"
                                   :user_id="user_id"
                               />
                               <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                       <span v-text="ago(data.published_at)" class="font-small-1 text-light "
                                             style="margin-top: 4px;"></span>
                               </div>

                               <div class=" mt-0 mb-0 border-top border-bottom">
                                   <div class=" pt-1 pb-1 col-12" v-if="post_text !== null && post_text !== ''">
                                       <div class="breakAll" v-linkified
                                            v-html="this.$options.filters.nl2br(post_text.substring(0,400), true)">
                                       </div>
                                   </div>
                                   <div class="col-12 p-0">
                                       <div class="text-center" v-if="data.module.video_link !== null">
                                           <video-player :options="videoOptions"/>
                                       </div>
                                       <div class="video_container"  v-else-if="data.module.video_external_url !== null">
                                           <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                                   mozallowfullscreen allowfullscreen :src="data.module.video_external_url"></iframe>
                                       </div>
                                       <div class="gallery-row" v-if="images.length > 1">
                                           <div class="gallery-container">
                                               <lightbox :items="images"></lightbox>
                                           </div>
                                       </div>
                                       <div v-else-if="images.length === 1">
                                           <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                                               <img class=" img-fluid"
                                                    :src="images[0]">
                                           </div>
                                       </div>
                                       <div class="" v-if="this.shared_group.length > 0">
                                           <a :href="'/member/group/'+this.shared_group[0].slug">
                                               <div class="card text-white mb-0">
                                                   <img class="card-img img-fluid" style="height: 3rem;"
                                                        :src="this.shared_group[0].group_cover == null ? '/images/cover.jpg' : this.shared_group[0].group_cover.url">
                                                   <div
                                                       class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                       <p class="text-white mb-0" style="line-height: 1rem;">
                                                           <strong>Posted
                                                               in: </strong> {{ this.shared_group[0].title }}</p>
                                                       <button class="btn-sm btn-outline-white ml-auto">View Group
                                                           <i
                                                               class="feather icon-chevron-right"></i></button>
                                                   </div>
                                               </div>
                                           </a>
                                       </div>
                                       <div class="" v-else-if="this.shared_supplier.length > 0">
                                           <a :href="'/member/supplier/'+this.shared_supplier[0].slug">
                                               <div class="card text-white mb-0">
                                                   <img class="card-img img-fluid" style="height: 3rem;"
                                                        :src="this.shared_supplier[0].supplier_cover_image == null ? '/images/cover.jpg' : this.shared_supplier[0].supplier_cover_image.url">
                                                   <div
                                                       class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                       <p class="text-white mb-0" style="line-height: 1rem;">
                                                           <strong>{{ __('globals.posted_to') }} </strong> {{ this.shared_supplier[0].title }} </p>
                                                       <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }}
                                                           <i
                                                               class="feather icon-chevron-right"></i></button>
                                                   </div>
                                               </div>
                                           </a>
                                       </div>
                                       <div class="" v-if="data.module.post_file.length > 0">
                                           <div class="card bg-outline-light text-center mb-0">
                                               <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                                                   <strong class="w-100 text-center"><i
                                                       class="feather icon-download"></i>
                                                       {{ __('post.form.buttons.download_file') }} </strong>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="modal-footer">
                       <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('globals.close') }}</button>
                       <button type="button" class="btn btn-primary" @click.prevent="share">{{ __('globals.share') }}
                       </button>
                   </div>
               </div>
           </div>
        </div>
    </div>
</template>

<script>

import lightbox from "../lightbox/lightbox"
import "../lightbox/lightbox.css"
import SharedFunctions from "../../../mixins/newsfeed/SharedFunctions";
import Comments from '../../newsfeed/components/Comments.vue';
import VideoPlayer from "../../VideoPlayer.vue";

export default {
    name: "SharedPost",
    components: {lightbox, Comments, VideoPlayer},
    mixins: [
        SharedFunctions
    ],
    data() {
        return {
            post_as_who: '',
            images: [],
            commentsShow: false,
            commentsCount: this.data.comments.length,
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: this.data.module.video_link,
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    mounted() {
        this.getSharedPost()
    },
    methods: {
        updateCommentsCount(count){
            this.commentsCount = count;
        },
        downloadFile(file_id, index) {
            this.$http.get('/member/post/' + this.data.module.id + '/download-file/'+file_id, {responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], {type: this.data.module.post_file.mime_type})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = this.data.module.post_file[index].file_name
                    link.click()
                })
        },
    },
    beforeMount() {
        let mime_type = ['image/gif', 'image/jpeg', 'image/png'];
        if (this.data.module.media !== undefined && this.data.module.media.length > 0) {
            for (let i = 0; i < this.data.module.media.length; i++) {
                if (this.data.module.media[i] !== undefined) {
                    if (mime_type.includes(this.data.module.media[i].mime_type)) {
                        this.images.push(window.App.siteUrl+'/storage/' + this.data.module.media[i].id + '/' + this.data.module.media[i].file_name);
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
