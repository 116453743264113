import { render, staticRenderFns } from "./AcceptRejectInvite.vue?vue&type=template&id=5824bcdd&scoped=true"
import script from "./AcceptRejectInvite.vue?vue&type=script&lang=js"
export * from "./AcceptRejectInvite.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5824bcdd",
  null
  
)

export default component.exports