<template>
    <div>
        <div class="card-content">
            <div class="card-img-top img-fluid">
                <img class=" img-fluid" :src="this.data.cover === null ? '/images/cover.jpg' : this.data.cover.url">
            </div>
            <div class="card-body p-0">
                <div class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                    <span class="h3">
                        <a :href="'/zoom/'+this.data.zoom_id" v-text="this.data.title" style="color:inherit;"></a>
                    </span>
                    <div class="ml-auto">
                        <div class="badge badge-pill bg-gradient-secondary" style=" padding: 0.2rem 0.5rem;">
                            <i class="feather icon-video"></i> {{ __('meeting.title_singular')}}
                        </div>
                    </div>
                </div>

                <div class="d-flex ml-1 mr-1 mt-0">
                    <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6>
                    <span class="h6 primary" v-text="showDateTime"></span>
                </div>

                <div class="pt-1 pl-1 pr-1 border-top">
                             <div v-if="collapse_body" class="breakAll image-resize" v-linkified v-html="data.description"></div>
                             <div v-else :class="class_body" ref="textBox" v-linkified v-html="data.description"></div>
                             <div v-if="enable_read_more+ ' image-resize'">
                                 <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                     {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                                 </button>
                                 <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                     {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                                 </button>
                             </div>
                </div>

                <div class="d-flex border-top" v-if="this.data.event_price != null">
                            <div class="d-flex ml-05 mr-05  mb-0 pb-05 pt-05 align-items-center justify-content-start">
                                <span class="font-small-2 ml-05"> {{ __('globals.from') }} {{ this.data.event_price | currency(site_tax) }} </span>
                            </div>
                            <div class="ml-auto p-1" data-toggle="modal" data-target="#registerModal">
                                <button class="btn btn-sm btn-primary waves-effect waves-light">
                                    <i class="fa fa-dollar"></i> {{ __('globals.buy_now') }}
                                </button>
                            </div>
                </div>
                <div class="d-flex border-top" v-else data-toggle="modal" data-target="#registerModal">
                    <div class="ml-auto p-1">
                        <button class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('webinar.form.buttons.register_now') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)
import moment from 'moment-timezone';

export default {
    name: "NewsfeedSharedZoom",
    props: ['data'],
    data() {
        return {
            site_tax: window.App.site_tax,
            price_setting: window.App.price_setting,
            enable_read_more: false,
            collapse_body: false,
            class_body: 'breakAll',
        }
    },
    computed: {
         showDateTime() {
            return moment(this.data.meeting_at).format('LLL');
        },
    },
    mounted(){
        if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>
