<template>
    <div class="search-input">
        <div class="search-input-icon"><i class="fa fa-search fa-2x"></i></div>
        <input type="text" :placeholder="'Explore ' + (sitetitle ? sitetitle : '') + '...'" tabindex="-1" id="search"
               class="answers-input input" v-model="term" autocomplete="off" />
        <div class="search-input-close">
            <strong class="type-label">Search types: </strong>
            <select id="searchType" class="form-control" v-model="type" multiple="multiple">
                <option value="posts">Posts</option>
                <option value="events">Events</option>
                <option value="meetings">Meetings</option>
                <option value="webinars">Webinars</option>
                <option value="videos">Videos</option>
                <option value="jobs">Jobs</option>
                <option value="products">Products</option>
                <option value="resources">Resources</option>
                <option value="suppliers">Suppliers</option>
                <option value="groups">Groups</option>
                <option value="users">Users</option>
                <option value="questions">Questions</option>
            </select>
            <i class="fas fa-times"></i>
        </div>
        <div class="d-flex ml-2">
            <ul class="search-list" id="answers-search-list"></ul>
        </div>
    </div>
</template>

<script>

import TomSelect from 'tom-select';

export default {
    name: "GlobalSearch",
    props: ['sitetitle'],
    data() {
        return {
            results: [],
            timeout: null,
            debouncedInput: '',
            type: ['posts', 'users', 'questions', 'resources'],
            typeSelect: null,
        }
    },
    computed: {
        term: {
            get() {
                return this.debouncedInput
            },
            set(val) {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.debouncedInput = val
                }, 800);
            }
        },
    },
    watch: {
        debouncedInput(newInput, oldInput) {
            this.loadSearch();
        },
        type(newInput, oldInput) {
            this.loadSearch();
        }
    },
    mounted() {
        new TomSelect('#searchType',
                {
                    plugins: {
                        remove_button: {}
                    },
                    closeAfterSelect: true,
                    controlInput: null,
                    maxItems: 5,
                    render:{
                        item: function(data, escape) {
                            return '<div class="btn btn-primary">' + escape(data.text) + '</div>';
                        },
                    }
                }
            );
        function isTextSelected() {
          var selection = window.getSelection().toString();
          const focusedElement = document.activeElement;
          return focusedElement.tagName === "INPUT" || focusedElement.tagName === "TEXTAREA" || selection.length !== 0;
        }
        document.onkeyup = function (e) {
            e.preventDefault();
            if (e.which === 191 && ! isTextSelected()) {
                $('.nav-link-search').click()
            } else if (e.which === 27 && ! isTextSelected()) {
                $('.search-input-close i').click()
            }
        };
    },
    methods: {
        loadSearch() {
            if (this.searching || ! this.term || this.term.length < 3) {
                $('#answers-search-list').html('<div>Keep typing...</div>');
                return;
            }
            $('#answers-search-list').html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
            this.search();
        },
        search() {
            axios
                .post('/member/global-search', {term: this.term, type: this.type})
                .then(response => {
                    this.results = response.data.results;
                    this.displayResults();
                }).catch(function (error) {
                    $('#answers-search-list').empty();
                    $('#answers-search-list').append('<li class="d-block p-1"><i class="feather icon-alert-triangle"></i>There was a problem while performing the search</li>');
                })
        },
        displayResults() {
            $('#answers-search-list').empty();
            if (this.results !== '' && this.results !== undefined && !!Object.values(this.results).filter(value => value.length).length) {
                $('#answers-search-list').append('<li class="d-block p-1"><i class="feather icon-info"></i>Searched <strong>' + this.type.join(', ') + '</strong>. You can add more types to search</a></li>');
                this.displayPosts();
                this.displayEvents();
                this.displayMeetings();
                this.displayWebinars();
                this.displayVideos();
                this.displayJobs();
                this.displayProducts();
                this.displayResources();
                this.displaySuppliers();
                this.displayGroups();
                this.displayUsers();
                this.displayQuestions();
                $('#answers-search-dropdown').css('display', 'inline');
            } else {
                $('#answers-search-list').append('<li class="d-block p-1"><i class="feather icon-alert-triangle"></i>There are no results based on your search</li>');
                $('#answers-search-dropdown').css('display', 'inline');
            }
        },
        displayPosts() {
            if (this.results.post.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Posts</h4></li>');
                for (let i = 0; i < this.results.post.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.post[i].url + '">' + this.results.post[i].title + '</a></li>');
                }
            }
        },
        displayEvents() {
            if (this.results.event.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Events</h4></li>');
                for (let i = 0; i < this.results.event.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.event[i].url + '">' + this.results.event[i].title + '</a></li>');
                }
            }
        },
        displayMeetings() {
            if (this.results.meetings.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Zoom Meetings</h4></li>');
                for (let i = 0; i < this.results.meetings.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.meetings[i].url + '">' + this.results.meetings[i].title + '</a></li>');
                }
            }
        },
        displayWebinars() {
            if (this.results.webinars.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Zoom Webinars</h4></li>');
                for (let i = 0; i < this.results.webinars.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.webinars[i].url + '">' + this.results.webinars[i].title + '</a></li>');
                }
            }
        },
        displayVideos() {
            if (this.results.videos.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Videos</h4></li>');
                for (let i = 0; i < this.results.videos.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.videos[i].url + '">' + this.results.videos[i].title + '</a></li>');
                }
            }
        },
        displayJobs() {
            if (this.results.job.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Jobs</h4></li>');
                for (let i = 0; i < this.results.job.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.job[i].url + '">' + this.results.job[i].title + '</a></li>');
                }
            }
        },
        displayProducts() {
            if (this.results.product.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Products</h4></li>');
                for (let i = 0; i < this.results.product.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.product[i].url + '">' + this.results.product[i].title + '</a></li>');
                }
            }
        },
        displayResources() {
            if (this.results.resource.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Resources</h4></li>');
                for (let i = 0; i < this.results.resource.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.resource[i].url + '">' + this.results.resource[i].title + '</a></li>');
                }
            }
        },
        displaySuppliers() {
            if (this.results.supplier.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Suppliers</h4></li>');
                for (let i = 0; i < this.results.supplier.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.supplier[i].url + '">' + this.results.supplier[i].title + '</a></li>');
                }
            }
        },
        displayGroups() {
            if (this.results.group.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Groups</h4></li>');
                for (let i = 0; i < this.results.group.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.group[i].url + '">' + this.results.group[i].title + '</a></li>');
                }
            }
        },
        displayUsers() {
            if (this.results.users.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Members</h4></li>');
                for (let i = 0; i < this.results.users.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.users[i].url + '">' + this.results.users[i].title + '</a></li>');
                }
            }
        },
        displayQuestions() {
            if (this.results.question.length > 0) {
                $('#answers-search-list').append('<li class="d-block"><h4 class="pb-05 mt-2 border-bottom primary">Questions</h4></li>');
                for (let i = 0; i < this.results.question.length; i++) {
                    $('#answers-search-list').append('<li class="d-block p-1"><a href="' + this.results.question[i].url + '">' + this.results.question[i].title + '</a></li>');
                }
            }
        }
    }
}
</script>

<style scoped>
.answers-search-input {
    float: left;
    width: 0px;
}

.answers-search-input .answers-search-icon {
    z-index: 2;
    display: none;
    position: absolute;
    left: 1.5rem;
    top: 37%;
    cursor: pointer;
}


.answers-input {
    width: 100%;
    height: 62px;
    outline: none;
    background: #fff;
    transition: all 0.3s ease-out 0s;
}

#answers-search-dropdown {
    top: 50px;
}


.search-list {
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    border: solid 1px #000;
    margin-top: 0 !important;
}

.search-list ::-webkit-scrollbar {
    width: 6px;
}

.search-list ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.search-list ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

</style>
