<template>
    <div>
        <div class="d-flex justify-content-between border-bottom">
            <div class="flex-grow-1">
                <newsfeed-owner-info
                        :user_data="data.module.owner"
                        :supplier_data="data.supplier"
                        :shared="false"
                        :type="data.type"
                        :time="data.published_at">
                </newsfeed-owner-info>
            </div>
            <div class="btn-group mt-05 mr-05">
                <div class="dropdown">
                    <button class="btn-sm btn-outline-light dropdown-toggle " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                        <i class="fa fa-chevron-circle-down mr-0"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                        <div v-if="canUpdate || isAdmin === true">
                            <a class="dropdown-item"
                                           :href="'/member/event/'+data.module.slug+'/edit'">
                                            <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                            </a>
                            <a class="dropdown-item" href="#" @click="destroy">
                                <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                            </a>
                        </div>
                        <a class="dropdown-item danger" @click.prevent="report">
                            <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

            <div class="card-content">
                <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                    <img class=" img-fluid"
                         :src="data.module.event_image === null ? '/images/cover.jpg' : data.module.event_image.url">
                </div>
                <div class="card-body p-0">

                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                            <span class="h3"><a :href="'/member/event/'+data.module.slug" v-text="data.module.title"
                                                style="color:inherit;"></a></span>


                    </div>
                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 align-items-center justify-content-start">
                            <span v-if="data.module.event_privacy === 1"
                                  class="badge badge-pill bg-gradient-secondary font-small-2 text-bold-700 mb-05 mr-05"
                                  style="padding: 0.2rem 0.5rem;"><i class="feather icon-award"></i> {{ __('globals.premium_only') }}</span>
                        <span v-if="data.module.tickets.length > 0 && this.sold_out"
                              class="badge font-small-2 text-bold-700 badge-pill badge-danger mb-05"
                              style="padding: 0.2rem 0.5rem;"><i class="feather icon-alert-circle"></i> {{ __('event.sold_out') }}</span>
                    </div>
                    <div class="d-flex ml-1 mr-1 mt-0">
                        <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span
                        class="h6 primary" v-text="data.module.event_start_end_date"></span>

                    </div>
                    <div class="d-flex ml-1 mt-0 mb-05 mr-1 align-items-center justify-content-start text-light font-small-2">
                        <i class="fa fa-map-marker"></i> <span class="pl-05" v-text="data.module.location"></span>
                    </div>

                    <div class="pt-1 pl-1 pr-1 pb-05 border-top">
                        <div v-if="collapse_body" class="breakAll image-resize" v-linkified v-html="data.module.event_description"></div>
                        <div v-else :class="class_body+' image-resize'" ref="textBox" v-linkified v-html="data.module.event_description"></div>

                        <div v-if="enable_read_more">
                             <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                            </button>
                            <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>

                    <div class="d-flex p-05 align-items-center justify-content-start border-top">

                        <span class="font-small-2 ml-05" v-if="this.can_buy_tickets === true && this.price !== null">
                            {{ __('globals.from') }} {{ this.price | currency(site_tax)}} <!--{{ __('globals.all_inclusive') }}-->
                        </span>

                        <span class="font-small-2 ml-05" v-if="this.can_buy_tickets === false && this.lowest_plan_name !== null && this.lowest_plan_price !== null">
                            {{ __('globals.from') }} {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('ticket.upgrade_plan_message') }}
                        </span>

                        <div class="ml-auto">
                            <a v-if="this.sold_out !== true && this.can_buy_tickets === true && this.price !== null" :href="'/member/event/'+data.module.slug+'/tickets'"
                               class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('event.form.buttons.buy_tickets') }} <i class="fa fa-ticket"></i>
                            </a>
                            <a v-if="data.module.contact_link !== null" :href="data.module.contact_link" style="color: #fff;" target="_blank"
                               class="btn btn-sm btn-primary waves-effect waves-light">{{ __('event.form.buttons.view_event_site') }} <i
                                class="feather icon-chevron-right"></i>
                            </a>
                            <a v-else :href="'/member/event/'+data.module.slug"
                               class="btn btn-sm btn-flat-primary text-primary text-bold-700 waves-effect waves-light">
                                {{ __('globals.view_event_page') }} <i class="feather icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import Share from '../../../../mixins/newsfeed/Share'
import Event from '../../../../mixins/newsfeed/Event'
import Tickets from '../../../../mixins/tickets'

export default {
    name: "NewsfeedEvent",
    props: ['data'],
    mixins: [
        Share, Event, Tickets
    ],
    mounted(){
         if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
    beforeMount() {
        this.ticketsPrice(this.data.module.tickets);
    }
}
</script>

<style>

</style>
