import { render, staticRenderFns } from "./ChildComments.vue?vue&type=template&id=87f1806a&scoped=true"
import script from "./ChildComments.vue?vue&type=script&lang=js"
export * from "./ChildComments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f1806a",
  null
  
)

export default component.exports