<template>
    <div class="d-flex mt-1 mb-1">
        <div v-if="filters.all === '1'">
            <button class="btn round btn-outline-primary mr-05" style="line-height: 1.4; min-width: 80px" v-on:click="all" v-bind:class=" (this.all_button === 'active') ? 'active' : '' ">
                {{ __('search.show_all') }}
            </button>
        </div>

        <div v-if="filters.user === '1' && guest !== true">
            <button class="btn round btn-outline-primary mr-05" style="line-height: 1.4" v-on:click="user" v-bind:class=" (this.user_button === 'active') ? 'active' : '' ">
                {{ __('search.your_interests') }}
            </button>
        </div>

        <div v-if="filters.latest === '1'">
            <button class="btn round btn-outline-primary mr-05" style="line-height: 1.4" v-on:click="latest" v-bind:class="(this.latest_button === 'active') ? 'active' : ''">{{ __('search.latest') }}</button>
        </div>

        <div v-if="filters.popular === '1'">
            <button class="btn round btn-outline-primary mr-05" style="line-height: 1.4" v-on:click="popular" v-bind:class="(this.popular_button === 'active') ? 'active' : ''" >{{ __('search.popular') }}</button>
        </div>

        <div v-if="filters.category === '1'" class="mr-05" >
            <select class="form-control round btn-outline-primary" v-model="category_id" v-bind:class="(this.category_button === 'active') ? 'active' : ''" style="min-width: 70px;line-height: 1" @change="categories_search" >
                <option value="">{{ __('search.categories') }} </option>
                    <option v-for="(category, id) in categories" :key="id" :value="category" :selected="category === category_id">
                        {{ id }}
                    </option>
            </select>
        </div>

        <div v-if="filters.tags === '1'" class="mr-05">
            <select class="form-control  round dropdown btn-outline-primary mr-05" v-model="tag_id" v-bind:class="(this.tag_button === 'active') ? 'active' : ''" style="min-width: 70px;line-height: 1" @change="tag_search">
                <option value="">{{ __('search.tags') }}</option>
                <option v-for="(tag, index) in tags" :key="index" :value="tag">{{ index }}</option>
            </select>
        </div>

        <div v-if="filters.type === '1'" class="mr-05">
            <select class="form-control round dropdown btn-outline-primary mr-05" v-model="type_id" v-bind:class="(this.type_button === 'active') ? 'active' : ''" style="min-width: 70px;line-height: 1" @change="type_search">
                <option value="">{{ __('search.types') }}</option>
                <option v-for="(type, index) in types" :key="index" :value="type">{{ index }}</option>
            </select>
        </div>

        <div v-if="filters.keyword === '1'" class="input-group">
            <input class="form-control round" type="text" v-bind:class="(this.keyword_button === 'active') ? 'active' : ''" v-model="search_term" v-on:input="debounceInput" :placeholder="__('search.type_to_search')">
            <div class="input-group-append" id="button-addon2">
                <button class="btn round btn-outline-primary waves-effect waves-light" type="button">{{ __('globals.search') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Search",
    data() {
        return {
            search_term: '',
            category_id: this.selected_category ? this.selected_category : '',
            type_id: '',
            tag_id: '',
            all_button: '',
            latest_button: '',
            popular_button: '',
            user_button: '',
            category_button: '',
            tag_button: '',
            type_button: '',
            keyword_button: ''
        }
    },
    props: ['filters', 'categories', 'types', 'tags', 'guest', 'selected_category'],
    methods: {
        resetForm(){
            this.category_id = '';
            this.type_id = '';
            this.tag_id = '';
            this.search_term = '';
        },
        latest() {
            this.latest_button = 'active'
            this.user_button = '';
            this.all_button = '';
            this.popular_button = '';
            this.category_button = '';
            this.tag_button = '';
            this.type_button = '';
            this.keyword_button = '';

            this.resetForm();

            this.$emit('searchType', {type: 'latest'})
        },
        all() {
            this.all_button = 'active';
            this.user_button = '';
            this.latest_button = ''
            this.popular_button = '';
            this.category_button = '';
            this.tag_button = '';
            this.type_button = '';
            this.keyword_button = '';

            this.resetForm();

            this.$emit('searchType', {type: 'all'})
        },
        user() {
            this.user_button = 'active';
            this.all_button = ''
            this.latest_button = ''
            this.popular_button = '';
            this.category_button = '';
            this.tag_button = '';
            this.type_button = '';
            this.keyword_button = '';

            this.resetForm();

            this.$emit('searchType', {type: 'user'})
        },
        popular() {
            this.all_button = ''
            this.user_button = '';
            this.latest_button = ''
            this.popular_button = 'active';
            this.category_button = '';
            this.tag_button = '';
            this.type_button = '';
            this.keyword_button = '';

            this.resetForm();

            this.$emit('searchType', {type: 'popular'})
        },
        categories_search() {
            this.all_button = '';
            this.user_button = '';
            this.latest_button = '';
            this.popular_button = '';
            this.category_button = 'active';
            this.tag_button = '';
            this.type_button = '';
            this.keyword_button = '';

            this.$emit('searchType', {type: 'category', categoryId: this.category_id})
        },
        tag_search() {
            this.all_button = ''
            this.user_button = '';
            this.latest_button = ''
            this.popular_button = '';
            this.category_button = '';
            this.tag_button = 'active';
            this.type_button = '';
            this.keyword_button = '';

            this.$emit('searchType', {type: 'tag', tagId: this.tag_id})
        },
        type_search() {
            this.all_button = '';
            this.user_button = '';
            this.latest_button = ''
            this.popular_button = '';
            this.category_button = '';
            this.tag_button = '';
            this.type_button = 'active';
            this.keyword_button = '';

            this.$emit('searchType', {type: 'type', typeId: this.type_id})
        },
        debounceInput: _.debounce(function (e) {

            this.all_button = ''
            this.user_button = '';
            this.latest_button = ''
            this.popular_button = '';
            this.category_button = '';
            this.tag_button = '';
            this.type_button = '';
            this.keyword_button = 'active';

            this.$emit('searchType', {type: 'keyword', keyword: this.search_term})
        }, 500)
    },
    beforeMount() {
        switch (this.filters.default) {
          case 'user':
              this.guest === true ? this.all_button = 'active' : this.user_button = 'active';
              break;
          case 'popular':
              this.popular_button = 'active';
              break;
          case 'latest':
              this.latest_button = 'active';
              break;
          default:
              this.all_button = 'active';
              break;
        }
    }

}
</script>

<style scoped>

</style>
