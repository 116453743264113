<template>
    <div class="card pb-3 mb-0">
        <div class=" shadow" v-if="resource.cover !== null">
            <a :href="'/member/'+resource.type+'/'+resource.slug">
                <img class="img-fluid card-img-top " :src="resource.cover.url" style="object-fit: cover;">
            </a>
        </div>
        <div class="card-content pl-05 pt-1 pr-1 pb-0">
            <div class="d-flex flex-row justify-content-between">
                <div class="flex-fill">
                    <a :href="'/member/'+resource.type+'/'+resource.slug">
                        <div v-if="resource.cover === null">
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'doc'"><img src="/images/icons/doc.png"
                                                                                   width="25" alt="Document"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'docx'"><img src="/images/icons/doc.png"
                                                                                    width="25" alt="Document"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'txt'"><img src="/images/icons/doc.png"
                                                                                   width="25" alt="Text file"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'pdf'"><img src="/images/icons/pdf.png"
                                                                                   width="25" alt="PDF document"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'xls'"><img src="/images/icons/xls.png"
                                                                                   width="25" alt="Spreadsheet"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'xlsx'"><img src="/images/icons/xls.png"
                                                                                    width="25" alt="Spreadsheet"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'ppt'"><img src="/images/icons/ppt.png"
                                                                                   width="25" alt="Powerpoint presentation"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                 v-if="resource.resource_file_type === 'pptx'"><img src="/images/icons/ppt.png"
                                                                                    width="25" alt="Powerpoint presentation"></div>
                        </div>
                        <div class="h5 text-bold-700 pl-05">{{ resource.title.substring(0, 70) }}</div>
                    </a>

                    <div v-if="resource.disabled === 1">
                        <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2">
                        <i class="feather icon-alert-circle"></i> {{ __(resource.type.replace('-', '_') + '.title') }} Inactive
                        </span>
                    </div>
                    <div class="clearfix"></div>

                    <div class="d-flex justify-content-between align-items-center w-100 ">
                        <div class="ml-05">
                            <span :class="'rating-static rating-'+resource.average_rating * 10" v-if="resource.average_rating !== null">
                            </span>
                        </div>
                    </div>
                </div>

                <span class="btn-sm btn-flat-secondary btn-no-hover static flex-shrink-0 text-light resource-date"> {{ formatDate(resource.published_at) }}</span>
            </div>

        </div>
        <div class="card-content resource-description font-small-4 mt-1 px-1" v-if="service_settings.description_display" v-html="description"></div>
        <div class="card-bottom m-05 p-0 d-flex align-items-center justify-content-between" style="width: 96%;">

            <span class="font-small-2 pl-05" v-if="resource.price === 0">
                Free Download
            </span>
            <span class="font-small-2 pl-05" v-else-if="resource.price !== null">
                {{ resource.price | currency(site_tax) }} inc {{ this.site_tax.name }}
            </span>

            <div class="ml-auto mr-05" v-if="confirmed_user === true">
                <span v-if="resource.price === 0 || resource.can_be_downloaded === true">
                    <div v-if="resource.external_url !== null">
                        <a :href="'/member/' + resource.type + '/'+resource.slug+'/access-external-link'" target="_blank"
                           class="btn-sm btn-primary waves-effect waves-light" style="border:none;"><i
                            class="fa fa-link"></i> {{ __('resource.access') }} </a>
                    </div>
                    <div v-else>
                        <a :href="'/member/'+resource.type+'/'+resource.slug+'/download-file'"
                           class="btn-sm btn-primary waves-effect waves-light" style="border:none;"><i
                            class="fa fa-download"></i> {{ __('resource.form.buttons.download') }} </a>
                    </div>
                </span>
                <span v-else-if="resource.price !== null">
                    <a :href="'/member/'+resource.type+'/'+resource.slug+'/add_to_cart'">
                        <button class="btn-sm btn-primary waves-effect waves-light" style="border:none;"><i
                            class="fa fa-download"></i> {{ __('resource.form.buttons.buy') }}
                        </button>
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card.Resource",
    props: ['resource', 'service_settings'],
    data() {
        return {
            user: window.App.user,
            site_tax: window.App.site_tax,
            confirmed_user: window.App.user.confirmed,
        }
    },
    methods: {
        formatRating(average_rating) {
            if (average_rating === null) return null;

            if (!Number.isInteger(average_rating)) {
                let rate = parseInt(average_rating);
                average_rating = (rate + 0.5);
            }

            return average_rating * 10;
        },
        formatDate(date) {
            return moment(date).format('DD MMM YY');
        },
    },
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.resource.resource_description.split(' ').length > 50 ? this.resource.resource_description.split(' ', 50).join(' ') + '...' : this.resource.resource_description) : '';
        }
    }
}
</script>
