<template>
    <div class="card" style="padding-bottom: 40px;" >
        <div class="shadow">
            <a :href="'/zoom-webinar/'+zoom_webinar.zoom_id">
                <img class="card-img-top img-fluid "
                     :src="zoom_webinar.cover !== null ? zoom_webinar.cover.url :  '/images/cover.jpg'"
                     style="object-fit: cover;">
            </a>
        </div>
        <div class="card-content">
            <div class="card-body pt-05 p-1">
                <a :href="'/zoom-webinar/'+zoom_webinar.zoom_id">
                    <h6 class="text-bold-700 mb-0"> {{ zoom_webinar.title }}</h6>
                </a>


                <a :href="'/zoom-webinar/'+zoom_webinar.zoom_id">
                    <p class="event-text primary font-small-1 mt-05 mb-05" style="line-height: 1.4em;"><i class="feather icon-calendar"></i>
                        {{ zoom_webinar_date(zoom_webinar.webinar_at) }}</p>
                </a>
                <div
                    class="badge badge-pill bg-gradient-secondary"
                    style=" padding: 0.2rem 0.5rem;">
                    <i class="feather icon-video"></i> {{ __('webinar.title_singular') }}
                </div>
                <div class="card-bottom mt-1 d-flex font-small-3 align-items-center ">
                    <div>
                        <span v-if="zoom_webinar.event_price !== 0 && zoom_webinar.event_price !== null" class="text-bold-700 font-small-2 text-light">
                            {{ __('globals.from') }} {{ zoom_webinar.event_price | currency(site_tax)}}
                        </span>
                    </div>

                    <div class="ml-auto">

                        <a :href="'/zoom-webinar/'+zoom_webinar.zoom_id">
                            <button class="btn-sm btn-flat-light" style="border:none;">{{ __('globals.view') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {
    name: "GuestZoomWebinarCard",
    props: ['zoom_webinar'],
    data() {
        return {
        }
    },
    methods: {
        zoom_webinar_date(date) {
            return moment(date).format('Do MMMM YYYY, h:mm A')
        },
    },
    beforeMount() {
    }
}
</script>

<style scoped>

</style>
