<template>
    <div>
        <div class="card">
            <div class="d-flex">
                 <post-owner-info :data="data"
                             :isServiceAdmin="isServiceAdmin"
                             :user_id="user_id"
                ></post-owner-info>
                <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                    <span v-text="ago(data.module.created_at)" class="font-small-1 text-light "
                      style="margin-top: 4px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" :href="'/member/video/'+data.module.slug+'/edit'"><i
                            class="feather icon-settings"></i>{{ __('video.form.links.edit_video') }}</a>
                    </span>
                            <span
                                v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                            class="feather icon-trash-2"></i>{{ __('video.form.links.delete_video') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('video.form.links.report_video') }}</a>
                        </div>
                    </div>
                </div>
            </div>


            <div class=" mt-0 mb-0 border-top border-bottom">

                <div class="col-12 p-0">
                    <span v-if="data.module.can_view_video === true">
                        <div class="text-center" v-if="data.module.external_url === null">
                            <video-player :options="videoOptions" v-if="videoOptions.sources[0].src"/>
                        </div>
                        <div class="video_container" v-else>
                                <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="data.module.external_url"></iframe>
                            </div>
                    </span>
                    <span v-else>
                            <img class="img-fluid"
                                 :src="data.module.cover == null ? data.module.thumbnail : data.module.cover.url">
                    </span>
                    <div class="pt-1 pb-1 col-12">
                        <a :href="'/member/video/'+data.module.slug"><span class="h3" v-text="data.module.title"/></a>
                        <div>
                            <span class="font-small-3 text-bold-700 text-secondary video-view"><i class="feather icon-eye"></i> {{ data.module.visits }} {{ __('globals.views') }}</span>
                            <span class="text-bold-700 text-secondary px-1">&bull;</span>
                            <span class="font-small-3 text-bold-700 text-secondary"><i class="feather icon-calendar"></i> {{ formatDate(this.data.created_at) }}</span>
                        </div>
                        <div>
                            <div class="breakAll pt-1" v-linkified style="max-height: 300px; overflow: hidden; text-overflow: ellipsis">
                                <span class="breakAll" v-html="data.module.description"></span>
                            </div>
                            <div class="text-right">
                                <a :href="'/member/video/'+data.module.slug" class="font-small-3">{{ __('globals.read_more') }}<i class="feather icon-chevron-right"></i></a>
                            </div>
                        </div>
                        <div v-if="data.module.can_view_video === false">
                            <div class="d-flex">
                                <span
                                    class="font-small-2 pt-1"> {{ data.module.video_price | currency(site_tax) }} {{ __('globals.all_inclusive') }}</span>
                                <div class="ml-auto " v-if="data.module.can_view_video === false">
                                    <a :href="'/member/video/'+data.module.slug+'/add_to_cart'"
                                       class="btn btn-primary waves-effect waves-light">{{ __('video.form.links.pay_for_view') }} <i
                                        class="fa fa-tag"></i></a>
                                </div>
                            </div>
                            <div id="progress" class="p-2" style="display: none"></div>
                        </div>
                    </div>
                </div>

                <div class="p-1" v-if="data.groups !== undefined && data.groups.length > 0">
                    <a :href="'/member/group/'+data.groups[0].slug">
                        <div class="card text-white mb-0">
                            <img class="card-img img-fluid" style="height: 3rem;"
                                 :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                            <div
                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                    {{ data.groups[0].title }}</p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                    class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>

                </div>
                <div class="p-1" v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                    <a :href="'/member/supplier/'+data.suppliers[0].slug">
                        <div class="card text-white mb-0">
                            <img class="card-img img-fluid" style="height: 3rem;"
                                 :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                            <div
                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{
                                        data.suppliers[0].title
                                    }} </p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                    class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="p-05">
                    <like :post="data.module" type="video"></like>
                </div>
                <div class="p-05">
                    <a @click.prevent="showComments(data.id)" class="font-small-3 text-bold-700">
                        <i class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span>
                    </a>
                </div>
                <social-share :id="data.id" :social="this.data.module.socialShare"/>
            </div>
        </div>


        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  module="video"
                  :slug="data.module.slug"
                  :members="members"
        ></comments>

        <div class="modal fade" :id="'shareModal'+data.id" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ __('video.share_video') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="p-1 card border-light">
                            <div class="">
                                <label :for="'comment_body'+data.id">{{ __('globals.enter_comment') }}</label>
                                <textarea :name="'comment_body'+data.id" :id="'comment_body'+data.id" cols="30" rows="3"
                                          class="form-control"></textarea>
                            </div>
                            <div class="pb-1">
                                <label :for="'feed'+data.id">{{ __('globals.select_share') }}</label>
                                <select :name="'feed'+data.id" :id="'feed'+data.id" class="form-control">
                                    <option value="profile">{{ __('globals.your_newsfeed') }}</option>
                                    <optgroup :label="__('globals.share_group')" v-if="groups_follow !== undefined">
                                        <option :value="'group_'+group.id" v-for="group in groups_follow">{{
                                                group.title
                                            }}
                                        </option>
                                    </optgroup>
                                    <optgroup :label="__('globals.share_supplier')"
                                              v-if="suppliers_follow !== undefined">
                                        <option :value="'supplier_'+supplier.id"
                                                v-for="supplier in suppliers_follow">{{ supplier.title }}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>

                            <div class="p-1 card border-light">
                                <post-owner-info :data="data"
                                                 :isServiceAdmin="isServiceAdmin"
                                                 :user_id="user_id"
                                />
                                <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                               <span v-text="ago(data.published_at)" class="font-small-1 text-light "
                                                     style="margin-top: 4px;"></span>
                                </div>

                                <div class=" mt-0 mb-0 border-top border-bottom">
                                    <div class=" pt-1 pb-1 col-12">
                                        <div class="text-center rounded" style="background-color: #000;">
                <span v-if="data.module.can_view_video === true">

                    <video-player :options="videoOptions" v-if="videoOptions.sources[0].src"/>
                </span>
                                            <span v-else>
                    <img class="img-fluid"
                         :src="data.module.cover == null ? data.module.thumbnail : data.module.cover.url">
                </span>


                                        </div>
                                        <a :href="'/member/video/'+data.module.slug"></a><span class="h3"
                                                                                               v-text="data.module.title"/>
                                        <div>
                         <span
                             class="badge badge-pill badge-light"><i class="fa fa-eye"></i> {{ data.module.visits }} {{ __('globals.views') }} </span>
                                        </div>
                                        <div class="breakAll pt-2" v-linkified
                                             v-html="this.$options.filters.nl2br(data.module.description.substring(0,400), true)"></div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="" v-if="data.groups !== undefined && data.groups.length > 0">
                                            <a :href="'/member/group/'+data.groups[0].slug">
                                                <div class="card text-white mb-0">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;">
                                                            <strong>{{ __('globals.posted_in') }} </strong> {{ data.groups[0].title }}</p>
                                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }}
                                                            <i
                                                                class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class=""
                                             v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                                            <a :href="'/member/supplier/'+data.suppliers[0].slug">
                                                <div class="card text-white mb-0">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;">
                                                            <strong>{{ __('globals.posted_to') }} </strong> {{ data.suppliers[0].title }} </p>
                                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }}
                                                            <i
                                                                class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevent="sharePost(data.id)">{{ __('globals.share') }}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Share from '../../../mixins/newsfeed/Share'
import Video from '../../../mixins/newsfeed/Video'
import DateTime from '../../../mixins/newsfeed/DateTime'

export default {
    name: "NewsfeedVideo",
    props: ['groups_follow', 'suppliers_follow', 'administrator', 'members'],
    data() {
        return {
            price: '',
            plan_prices: '',
        }
    },
    mixins: [
        Share, Video, DateTime
    ],
    methods: {
         formatDate(date) {
            return moment(date).format('DD MMM YY');
        },
    },
    mounted() {
        axios
            .get('/member/video/' + this.data.module.slug + '/get_price')
            .then(response => {
                this.price = response.data.price;
            });

        axios
            .get('/member/video/' + this.data.module.slug + '/get_all_price_plans')
            .then(response => {
                this.plan_prices = response.data.plan_prices;
            });
    },
}
</script>

<style>
.video_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
