<template>
    <div>
        <div class="d-flex justify-content-between border-bottom">
            <div class="flex-grow-1">
                <newsfeed-owner-info
                            :user_data="data.module.owner"
                            :supplier_data="data.supplier"
                            :shared="false"
                            :type="data.type"
                            :time="data.published_at">
                </newsfeed-owner-info>
            </div>
            <div class="btn-group mt-05 mr-05">
                <div class="dropdown">
                            <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                                <i class="fa fa-chevron-circle-down mr-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                                <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                                    <a class="dropdown-item" :href="'/member/video/'+data.module.slug+'/edit'"><i
                                        class="feather icon-settings"></i>{{ __('video.form.links.edit_video') }}</a>
                                </span>
                                <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                                    <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                                        class="feather icon-trash-2"></i>{{ __('video.form.links.delete_video') }}</a>
                                </span>
                                <a class="dropdown-item danger" @click.prevent="report">
                                    <i class="feather icon-alert-triangle"></i>{{ __('video.form.links.report_video') }}</a>
                            </div>
                </div>
            </div>
        </div>
        <div class=" mt-0 mb-0 border-top">
            <div class="col-12 p-0">
                    <span v-if="data.module.can_view_video === true">
                        <div class="text-center" v-if="data.module.external_url === null">
                            <video-player :options="videoOptions" v-if="videoOptions.sources[0].src"/>
                        </div>
                        <div class="video_container" v-else>
                                <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="data.module.external_url"></iframe>
                            </div>
                    </span>
                    <span v-else>
                            <img class="img-fluid"
                                 :src="data.module.cover == null ? data.module.thumbnail : data.module.cover.url">
                    </span>

                    <div class="pt-1 pb-05 col-12">
                        <a :href="'/member/video/'+data.module.slug"><span class="h3" v-text="data.module.title"/></a>
                        <div>
                            <span class="font-small-3 text-bold-700 text-secondary video-view"><i class="feather icon-eye"></i> {{ data.module.views }} {{ __('globals.views') }}</span>
                            <span class="text-bold-700 text-secondary px-1">&bull;</span>
                            <span class="font-small-3 text-bold-700 text-secondary"><i class="feather icon-calendar"></i> {{ formatDate(this.data.created_at) }}</span>
                        </div>

                        <div class="pt-1 pb-05">
                            <div v-if="collapse_body" class="breakAll image-resize " v-linkified v-html="data.module.description"></div>
                            <div v-else :class="class_body+' image-resize '" ref="textBox" v-linkified v-html="data.module.description"></div>

                            <div v-if="enable_read_more">
                                 <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                    {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                                </button>
                                <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                    {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                <div class="pt-1 pb-1 col-12 border-top" v-if="user.confirmed && data.module.can_view_video === false">
                    <span v-if="data.module.price === null && this.lowest_plan_price !== null" class="font-small-2">
                        {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('plan.upgrade_plan_message') }}
                    </span>
                    <div v-else-if="data.module.price !== null" class="d-flex">
                        <span class="font-small-2">{{ __('globals.form.paragraph.your_price') }} {{ data.module.price | currency(site_tax) }} {{ __('globals.all_inclusive') }}</span>
                        <div class="ml-auto">
                            <a :href="'/member/video/'+data.module.slug+'/add_to_cart'" class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('video.form.links.pay_for_view') }} <i class="fa fa-tag"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="progress" class="p-2" style="display: none"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Share from '../../../../mixins/newsfeed/Share'
import Video from '../../../../mixins/newsfeed/Video'

export default {
    name: "NewsfeedVideo",
    props: ['data'],
    mixins: [
        Share, Video
    ],
    beforeMount() {
        this.videoPlanPrices(this.data.module.video_plan_prices);
    },
    mounted(){
         if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>

<style>
.video_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
