<template>
    <div v-if="sidebar === true ? 'class=\'col-12\'' : 'class=\'col-12 col-md-6\''">
        <div class="card">
            <div class="d-flex justify-content-start border-bottom">
                <div class="flex-grow-1">
                    <newsfeed-owner-info
                            :user_data="this.post.owner"
                            :supplier_data="this.post.supplier"
                            :shared="false"
                            type="post"
                            :time="this.post.published_at">
                    </newsfeed-owner-info>
                </div>

                <div class="btn-group mt-05 mr-05">
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="canUpdate">
                        <a class="dropdown-item" href="#" @click.prevent="editPost"><i
                            class="feather icon-settings"></i>{{ __('post.form.buttons.edit_post') }}</a>
                    </span>
                            <span v-if="canUpdate">
                        <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                            class="feather icon-trash-2"></i>{{ __('post.form.buttons.delete_post') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('post.form.buttons.report_post') }}</a>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="editing" class="form-group w-100 p-1">
                <div class="card mb-1">
                    <editor
                        v-model="post_text"
                        api-key="no-api-key"
                        :init='{
                            skin: "trybz-member",
                            skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                            content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                            external_plugins: {
                                customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                            },
                            valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                            plugins: "link quickbars autolink lists customtags loadingbar",
                            link_context_toolbar: true,
                            quickbars_selection_toolbar: "bold italic link bullist",
                            quickbars_image_toolbar: false,
                            quickbars_insert_toolbar: false,
                            link_assume_external_targets: "https",
                            link_target_list: false,
                            link_title: false,
                            toolbar: false,
                            branding: false,
                            elementpath: false,
                            menubar: false,
                            file_picker_types: "",
                            height: 100,
                            content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                            relative_urls : false,
                            remove_script_host : false,
                        }'
                    />
                </div>
                <button class="btn btn-sm btn-primary" @click="update">{{ __('globals.update') }}</button>
                <button class="btn btn-sm btn-link" @click="editing = false">{{ __('globals.cancel') }}</button>
            </div>
            <div class=" pt-1 col-12 pb-1" v-else-if="post_text !== null">
                <div class="breakAll">
                    <span class="breakAll" v-html="post_text"></span>
                </div>
            </div>
            <div class="gallery-row" v-if="images.length > 1">
                <div class="gallery-container">
                    <lightbox :items="images"></lightbox>
                </div>
            </div>
            <div v-else-if="images.length === 1">
                <div :id="'gallery-'+post.id" class="card-img-top img-fluid">
                    <img class=" img-fluid"
                         :src="images[0]">
                </div>
            </div>
            <div class="text-center" v-if="post.video_link !== null">
                <video-player :options="videoOptions"/>
            </div>
            <div class="video_container" v-else-if="post.video_external_url !== null">
                <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                        mozallowfullscreen allowfullscreen :src="post.video_external_url"></iframe>
            </div>

            <div class="" v-if="post.post_file.length > 0">

                <a @click.prevent="downloadFile(file.id, index)" v-for="(file, index) in post.post_file">
                    <div class="card bg-outline-light text-center mr-1 ml-1 mb-05">
                        <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                            <strong class="w-100 text-center"><i class="feather icon-download"></i> {{ __('post.form.buttons.download_file') }} </strong>
                        </div>
                    </div>
                </a>

            </div>
            <div class="m-1" v-if="post.group !== undefined && post.group !== null">
                <a :href="'/member/group/'+post.group.slug">
                    <div class="card text-white mb-0">
                        <img class="card-img img-fluid" style="height: 3rem;" :src="post.group.group_cover == null ? '/images/cover.jpg' : post.group.group_cover.url">
                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>Posted in: </strong> {{ post.group.title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">View Group <i class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
            <div class="m-1" v-else-if="post.supplier !== undefined && post.supplier !== null">
                <a :href="'/member/'+post.supplier.type+'/'+post.supplier.slug">
                    <div class="card text-white mb-0">
                        <img class="card-img img-fluid" style="height: 3rem;" :src="post.supplier.supplier_cover_image == null ? '/images/cover.jpg' : post.supplier.supplier_cover_image.url">
                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>Posted to </strong> {{ post.supplier.title }} </p>
                            <button class="btn-sm btn-outline-white ml-auto">View Page <i class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
             <div class="d-flex border-top justify-content-between">
                <div class="font-small-3 text-bold-700">
                    <likes :id="this.post.id" :likes-count="this.post.likesCount" :is-liked="this.post.isLiked" :liked-by-users="this.post.userLikes" type="post"></likes>
                </div>
                <div class="font-small-3 text-bold-700">
                        <a class="p-05 d-block" @click.prevent="commentsShow = !commentsShow"><i
                           class="fa fa-comment-o"></i>
                            <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                            <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                        </a>
                </div>
                <div class="font-small-3 text-bold-700">
                    <social-share :id="this.post.newsfeed_uuid" :social="this.post.socialShare" @shareModal="triggerShareModal"></social-share>
                </div>
            </div>
        </div>

        <div class="row" v-if="sidebar === false">
            <div class="mr-auto ml-1 mb-1">
                <button class="btn btn-outline-danger" @click.prevent="report">{{ __('globals.report') }}</button>
            </div>
        </div>

        <div v-if="commentsShow && sidebar === false">
            <comments :newsfeed_uuid="this.post.newsfeed_uuid" :id="this.post.id" type="post" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <share-modal :original_newsfeed_id="this.post.newsfeed_id" :module="this.post" type="post" @closeModal="share_newsfeed = false" ></share-modal>
        </div>

    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue'

    import lightbox from "../post/lightbox/lightbox"
    import "../post/lightbox/lightbox.css"

    import Likes from "../newsfeed/components/Likes.vue";
    import Comments from "../newsfeed/components/Comments.vue";
    import ShareModal from "../newsfeed/components/ShareModal.vue";

    import Post from "../../mixins/newsfeed/Post";
    import Share from "../../mixins/newsfeed/Share";

    export default {
        name: "Post",
        props: ['data', 'section'],
        components: { lightbox, 'editor': Editor, Likes, Comments, ShareModal },
        mixins: [Post, Share],
        data() {
            return {
                commentsShow: false,
                commentsCount: this.post.commentsCount,
                sidebar: this.section === 'sidebar',
                share_newsfeed: false,
            }
        },
        methods: {
            updateCommentsCount(count){
                this.commentsCount = count;
            },
            triggerShareModal(){
                this.share_newsfeed = !this.share_newsfeed;
            }
        },
        mounted() {
        },
        computed: {
        }
    }
</script>
