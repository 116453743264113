<template>
    <div class="mb-2">
        <search :filters="filters" :categories="categories" :tags="tags" :guest="true" v-on:searchType="changeFilter($event)" :selected_category="categoryId"></search>

        <div class="row match-height p-05">
            <guest-resource-card :resource="resource" v-for="(resource, index) in resources" :key="infiniteId+index" :service_settings="service_settings"></guest-resource-card>
        </div>

        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results">{{ __('globals.no_resources_found_header') }}</div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Search from "../../Search.vue";
import GuestResourceCard from "../cards/Resource.vue";

export default {
    name: "ResourceInfiniteScroll",
    components: {InfiniteLoading, Search, GuestResourceCard},
    props: ['filters', 'categories', 'tags', 'service_settings'],
    data() {
        return {
            resources: [],
            page: 1,
            search: '',
            infiniteId: +new Date(),
            keyword: '',
            tagId: '',
            categoryId: '',
        }
    },
    methods: {
        infiniteHandler($state) {
            if(this.search === 'custom'){
                this.getFilteredResources($state);
            } else {
                this.getResources($state);
            }
        },
        getResources($state){
            let vm = this;
            axios
                .get("/resource/search/"+this.search+"?page="+this.page)
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.resources.push(value);
                        });
                        this.matchHeight();
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getFilteredResources($state){
            let vm = this;
            axios
                .get("/resource/search/custom?categoryId=" + this.categoryId + "&tagId=" + this.tagId + "&keyword=" + this.keyword + "&page=" + this.page)
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.resources.push(value);
                        });
                        this.matchHeight();
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        changeFilter(args) {
            if (args.type === "tag" || args.type === "keyword" || args.type === "category") {
                if(args.tagId !== undefined) {
                    this.tagId = args.tagId
                }
                if(args.keyword !== undefined) {
                    this.keyword = args.keyword
                }
                if(args.categoryId !== undefined) {
                  const searchURL = new URL(window.location);
                    searchURL.searchParams.set('category', args.categoryId);
                    window.history.pushState({}, '', searchURL);
                    this.categoryId = args.categoryId
                }
                this.search = 'custom';
            } else {
                this.categoryId = '';
                this.tagId = '';
                this.keyword = '';
                this.search = args.type;
            }
            this.page = 1;
            this.resources = [];
            this.infiniteId += 1;
        },
        matchHeight() {
          let iteration = 0;
          let intervalId = setInterval(function () {
            if (iteration < 10) {
              $(".row.match-height").each(function () {
                $(this).find(".card").not(".card .card").matchHeight(); // Not .card .card prevents collapsible cards from taking height
              });
              iteration++;
            } else {
              clearInterval(intervalId);
            }
          }, 100);
        }
    },
    beforeMount() {
        switch (this.filters.default) {
          case 'popular':
              this.search = 'popular';
              break;
          case 'latest':
              this.search = 'latest';
              break;
          default:
              this.search = 'all';
              break;
        }
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        if (params.get("category")) {
          this.search = 'custom'
          this.categoryId = params.get("category")
        }
    }
}
</script>
