<template>
    <div class="d-flex card">

        <div v-if="data_updated" class="p-1">
            <vue-apex-charts width="100%" height="400" type="line" :options="options" :series="series"></vue-apex-charts>
        </div>
        <div class="d-flex align-items-center justify-content-center p-1" style="width: 100%; min-height: 400px;" v-else>
            <div class="spinner-border text-primary" role="status" >
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="p-1">
            <button type="button" class="btn btn-outline-primary" @click.prevent="show_filter_options()">
                {{ __('globals.filter_options') }}
                <i class="feather icon-chevron-down" v-if="!show_filters"></i>
                <i class="feather icon-chevron-up" v-if="show_filters"></i>
            </button>
        </div>

        <form :class="show_filters  ? 'p-1': 'p-1 hidden'">
            <div class="d-flex">
                 <div class="form-group flex-grow-1">
                     <label for="start_date">{{ __('globals.start_date') }}</label>
                     <input id="start_date" type="month" class="form-control" v-model="start_date" />
                     <div class="text-left text-danger help-block font-small-1" role="alert" v-if="show_error_message">
                         <i class="feather icon-x-circle"></i> {{ __('globals.start_end_date_error') }}
                     </div>
                </div>
                 <div class="form-group flex-grow-1 ml-1">
                     <label for="end_date">{{ __('globals.end_date') }}</label>
                     <input id="end_date" type="month" class="form-control" v-model="end_date" />
                </div>
            </div>

            <div class="form-group">
                <label :for='"selected_fields"+this._uid'>Fields</label>
                <select :id='"selected_fields"+this._uid' class="form-control" multiple="multiple" v-model="selected_fields" ref="fieldMultiselect">
                    <option :value="field" v-for="field in default_fields">{{field}}</option>
                </select>
            </div>

            <div class="d-flex">
                <button type="button" class="btn btn-outline-warning" @click.prevent="reset()">{{ __('globals.reset') }}</button>
                <button type="button" class="btn btn-primary ml-1" @click.prevent="filter()">{{ __('globals.filter') }}</button>
            </div>
        </form>

    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import TomSelect from 'tom-select';
    import moment from "moment-timezone";

    export default {
        name: "PageViews",
        components: {VueApexCharts, TomSelect},
        props: [],
        data() {
            return {
                tom_select_instance: '',
                data_updated: false,
                show_filters: false,
                show_error_message: false,
                start_date: '',
                end_date: '',
                months_range: [],
                default_fields: ['home', 'group', 'supplier', 'event', 'product', 'video', 'zoom', 'resource', 'question'],
                selected_fields: ['home', 'group', 'supplier', 'event', 'product', 'video', 'zoom', 'resource', 'question'],
                options: {
                    chart: {
                        id: 'page-views-chart',
                    },
                    title: {
                        text: 'Public Page Views',
                        align: 'center',
                    },
                    stroke: {
                      width: 4
                    },
                    markers: {
                        size: 2
                    },
                    xaxis: {
                        categories: []
                    },
                    yaxis: {
                        title: {
                            text: 'Views',
                        },
                    },
                    legend: {
                        position: 'top',
                    },
                    colors: ['#FF0000', '#FF8000', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#8000FF', '#FF00FF', '#FF0080']
                },
                series: [],
            }
        },
        methods: {
            show_filter_options(){
                this.show_filters = !this.show_filters;
            },
            filter(){
                this.show_error_message = false;

                if(this.start_date >= this.end_date || this.start_date === '' || this.end_date === ''){
                    this.show_error_message = true;
                    return false;
                }

                this.data_updated = false;
                this.setDateRange(this.start_date, this.end_date)

                axios.post('/admin/public-views-stats', {
                        fields: this.selected_fields,
                        start_date: this.start_date,
                        end_date: this.end_date,
                    })
                    .then((response) => {
                        // Filter the Data and update the chart
                        this.options.xaxis.categories = this.months_range;
                        this.series = this.format_data(response.data.data);
                        this.data_updated = true;
                    })
            },
            setDateRange(start_date, end_date){
                this.months_range = [];
                let currentDate = moment(start_date).clone();
                while (currentDate.isSameOrBefore(end_date, 'month')) {
                  this.months_range.push(currentDate.format('MMM yyyy'));
                  currentDate.add(1, 'month');
                }
            },
            reset(){
                this.start_date = moment().subtract(11, 'months').format('YYYY-MM');
                this.end_date = moment().format('YYYY-MM');
                this.setDateRange(this.start_date, this.end_date);
                this.tom_select_instance.setValue(this.default_fields);
                this.filter();
            },
            renderChart(options) {

            },
            format_data(data){
                const series = [];
                for (const type in data) {
                    const dataset = {
                        name: type,
                        data: [],
                    };

                    for(const month in this.months_range){
                        if(data[type][this.months_range[month]]){
                            dataset.data.push(data[type][this.months_range[month]]);
                        } else {
                            dataset.data.push(0);
                        }
                    }
                    series.push(dataset);
                }
                return series;
            }
        },
        mounted() {
            this.tom_select_instance = new TomSelect('#selected_fields'+this._uid, {
                plugins: {
                    remove_button:{
                        title:'Remove this item',
                    }
                },
            });
            this.filter();
        },
        beforeMount() {
            this.start_date = moment().subtract(11, 'months').format('YYYY-MM');
            this.end_date = moment().format('YYYY-MM');
            this.setDateRange(this.start_date, this.end_date);
        },
    }
</script>

<style scoped>

</style>
