<template>
    <div class="card pb-3 mb-0">
        <div class="card-content">
            <div class="shadow">
                <a :href="'/member/'+supplier.type+'/'+supplier.slug">
                    <img class="img-fluid card-img-top" :src="supplier.cover == null ? '/images/cover.jpg' : supplier.cover.url"  style="object-fit: cover;" alt="Cover image">
                </a>
            </div>
            <div class="d-flex">
                <div class="text-center">
                    <div class="avatar avatar-lg" style="margin-top: -20px;">
                        <a :href="'/member/'+supplier.type+'/'+supplier.slug">
                            <img class="rounded-circle shadow" :src="supplier.avatar == null ? '/images/avatar.png' : supplier.avatar.url" alt="Avatar image">
                        </a>
                    </div>
                </div>

                <div class="pt-05 pr-05">
                    <a :href="'/member/'+supplier.type+'/'+supplier.slug"><h6 class="mb-0 text-bold-700">{{ supplier.title }}</h6></a>
                </div>
            </div>

            <div class="p-2" v-if="supplier.disabled === 1">
                <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2 ">
                    <i class="feather icon-alert-circle"></i> {{ __('resource.page_inactive') }}
                </span>
            </div>

<!--            <div class="card-body pt-0 pl-1 pr-1 pb-0">-->
<!--                <div class="pt-05 text-bold-700 text-primary font-small-2" style="line-height: 1.1;"> {{ supplier.topic }}   </div>-->
<!--            </div>-->
            <div class="card-content supplier-description font-small-4 mt-1 mx-1" v-if="service_settings.description_display" v-html="description"></div>
            <div class="card-bottom p-05  d-flex font-small-3 align-items-center justify-content-between ">

                <span class=" badge-sm badge-pill badge-light text-white font-small-1 " style="padding: 0.3rem 0.5rem" v-if="supplier.privacy === '1'">
                    <i class="fa fa-shield-alt" data-toggle="tooltip" data-placement="bottom" title="Private Page"></i>  {{ __('globals.private') }}
                </span>

                <join-service class="ml-auto"
                    type="supplier"
                    :slug="supplier.slug"
                    :status="supplier.is_following"
                    button_size="small">
                </join-service>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card.Supplier",
    props: ['supplier', 'service_settings'],
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.supplier.description.split(' ').length > 50 ? this.supplier.description.split(' ', 50).join(' ') + '...' : this.supplier.description) : '';
        }
    }
}
</script>
