<template>
    <div class="modal fade" ref="shareModal" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ __('question.convert') }}</h5>
                        <button type="button" class="close" @click.prve.prevent="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body form-group">
                        <div class="mb-05">
                            <label for="question_title" class="required">Question Title</label>
                            <input  v-model="question_title" type="text" class="form-control"/>
                            <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.question_title != null">
                                <i class="feather icon-x-circle"></i> {{this.errors.question_title[0]}}
                            </div>
                        </div>

                        <div class="mb-05">
                            <label for="question_body" class="required d-block">Question Text</label>
                            <div id="loader" class="card p-1">
                                <div class="spinner-border spinner-border-sm text-secondary m-auto" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div id="editor" class="card my-0 d-none">
                                <div>
                                    <editor
                                        v-model="question_text"
                                        api-key="no-api-key"
                                        :init='{
                                            skin: "trybz-member",
                                            skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                            content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                            external_plugins: {
                                                customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                                loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                            },
                                            valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                            plugins: "link quickbars autolink lists customtags loadingbar autoresize",
                                            link_context_toolbar: true,
                                            quickbars_selection_toolbar: "bold italic link bullist",
                                            quickbars_image_toolbar: false,
                                            quickbars_insert_toolbar: false,
                                            link_assume_external_targets: "https",
                                            link_target_list: false,
                                            link_title: false,
                                            toolbar: false,
                                            branding: false,
                                            elementpath: false,
                                            menubar: false,
                                            max_height: 300,
                                            file_picker_types: "",
                                            content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                            relative_urls : false,
                                            remove_script_host : false,
                                            init_instance_callback: this.loader,
                                        }'
                                    />
                                </div>
                            </div>
                            <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.question_body != null">
                                <i class="feather icon-x-circle"></i> {{this.errors.question_body[0]}}
                            </div>
                        </div>

                         <div v-if="tags.length > 0" class="mb-05 w-100">
                             <label class="">{{ __('question.field.text.tags') }}</label>
                             <select :id='"tags"+this._uid' class="form-control" multiple="multiple" v-model="selected_question_tags">
                                <option :value="tag.id" v-for="tag in tags">{{tag.name}}</option>
                             </select>
                             <div class="text-left text-danger help-block font-small-1 " role="alert" v-if="this.errors.question_tags != null">
                                <i class="feather icon-x-circle"></i> {{this.errors.question_tags[0]}}
                             </div>
                         </div>

                         <div class="mb-05 w-100">
                             <label class="required">{{ __('question.field.text.service_label') }}</label>
                             <select :id='"service"+this._uid' class="form-control" v-model="service">
                                 <option value="profile" selected>{{ __('globals.your_newsfeed') }}</option>
                                 <optgroup :label="__('globals.share_group')">
                                    <option :value="'group_'+group.id" v-for="group in groups_followed">{{group.title}}</option>
                                 </optgroup>
                                 <optgroup :label="__('globals.share_supplier')">
                                    <option :value="'supplier_'+supplier.id" v-for="supplier in suppliers_followed" v-if="supplier.enable_faqs === 1">{{supplier.title}}</option>
                                 </optgroup>
                             </select>
                             <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.service != null">
                                <i class="feather icon-x-circle"></i> {{this.errors.service[0]}}
                             </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="close">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevevnt="convertQuestion">{{ __('questions.convert') }}</button>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import TomSelect from 'tom-select';

    export default {
        name: 'QuestionModal',
        components: {
            'editor': Editor,
            TomSelect
        },
        props: ['question_body', 'module_id', 'type', 'user_id'],
        data(){
            return {
                question_title: '',
                question_text: this.question_body,
                tags: [],
                selected_question_tags: [],
                service: 'profile',
                groups_followed: window.App.groupsFollowed,
                suppliers_followed: window.App.suppliersFollowed,
                errors: {
                    'question_title': null,
                    'question_body': null,
                    'question_tags': null,
                    'service': null
                },
                submitting: false,
          }
        },
        mounted() {
            $('#shareModal').modal('show');
            $('#shareModal').on('shown.bs.modal', function() {
                $(document).off('focusin.modal');
            });

            new TomSelect('#service'+this._uid,
                {
                }
            );
        },
        methods: {
            errorsReset(){
                this.errors = {
                    'question_title': null,
                    'question_body': null,
                    'question_tags': null,
                    'service': null
                };
            },
            async convertQuestion() {
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                this.errorsReset();
                return await axios
                    .post('/member/make-question', {
                        question_title: this.question_title,
                        question_body: this.question_text,
                        question_tags: this.selected_question_tags,
                        service: this.service,
                        module_id: this.module_id,
                        module_type: 'App\\Models\\Post',
                        user_id: this.user_id,
                    })
                    .then(() => {
                        $('#shareModal').modal('hide');
                        let message = {
                            'message': 'Question Converted Successfully',
                            'level': 'success'
                        }
                        flash(message);
                        location.reload();
                    })
                    .catch(err => {
                        this.errors = err.response.data.errors;
                        this.submitting = false;
                    });
            },
            close() {
                this.$emit('close-modal');
                $('#shareModal').modal('hide');
            },
            loader(){
                  $('#editor').removeClass('d-none');
                  $('#loader').addClass('d-none');
            },
            async getTags(){
               await  axios
                    .get('/member/question/tags')
                    .then(response => {
                        this.tags = response.data;
                    })

               if(this.tags.length > 0){
                    new TomSelect('#tags'+this._uid,
                        {
                                plugins: {
                                    'clear_button':{
                                        'title':'Remove all selected options',
                                    },
                                    'remove_button':{
                                        'title':'Remove this item',
                                    }
                                },
                        }
                    );
               }
            },
        },
        beforeMount() {
            this.getTags()
        }
    }
</script>
