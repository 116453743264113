<template>
    <div>
        <h4 class="border-bottom pb-05 mb-1">{{ __('group.title_invite') }}</h4>

        <p>{{ __('group.paragraphs.invite_contacts') }}</p>

        <input class="form-control round" type="text" name="search_term" id="search_term" v-model="search_term" v-on:input="debounceInput" :placeholder="__('group.form.placeholder.search')"/>

        <ul class="list-supplier m-1 p-0">
            <li class="list-supplier-item d-flex justify-content-between align-items-center mt-05" style="list-style-type: none;" v-for="(friend, index) in friends" :key="index">
                <user-invite-card :user="friend" :type="type" :slug="slug"></user-invite-card>
            </li>
        </ul>

        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results"> </div>
        </infinite-loading>
    </div>

</template>

<script>

    import UserInviteCard from "./UserInviteCard";

    export default {
        name: "Invite",
        props: ['type', 'slug'],
        components: {UserInviteCard},
        data() {
            return {
                friends: [],
                page: 1,
                user: window.App.user,
                infiniteId: +new Date(),
                search_term: '',
                endpoint: ''
            }
        },
        methods: {
            infiniteHandler($state) {
                this.loadFriends($state);
            },
            loadFriends($state){
                let vm = this;
                axios
                    .get(this.endpoint+'?search_term='+this.search_term+'&page='+ this.page)
                    .then(response => {
                        let entries = Object.values(response.data.data)
                        if (entries.length > 0) {
                            $.each(entries, function (key, value) {
                                vm.friends.push(value);
                            });
                            if($state) {
                                $state.loaded();
                            }
                        } else {
                            if($state) {
                                $state.complete();
                            }
                        }
                    });
                vm.page++;
            },
            debounceInput: _.debounce(function (e) {
                this.search_term = e.target.value;
                if(this.search_term.length < 3 && this.search_term.length !== 0){return false;}
                this.page = 1;
                this.friends = [];
                this.infiniteId += 1;
            }, 500)
        },
        mounted() {
            this.loadFriends();
        },
        beforeMount() {
            this.endpoint = `/member/${this.type}/${this.slug}/user-friends-invites`;
        }
    }
</script>

<style scoped>

</style>
