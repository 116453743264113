<template>
        <div>
            <button class="btn btn-outline-primary btn-sm" @click.prevent="makeAdmin" v-if="admin === false">{{ __('globals.make_admin')}}</button>
            <button class="btn btn-outline-success btn-sm" @click.prevent="removeAdmin" v-else-if="admin === true">{{ __('globals.remove_admin')}}</button>
        </div>
</template>

<script>
    export default {
        props: ['type', 'slug', 'member', 'is_admin'],
        name: "ServiceAdmin",
        data() {
            return {
                admin: this.is_admin,
                submitting: false,
            }
        },
        methods: {
            makeAdmin(){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .post(`/member/${this.type}/${this.slug}/assign-admin/${this.member.username}`)
                    .then((response) => {
                        this.admin = true;
                        let flash_data = {
                            'message': response.data.message,
                            'level': response.data.status
                        }
                        flash(flash_data);
                        this.$parent.$emit('updateAdmins', {'status': 'add', 'id': this.member.id});
                        this.submitting = false;
                    })
                    .catch(() => {
                        let flash_data = {
                            'message': 'An Error Occurred',
                            'level': 'danger'
                        }
                        flash(flash_data);
                        this.submitting = false;
                    });
            },
            removeAdmin(){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .delete(`/member/${this.type}/${this.slug}/remove-admin/${this.member.username}`)
                    .then((response) => {
                        this.admin = false;
                        this.submitting = false;
                        this.$parent.$emit('updateAdmins', {'status': 'remove', 'id': this.member.id});
                    })
                    .catch(() => {
                        let flash_data = {
                            'message': 'An Error Occurred',
                            'level': 'danger'
                        }
                        flash(flash_data);
                        this.submitting = false;
                    });
            }
        },
    }
</script>

<style scoped>

</style>
