<template>
    <div class="card card-default chat-box" style="min-height: 600px;">
        <div class="card-header pt-1 pb-1 pl-0 pr-0 border-bottom ml-1 mr-1 align-items-center">
            <strong :class="{'text-danger':session.block}">
                <div class="d-flex align-items-center">
                    <div class="">
                        <img class="round"
                             :src="friend.avatar !== null ? friend.avatar.url :  '/images/avatar.png'"
                             alt="avatar"
                             height="30"
                             width="30"/></div>
                    <div><span class="h4 pt-05 ml-05">   <a
                        :href="'/member/profiles/'+friend.username" class="text-dark">{{ friend.first_name }} {{ friend.last_name }}</a> <span
                        v-if="isTyping">{{ __('chat.is_typing') }} . . .</span></span>
                        <span v-if="session.block">({{ __('chat.blocked') }})</span>
                    </div>
                </div>
            </strong>

            <!-- options -->

            <div class="dropdown float-right">
                <!-- Close button -->


<!--                <a href="#" v-if="session.block && can" @click.prevent="unblock">-->
<!--                    <button class="btn-sm btn-outline-danger">{{ __('chat.unblock') }}</button>-->
<!--                </a>-->
<!--                <a href="#" @click.prevent="block" v-if="!session.block">-->
<!--                    <button class="btn-sm btn-outline-danger">{{ __('chat.block') }}</button>-->
<!--                </a>-->

<!--                <a href="#" @click.prevent="clear">-->
<!--                    <button class="btn-sm btn-outline-light"> {{ __('chat.clear_chat') }}</button>-->
<!--                </a>-->
                <a href="/member/messages">
                    <button class="btn-sm btn-outline-primary">{{ __('chat.back_to_messages') }}</button>
                </a>

            </div>
        </div>
        <div class="card-body" v-chat-scroll>

            <p class="card-text single-message"
               v-html="nl2br(chat.message) + dateSpan(chat)"
               :class="{'text-message-right':chat.type == 0, 'text-success':chat.read_at != null}" v-for="chat in chats"
               :key="chat.id">
            </p>


        </div>
        <form class="card-footer p-2" @submit.prevent="send">
            <div class="form-group" style="position:relative;">
                <div class="d-flex">
                    <textarea @input="resize()" ref="textarea" rows="1" class="input-message form-control" placeholder="Write your message here"
                           :disabled="session.block" v-model="message" autofocus></textarea>
                    <button class="btn btn-primary btn-send-message"><i class="feather icon-send"></i> {{ __('chat.send') }}</button>
                </div>

                <emoji-picker @emoji="append" :search="search">
                    <div
                        class="emoji-invoker"
                        slot="emoji-invoker"
                        slot-scope="{ events: { click: clickEvent } }"
                        @click.stop="clickEvent"
                    >
                        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                        </svg>
                    </div>
                    <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                        <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                            <div class="emoji-picker__search">
                                <input type="text" v-model="search" autofocus>
                            </div>
                            <div>
                                <div v-for="(emojiGroup, category) in emojis" :key="category">
                                    <h5>{{ category }}</h5>
                                    <div class="emojis">
                <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    @click="insert(emoji)"
                    :title="emojiName"
                >{{ emoji }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </emoji-picker>
                <vue-dropzone
                    @vdropzone-success="uploadSuccess"
                    v-if="showImageUpload" ref="chatImageDropzone" id="dropzone"
                    :options="dropzoneOptions"></vue-dropzone>
                <vue-dropzone
                    @vdropzone-success="uploadSuccessFile"
                    v-if="showFileUpload" ref="chatFileDropzone" id="dropzoneFile"
                    :options="dropzoneFileOptions"></vue-dropzone>

                <div id="show_options_chat">
                    <div class="d-flex" id="post_options_layer_chat">
                        <div class="pl-0">
                            <a @click="showImageUpload = !showImageUpload" id="upload_images" data-toggle="tooltip"
                               data-placement="top" data-original-title="Upload Image">
                                <i class="far fa-image" style="font-size: 1.5em; margin-bottom: 10px;"></i>
                            </a>

                            <a class="file-icon" @click="showFileUpload = !showFileUpload"
                               id="upload_file" data-toggle="tooltip"
                               data-placement="top" data-title="Attach File">
                                <i class="fas fa-paperclip" style="font-size: 1.5em; margin-bottom: 10px;"></i>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </form>

    </div>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    props: ['friend'],
    components: {
        EmojiPicker,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            dropzoneOptions: {
                url: `/member/session/${this.friend.session.id}/media`,
                maxFiles: 1,
                acceptedFiles: '.' + window.App.chat.media.images.acceptedTypes.join(",."),
                maxFilesize: window.App.chat.media.images.maxSize * 1048576,
                addRemoveLinks: true,
                dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> "+'Upload image',
                headers: {
                    'X-CSRF-TOKEN': window.App.csrfToken
                },
                init: function() {
                    this.on('error', function(file, response) {
                        let existing = file.previewElement.querySelectorAll('.dz-error-message span')[0].textContent;
                        let error = response && response.errors && response.errors.file ? (response.errors.file[0] ? response.errors.file[0] : 'File is not supported') : existing; // TODO: translate
                        file.previewElement.querySelectorAll('.dz-error-message span')[0].textContent = error;
                    });
                },
            },
            dropzoneFileOptions: {
                url: `/member/session/${this.friend.session.id}/media`,
                maxFiles: 1,
                acceptedFiles: '.' + window.App.chat.media.files.acceptedTypes.join(",."),
                maxFilesize: window.App.chat.media.files.maxSize * 1048576,
                timeout: 36000000,
                addRemoveLinks: true,
                dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> "+'Upload file',
                headers: {
                    'X-CSRF-TOKEN': window.App.csrfToken
                },
                init: function() {
                    this.on('error', function(file, response) {
                        let existing = file.previewElement.querySelectorAll('.dz-error-message span')[0].textContent;
                        let error = response && response.errors && response.errors.file ? (response.errors.file[0] ? response.errors.file[0] : 'File is not supported') : existing; // TODO: translate
                        file.previewElement.querySelectorAll('.dz-error-message span')[0].textContent = error;
                    });
                },
            },
            showFileUpload: false,
            showImageUpload: false,
            chats: [],
            message: null,
            open: true,
            isTyping: false,
            attachment: null,
            search: '',
            translations: {},
            just_now_message: 'Just now',
            upload_image_message: 'Upload image',
            upload_file_message: 'Upload file',
        }
    },
    computed: {
        session() {
            return this.friend.session;
        },
        can() {
            return this.session.blocked_by == auth.id;
        }
    },
    watch: {
        message(value) {
            if (value) {
                Echo.private(`chat.${this.friend.session.id}`)
                    .whisper('typing', {
                        name: auth.first_name
                    })
            }
        }
    },
    methods: {
        hasImage(content) {
            return content.match(`/<img/`);
        },
        uploadSuccess(file, response) {
            this.attachment = response.name;
            this.sendImage();
        },
        uploadSuccessFile(file, response) {
            this.attachment = response.name;
            this.sendFile();
        },
        append(emoji) {
            if (this.message !== null) {
                this.message += emoji;
            } else {
                this.message = emoji;
            }

        },
        send() {
            if (this.message) {
                this.pushToChats(this.message);
                axios.post(`/member/send/${this.friend.session.id}`, {
                    content: this.message,
                    chat_image: null,
                    to_user: this.friend.id
                }).then(res => this.chats[this.chats.length - 1].id = res.data.id);
                this.message = null;
            }
        },
        sendImage() {
            let chat = this;
            if (this.attachment) {
                axios.post(`/member/send/${this.friend.session.id}`, {
                    content: 'image',
                    chat_image: this.attachment,
                    to_user: this.friend.id
                }).then((res) => {
                    chat.pushToChats(res.data.message);
                    if (chat.chats.length > 0) {
                        chat.chats[chat.chats.length - 1].id = res.data.id;
                    }
                });
                this.attachment = null;
                this.$refs.chatImageDropzone.removeAllFiles();
                this.showImageUpload = false;

            }
        },
        sendFile() {
            let chat = this;
            if (this.attachment) {
                axios.post(`/member/send/${this.friend.session.id}`, {
                    content: 'file',
                    chat_file: this.attachment,
                    to_user: this.friend.id
                }).then((res) => {
                    //.match(/<img/)
                    chat.pushToChats(res.data.message);
                    if (chat.chats.length > 0) {
                        chat.chats[chat.chats.length - 1].id = res.data.id;
                    }
                });
                this.attachment = null;
                this.$refs.chatFileDropzone.removeAllFiles();
                this.showFileUpload = false;

            }
        },
        pushToChats(message) {
            this.chats.push({message: message, type: 0, read_at: null, sent_at: this.just_now_message});
        },
        close() {
            this.$emit('close');
        },
        clear() {
            axios
                .post(`/member/session/${this.friend.session.id}/clear`)
                .then(res => (this.chats = []));
        },
        block() {
            this.session.block = true;
            axios
                .post(`/member/session/${this.friend.session.id}/block`)
                .then(res => this.session.blocked_by = auth.id)
        },
        unblock() {
            this.session.block = false
            axios
                .post(`/member/session/${this.friend.session.id}/unblock`)
                .then(res => this.session.blocked_by = null)
        },
        getAllMessages() {
            axios
                .post(`/member/session/${this.friend.session.id}/chats`)
                .then(res => this.chats = res.data.data);
        },
        read() {
            axios.post(`/member/session/${this.friend.session.id}/read`);
        },
        resize() {
            let element = this.$refs["textarea"];

            element.style.height = "18px";
            element.style.height = element.scrollHeight + "px";
        },
        nl2br(str, replaceMode, isXhtml) {
            var breakTag = (isXhtml) ? '<br />' : '<br>';
            var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
        },
        dateSpan(chat) {
            let style = 'margin-left: 0.5rem; font-size: 8px;';
            let title = chat.send_at_datetime;
            let content = (chat.type ? 'received ' : 'sent ') + (chat.send_at ? chat.send_at : 'now'); // TODO: translation is needed
            if (chat.read_at) {
                title = chat.read_at_datetime;
                content = 'read ' + chat.read_at; // TODO: translation is needed
            }
            return `<span title="${title}" style="${style}">${content}</span>`;
        }
    },
    created() {
        this.read();

        this.getAllMessages();

        Echo.private(`chat.${this.friend.session.id}`).listen("PrivateChatEvent", e => {
            this.friend.session.open ? this.read() : "";
            this.chats.push({message: e.message, type: 1, sent_at: "Just Now"});
        });

        Echo.private(`chat.${this.friend.session.id}`).listen("MessageReadEvent", e =>
            this.chats.forEach(
                chat => (chat.id == e.chat.id ? (chat.read_at = e.chat.read_at) : ""))
        );

        Echo.private(`chat.${this.friend.session.id}`).listen(
            "BlockEvent",
            e => (this.session.block = e.blocked)
        );

        Echo.private(`chat.${this.friend.session.id}`).listenForWhisper(
            "typing",
            e => {
                this.isTyping = true
                setTimeout(() => {
                    this.isTyping = false
                }, 2000)
            }
        );
    }
}
</script>

<style scoped>
#show_options_chat {
    position: absolute;
    right: 8.5rem;
    top: 0.7rem;
}

#show_options_chat .fas, #show_options_chat .far {
    color: #b1c6d0;
}

.btn-send-message {
    border-radius: 0 0.42rem 0.42rem 0;
}

.input-message {
    padding-right: 6.5rem;
    border-radius: 0.42rem 0 0 0.42rem;
}

.chat-box {
    height: 400px;
}

.card-body {
    overflow-y: scroll
}

/* Emoji */
.emoji-invoker {
    position: absolute;
    top: 0.5rem;
    right: 6.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
}

.emoji-invoker:hover {
    transform: scale(1.1);
}

.emoji-invoker > svg {
    fill: #b1c6d0;
}

.emoji-picker {
    z-index: 1;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 100%;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
}

.emoji-picker__search {
    display: flex;
}

.emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
}

.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
}

.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}

.emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
}

.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}
</style>
