<template>
    <div>
        <div v-for="item in items" >
          <div class="card" style="width: 100%">
            <div class="row">
              <div class="col-12 col-md-9 border-right">
                <div class="mt-05 ml-1">
                  <img  class="rounded m-05" :src="event_info.event_image == null ? '/images/cover.png' : event_info.event_image.url" height="50" width="50">
                </div>
                <div>
                  <h2 class="ml-1"  v-text="item.title"></h2><div v-if="item.sold_out === 1" class="badge badge-pill badge-glow badge-danger ml-1 mb-1" style="font-size: 1rem;">{{ __('event.sold_out')}}</div>
                </div>
                <div>
                  <p class="ml-1"  v-html="item.description.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>
                </div>
              </div>
              <div class="col-12 col-md-3 pt-05 text-center" v-if="item.plan_price !== null || item.price !== null || item.quantity_available === 0">
                <span class="font-large-1" v-if="price_setting === 'plan-price'">{{ item.plan_price | currency(site_tax)}}</span>
                  <span class="font-large-1" v-if="price_setting === 'one-price'">{{ item.price | currency(site_tax)}}</span>
                <span class="font-small-1 text-muted d-block" style="margin-top: -5px;">inc {{ site_tax.name}}</span>
                <span class="font-small-1 text-muted d-block" style="margin-top: -5px;">{{item.purchase_count}} sold</span>
                <div v-if="allowPurchase" class=" mt-05">
                  <span class="font-small-2 text-center">Quantity:</span>
                  <div class="d-flex mb-05 mt-05">
                    <div class="m-auto" v-if="item.sold_out === 0">
                      <input style="width: 75px" :id="'qty-'+item.id" @change="checkQty(item.id, item.qty, $event)" type="number" :name="'qty['+item.id+']'" class="touchspin form-control" :value="items.length === 1 ? 1 : '' ">

                      <div :id="'error-'+item.id" class="ml-auto text-danger help-block font-small-1" role="alert"></div>
                    </div>
                  </div>
                </div>

              </div>
                <div class="col-12 col-md-3" v-else>
                    <div class="d-flex h-100">
                        <div class="justify-content-center align-self-center">
                            Not available for purchase
                        </div>
                    </div>
                </div>
            </div>
          </div>

        </div>
    </div>

</template>

<script>
    import NumberInputSpinner from "vue-number-input-spinner";

    export default {
        name: "Event",
        props: ['data', 'event', 'allowPurchase', 'guest'],
        components: {
            NumberInputSpinner,
        },
        data() {
            return {
                items: this.data,
                event_info: this.event,
                site_tax: window.App.site_tax,
                price_setting: window.App.price_setting,
                guest_user: this.guest
            }
        },
        methods: {
            checkQty(ticket_id, qty, $event){
               let input = $event.target.value;

               if (input <= 0){
                   $('#error-'+ticket_id).html('<i class="feather icon-x-circle"></i>'+this.__('event.form.validation.qty_gt_0'));
                   $('#continue_purchase').attr('disabled', true);
                   $('#qty-'+ticket_id).val('');
                   return;
               }

               axios.post(this.checkQtyEndpoint(), {
                   ticket_id: ticket_id,
                   ticket_qty: qty,
                   input: input
               })
                .then(response => {
                    if (response.data.exceeds === true){
                        $('#qty-'+ticket_id).val('');
                        $('#error-'+ticket_id).html('<small style="color: red">'+this.__('event.form.validation.form.validation.qty_exceeds')+'</small>');
                        $('#continue_purchase').attr('disabled', true);
                    }
                    else {
                        $('#error-'+ticket_id).html('');
                        $('#continue_purchase').attr('disabled', false);
                    }
                });
            },
            checkQtyEndpoint(){
                if (this.guest_user === true){
                    return '/ticket/purchased-qty';
                }
                else {
                    return '/member/ticket/purchased-qty';
                }
            }
        },

    }
</script>
