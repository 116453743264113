<template>
    <div>
        <div class="card">
            <div class="">
                    <img class=" img-fluid" :src="video.cover === null ? '/images/cover.jpg' : video.cover.url">
            </div>
            <div class="card-content">

                <div class="card-body pb-05">
                    <h1 class="font-large-2" v-text="video.title"></h1>
                    <p v-if="video.privacy === 1" style="color:#d0198b;" class="text-bold-600 mb-05"><i
                        class="feather icon-award"></i> PREMIUM ONLY</p>

                    <div class="pt-1 pb-1 border-bottom border-top mt-1">
                        <span class="breakAll image-resize" v-html="video.description"></span>
                    </div>

                    <div class="col-12">
                        <div class="pt-1">

                                    <a href="#" data-toggle="modal" data-target="#registerModal"
                                       class="download-button btn btn-primary waves-effect waves-light form-control"
                                       style="border:none;"><i
                                        class="feather icon-eye"></i> {{ __('video.watch_now') }} </a>

                            <div id="progress" class="p-2" style="display: none"></div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between border-top pl-05 pr-05">
                <div class="p-05">
                    <i class="fa fa-thumbs-o-up" data-toggle="modal" data-target="#registerModal"></i>
                     {{ video.likes === 0 ? '' : video.likes }}
                </div>
                <div class="p-05">
                    <span data-toggle="modal" data-target="#registerModal" class="comment-button">
                        <i class="fa fa-comment-o"></i>
                        {{ video.comments === 0 ? '' : video.comments }}
                        {{ video.comments === 1 ? __('globals.comment') : __('globals.comments') }}
                    </span>
                </div>
                <div>
                    <guest-social-share :social="video.social"></guest-social-share>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import moment from "moment-timezone";
    import linkify from 'vue-linkify'

    Vue.directive('linkified', linkify)

    export default {
        props: ['video'],
        data() {
            return {}
        },
        computed: {
        },
    }
</script>
