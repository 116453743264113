<template>
    <div :id="'child-comment-'+newsfeed_id" style="display:none;">
        <div v-for="(comment, index) in items"  :key="comment.id">
            <child-comment :data="comment" :administrator="administrator" @deleted="remove(index)"></child-comment>
        </div>
        <new-comment :type="this.type" :slug="slug" :members="members"  @created="add" :parent="false"></new-comment>
    </div>
</template>

<script>
    import ChildComment from './ChildComment';
    import NewComment from './NewComment';

    export default {
        props:['data', 'newsfeed_id', 'module', 'slug', 'members', 'commentable', 'administrator'],
        components: { ChildComment, NewComment },
        name: 'ChildComments',
        data() {
            return {
                items: this.data,
                type: 'comment',
                are_you_sure_message: this.__('globals.are_you_sure'),
                user: window.App.user
            }
        },
        methods : {
            add(comment) {
                this.items.push(comment);
            },
            remove(index) {
                if (confirm(this.are_you_sure_message)){
                    this.items.splice(index, 1);
                }
            }
        }
    }
</script>

<style scoped>

</style>
