<template>
    <div class="global-languages">
        <a
            @click="addNewLang()"
            class="btn btn-success waves-effect waves-light text-white"><i class="feather icon-plus"></i>
            Add New
        </a>
        <div v-if="loading">Loading..</div>
        <div v-for="language in filteredLanguages" class="global-language pt-05 d-flex">
            <input style="width:300px;" type="text" class="mr-05 form-control" v-model="language[0]">
            <input type="text" class="form-control" v-model="language[1]">
            <div style="width:260px;" class="pl-05 action-btns align-self-center">
                <a @click="saveLanguage(language)" class="btn-sm btn-success text-white">Save</a>
                <a @click="removeLanguage(language)" class="btn-sm btn-danger text-white">Delete</a>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'GlobalLanguages',
        props: ['searchKeyword'],
        data() {
            return {
                loading: false,
                languages: [],
            }
        },
        mounted() {
            this.fetchGlobalLanguages();
        },
        computed: {
            filteredLanguages() {
                if (this.searchKeyword) {
                    return this.languages.filter((language) => {
                        console.log(language[0]);
                        return language[0].includes(this.searchKeyword) || language[1].includes(this.searchKeyword);
                    })
                } else {
                    return this.languages;
                }
            }
        },
        methods: {
            removeLanguage(language) {
                if(confirm('Are you sure, you want to delete?')) {
                    // remove from database
                    axios.delete('/admin/language-globals/' + language[0])
                        .then(response => {
                        if (response.data) {
                            //alert(response.data.message);
                        }
                    });
                    this.fetchGlobalLanguages();
                }
            },
            saveLanguage(language) {
                axios.post('/admin/language-globals', {
                    key : language[0],
                    value: language[1]
                }).then(response => {
                        if (response.data) {
                            alert(response.data.message);
                        }
                    });
            },
            addNewLang() {
              this.languages.unshift(['key','value']);
            },
            fetchGlobalLanguages() {
                this.loading = true;
                axios.get('/admin/language-globals')
                    .then(response => {
                        if (response.data) {
                            this.loading = false;
                            this.languages = Object.entries(response.data);
                        }
                    });
            }
        }
    }
</script>
