<template>
    <div class="p-05 col-xl-3 col-md-3 col-sm-6">
        <div class="card pb-5">
            <div class="cover-img-lg  shadow">
                <a :href="'/product/'+product.slug">
                    <img class="card-img-top cover-img-lg img-fluid" :src="product.product_image.length > 0 && product.product_image[0] !== null ? product.product_image[0].url :  '/images/cover.jpg'" style="object-fit: cover;" alt="Cover image">
                </a>
            </div>
            <div class="card-content p-1 pt-05">
                <a :href="'/product/'+product.slug">
                    <h6 class="text-bold-700">{{ product.title}}</h6>
                </a>
                <div class="mt-05 mb-05" v-if="product.average_rating !== null">
                    <span :class="'rating-static rating-'+product.average_rating * 10">  </span>
                </div>
                <div class="card-content product-description font-small-4 mt-1" v-if="service_settings.description_display" v-html="description"></div>
                <div class="card-bottom d-flex align-items-center">
                    <div v-if="product.disabled === 1">
                        <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2 ">
                            <i class="feather icon-alert-circle"></i> {{ __('product.product_inactive') }}
                        </span>
                    </div>
                    <button class="btn-sm btn-flat-light ml-auto" style="border:none;"><a :href="'/product/'+product.slug">{{ __('globals.view') }} <i class="feather icon-chevron-right"></i></a></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GuestProductCard",
    props: ['product', 'service_settings'],
    data() {
        return {
            site_tax: window.App.site_tax,
        }
    },
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.product.product_description.split(' ').length > 50 ? this.product.product_description.split(' ', 50).join(' ') + '...' : this.product.product_description) : '';
        }
    }
}
</script>
