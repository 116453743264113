<template>
    <div>
        <div>
            <div class="ml-1 mr-1 pt-1 pb-1 mb-0">
                <div class="breakAll font-medium-3 text-dark text-bold-700" v-html="this.$options.filters.nl2br(this.data.title, true)"></div>
                <div class="breakAll pt-05 pb-05" v-linkified v-html="this.$options.filters.nl2br(this.data.body, true)"></div>
            </div>

            <div class="text-right p-05 border-top">
                <a :href="'/question/'+data.slug" class="ml-auto text-bold-700 font-small-3">{{ __('question.view_this_question') }} <i class="feather icon-chevron-right"></i> </a>
            </div>
        </div>
    </div>
</template>

<script>
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)

export default {
    name: "GuestNewsfeedQuestion",
    props: ['data'],
}
</script>

