<template>
    <div>
        <div class="pb-2">
            <label for="service">{{ __('globals.filter_activity_by_service') }}</label>
            <select :id='"service"+this._uid' class="form-control" v-model="service">
                <option value="">{{ __('globals.search_service_filter') }}</option>
                <option value="App\Models\Event">{{ __('globals.event') }}</option>
                <option value="App\Models\Group">{{ __('globals.group') }}</option>
                <option value="App\Models\Job">{{ __('globals.job') }}</option>
                <option value="App\Models\Product">{{ __('globals.product') }}</option>
                <option value="App\Models\Resource">{{ __('globals.resource') }}</option>
                <option value="App\Models\Supplier">{{ __('globals.supplier') }}</option>
                <option value="App\Models\Ticket">{{ __('globals.ticket') }}</option>
                <option value="App\Models\Video">{{ __('globals.video') }}</option>
            </select>
        </div>

        <div v-if="service !== ''">
            <ul class="list-unstyled" >

                <li class="list-group-item" v-for="(activity, index) in activities" :key="index">
                    <div class="d-flex">
                        <div>
                            <a :href="activity.url" target="_blank">{{ capitalizeTheFirstLetterOfEachWord(activity.type) }} - {{ activity.subject !== null ? activity.subject.title : '' }}</a> on {{ activityDate(activity.created_at)}}
                        </div>
                        <div class="ml-auto" v-if="activity.subject === null">
                            <span class="badge badge-pill badge-danger font-size-xsmall">{{ __('globals.deleted') }}</span>
                        </div>
                    </div>

                </li>
            </ul>

            <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"> {{ __('globals.no_activities_found') }}</div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>

import InfiniteLoading from 'vue-infinite-loading';
import moment from "moment-timezone";
import TomSelect from 'tom-select';

export default {
    components: {InfiniteLoading},
    name: "ActivityInfiniteScroll",
    props: ['activity', 'user'],
    data() {
        return {
            activities: [],
            page: 1,
            infiniteId: +new Date(),
            service: '',
        }
    },
    watch: {
        service: function (val) {
            this.activities = [];
            this.page = 1;
            this.infiniteId += 1;
        }
    },
    mounted() {
       new TomSelect('#service'+this._uid, {});
    },
    methods: {
        infiniteHandler($state) {
            let vm = this;
            axios
                .get('/admin/activities/' + this.user + '?page=' + this.page+'&service='+this.service)
                .then(response => {
                    let entries = Object.values(response.data.data);
                    if (entries.length > 0) {
                        $.each(entries, function (key, value) {
                            vm.activities.push(value);
                        });
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        activityDate(date){
            return moment(date).format("MMMM Do YYYY, h:mm A")
        },
        capitalizeTheFirstLetterOfEachWord(words) {
            var separateWord = words.toLowerCase().split('_');
            for (var i = 0; i < separateWord.length; i++) {
                separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                    separateWord[i].substring(1);
            }
            return separateWord.join(' ');
        },
    }
}
</script>

<style scoped>

</style>
