export default {
    name: "GuestTickets",
    data() {
        return {
            price: null,
            sold_out: null,
            price_setting: window.App.price_setting,
            site_tax: window.App.site_tax,
        }
    },
    methods: {
        ticketsPrice(tickets) {
            if (tickets.length > 0) {
                this.sold_out = true;
            }

            if (this.price_setting === 'one-price' && tickets.length > 0) {
                tickets.forEach(ticket => {
                    if (ticket.sold_out !== 1) {
                        if (ticket.ticket_one_price !== null) {
                            if (this.price === null) {
                                this.price = ticket.ticket_one_price;
                            } else if (ticket.ticket_one_price < this.price) {
                                this.price = ticket.ticket_one_price;
                            }
                        }
                        this.sold_out = false;
                    }
                })
            }

            if (this.price_setting === 'plan-price' && tickets.length > 0) {
                tickets.forEach(ticket => {
                    if (ticket.sold_out !== 1) {
                        ticket.ticket_plan_prices.forEach(plan_price => {
                            // This is used to set the price of the available ticket based on lowest plan price if the user is not on a plan where price is available
                            if (this.price === null && plan_price.price !== null && plan_price.plan_type === 'free') {
                                this.price = plan_price.price;
                            } else if (plan_price.price < this.price && plan_price.price !== null && plan_price.plan_type === 'free') {
                                this.price = plan_price.price;
                            }
                        })
                        this.sold_out = false;
                    }
                })
            }
        }
    }
}
