<template>
    <div class="row col-12">
        <div class="col-8">
            <div class="d-flex align-items-center">
                <div class="">
                    <a :href="'question/'+question.slug" class="font-medium-3 text-dark">{{question.title}}</a>
                    <div class="font-small-1 text-muted">
                        <div class="avatar avatar-sm">
                            <img :src="question.owner.avatar === null ? '/images/avatar.png' : question.owner.avatar.url" class="img holder">
                        </div> {{ question.owner.first_name }} {{ question.owner.last_name }} {{ __('globals.asked') }} <span v-text="ago(question.published_at)"></span>
                    </div>
                </div>

                <div class="ml-auto">
                    <span class="question-tag" v-for="tag in question.tags" :key="tag.id">
                        {{ tag.name }}
                    </span>
                </div>
            </div>
        </div>

        <div class="col-4">
            <div class="row">
                <div class="col-4 p-2">
                    <div class="text-center" style="font-size: 17px; font-weight: 300; margin-bottom: 4px">
                        <span>{{ question.votesCount }}</span>
                    </div>
                    <div class="text-center">
                        {{ question.votesCount === 1 ? __('globals.vote') : __('globals.votes') }}
                    </div>
                </div>

                <div class="col-4 p-2">
                    <div class="text-center" style="font-size: 17px; font-weight: 300; margin-bottom: 4px">
                        <span :title="question.answersCount+' answer'" v-text="question.answersCount"></span>
                    </div>
                    <div class="text-center">
                        {{ question.answersCount === 1 ? __('globals.answer') : __('globals.answers') }}
                    </div>
                </div>
                <div class="col-4 p-2">
                    <div class="text-center" style="font-size: 17px; font-weight: 300; margin-bottom: 4px">
                        <span>{{ question.views }}</span>
                    </div>
                    <div class="text-center">
                        {{ question.views === 1 ? __('globals.view') : __('globals.views') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment-timezone";

export default {
    name: "GuestQuestionCard",
    props: ['question'],
    data() {
        return {
            site_tax: window.App.site_tax,
        }
    },
    methods: {
        ago(date) {
            return moment(date).fromNow();
        },
    },
    beforeMount() {
    }
}
</script>

<style>
</style>
