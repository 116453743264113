<template>
    <div>
        <div class="pb-2 font-weight-bold text-uppercase">
            {{ __('plan.form.paragraph.included') }}
        </div>

        <div class="d-flex pb-1" v-for="(plan_feature, index) in plan_features" :key="index">

            <div class="pr-1" style="font-size: 1.3em">
                <i class="feather icon-check text-bold-700" style="color: green"></i>
            </div>

            <div>
                <button type="button" class="btn btn-link p-0 text-left" :id="id + '-' + index + '-popover-engagement'"
                        data-toggle="popover" data-trigger="focus" :data-html="true"
                        :data-content="plan_feature.description"
                >
                    {{ plan_feature.title }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['plan_features'],
        name: "PlanFeatures",
        data() {
          return  {
              id: null,
          }
        },
        mounted() {
            this.id = this._uid;
            this.$nextTick(() => {
                for (let i = 0; i < this.plan_features.length; i++) {
                  $('#'+ this.id + '-' + i + '-popover-engagement').popover();
                }
            });
        }
    }
</script>

<style scoped>
    button {
        font-size: 1em;
        white-space: normal;
        word-wrap: break-word;
        max-width: 100%;
    }
</style>
