<template>
    <div>
        <div class=" mt-0 mb-0">
            <div class="col-12 p-0">
                    <span>
                        <img class="img-fluid" :src="this.data.cover == null ? '/images/cover.png' : this.data.cover.url">
                    </span>
                    <div class="pt-1 pb-1 col-12">
                        <a :href="'/video/'+this.data.slug"><span class="h3" v-text="this.data.title"/></a>
                        <div>
                            <span v-if="this.data.can_view_video === false" class="badge badge-pill bg-gradient-success">
                                <i class="feather icon-award"></i> {{ __('globals.premium_only') }}</span>
                            <span class="badge badge-pill badge-light"><i class="fa fa-eye"></i> {{this.data.views}} Views</span>
                        </div>
                        <div>
                            <div class="breakAll pt-1" v-linkified>
                                <span class="breakAll image-resize" v-html="this.data.description"></span>
                            </div>
                            <div class="text-right">
                                <a :href="'/video/'+this.data.slug" class="font-small-3">{{ __('globals.read_more') }}<i class="feather icon-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>

import VideoPlayer from "../../VideoPlayer";

export default {
    name: "GuestNewsfeedVideo",
    props: ['data'],
    components: {VideoPlayer},
    data() {
        return {
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: typeof this.data.video_link !== undefined ? this.data.video_link : '',
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
}
</script>

<style>
.video_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
