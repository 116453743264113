<template>
    <div class="card p-1 pt-2 mb-0 h-100">
        <div v-if="data_updated">
            <apexchart width="100%" height="200" type="area" :options="options" :series="series"></apexchart>
        </div>
    </div>
</template>

<script>
   // import {Apexcharts} from "../../../vendors/js/charts/apexcharts";
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: "RegisterStepsStats",
        components: {VueApexCharts},
        props: ['data'],
        data() {
            return {
                search_from: null,
                search_to: null,
                data_updated: false,

                options: {
                    chart: {
                      id: 'registration-activity-chart',
                    },
                    xaxis: {
                        categories: [],
                      //  tickPlacement: 'between'
                    },
                    fill: {

                    }
                },
                series: [{
                    name: 'series-1',
                    data: []
                }]
            }
        },
        beforeMount() {
            this.options.xaxis.categories = Object.keys(this.data);
            this.series[0].data = Object.values(this.data);
            this.data_updated = true;
        },
    }
</script>

<style scoped>

</style>
