<template>
    <div>
        <div class="d-flex mb-2">
            <div class=" flex-fill member-search-area">
                <div class="d-flex search-text-area">
                    <select name="period" id="period" class="mr-05" v-model="period" @change="showByPeriod">
                        <option value="">{{ __('search.filter') }}</option>
                        <option value="1">{{ __('search.1_day') }}</option>
                        <option value="7">{{ __('search.7_day') }}</option>
                        <option value="14">{{ __('search.14_day') }}</option>
                        <option value="30">{{ __('search.30_day') }}</option>
                        <option value="60">{{ __('search.60_day') }}</option>
                        <option value="90">{{ __('search.90_day') }}</option>
                    </select>
                    <input type="text"
                           @input="isTyping = true"
                           v-model="keyword" class="form-control input-search"
                           placeholder="Search for another subscriber by their name, position or organisation...">
                    <button class="btn-member-search btn btn-primary">{{ __('globals.search') }}</button>
                </div>
                <div class="d-flex filter-letter-index">
                    <div class="filter-options" v-for="letter in filterOptions">
                        <a class="text-uppercase font-weight-bold"
                           @click="filterMemberResults(letter.key)"
                           :class="{ active: selectedFilter === letter.key }">
                            {{ letter.value }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row match-height">
            <div class="col-lg-6 col-md-6 col-sm-6" v-for="(member, index) in members" :key="index">
                <member-directory-card :member="member" :index="index"></member-directory-card>
            </div>
        </div>
        <infinite-loading spinner="spiral" :identifier="infiniteId"  @distance="1" @infinite="infiniteHandler">
            <div slot="no-more">{{ __('globals.no_more_members') }}</div>
            <div slot="no-results"></div>
        </infinite-loading>
        <div v-if="members.length === 0">{{ __('globals.no_members_found') }}
            <span v-if="searchForFirstLetter">{{ __('globals.starting_with') }} <strong>{{selectedFilter}}</strong></span>.
        </div>
    </div>

</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    import MemberDirectoryCard from './MemberDirectoryCard.vue';

    export default {
        name: "MemberSearch",
        components: {InfiniteLoading, MemberDirectoryCard},
        data() {
            return {
                members: [],
                page: 1,
                keyword: '',
                period: '',
                infiniteId: +new Date(),
                isTyping: false,
                isLoading: false,
                selectedFilter: 'all',
                searchForFirstLetter: false,
            }
        },
        computed: {
            filterOptions() {
                let filterOptions = [{key: "all", value: "ALL"}];
                for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
                    let $char = String.fromCharCode([i]);
                    filterOptions.push({key: $char, value: $char});
                }
                return filterOptions;
            },
        },
        watch: {
            keyword: _.debounce(function () {
                this.isTyping = false;
                this.page = 1,
                    this.members = [],
                    this.searchForFirstLetter = false;
                this.infiniteId += 1
            }, 1500),
            isTyping: function (value) {
                if (!value) {
                    this.infiniteHandler();
                }
            }
        },
        methods: {
            showByPeriod(){
                this.page = 1;
                this.members = [];
                this.infiniteId += 1;

                this.infiniteHandler();
            },
            filterMemberResults(filterBy) {
                this.page = 1;
                this.members = [];
                this.infiniteId += 1
                this.selectedFilter = filterBy;
                // if 'all' is selected then call the parent search
                if (filterBy === 'all') {
                    this.keyword = '';
                    this.searchForFirstLetter = false;
                } else {
                    // if letters are selection call a new funtion to fetch members by first letter.
                    this.searchForFirstLetter = true;
                    this.infiniteHandler();
                }
            },
            infiniteHandler($state) {
                this.loadUsers($state)
            },
            loadUsers($state) {
                let vm = this;
                let url = "/member/search/load?page=" + this.page;
                if (this.searchForFirstLetter) {
                    url = "/member/search-starts-with/load?page=" + this.page;
                }
                axios
                    .post(url, {keyword: this.searchForFirstLetter ? this.selectedFilter : this.keyword, period: this.period})
                    .then(response => {
                        if (response.data.data.length > 0) {
                            $.each(response.data.data, function (key, value) {
                                vm.members.push(value);
                            });
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    });
                vm.page++;
            },
        },

    }
</script>

<style scoped>
    .filter-letter-index {
        padding-top: 5px;
    }

    .filter-options {
        padding-right: 10px;
    }

    .filter-options a {
        color: #3399FE;
    }

    .filter-options a:hover, .filter-options a.active {
        color: #626262;
    }

    .input-search {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

    }

    .btn-member-search {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    select {
        background-color: #fff;
        color: #333;
        border-color: #ccc;
        outline: none;
    }

</style>
