<template>
    <div class="card p-05">
        <div class="card-container">
            <div class="d-inline-flex p-2">
                <div>
                    <div style="">
                        <h3 >{{ plan.title }}</h3>
                    </div>

                    <div v-if="plan.type === 'company'">
                        <p><i class="feather icon-users"></i> {{ plan.num_individuals === 0 ? "Unlimited" : plan.num_individuals }} Users</p>
                    </div>

                    <div class="font-weight-bolder font-size-medium">
                        <div v-if="plan.price === 0">
                            Free
                        </div>
                        <template v-else>
                            {{ plan.price | currency(site_tax)}} / {{ plan.interval }}
                        </template>
                    </div>

                    <div class="mt-05" v-html="plan.description"></div>
                </div>
            </div>
        </div>

        <div class="card-body" v-if="plan.plan_features.length > 0 && show_features">
            <plan-features :plan_features="plan.plan_features"></plan-features>
        </div>

        <div class="card-footer border-0 bg-white mt-auto">

            <template v-if="user_current_plan && user_current_plan.stripe_plan_id === plan.stripe_plan_id && !user_part_of_company">

                <template v-if="user_subscription !== null && user_subscription.ends_at !== null">
                    <p class="text-primary">{{ __('plan.form.paragraph.subscription_end') }} {{ formatDate(user_subscription.ends_at) }}</p>
                    <button class="btn btn-primary mt-1 btn-block mt-auto"  @click.prevent="resumeSubscription()">
                        {{ __('plan.form.links.resume') }}
                    </button>
                </template>

                <button class="btn btn-success mt-1 mb-0 btn-block" v-else-if="plan.price === 0">
                    {{ __('plan.form.paragraph.your_current_plan') }}
                </button>

                <button class="btn btn-danger mt-1 btn-block mt-auto" @click.prevent="cancelSubscription()" v-else>
                    {{ __('plan.form.buttons.cancel') }}
                </button>
            </template>

             <button v-else-if="company_owner" class="btn btn-primary mt-1 btn-block mt-auto"  @click.prevent="plan.type === 'company' ? upgradeCompany(plan.plan_link) : changeIndividual(plan.plan_link)">
                 {{ plan.price === 0 ? __('plan.form.links.continue_upgrade') : __('plan.form.links.purchase_this_upgrade') }}
             </button>

            <button v-else class="btn btn-primary mt-1 btn-block mt-auto" @click.prevent="user_part_of_company ? removeCompanyPlan(plan.plan_link) : upgradePlan(plan.plan_link)">
                {{ plan.price === 0 ? __('plan.form.links.continue_upgrade') : __('plan.form.links.purchase_this_upgrade') }}
            </button>

        </div>
    </div>
</template>

<script>

    import moment from "moment-timezone";
    import PlanFeatures from "./PlanFeatures.vue";

    export default {
        props: ['plan', 'user_subscription', 'user_current_plan', 'company_owner', 'show_features', 'user_part_of_company'],
        name: "PlanCard",
        components: {PlanFeatures},
        data() {
            return {
                site_tax: window.App.site_tax,
            }
        },
        mounted() {
        },
        beforeMount() {
        },
        methods: {
            formatDate(date) {
              return moment(date).format('ddd, MMM D, YYYY h:mm A');
            },
            upgradePlan(plan_link){
                if(window.confirm(this.__('plan.upgrade_subscription'))){
                    window.location.href = plan_link;
                }
            },
            upgradeCompany(plan_link){
                if(window.confirm(this.__('plan.form.validation.company_upgrade_added'))){
                    window.location.href = plan_link;
                }
            },
            changeIndividual(plan_link){
                if(window.confirm(this.__('plan.form.validation.company_upgrade_removed'))){
                    window.location.href = plan_link;
                }
            },
            removeCompanyPlan(plan_link){
                if(window.confirm(this.__('plan.cancel_company_member'))){
                    window.location.href = plan_link;
                }
            },
            resumeSubscription(){
                if(window.confirm(this.__('plan.resume_subscription'))){
                    window.location.href="/member/plans/resume";
                }
            },
            cancelSubscription(){
                if(window.confirm(this.__('plan.cancel_subscription'))){
                    window.location.href="/member/plans/cancel";
                }
            },
        }
    }

</script>

<style>

</style>
