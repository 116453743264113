<template>
    <div>
        <div class="card" v-if="this.shared_user">
            <div class="d-flex justify-content-start border-bottom">
                <shared-post-owner-info
                        :user_data="this.shared_user"
                        :supplier_data="[]"
                        :isServiceAdmin="isServiceAdmin"
                        :shared="true"
                        :type="this.data.type"
                        :user_id="user_id" />
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                        <span v-text="ago(this.data.created_at)" class="font-small-1 text-light "
                              style="margin-top: 3px;"></span>
                        <div class="dropdown">
                            <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    style="border:none">
                                <i class="fa fa-chevron-circle-down mr-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">

                                <span v-if="isServiceAdmin === true || user_id === data.profile_id || administrator === true">
                                    <a class="dropdown-item" href="#" @click.prevent="editPost"><i class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                                </span>

                                <span v-if="isServiceAdmin === true || user_id === data.profile_id || administrator === true">
                                    <a class="dropdown-item" href="#" @click.prevent="deleteNewsfeed"><i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                                </span>

                                <a class="dropdown-item danger" @click.prevent="report"><i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                            </div>
                        </div>
                </div>
            </div>

            <div class="pl-05 pr-05 mt-05">
                    <div v-if="editing" class="form-group pr-05 pl-05">
                        <div class="mb-05 mx-05">
                            <div class="card my-0">
                                <editor
                                    v-model="shared_text"
                                    api-key="no-api-key"
                                    :init='{
                                    skin: "trybz-member",
                                    skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                    content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                    external_plugins: {
                                        customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                        loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                    },
                                    valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                    plugins: "link quickbars autolink lists customtags loadingbar",
                                    link_context_toolbar: true,
                                    quickbars_selection_toolbar: "bold italic link bullist",
                                    quickbars_image_toolbar: false,
                                    quickbars_insert_toolbar: false,
                                    link_assume_external_targets: "https",
                                    link_target_list: false,
                                    link_title: false,
                                    toolbar: false,
                                    branding: false,
                                    elementpath: false,
                                    menubar: false,
                                    file_picker_types: "",
                                    height: 100,
                                    content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                    relative_urls : false,
                                    remove_script_host : false,
                                }'
                                />
                            </div>
                        </div>
                        <button class="btn btn-sm btn-primary" @click.prevent="updateSharedNewsfeed">{{ __('globals.update') }}</button>
                        <button class="btn btn-sm btn-link" @click.prevent="cancelEditing">{{ __('globals.cancel') }}</button>
                    </div>

                    <div class="pt-05" v-else-if="body !== null">
                        <div class="breakAll pr-05 pl-05" v-linkified v-html="this.$options.filters.nl2br(body, true)">
                        </div>
                    </div>

                    <div class="d-flex justify-content-center pr-05 pl-05 pt-05">
                        <div class="card border-light w-100">
                            <shared-post-owner-info
                                    :user_data="this.data.module.owner"
                                    :supplier_data="this.shared_supplier.length > 0 ? this.shared_supplier : []"
                                    :isServiceAdmin="isServiceAdmin"
                                    :shared="false"
                                    type="resource"
                                    :user_id="user_id"
                            />
                            <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                <span v-text="ago(this.data.module.created_at)" class="font-small-1 text-light " style="margin-top: 4px;"></span>
                            </div>

                            <div class="shadow border-top " v-if="data.module.cover !== null">

                                <img class="img-fluid"
                                     :src="data.module.cover.url" style="object-fit: cover;">

                            </div>
                            <div class="pt-1 mb-05 border-top">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <div class="w-100">
                                            <div v-if="data.module.cover === null">
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'doc'"><img
                                                    src="/images/icons/doc.png"
                                                    width="25px"></div>
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'docx'"><img
                                                    src="/images/icons/doc.png"
                                                    width="25px"></div>
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'txt'"><img
                                                    src="/images/icons/doc.png"
                                                    width="25px"></div>
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'pdf'"><img
                                                    src="/images/icons/pdf.png"
                                                    width="25px"></div>
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'xls'"><img
                                                    src="/images/icons/xls.png"
                                                    width="25px"></div>
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'xlsx'"><img
                                                    src="/images/icons/xls.png"
                                                    width="25px"></div>
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'ppt'"><img
                                                    src="/images/icons/ppt.png"
                                                    width="25px"></div>
                                                <div class="d-inline-block mr-05 mb-05 float-left"
                                                     v-if="data.module.resource_file_type === 'pptx'"><img
                                                    src="/images/icons/ppt.png"
                                                    width="25px"></div>
                                            </div>
                                            <a :href="'/member/' + this.data.module.type + '/'+data.module.slug">
                                                <span class="h3">{{ data.module.title }}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="breakAll pt-1" v-linkified style="max-height: 300px; overflow: hidden; text-overflow: ellipsis;">
                                            <span class="breakAll" v-html="data.module.resource_description"></span>
                                        </div>
                                        <div class="text-right">
                                            <a :href="'/member/'+data.module.type+'/'+data.module.slug" class="font-small-3">{{ __('globals.read_more') }}<i class="feather icon-chevron-right"></i></a>
                                        </div>
                                    </div>
                                    <div class="pb-1 pt-05 text-bold-700" v-if="data.module.canBeDownloaded === false">
                                        <span class="font-small-2 pt-1"> {{ this.price | currency(site_tax) }} {{ __('globals.all_inclusive') }}</span>
                                    </div>
                                    <div>
                                        <div class="" v-if="user.confirmed">
                                            <span class="border-bottom" v-if="data.module.canBeDownloaded">
                                                <div v-if="data.module.external_url !== null">
                                                    <a :href="data.module.external_url" target="_blank"
                                                       class="download-button btn btn-primary waves-effect waves-light form-control">
                                                            <i class="fa fa-link"></i> {{ __('resource.form.buttons.access_external_resource') }}
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <a :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/download-file'"
                                                       class="download-button btn btn-primary waves-effect waves-light form-control">
                                                            <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                                                    </a>
                                                </div>
                                             </span>
                                            <span class="border-bottom" v-else-if="data.module.resource_price > 0 && data.module.canBeDownloaded === false">
                                                <a :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/add_to_cart'"
                                                   class="download-button btn btn-primary waves-effect waves-light form-control"><i
                                                    class="fa fa-download"></i> {{ __('resource.form.buttons.buy') }}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="pl-1 pr-1 pt-05" v-if="this.shared_group.length > 0">
                                    <a :href="'/member/group/'+this.shared_group[0].slug">
                                        <div class="card text-white mb-0 border-0">
                                            <img class="card-img img-fluid" style="height: 3rem;"
                                                 :src="this.shared_group[0].group_cover == null ? '/images/cover.jpg' : this.shared_group[0].group_cover.url">
                                            <div
                                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ this.shared_group[0].title }}</p>
                                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                                    class="feather icon-chevron-right"></i></button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="pl-1 pr-1 pt-05" v-if="this.shared_supplier.length > 0">
                                    <a :href="'/member/supplier/'+this.shared_supplier[0].slug">
                                        <div class="card text-white mb-0 border-0">
                                            <img class="card-img img-fluid" style="height: 3rem;"
                                                 :src="this.shared_supplier[0].supplier_cover_image == null ? '/images/cover.jpg' : this.shared_supplier[0].supplier_cover_image.url">
                                            <div
                                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ this.shared_supplier[0].title }}</p>
                                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                                    class="feather icon-chevron-right"></i></button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="pl-1 pr-1 pb-05" v-if="data.groups !== undefined && data.groups.length > 0">
                <a :href="'/member/group/'+data.groups[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.groups[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pl-1 pr-1 pb-05" v-if="data.suppliers !== undefined && data.suppliers.length > 0">
                <a :href="'/member/supplier/'+data.suppliers[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.suppliers[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.module.id"
                  :module="data.module.type"
                  :slug="data.module.id"
                  :members="members"
        ></comments>
    </div>
</template>

<script>
import SharedFunctions from '../../../mixins/newsfeed/SharedFunctions'

export default {
    name: "SharedResource",
    mixins: [
        SharedFunctions
    ],
    data() {
        return {
            post_as_who: '',
            images: [],
            user: window.App.user,
            site_tax: window.App.site_tax,
            price: '',

        }
    },

    mounted() {
        this.getSharedPost()

        axios
            .get('/member/' + this.data.module.type + '/' + this.data.module.slug + '/get_price')
            .then(response => {
                this.price = response.data.price;
            });
    }
}
</script>

<style scoped>

</style>
