<template>
    <div>
        <div class="col-12 m-auto">
            <div class="card">
                <div class="shadow">

                    <img class="card-img-top img-fluid"
                         :src="zoom.media.length > 0 ? zoom.media[0].url : '/images/cover.jpg'"
                         style="object-fit: cover;">

                </div>
                <div class="card-content" v-if="zoom.description !== null">
                    <div class="card-body pt-05 pb-05">
                        <div class="d-flex">
                            <div>
                                <h1 class="font-large-1" v-text="zoom.title"></h1>
                                <h2 class="primary font-medium-3 pb-2"><span v-text="showDateTime"></span></h2>
                            </div>
                            <div class="ml-auto pt-1">
                                <div
                                    class=" badge badge-pill bg-gradient-primary"
                                    style=" padding: 0.2rem 0.5rem;">
                                    <i class="feather icon-video"></i> {{ __('webinar.title_singular')}}
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-12 col-md-8">
                                <h5 class="border-bottom pb-05 mb-1"><i class="feather icon-book-open"></i> {{ __('webinar.headers.admin.event_detail') }}
                                </h5>

                                <div class="d-flex justify-content-start">
                                    <p><span class="user-name text-bold-600"><i
                                        class="fa fa-clock"></i> {{ __('webinar.fields.text.timezone') }}: </span><br>{{ zoom.timezone }}
                                    </p>
                                </div>
                                <p><span class="user-name text-bold-600"><i
                                    class="fas fa-file-alt"></i> {{ __('webinar.fields.text.description') }}: </span><br>
                                    <span class="breakAll image-resize" v-html="zoom.description"></span>
                                </p>
                            </div>
                            <div class="col-12 col-md-4">
                                <h5 class="border-bottom pb-05 mb-1"><i class="feather icon-user-plus"></i>{{ __('webinar.headers.admin.event_organiser') }}
                                </h5>
                                <p><span class="user-name text-bold-600"><i
                                    class="fa fa-building"></i> {{ __('webinar.fields.text.organisation_name') }}: </span><br>{{ zoom.organisation_name }}</p>
                                <p><span class="user-name text-bold-600"><i
                                    class="fa fa-user"></i> {{ __('globals.name') }}: </span><br>{{ zoom.contact_name }}</p>
                                <p>
                            <span class="user-name text-bold-600"><i
                                class="fa fa-phone"></i>  {{ __('globals.phone') }}: </span><br>{{ zoom.contact_phone }}
                                </p>
                                <p>
                            <span class="user-name text-bold-600"><i
                                class="fa fa-envelope"></i> {{ __('globals.email') }}: </span><br>{{ zoom.contact_email }}
                                </p>
                            </div>
                        </div>


                        <div class="d-flex pr-05 align-items-center border-top pt-1 mt-05">

                            <a  data-toggle="modal" data-target="#registerModal"
                                class="download-button btn btn-primary waves-effect waves-light form-control text-white"><i
                                class="fa fa-calendar"></i> {{ __('webinar.form.buttons.register_now') }} </a>

                        </div>

                    </div>

                </div>
                <div class="d-flex justify-content-between border-top mb-2">
                    <div class="p-1">
                        <i class="fa fa-thumbs-o-up" data-toggle="modal" data-target="#registerModal"></i>
                    </div>
                    <div class="p-1">
                    <span data-toggle="modal" data-target="#registerModal">
                        <i class="fa fa-comment-o"></i> {{ __('globals.comment') }}
                    </span>
                    </div>
                    <div class="p-1">
                        <div class="dropdown">
                            <a class="dropdown-toggle" id="socialShareButton" data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false">
                                <i class="fa fa-share-alt"></i> {{ __('globals.share') }}
                            </a>
                            <div class="dropdown-menu" aria-labelledby="socialShareButton">
                                <span v-html="social"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import linkify from 'vue-linkify'
import PostOwnerInfo from '../post/PostOwnerInfo'
import moment from "moment-timezone";

Vue.directive('linkified', linkify)

export default {
    name: "Zoom",
    props: ['data', 'social'],
    components: {PostOwnerInfo},
    data() {
        return {
            zoom: this.data,
            price: '',
            plan_prices: '',
            is_registered: this.registered,
            site_tax: window.App.site_tax,
            price_setting: window.App.price_setting
        }
    },
    methods: {


    },
    computed: {
        postOwnerLink: function () {
            return (this.data.post_as === 'supplier')
                ? `/member/supplier/${this.data.news_feed.newsfeed_supplier[0].slug}`
                : '#'
        },
        postOwnerName: function () {
            return (this.data.post_as === 'supplier')
                ? this.data.news_feed.newsfeed_supplier[0].title
                : this.data.owner.first_name + ' ' + this.data.owner.last_name
        },
        canUpdate() {
            return this.authorize(user => this.data.user_id === user.id)
        },
        ago() {
            return this.data.published_at !== undefined ? moment(this.data.published_at).fromNow() : moment(this.data.created_at).fromNow();
        },
        showDateTime() {
            return moment(this.data.webinar_at).format('LLL');
        },
    },
    mounted() {
        axios
            .get('/member/zoom/' + this.data.zoom_id + '/get_price')
            .then(response => {
                this.price = response.data.price;
            });

        axios
            .get('/member/zoom/' + this.data.zoom_id + '/get_all_price_plans')
            .then(response => {
                this.plan_prices = response.data.plan_prices;
            });
    }
}
</script>
