<template>
    <div>
        <div class="list-group overflow-auto pr-1" style="max-height: 800px; overflow-y: auto;">
            <div class="card" v-for="(member, index) in members" :key="index">
                <div class="d-flex">
                    <div class="p-1">
                        <img class="round" :src="member.avatar !== null ? member.avatar.url : '/images/avatar.png'"
                             alt="avatar"
                             height="40"
                             width="40"/>
                    </div>

                    <div class="pl-0 pt-1 pr-1 pb-1">
                        <div>
                            <a :href="'/member/profiles/'+member.username" style="color:inherit;" class="text-bold-600">
                                {{ member.first_name }} {{ member.last_name }}
                            </a>

                            <img v-if="member.roles[0].title === 'Admin'"
                                 src="/images/badges/admin.png" alt="user role badge" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" title="Administrator" />

                            <img v-for="badge in member.badges"
                                :src="badge.image" alt="user custom badge" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="badge.name" />

                            <img v-if="member.user_badge_id !== null && member.user_badge_image != null"
                                :src="member.user_badge_image.url" alt="user plan badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="member.user_badge_name" />
                        </div>

                        <span class="font-small-3 text-muted">{{ member.organisation }} | {{ member.position }}</span>
                    </div>

                    <div class="ml-auto p-1 mobile-hide text-center d-flex align-items-center">
                        <button class="btn-sm btn-outline-light" @click.prevent="goProfile(member.username)">
                            {{ __('group.form.buttons.view_profile') }} <i class="feather icon-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            <infinite-loading @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
        </div>

        <div class="card bg-outline-gray" v-if="members.length === 0">
            <div class="card-header">
                <h4 class="card-title gray">{{ __(`${type}.no_members_header`) }}</h4>
                <a data-action="close" class="heading-elements"><i class="feather icon-x"></i></a>
            </div>
            <div class="card-body">
                <span class="gray">{{ __(`${type}.no_members.content`) }}</span>
                <div>
                    <a href="/member/help">
                        <button class="btn btn-outline-light mt-1">
                            {{ __(`${type}.form.buttons.help`) }}
                        </button>
                    </a>
                </div>
                <div class="ml-auto">
                    <img src="/images/annie.png" width=50px style="float:right; margin-top: -40px;" alt="image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InfiniteLoading from "vue-infinite-loading";

    export default {
        name: "GroupMembers",
        components: {InfiniteLoading},
        props: ['type', 'slug'],
        data() {
            return {
                endpoint: '',
                members: [],
                page: 1,
            }
        },
        methods: {
            infiniteHandler($state) {
                this.loadMembers($state);
            },
            loadMembers($state) {
                let vm = this;
                axios
                    .get(`${this.endpoint}?page=${this.page}`)
                    .then(response => {
                        if (response.data.data.length > 0) {
                            $.each(response.data.data, function (key, value) {
                                vm.members.push(value);
                            });
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    });
                vm.page++;
            },
            goProfile(username){
                window.location.href = "/member/profiles/"+username;
            },
        },
        beforeMount(){
            if(this.type === 'group'){
              this.endpoint = `/member/${this.type}/${this.slug}/get-members`;
            } else {
              this.endpoint = `/member/${this.type}/${this.slug}/get-followers`;
            }
        }
    }
</script>

<style scoped>
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #c7c5c5;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: grey;
    }
</style>
