<template>
    <div>
        <div class="shadow" v-if="this.data.cover !== null">
            <img class="img-fluid" :src="this.data.cover.url" style="object-fit: cover;">
        </div>
        <div class="card-content pt-1 pb-1">
            <div class="col-md-12">
                <div class="d-flex">
                    <div class="w-100">
                        <div v-if="this.data.cover === null">
                            <div class="d-inline-block mr-05 mb-05 float-left" v-if="this.data.resource_file_type === 'doc'">
                                <img src="/images/icons/doc.png" width="25px">
                            </div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="this.data.resource_file_type === 'docx'">
                                <img src="/images/icons/doc.png"
                                                                                           width="25px"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="this.data.resource_file_type === 'txt'"><img src="/images/icons/doc.png"
                                                                                          width="25px"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="this.data.resource_file_type === 'pdf'"><img src="/images/icons/pdf.png"
                                                                                          width="25px"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="this.data.resource_file_type === 'xls'"><img src="/images/icons/xls.png"
                                                                                          width="25px"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="this.data.resource_file_type === 'xlsx'"><img src="/images/icons/xls.png"
                                                                                           width="25px"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="this.data.resource_file_type === 'ppt'"><img src="/images/icons/ppt.png"
                                                                                          width="25px"></div>
                            <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="this.data.resource_file_type === 'pptx'"><img src="/images/icons/ppt.png"
                                                                                           width="25px"></div>
                        </div>
                            <span class="h3"><a :href="'/' + this.data.type + '/'+this.data.slug"
                                                style="color: inherit">{{ this.data.title }}</a></span>

                    </div>
                </div>
                <div class="mb-05">
                    <div class="breakAll pt-1" v-linkified>
                        <span class="breakAll image-resize" v-html="this.data.resource_description"></span>
                    </div>
                    <div class="text-right">
                        <a :href="'/'+this.data.type+'/'+this.data.slug" class="font-small-3">{{ __('globals.read_more') }}<i class="feather icon-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="pl-05 pr-05">
                <span data-toggle="modal" data-target="#registerModal">
                    <div v-if="this.data.external_url !== null">
                        <a class="download-button btn btn-primary waves-effect waves-light form-control">
                            <i class="fa fa-link"></i> {{ __('resource.form.buttons.access_external_resource') }}
                        </a>
                    </div>
                    <div v-else>
                        <a class="download-button btn btn-primary waves-effect waves-light form-control">
                            <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                        </a>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['data'],
    name: "GuestNewsfeedResource",
    data() {
        return {
            site_tax: window.App.site_tax,
        }
    },
    mounted() {
    },

}
</script>

<style scoped>

</style>
