<template>
    <div class="col-12 m-auto">
        <div class="card">
            <div class="d-flex justify-content-start">
                <guest-post-owner-info
                            :user_data="question.owner"
                            :supplier_data="null"
                            :shared="false"
                            :time="question.published_at">
                </guest-post-owner-info>
            </div>

            <div class="p-1">
                <div class="breakAll font-medium-4 text-dark text-bold-700" v-html="question.title"></div>
                <div class="breakAll pt-05 pb-05" v-linkified v-html="question.body"></div>
                <div>
                   <span v-for="tag in question.tags" class="question-tag">
                       {{ tag.name }}
                   </span>
                </div>

                <div v-if="question.group !== null || question.supplier !== null">
                    <guest-service-owner-info :group="question.group" :supplier="question.supplier"></guest-service-owner-info>
                </div>
            </div>

            <div class="d-flex justify-content-between border-top pl-05 pr-05">
                <div class="p-05">
                    <span data-toggle="modal" data-target="#registerModal">
                        {{ question.votesCount === 0 ? '' : question.votesCount }} {{ question.votesCount === 1 ? __('globals.vote') : __('globals.votes') }}
                    </span>
                </div>
                <div class="p-05">
                    <span data-toggle="modal" data-target="#registerModal">
                        <i class="fa fa-comment-o"></i>
                        {{ question.answersCount === 0 ? '' : question.answersCount }} {{ question.answersCount === 1 ? __('globals.answer') : __('globals.answers') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)

export default {
    name: "GuestNewsfeedQuestion",
    props: ['question'],
}
</script>

<style>
</style>
