<template>
    <div class="pt-05 pb-05">
        <div v-if="group !== null && this.section !== 'group'">
            <a :href="'/group/'+group.slug">
                <div class="card text-white mb-0 border-0">
                    <img class="card-img img-fluid" style="height: 3rem;" :src="group.group_cover == null ? '/images/cover.jpg' : group.group_cover.url">
                    <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                        <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                            {{ group.title }}</p>
                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                    </div>
                </div>
            </a>
        </div>
        <div v-if="supplier !== null && this.section !== 'supplier'">
            <a :href="'/supplier/'+supplier.slug">
                <div class="card text-white mb-0 border-0">
                    <img class="card-img img-fluid" style="height: 3rem;" :src="supplier.supplier_cover_image == null ? '/images/cover.jpg' : supplier.supplier_cover_image.url">
                    <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                        <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                            {{ supplier.title }}</p>
                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                    </div>
                </div>
            </a>
        </div>
    </div>

</template>

<script>
export default {
    name: "GuestServiceOwnerInfo",
    props: ['group', 'supplier'],
}
</script>
