<template>
    <div class="modal fade" ref="leaveGroupModal" id="leaveGroupModal" tabindex="-1" role="dialog" aria-labelledby="leaveQuestionTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{ __('globals.before_you_leave') }}</h5>
                    <button type="button" class="close" @click.prevent="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="text-left">{{ this.leave_question }}</p>
                    <div class="form-group">
                        <textarea class="form-control" cols="30" rows="5" v-model="user_response"></textarea>
                        <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.leave_response && this.errors.leave_response.length > 0">
                            <i class="feather icon-x-circle"></i>
                            <template v-for="error in this.errors.leave_response">
                                {{ error }}
                            </template>
<!--                            {{this.errors.leave_response[0]}}-->
                        </div>
                    </div>
                    <div class="text-success text-left"></div>
                    <div class="d-flex justify-content-end">
                        <button id="skip" class="btn btn-sml btn-danger" @click="skip()">Skip</button>
                        <button id="submit_response" class="btn btn-sml btn-success ml-05" @click="storeResponse()">{{ __('globals.submit_response') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['type', 'slug', 'leave_question'],
        name: "LeaveServiceModal",
        data () {
            return {
                user_response: '',
                submitting: false,
                errors: {},
            }
        },
        methods: {
            storeResponse(){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                this.erorrs = {};

                axios
                    .post('/member/'+this.type+'/'+this.slug+'/leave_response', {leave_response: this.user_response})
                    .then((response) => {
                        let flash_data = {
                            'message': response.data.message,
                            'level': 'success'
                        }
                        flash(flash_data);
                        this.submitting = false;
                        this.skip();
                    })
                     .catch(err => {
                        this.errors = err.response.data.errors;
                        this.submitting = false;
                    });
            },
            close() {
                this.$emit('close-modal');
                $('#leaveGroupModal').modal('hide');
            },
            skip(){
                this.$emit('question-complete');
                $('#leaveGroupModal').modal('hide');
            },
        },
        mounted() {
            $('#leaveGroupModal').modal('show');
            $('#leaveGroupModal').on('shown.bs.modal', function() {
                $(document).off('focusin.modal');
            });
        },
    }
</script>

<style scoped>

</style>
