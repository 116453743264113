<template>
    <a @click.prevent="closeHelpBox" data-action="close" class="heading-elements text-white"><i class="feather icon-x"></i></a>
</template>

<script>
    export default {
        props: ['name'],
        name: "CloseHelpBox",
        data() {
            return {
                help_box: this.name,

            }
        },
        methods: {
            closeHelpBox()
            {
                axios.get('/member/help/close/'+this.help_box)
                .then(response => {
                    console.log(response)
                });
            }
        }
    }
</script>

<style scoped>

</style>
