<template>
    <div>
        <div class="card">
            <div>
                <a :href="previous_page" class="btn btn-outline-white text-white btn-default waves-effect waves-light position-absolute m-05">
                    <i class="feather icon-arrow-left"></i>
                    {{ __('event.form.buttons.back') }}
                </a>
                <img class="card-img-top img-fluid" :src="event.event_image == null ? '/images/cover.png' : event.event_image.url" style="object-fit: cover;" alt="Event Image" />
            </div>
            <div class="card-content">
                <div class="card-body pb-05">
                    <div class="d-flex justify-content-between mb-05">
                        <div>
                            <h1 class="font-large-1 mb-05" v-text="event.title"></h1>
                            <span class="font-medium-3 primary mb-0">
                                <i class="fa fa-calendar"></i>
                                <span v-text="event.event_start_end_date"></span>
                            </span>
                        </div>

                        <div class="mt-05">
                            <span v-if="event.access === 'Offline Event'" class="badge badge-pill bg-gradient-secondary d-block mb-05 text-bold-700 font-small-2 ">
                                <i class="feather icon-user-plus"></i> {{ __('event.cards.offline') }}
                            </span>
                            <span v-if="event.access === 'Online Event'" class="badge badge-pill badge-success d-block mb-05 text-bold-700 font-small-2 ">
                                <i class="feather icon-globe"></i> {{ __('event.cards.online') }}
                            </span>
                            <span v-if="event.access === 'Hybrid Event'" class="badge badge-pill badge-warning d-block mb-05 text-bold-700 font-small-2 ">
                                <i class="feather icon-calendar"></i> {{ __('event.cards.hybrid') }}
                            </span>
                            <span v-if="this.sold_out === true" class="badge badge-pill badge-danger d-block mb-05 font-small-2 text-bold-700">
                                <i class="feather icon-alert-circle"></i> {{ __('event.sold_out') }}
                            </span>
                            <span v-if="event.event_privacy === 1" class="badge badge-pill  d-block mb-05 font-small-2 text-bold-700">
                                <i class="feather icon-award"></i>  {{ __('event.cards.premium') }}
                            </span>
                        </div>
                    </div>

                    <div class="card-text mt-05 pr-1 text-muted">
                        <i class="fa fa-map-marker"></i> {{ event.location }} | <i class="fa fa-map-pin"></i> {{ event.venue }}
                    </div>
                    <div class="pt-1 pb-1 border-top mt-1">
                        <span class="breakAll image-resize" v-html="event.event_description"></span>
                    </div>
                    <div class="d-flex align-items-center border-top">
                        <div class="pr-1 pt-1 pb-1 pl-0">
                            <span class="user-name text-bold-600" v-show="event.contact_name">
                                {{ __('globals.contact_name') }}
                            </span>
                            <br>{{ event.contact_name }}
                        </div>
                        <div class="p-1">
                            <span class="user-name text-bold-600" v-show="event.contact_phone">
                                {{ __('globals.contact_phone') }}
                            </span>
                            <br>{{ event.contact_phone }}
                        </div>
                        <div class="p-1" v-show="event.contact_email">
                            <span class="user-name text-bold-600">
                                {{ __('globals.contact_email') }}
                            </span>
                            <br>{{ event.contact_email }}
                        </div>
                    </div>

                    <div class="d-flex align-items-center border-top" v-if="event.tickets.length > 0 || event.contact_link !== null">

                        <div v-if="event.tickets.length > 0 && this.sold_out !== true ">
                            {{ __('globals.from') }} {{ this.price | currency(site_tax) }}
                        </div>

                        <div class="d-flex flex-row-reverse ml-auto mt-1 mb-05">
                            <div v-if="event.tickets.length > 0 && !this.sold_out && guestPurchaseTickets === 1">
                                <button class="btn btn-primary waves-effect waves-light" @click.prevent="buyTickets">
                                    {{ __('event.form.buttons.buy_tickets') }} <i class="fa fa-tag"></i>
                                </button>
                            </div>
                            <div class="mr-05" v-if="event.contact_link !== null">
                                <a :href="event.contact_link" target="_blank">
                                    <button class="btn btn-primary waves-effect waves-light pl-1 pr-1">
                                        {{ __('event.form.buttons.view_event_site') }}
                                        <i class="feather icon-chevron-right"></i>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between border-top pl-05 pr-05">
                <div class="p-05">
                    <i class="fa fa-thumbs-o-up" data-toggle="modal" data-target="#registerModal"></i>
                     {{ event.likesCount === 0 ? '' : event.likesCount }}
                </div>
                <div class="p-05">
                    <span data-toggle="modal" data-target="#registerModal">
                        <i class="fa fa-comment-o"></i>
                        {{ event.commentsCount === 0 ? '' : event.commentsCount }}
                        {{ event.commentsCount === 1 ? __('globals.comment') : __('globals.comments') }}
                    </span>
                </div>
                <div>
                    <guest-social-share :social="event.social"></guest-social-share>
                </div>
            </div>
        </div>


        <div class="modal fade" id="registerUserModal" tabindex="-1" role="dialog" aria-labelledby="registerUserModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="registerUserModalLabel">{{ __('globals.your_details') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form id="registerForm">
                        <div class="modal-body">

                            <div class="text-center text-danger help-block" role="alert" v-if="errorMessage">
                                <i class="feather icon-x-circle"></i> {{ errorMessage }}
                            </div>

                            <div class="form-group">
                                <label class="required" for="first_name">{{ __('user.field.text.first_name') }}</label>
                                <input type="text" name="first_name" id="first_name" class="form-control" v-model="first_name">
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors.first_name">
                                    <i class="feather icon-x-circle"></i> {{errors.first_name[0]}}
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="required" for="last_name">{{ __('user.field.text.last_name') }}</label>
                                <input type="text" name="last_name" id="last_name" class="form-control" v-model="last_name">
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors.last_name">
                                    <i class="feather icon-x-circle"></i> {{errors.last_name[0]}}
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="required" for="email">{{ __('user.field.text.email') }}</label>
                                <input type="text" name="email" id="email" class="form-control" v-model="email">
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors.email">
                                    <i class="feather icon-x-circle"></i> {{errors.email[0]}}
                                </div>
                            </div>

                            <div class="form-group" v-if="allow_registration">
                                <div class="d-flex align-items-center">
                                    <div class="vs-checkbox-con vs-checkbox-primary">
                                        <input type="checkbox" name="create_account" id="create_account" v-model="create_account">
                                        <span class="vs-checkbox">
                                            <span class="vs-checkbox--check">
                                                <i class="vs-icon feather icon-check"></i>
                                            </span>
                                        </span>
                                    </div>
                                    <span class="font-small-3">
                                        <label for="create_account">{{ __('user.create_member_account') }}</label>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group" v-if="create_account">
                                <div class="d-flex align-items-center">
                                    <div class="vs-checkbox-con vs-checkbox-primary">
                                        <input type="checkbox" name="accepted_terms_and_conditions" id="accepted_terms_and_conditions" v-model="terms">
                                        <span class="vs-checkbox">
                                            <span class="vs-checkbox--check">
                                                <i class="vs-icon feather icon-check"></i>
                                            </span>
                                        </span>
                                    </div>
                                    <span class="font-small-3">
                                        <label for="accepted_terms_and_conditions" v-html="__('globals.accept_terms_and_privacy_policy')"></label>
                                    </span>
                                </div>
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors.terms">
                                    <i class="feather icon-x-circle"></i> {{errors.terms[0]}}
                                </div>
                            </div>

                            <div class="form-group" v-if="create_account">
                                <label class="required" for="password">{{ __('user.field.text.password') }}</label>
                                <input type="password" name="password" id="password" class="form-control" v-model="password">
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors.password">
                                    <i class="feather icon-x-circle"></i> {{errors.password[0]}}
                                </div>
                            </div>

                            <input type="hidden" name="recaptcha" id="recaptcha">
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" @click.prevent="closeModal()">{{ __('globals.close') }}</button>
                                <button type="button" class="btn btn-primary"  @click.prevent="submitForm()">{{ __('event.select_tickets') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import linkify from 'vue-linkify'
    import GuestTickets from '../../mixins/guest/tickets.js'

    Vue.directive('linkified', linkify)

    export default {
        name: "Event",
        mixins: [GuestTickets],
        props: ['event', 'canguestpurchasetickets', 'previous_page', 'allow_registration'],
        data() {
            return {
                guestPurchaseTickets: this.canguestpurchasetickets,
                token: window.App.csrfToken,
                authUser: window.App.authUser,
                first_name: window.App.user ? window.App.user.first_name : '',
                last_name: window.App.user ? window.App.user.last_name : '',
                email: window.App.user ? window.App.user.email : '',
                create_account: true,
                terms: false,
                password: '',
                errors: [],
                errorMessage: null,
            }
        },
        methods: {
            closeModal(){
                $('#registerUserModal').modal('hide');
            },
            buyTickets(){
                if(this.authUser){
                    window.location.href = `/member/event/${this.event.slug}/tickets`;
                } else {
                    $('#registerUserModal').modal('show');
                    $('#registerUserModal').on('shown.bs.modal', function () {
                        $(document).off('focusin.modal');
                    });
                }
            },
            submitForm(){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                this.errors = [];
                let recaptchaValue = document.getElementById("recaptcha").value;
                axios
                    .post('/register-guest', {
                         _token: this.token,
                        event_id: this.event.id,
                        first_name: this.first_name,
                        last_name: this.last_name,
                        email: this.email,
                        create_account: this.create_account ? 'on' : '',
                        password: this.password,
                        terms: this.terms ? 'on' : '',
                        recaptcha: recaptchaValue,
                    })
                    .then((response) => {
                        this.submitting = false;
                        if(response.data.url){
                            window.location.href = response.data.url;
                        }
                        if(response.data.message){
                            this.errorMessage = response.data.message;
                        }
                    })
                    .catch((errors) => {
                        if(errors.response.data.message){
                            this.errorMessage = errors.response.data.message;
                        };
                        if(errors.response.data.errors) {
                            this.errors = errors.response.data.errors;
                        };
                        this.submitting = false;
                    });
            }
        },
        beforeMount() {
            this.ticketsPrice(this.event.tickets);
        }
    }
</script>
