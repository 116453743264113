<template>
    <div class="card p-05">
        <div class="card-container">
            <div class="d-inline-flex p-2">
                <div>
                    <div>
                        <h3 >{{ plan.title }}</h3>
                    </div>

                    <div v-if="plan.type === 'company'">
                        <p><i class="feather icon-users"></i> {{ plan.num_individuals === 0 ? "Unlimited" : plan.num_individuals }} Users</p>
                    </div>

                    <div class="font-weight-bolder font-size-medium">
                        <div v-if="plan.price === 0">
                            Free
                        </div>
                        <template v-else>
                            {{ plan.price | currency(site_tax)}} / {{ plan.interval }}
                        </template>
                    </div>

                    <div class="mt-05" v-html="plan.description"></div>
                </div>
            </div>
        </div>

        <div class="card-body" v-if="plan.plan_features.length > 0">
            <plan-features :plan_features="plan.plan_features"></plan-features>
        </div>

        <div class="card-footer border-0 bg-white mt-auto">
            <button class="btn btn-success btn-block" v-if="selected_plan_id === plan.stripe_plan_id" @click.prevent="cancelPlan">
                Selected
            </button>

            <button class="btn btn-primary btn-block" v-else @click.prevent="selectPlan">
                Select this plan
            </button>
        </div>
    </div>
</template>

<script>

    import moment from "moment-timezone";
    import PlanFeatures from "../../plans/PlanFeatures.vue";

    export default {
        props: ['plan', 'selected_plan_id'],
        name: "PlanCard",
        components: {PlanFeatures},
        data() {
            return {
                site_tax: window.App.site_tax,
                selected: false,
            }
        },
        mounted() {
        },
        beforeMount() {
        },
        methods: {
            formatDate(date) {
              return moment(date).format('ddd, MMM D, YYYY h:mm A');
            },
            selectPlan(){
                this.selected = true;
                this.$emit('plan-select', {type: 'select', plan_product_id: this.plan.plan_product_id, plan_id: this.plan.id, plan_stripe_id: this.plan.stripe_plan_id})
            },
            cancelPlan(){
                this.selected = false;
                this.$emit('plan-select', {type: 'deselect', plan_product_id: null, plan_id: null, plan_stripe_id: null})
            },
        }
    }

</script>

<style>

</style>
