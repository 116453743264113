<template>
    <li class="list-supplier-item "  style="list-style-type: none;">
        <div class="d-flex align-items-center">
            <div>
                <img class="round"
                     :src="member.avatar !== null ? member.avatar.url :  '/images/avatar.png'"
                     alt="avatar"
                     height="25"
                     width="25"/>
            </div>

            <div>
                <span class="font-weight-bold ml-05 mb-0 mt-1">{{ member.first_name }} {{ member.last_name }}</span>

                <span v-for="badge in member.badges">
                    <img :src="badge.image" style="margin-top: -3px;  height: 12px;" data-toggle="tooltip" data-placement="bottom"
                         :title="badge.name" alt="badge image" />
                </span>

                <img :src="member.user_badge_image.url" style="margin-top: -3px;  height: 12px;" data-toggle="tooltip" data-placement="bottom"
                     :title="member.user_badge_name" v-if="member.user_badge_name !== null && member.user_badge_icon !== null" alt="badge image" />
            </div>
            <div class="ml-auto">
                <div class="d-flex">
                    <div class="pr-05">
                        <span class="float-right d-flex mt-05" :id="'group_actions_'+member.id">
                            <a @click.prevent.stop="acceptRequest()" class="btn-sm btn-success mr-05" style="color: #fff; border: none;">
                                {{ __('globals.accept') }}
                            </a>
                            <a @click.prevent.stop="declineRequest()" class="btn-sm btn-danger" style="color: #fff; border: none;">
                                {{ __('globals.decline') }}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>

import EventBus from '../EventBus'

export default {
    name: "PendingFollowers",
    props: ['type', 'slug', 'member'],
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        acceptRequest() {
            if(this.submitting){
                return false;
            }
            this.submitting = true;
            axios
                .post(`/member/${this.type}/${this.slug}/accept-request/${this.member.username}`)
                .then((response) => {
                    let flash_data = {
                        'message': response.data.message,
                        'level': 'success'
                    }
                    flash(flash_data);
                    this.$emit('updatePending', this.member.id);
                    EventBus.$emit('addMember', this.member);
                    this.submitting = false;
                })
                .catch(() => {
                    let flash_data = {
                        'message': 'An Error Occurred',
                        'level': 'danger'
                    }
                    flash(flash_data);
                    this.submitting = false;
                })
        },
        declineRequest() {
            if(this.submitting){
                return false;
            }
            this.submitting = true;
            axios
                .delete(`/member/${this.type}/${this.slug}/decline-request/${this.member.username}`)
                .then((response) => {
                    let flash_data = {
                        'message': response.data.message,
                        'level': 'primary'
                    }
                    flash(flash_data);
                    this.$emit('updatePending', this.member.id);
                    this.submitting = false;
                })
                .catch(() => {
                    let flash_data = {
                        'message': 'An Error Occurred',
                        'level': 'danger'
                    }
                    flash(flash_data);
                    this.submitting = false;
                })
        },
    }
}
</script>

<style scoped>

</style>
