<template>
    <div class="modal fade" id="attendeeModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="">
                        <i class="feather icon-briefcase"></i>
                        {{ __('event.tickets.table.attendees.attendee_details') }}
                    </h4>
                    <button type="button" class="close" @click.prevent="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <form>
                        <div class="row mb-05">
                            <div class="col-12 form-group m-0">
                                <label for="name_add" class="required">{{ __('event.tickets.table.attendees.name') }}</label>
                                <input class="form-control" type="text" id="name_add" v-model="name" />
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.name">
                                    <i class="feather icon-x-circle"></i> {{this.errors.name[0]}}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-05">
                             <div class="col-6 form-group m-0">
                                <label for="phone_add" class="required">{{ __('event.tickets.table.attendees.phone') }}</label>
                                <input class="form-control" type="tel" id="phone_add" v-model="phone"/>
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.phone">
                                    <i class="feather icon-x-circle"></i> {{this.errors.phone[0]}}
                                </div>
                            </div>
                            <div class="col-6 form-group m-0">
                                <label for="email_add" class="required">{{ __('event.tickets.table.attendees.email') }}</label>
                                <input class="form-control" type="email" id="email_add" v-model="email" />
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.email">
                                    <i class="feather icon-x-circle"></i> {{this.errors.email[0]}}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-05">
                            <div class="col-12 form-group m-0">
                                <label for="organisation_add">{{ __('globals.organisation') }}</label>
                                <input class="form-control" type="text" id="organisation_add" v-model="organisation" />
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.organisation">
                                    <i class="feather icon-x-circle"></i> {{this.errors.organisation[0]}}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-05">
                            <div class="col-6 form-group m-0">
                                <label for="position_add">{{ __('globals.position') }}</label>
                                <input class="form-control" type="text" id="position_add" v-model="position">
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.position">
                                    <i class="feather icon-x-circle"></i> {{this.errors.position[0]}}
                                </div>
                            </div>
                            <div class="col-6 form-group m-0">
                                <label for="suburb_add">{{ __('globals.organisation_suburb') }}</label>
                                <input class="form-control" type="text" id="suburb_add" v-model="suburb"/>
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.suburb">
                                    <i class="feather icon-x-circle"></i> {{this.errors.suburb[0]}}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-05" v-if="attendee === null">
                             <div class="col-6 form-group m-0">
                                <label class="required">{{ __('globals.select_ticket') }}</label>
                                <select class="form-control" id="ticket_add" v-model="ticket">
                                    <option v-for="(value, key, index) in tickets" :key="index" :value="key">{{ value }}</option>
                                </select>
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.ticket">
                                    <i class="feather icon-x-circle"></i> {{this.errors.ticket[0]}}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-05">
                            <div class="col-12 form-group m-0">
                                <label for="dietary_add">{{ __('globals.dietary_requirements') }}</label>
                                <textarea class="form-control" type="text" cols="30" rows="5" id="dietary_add" v-model="dietary"></textarea>
                                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors !== '' && this.errors.dietary">
                                    <i class="feather icon-x-circle"></i> {{this.errors.dietary[0]}}
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center">
                            <button class="btn btn-success w-50" @click.prevent="addAttendee()" v-if="attendee === null">
                                <span v-if="submitting" class="spinner-border spinner-border-sm"></span>
                                <span v-else>
                                    <i class="feather icon-check-circle"></i>
                                    {{ __('globals.save') }}
                                </span>
                            </button>
                            <button class="btn btn-success w-50" @click.prevent="updateAttendee()" v-else>
                                <span v-if="submitting" class="spinner-border spinner-border-sm"></span>
                                <span v-else>
                                    <i class="feather icon-check-circle"></i>
                                    {{ __('globals.update') }}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['event_slug', 'attendee', 'tickets'],
        name: "EventAttendeeModal",
        data() {
            return {
                add_attendee: false,
                edit_attendee: false,
                submitting: false,
                name: '',
                phone: '',
                email: '',
                organisation: '',
                position: '',
                suburb: '',
                ticket: '',
                dietary: '',
                errors: '',
            }
        },
        mounted() {
            let vm = this;
            $('#attendeeModal').modal('show');
            $('#attendeeModal').on('hidden.bs.modal', function() {
                vm.$emit('closeModal');
            });

            if(this.attendee !== null){
               this.name = this.attendee.name;
               this.phone = this.attendee.phone;
               this.email = this.attendee.email;
               this.organisation = this.attendee.organisation;
               this.position = this.attendee.position;
               this.suburb = this.attendee.organisation_suburb;
               this.dietary = this.attendee.dietaryrequirements;
            }
        },
        methods: {
            addAttendee(){
                if(this.submitting){
                    return false;
                }
                this.errors = '';
                this.submitting = true;

                axios
                    .post(`/member/event/${this.event_slug}/attendee`, {
                            name: this.name,
                            phone: this.phone,
                            email: this.email,
                            organisation: this.organisation,
                            position: this.position,
                            suburb: this.suburb,
                            ticket: this.ticket,
                            dietary: this.dietary,
                    })
                    .then((response) => {
                        this.submitting = false;
                        this.$emit('newAttendee', response.data.data);
                        let data = {
                            'message': this.__('attendee.added'),
                            'level': 'success'
                        }
                        flash(data);
                        this.close();
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                        this.submitting = false;
                    });
            },
            updateAttendee(){
                if(this.submitting){
                    return false;
                }
                this.errors = '';
                this.submitting = true;
                axios
                    .put(`/member/event/${this.event_slug}/attendee/${this.attendee.id}`, {
                            name: this.name,
                            phone: this.phone,
                            email: this.email,
                            organisation: this.organisation,
                            position: this.position,
                            suburb: this.suburb,
                            dietary: this.dietary,
                    })
                    .then((response) => {
                        this.submitting = false;
                        this.$emit('updateAttendee', response.data.data);
                        let data = {
                            'message': this.__('attendee.updated'),
                            'level': 'success'
                        }
                        flash(data);
                        this.close();
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                        this.submitting = false;
                    });

            },
            close() {
                $('#attendeeModal').modal('hide');
            },
        }
    }
</script>

<style>

</style>
