<template>
    <div>
        <search :tags="this.tags" :filters="this.search_filters" v-on:searchType="changeFilter($event)"></search>
        <div class="row p-1 bg-white border-bottom d-flex align-items-center" v-for="(question, index) in questions" :key="question.id" >
            <div class="col-md-8 col-12">
                <div class="d-flex align-items-center">
                    <div class="">
                        <a :href="'/member/'+sections+'question/'+question.slug" class="font-medium-3 text-dark">{{question.title}}</a>
                        <div class="font-small-1 text-muted"><div class="avatar avatar-sm"><img :src="question.owner === null || question.owner.avatar === null ? '/images/avatar.png' : question.owner.avatar.url" class="img holder"></div> {{ question.owner.full_name }} {{ __('globals.asked') }} <span v-text="ago(question.published_at)"></span></div>
                        <div class="font-small-1" v-if="question.group !== null">{{ __('globals.asked_in') }} <a :href="'/member/group/'+question.group.slug">{{ question.group.title }}</a></div>
                        <div class="font-small-1" v-if="question.supplier !== null">{{ __('globals.asked_in') }} <a :href="'/member/supplier/'+question.supplier.slug">{{ question.supplier.title }}</a></div>
                    </div>
                    <div class="ml-auto">
                        <span class="question-tag" v-for="(tag, index) in question.question_tags" :key="index">
                            {{ tag.name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row">
                    <div class="col-4 p-2">
                        <div class="text-center" style="font-size: 17px; font-weight: 300; margin-bottom: 4px">
                            <span>{{ question.votesCount }}</span>
                        </div>
                        <div class="text-center">{{ __('globals.votes') }}</div>
                    </div>
                    <div class="col-4 p-2">
                        <div class="text-center" style="font-size: 17px; font-weight: 300; margin-bottom: 4px">
                            <span :title="question.answersCount+' answer'" v-text="question.answersCount"></span>
                        </div>
                        <div class="text-center">{{ __('question.answers') }}</div>
                    </div>
                    <div class="col-4 p-2">
                        <div class="text-center" style="font-size: 17px; font-weight: 300; margin-bottom: 4px">
                            <span>{{ question.views }}</span>
                        </div>
                        <div class="text-center">{{ __('globals.views') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results">{{ __('globals.no_questions_found_header') }}</div>
        </infinite-loading>
    </div>

</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import moment from "moment-timezone";
import Search from '../Search'

export default {
    name: "QuestionInfiniteScroll",
    components: {InfiniteLoading, Search},
    props: ['filters', 'tags', 'tag', 'sections'],
    data() {
        return {
            questions: [],
            page: 1,
            user_id: window.App.user.id,
            search: 'all',
            infiniteId: +new Date(),
            keyword: '',
            tagId: '',
            search_filters: this.filters,
        }
    },
    methods: {
        ago(date) {
            return moment(date).fromNow();
        },
        infiniteHandler($state) {
            if (this.tag !== null){
                this.getTaggedQuestions($state);

            }
            else {
                this.getQuestions($state);
            }
        },
        getQuestions($state){
            let vm = this;
            axios
                .get(this.getUrl())
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.questions.push(value);
                        });
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getTaggedQuestions($state){
            let vm = this;
            axios
                .get("/member/question/tag/"+this.tag+"/list?page="+this.page)
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.questions.push(value);
                        });
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getUrl(){
            let section = '';

            if (window.location.pathname.includes('/group/')){
                 section = '&section='+this.sections;
            }

            if (window.location.pathname.includes('/supplier/')){
                section = '&section='+this.sections;
            }

            if (this.search === "tag"){
                return "/member/question/search/"+this.search+"?tagId="+this.tagId+"&page=" + this.page+section;
            }

            if (this.search === "keyword"){
                return "/member/question/search/"+this.search+"?keyword="+this.keyword+"&page=" + this.page+section;
            }

            return "/member/question/search/"+this.search+"?page=" + this.page+section;
        },
        changeFilter(args){
            if (args.type === "tag")
            {
                this.tagId = args.tagId
            }

            if (args.type === "keyword")
            {
                this.keyword = args.keyword
            }

            this.search = args.type;
            this.page = 1;
            this.questions = [];
            this.infiniteId += 1;
        },
    },
    beforeMount() {
        if(this.filters) {
            this.search = this.filters.default;
        }
    }
}
</script>

<style scoped>

</style>
