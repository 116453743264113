<template>
    <div>
        <search :filters="filters" :categories="categories" :types="types" v-on:searchType="changeFilter($event)" v-if="!user_filter"></search>

        <div v-if="this.user.user_categories.length === 0 && filters.default === 'user'">
            <div class="alert alert-danger" role="alert">
                {{ __('search.paragraph.no_interests') }} <a :href="'/member/profiles/'+this.user.username+'/edit'">{{ __('globals.click_here') }}</a>
            </div>
        </div>

        <div class="row match-height pl-05 pr-05">
            <div class="col-md-3 col-sm-4 col-6 p-05" v-for="(event, index) in events" :key="infiniteId+index">
                <event-card :event="event" v-if="event.event_type === 'event'" :service_settings="service_settings"></event-card>
                <zoom-meeting-card :zoom_meeting="event" v-if="event.event_type === 'zoom'" :service_settings="service_settings"></zoom-meeting-card>
                <zoom-webinar-card :zoom_webinar="event" v-if="event.event_type === 'webinar'" :service_settings="service_settings"></zoom-webinar-card>
            </div>
        </div>

        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results"> {{ __('globals.no_events_found') }}</div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Search from '../Search'
import EventCard from './cards/Event.vue';
import ZoomMeetingCard from './cards/Zoom.vue';
import ZoomWebinarCard from './cards/Webinar.vue';

export default {
    name: "EventInfiniteScroll",
    components: {InfiniteLoading, Search, EventCard, ZoomMeetingCard, ZoomWebinarCard},
    props: ['filters', 'categories', 'types', 'user_filter', 'service_settings'],
    data() {
        return {
            events: [],
            page: 1,
            user: window.App.user,
            search: '',
            infiniteId: +new Date(),
            site_tax: window.App.site_tax,
            categoryId: '',
            typeId: '',
            keyword: '',
        }
    },
    methods: {
        infiniteHandler($state) {
            let vm = this;
            axios
                .get(this.getUrl())
                .then(response => {
                    let entries = Object.values(response.data.data)
                    if (entries.length > 0) {
                        $.each(entries, function (key, value) {
                            vm.events.push(value);
                        });
                        this.matchHeight();
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getUrl() {
            if(this.user_filter){
                switch (this.user_filter) {
                    case 'past_created':
                        return "/member/event/created?page=" + this.page;
                    case 'past_attended':
                        return "/member/event/attended?page=" + this.page ;
                    default:
                        return "/member/event/my?page=" + this.page;
                }
            }

            if (this.search === "category") {
                return "/member/event/search/" + this.search + "?categoryId=" + this.categoryId + "&page=" + this.page;
            }

            if (this.search === "keyword") {
                return "/member/event/search/" + this.search + "?keyword=" + this.keyword + "&page=" + this.page;
            }

            if (this.search === "type") {
                return "/member/event/search/" + this.search + "?type=" + this.type + "&page=" + this.page;
            }

            return "/member/event/search/" + this.search + "?page=" + this.page;
        },
        changeFilter(args) {
            if (args.type === "category") {
                this.categoryId = args.categoryId
            }

            if (args.type === "keyword") {
                this.keyword = args.keyword
            }

            if (args.type === "type") {
                this.type = args.typeId
            }

            this.search = args.type;
            this.page = 1;
            this.events = [];
            this.infiniteId += 1;
        },
        matchHeight() {
          let iteration = 0;
          let intervalId = setInterval(function () {
            if (iteration < 10) {
              $(".row.match-height").each(function () {
                $(this).find(".card").not(".card .card").matchHeight(); // Not .card .card prevents collapsible cards from taking height
              });
              iteration++;
            } else {
              clearInterval(intervalId);
            }
          }, 100);
        }
    },
    beforeMount() {
        if(this.filters) {
            switch (this.filters.default) {
                case 'user':
                    this.search = 'user';
                    break;
                case 'popular':
                    this.search = 'popular';
                    break;
                case 'latest':
                    this.search = 'latest';
                    break;
                default:
                    this.search = 'all';
                    break;
            }
        }
    }
}
</script>
