var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-top ml-3 mr-1"},[_c('div',{staticClass:"card  mb-1 d-inline-block ml-05 w-100"},[_c('div',{staticClass:"d-flex border-bottom pt-05 p-05"},[_c('comment-owner-info',{attrs:{"user_data":this.data.owner}}),_vm._v(" "),_c('div',{staticClass:"ml-auto"},[_c('span',{staticClass:"text-light font-small-1 d-block",domProps:{"textContent":_vm._s(_vm.ago)}}),_vm._v(" "),(_vm.data.owner.id === this.user_id || this.is_admin === true)?_c('div',[_c('button',{staticClass:"btn-sm btn-flat-dark float-right",staticStyle:{"border":"none"},on:{"click":function($event){_vm.editing = !_vm.editing}}},[_c('i',{staticClass:"fa fa-edit"})]),_vm._v(" "),_c('button',{staticClass:"btn-sm btn-flat-danger float-right",staticStyle:{"border":"none"},on:{"click":_vm.destroy}},[_c('i',{staticClass:"fa fa-trash-o"})])]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"d-flex ml-1 mr-1 mb-1 mt-05"},[(_vm.editing)?_c('div',{staticClass:"form-group w-100 mb-0"},[_c('div',{staticClass:"card mb-1 shared-post-reply"},[_c('editor',{attrs:{"api-key":"no-api-key","init":{
                            skin: "trybz-member",
                            skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                            content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                            external_plugins: {
                                customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                            },
                            valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                            plugins: "link quickbars autolink lists customtags loadingbar",
                            link_context_toolbar: true,
                            quickbars_selection_toolbar: "bold italic link bullist",
                            quickbars_image_toolbar: false,
                            quickbars_insert_toolbar: false,
                            link_assume_external_targets: "https",
                            link_target_list: false,
                            link_title: false,
                            toolbar: false,
                            branding: false,
                            elementpath: false,
                            menubar: false,
                            file_picker_types: "",
                            height: 100,
                            content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                            relative_urls : false,
                            remove_script_host : false,
                        }},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_vm._v(" "),_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":_vm.update}},[_vm._v(_vm._s(_vm.__('globals.update')))]),_vm._v(" "),_c('button',{staticClass:"btn btn-sm btn-link",on:{"click":_vm.cancelEditing}},[_vm._v(_vm._s(_vm.__('globals.cancel')))])]):_c('div',{staticClass:"col-12",domProps:{"innerHTML":_vm._s(_vm.comment_body)}}),_vm._v(" "),_c('div',{staticClass:"p-05"})]),_vm._v(" "),_c('div',{staticClass:"card-body p-0 border-top w-100"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pr-1 pl-1"},[_c('likes',{attrs:{"id":this.data.id,"likesCount":this.data.likesCount,"isLiked":this.data.isLiked,"type":"comment","likedByUsers":this.data.userLikes}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }