<template>
    <div>
        <div v-for="(comment, index) in items"  :key="comment.id">
            <comment :comment="comment" :type="type" @deleted="remove(index)"></comment>
        </div>
        <infinite-loading @infinite="infiniteHandler">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
        </infinite-loading>
        <new-comment :newsfeed_uuid="this.newsfeed_uuid" :id="this.id"  :type="this.type" @created="add"></new-comment>
    </div>
</template>

<script>
    import Comment from './Comment';
    import NewComment from './NewComment';
    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        props:['newsfeed_uuid', 'id', 'type'],
        components: {Comment, NewComment, InfiniteLoading},
        name: 'NewsfeedComments',
        data() {
            return {
                items: [],
                are_you_sure_message: this.__('globals.are_you_sure'),
                user: window.App.user,
                page: 1,
            }
        },
        methods : {
            infiniteHandler($state){
                this.init($state);
            },
            endpoint() {
                return '/member/newsfeed/'+ this.newsfeed_uuid + '/comments';
            },
            init($state){
                let vm = this;
                axios
                    .get(this.endpoint()+"?page="+this.page)
                    .then(response => {
                        if(response.data.data.length >0) {
                            $.each(response.data.data, function(key, value){
                                if(!Array.isArray(value)) {
                                    vm.items.push(value);
                                }
                            });
                            $state.loaded();
                        } else {
                            this.$emit('length', this.items.length);
                            $state.complete();
                        }
                    });
                vm.page++;
            },
            add(comment) {
                this.items.push(comment);
                this.$emit('length', this.items.length);
            },
            remove(index) {
                if (confirm(this.are_you_sure_message)){
                    this.items.splice(index, 1);
                    this.$emit('length', this.items.length);
                }
            }
        }
    }
</script>

<style scoped>

</style>
