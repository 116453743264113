<template>
    <div>
        <div class="card p-05">
            <div v-if="this.shared_user">
                <div class="d-flex justify-content-start mb-05 ">
                    <div class="p-05">
                        <img class="round"
                             :src="this.shared_user !== null && this.shared_user.user.avatar !== null ? this.shared_user.user.avatar.url :  '/images/avatar.png'"
                             alt="avatar"
                             height="30"
                             width="30"/>
                    </div>
                    <div class="p-0  pt-05 justify-content-start">
                        <div class="user-name text-bold-600">
                            <div class="dropdown dropdown-user nav-item">
                                <a class="dropdown-toggle dropdown-user-link"
                                   href="#"
                                   data-toggle="dropdown"
                                   v-text="this.shared_user.user.first_name+' '+this.shared_user.user.last_name">
                                </a>
                                <span v-for="badge in this.shared_user.user.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name">
                    </span>
                                <span class="text-bold-400">shared a resource</span>
                            </div>
                        </div>
                        <div style="margin-top: -3px; line-height: 1;"><span v-text="this.shared_user.user.position"
                                                                             class="font-small-2 text-light"></span><span
                            class="font-small-2 text-light"> at </span><span v-text="this.shared_user.user.organisation"
                                                                             class="font-small-2 text-light"></span>
                        </div>
                    </div>
                    <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                        <span v-text="ago(this.data.created_at)" class="font-small-1 text-light "
                              style="margin-top: 3px;"></span>
                        <div class="dropdown">
                            <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    style="border:none">
                                <i class="fa fa-chevron-circle-down mr-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || user_id === data.user_id || administrator === true">
                        <a class="dropdown-item" href="#" @click.prevent="editPost"><i
                            class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                    </span>
                                <span
                                    v-if="isServiceAdmin === true || user_id === data.user_id || administrator === true">
                        <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                            class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                    </span>
                                <a class="dropdown-item danger" @click.prevent="report">
                                    <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="editing" class="form-group p-1">
                    <textarea class="form-control mb-1" v-model="body"></textarea>
                    <button class="btn btn-sm btn-primary" @click.prevent="updateNewsfeed">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click.prevent="editing = false">{{ __('globals.cancel') }}</button>
                </div>
                <div class=" pt-1 pb-1" v-else-if="body !== null">
                    <div class="breakAll pb-1 pr-05 pl-05" v-linkified
                         v-html="this.$options.filters.nl2br(body, true)">
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="card border-light p-1 text-center" style="width: 100%">
                        This is a deleted post
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SharedFunctions from "../../../mixins/newsfeed/SharedFunctions";

export default {
    name: "DeletedResource",
    mixins: [
        SharedFunctions
    ],
    data() {
        return {
            shared_user: '',
            post_as_who: '',
            images: [],
            user: window.App.user,
            site_tax: window.App.site_tax,
            price: '',

        }
    },

    methods: {

    },

    mounted() {
        this.getSharedPost()
    }
}
</script>

<style scoped>

</style>
