<template>
    <div>
        <div class="card">
            <div class="d-flex justify-content-start p-1">
                <div class="avatar bg-rgba-primary p-50 mr-1">
                    <div class="avatar-content">
                        <i class="feather icon-eye primary font-medium-5"></i>
                    </div>
                </div>
                <div style="margin-top: 5px;" class="flex-grow-1">
                    <h2 class="text-bold-700 mb-0">{{ total }}</h2>
                    <p class="mb-0">{{ __('globals.views') }}</p>
                </div>
            </div>

            <button v-if="!show_views && views.length !== 0" type="button" class="btn btn-link font-small-3 ml-auto mt-auto p-05" @click="show_views = true">
                {{ __('globals.show_views') }} <i class="fa fa-chevron-down"></i>
            </button>
            <button v-if="show_views && views.length !== 0" type="button" class="btn btn-link font-small-3 ml-auto mt-auto p-05" @click.prevent="show_views = false">
                {{ __('globals.hide_views') }} <i class="fa fa-chevron-up"></i>
            </button>
        </div>

        <transition name="fade">
            <div class="card" v-if="show_views">
                <table class="table">
                    <thead class="thead-light">
                        <tr class="bg-primary">
                            <th>
                                {{ __('globals.name') }}
                            </th>
                            <th class="text-center">
                                {{ __('globals.views') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="view in views">
                            <td>
                                <p v-if="!view.user">{{ __('globals.deleted_user') }}</p>
                                <a v-else :href="'/member/profiles/'+view.user.username">{{view.user.name}}</a>
                            </td>
                             <td class="text-center">
                                {{ view.total }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Views",
        props: ['views', 'total'],
        data() {
            return {
                show_views: false,
            }
        },
    }
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
