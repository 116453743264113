<template>
    <div>
        <div class="d-flex mb-1">
            <div class="font-large-1  d-inline-block pr-1 text-bold-700"> {{ __('event.title') }}</div>
            <div class=" w-25 border-left-light pl-1 font-small-3  d-none d-md-inline-block">
                {{ __('event.search.heading') }}
            </div>
        </div>

        <div v-if="top_events.length > 0">
            <div  class="font-medium-4 text-primary d-inline-block py-1 text-bold-700">
                {{ __('event.title_popular') }}
            </div>
            <div class="row match-height pl-05 pr-05">
                <template v-for="(event, index) in top_events">
                    <guest-event-card :event="event" :key="infiniteId+index" :service_settings="service_settings"></guest-event-card>
                </template>
            </div>
        </div>

        <search :filters="filters" :categories="categories" :types="types" :guest="true" v-on:searchType="changeFilter($event)"></search>

        <div class="row match-height pl-05 pr-05">
            <template v-for="(event, index) in events">
                <guest-event-card :event="event" v-if="event.event_type === 'event'" :key="infiniteId+(index*1000)" :service_settings="service_settings"></guest-event-card>
                <guest-zoom-meeting-card :zoom_meeting="event" v-else-if="event.event_type === 'zoom'" :key="infiniteId+(index*2000)" :service_settings="service_settings"></guest-zoom-meeting-card>
                <guest-zoom-webinar-card :zoom_webinar="event" v-else-if="event.event_type === 'webinar'" :key="infiniteId+(index*3000)" :service_settings="service_settings"></guest-zoom-webinar-card>
            </template>
        </div>

        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results"> {{ __('globals.no_events_found') }}</div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Search from '../../Search'
import GuestEventCard from '../cards/Event.vue';
import GuestZoomMeetingCard from '../cards/Zoom.vue';
import GuestZoomWebinarCard from '../cards/Webinar.vue';

export default {
    name: "EventInfiniteScroll",
    components: {InfiniteLoading, Search, GuestEventCard, GuestZoomMeetingCard, GuestZoomWebinarCard},
    props: ['filters', 'categories', 'types', 'top_events', 'service_settings'],
    data() {
        return {
            events: [],
            page: 1,
            search: '',
            infiniteId: +new Date(),
            categoryId: '',
            typeId: '',
            keyword: '',
        }
    },
    methods: {
        infiniteHandler($state) {
            if(this.search === 'custom'){
                this.getFilteredEvents($state);
            } else {
                this.getEvents($state);
            }
        },
        getEvents($state){
            let vm = this;
            axios
                .get("/event/search/"+this.search+"?page="+this.page)
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.events.push(value);
                        });
                        this.matchHeight();
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getFilteredEvents($state){
            let vm = this;
            axios
                .get("/event/search/custom?categoryId=" + this.categoryId + "&typeId=" + this.typeId + "&keyword=" + this.keyword + "&page=" + this.page)
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.events.push(value);
                        });
                        this.matchHeight();
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        changeFilter(args){
            if (args.type === "type" || args.type === "keyword" || args.type === "category") {
                if(args.typeId !== undefined) {
                    this.typeId = args.typeId
                }
                if(args.keyword !== undefined) {
                    this.keyword = args.keyword
                }
                if(args.categoryId !== undefined) {
                    this.categoryId = args.categoryId
                }
                this.search = 'custom';
            } else {
                this.categoryId = '';
                this.typeId = '';
                this.keyword = '';
                this.search = args.type;
            }
            this.page = 1;
            this.events = [];
            this.infiniteId += 1;
        },
        matchHeight() {
          let iteration = 0;
          let intervalId = setInterval(function () {
            if (iteration < 10) {
              $(".row.match-height").each(function () {
                $(this).find(".card").not(".card .card").matchHeight(); // Not .card .card prevents collapsible cards from taking height
              });
              iteration++;
            } else {
              clearInterval(intervalId);
            }
          }, 100);
        }
    },
    beforeMount() {
        switch (this.filters.default) {
          case 'popular':
              this.search = 'popular';
              break;
          case 'latest':
              this.search = 'latest';
              break;
          default:
              this.search = 'all';
              break;
        }
    }
}
</script>
