<template>

    <div class="row mt-1">
    <div class="col-12 col-md-4 order-2">
        <invoice :data="this.items" :cart_item_count="this.itemsCount" :is_guest="is_guest" :key="invoiceKey"></invoice>
    </div>
        <div class="col-12 col-md-8 order-1">
            <div v-for="(item, index) in items" :key="index">
                <product v-if="item.cart_type === 'App\\Models\\Product'"
                         :data="item"
                         :index="index"
                         @updated="update"
                         @deleted="remove">
                </product>
                <resource v-if="item.cart_type === 'App\\Models\\Resource'"
                          :data="item"
                          :index="index"
                          @updated="update"
                          @deleted="remove">
                </resource>
                <service v-if="item.cart_type === 'App\\Models\\Service'"
                         :data="item"
                         :index="index"
                         @updated="update"
                         @deleted="remove">
                </service>
                <ticket v-if="item.cart_type === 'App\\Models\\Ticket'"
                        :ticket="item"
                        :allowed_fields="allowed_fields"
                        :ethnicity_values="ethnicity_values"
                        :is_guest="is_guest"
                        :index="index"
                        @updated="update"
                        @deleted="remove">
                </ticket>
                <videos v-if="item.cart_type === 'App\\Models\\Video'"
                        :data="item"
                        :index="index"
                        @updated="update"
                        @deleted="remove">
                </videos>
                <supplier v-if="item.cart_type === 'App\\Models\\Supplier'"
                        :data="item"
                        :index="index"
                        @updated="update"
                        @deleted="remove">
                </supplier>
                <zoom v-if="item.cart_type === 'App\\Models\\Zoom'"
                        :data="item"
                        :index="index"
                        @updated="update"
                        @deleted="remove">
                </zoom>
                <zoom-webinar v-if="item.cart_type === 'App\\Models\\ZoomWebinar'"
                        :data="item"
                        :index="index"
                        @updated="update"
                        @deleted="remove">
                </zoom-webinar>
            </div>
        </div>
    </div>
</template>

<script>
    import Product from './items/Product.vue';
    import Resource from './items/Resource.vue';
    import Videos from './items/Video.vue';
    import Service from './items/Service.vue';
    import Ticket from './items/Ticket.vue';
    import Supplier from './items/Supplier.vue';
    import Zoom from './items/Zoom.vue';
    import ZoomWebinar from './items/ZoomWebinar.vue';
    import Invoice from './Invoice.vue';

    export default {
        name: "cart",
        props: ['data', 'cart_item_count', 'allowed_fields', 'ethnicity_values', 'is_guest'],
        components: {
            Product, Invoice, Resource, Service, Ticket, Videos, Supplier, Zoom, ZoomWebinar
        },
        data() {
            return {
                items: this.data,
                itemsCount: this.cart_item_count,
                invoiceKey: 0,
                submitting: false,
            }
        },
        methods: {
            update(event){
                this.items[event.index].qty = event.qty;
                // Needed to refresh the invoice component
                this.invoiceKey += 1;
            },
            remove(event) {
                if (confirm(this.__('globals.are_you_sure'))){
                    if(this.submitting){
                        return false;
                    }
                    this.submitting = true
                    axios
                        .delete('/member/cart/' + this.items[event.index].id)
                        .then((response) => {
                            this.items.splice(event.index, 1);
                            this.invoiceKey += 1;
                            this.submitting = false;
                        })
                        .catch((error) => {
                            this.submitting = false;
                        })
                }
            }
        },
    }
</script>

<style scoped>

</style>
