<template>
    <div>
        <div class="card" style="width: 100%">
            <div class="card-content">
                <div class="card-body">
                    <div class="h1 font-large-1 d-inline-block" v-text="job.title"></div>
                    <div class="float-right pl-05" v-if="data.organisation_logo !== null">
                        <img :src="data.organisation_logo.url"
                             width="150">
                    </div>

                    <div>
                        <div class="">
                            <p class="primary text-bold-700 font-medium-3 mb-05">
                                <i class="fa fa-briefcase"></i> {{ data.employment_type }}
                            </p>
                            <p class=" font-small-3 text-light"><i class="fa fa-map-marker"></i> {{
                                    data.position_location
                                }}</p>
                        </div>
                        <div class="pt-1 pb-1 border-bottom breakAll">
                            <span class="breakAll image-resize" v-html="data.position_description"></span>
                        </div>
                        <div class="p-1" v-if="data.disabled === 1">
                            <div class="card bg-outline-gray">
                                <div class="card-header">
                                    <h4 class="card-title danger"> {{ __('globals.disabled_job_box_header') }}</h4>
                                </div>
                                <div class="card-body">{{ __('globals.disabled_job_box_content') }}</div>
                            </div>
                        </div>
                        <div class="pt-1 pb-1 border-bottom" v-if="data.hide_salary == 0">
                            <strong> Salary:</strong> {{ data.custom_salary }}
                        </div>


                        <div class="d-flex align-items-center pt-1 pb-1">


                            <div>
                                <div class="pb-1"><strong>{{ __('job.company_name') }}:</strong><br>
                                    {{ data.organisation_name }}
                                </div>
                                <div><strong>{{ __('job.company_description') }}:</strong><br>
                                    <span class="breakAll" v-html="data.organisation_description"></span>
                                </div>
                                <div class="pt-1" v-if="data.hide_organisation_size == 0">
                                    <strong>{{ __('job.company_size') }}:</strong> {{ data.organisation_size }}
                                </div>

                            </div>
                        </div>
                        <div class="d-flex mt-1" v-if="user.confirmed === '1'">
                            <div v-if="! data.isPositionClosed">
                                <span v-if="data.external_link === null">
                                    <a :href="'/member/job-applies?apply='+data.slug"
                                       class="btn btn-sm btn-primary waves-effect waves-light ">{{
                                            __('job.form.links.apply_for_position')
                                        }}</a>
                                </span>
                                <span class="ml-auto" v-else-if="data.external_link !== null">
                                    <a :href="data.external_link"
                                       target="_blank"
                                       class="btn btn-sm btn-primary waves-effect waves-light ">{{
                                            __('job.form.buttons.view_job_site')
                                        }} <i class="feather icon-chevron-right"></i></a>
                                </span>
                            </div>
                            <div class="btn-outline-danger p-05 rounded" v-else>
                                <strong>{{ __('job.form.paragraph.application_closed') }}</strong>
                            </div>


                        </div>
                        <div class="pt-1" v-if="! data.isPositionClosed">
                            <strong>{{ __('job.application_close') }}:</strong><br> {{ data.close_date }} @
                            {{ data.close_time }}

                        </div>
                    </div>
                </div>
                <div class="d-flex border-top justify-content-between">
                    <div class="font-small-3 text-bold-700">
                        <likes :id="data.slug" :likes-count="data.likesCount" :is-liked="data.isLiked" :liked-by-users="data.userLikes" type="job"></likes>
                    </div>
                    <div class="font-small-3 text-bold-700">
                            <a class="d-block p-05" @click.prevent="commentsShow = !commentsShow"><i
                               class="fa fa-comment-o"></i>
                                <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                                <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                            </a>
                    </div>
                    <div class="font-small-3 text-bold-700">
                        <social-share :id="data.id" :social="data.socialShare" @shareModal="triggerShareModal"></social-share>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="commentsShow">
            <comments :newsfeed_uuid="data.newsfeed_uuid" :id="data.slug" type="job" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <share-modal :original_newsfeed_id="data.newsfeed_id" :module="data" type="job" @closeModal="share_newsfeed = false" ></share-modal>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import linkify from 'vue-linkify'
import Share from '../../mixins/newsfeed/Share'
import Job from "../../mixins/newsfeed/Job";
import ShareModal from "../newsfeed/components/ShareModal.vue";

import Likes from "../newsfeed/components/Likes.vue";
import Comments from "../newsfeed/components/Comments.vue";

Vue.directive('linkified', linkify)

export default {
    name: "Job",
    mixins: [
        Job, Share
    ],
    components: {ShareModal, Likes, Comments},
    props: ['data'],
    data() {
        return {
            commentsShow: false,
            commentsCount: this.data.commentsCount,
            share_newsfeed: false,
        }
    },
    methods: {
        updateCommentsCount(count){
            this.commentsCount = count;
        },
        triggerShareModal(){
            this.share_newsfeed = !this.share_newsfeed;
        }
    },
    computed: {}
}
</script>


