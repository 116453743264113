<template>
    <div :id="'comment-'+newsfeed_id" style="display:none;">
        <div v-for="(comment, index) in items"  :key="comment.id">
            <comment :data="comment" :members="members" :administrator="administrator" @deleted="remove(index)"></comment>
        </div>

        <new-comment :type="type" :slug="slug" :members="members" @created="add" :parent="true"></new-comment>
    </div>
</template>

<script>
    import Comment from './Comment';
    import NewComment from './NewComment';


    export default {
        props:['data', 'newsfeed_id', 'module', 'slug', 'members', 'commentable', 'administrator'],
        components: { Comment, NewComment },
        name: 'Comments',
        data() {
            return {
                items: this.data,
                type: this.module,
                are_you_sure_message: this.__('globals.are_you_sure'),
                user: window.App.user
            }
        },
        methods : {
            add(comment) {
                this.items.push(comment);
            },
            remove(index) {
                if (confirm(this.are_you_sure_message)){
                    this.items.splice(index, 1);
                }
            }
        }
    }
</script>

<style scoped>

</style>
