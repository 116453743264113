<template>
    <div>
        <div v-if="data.newsfeed_type === 'post'">
            <newsfeed-post :data="data"
                           :groups_follow="groups_follow"
                           :suppliers_follow="suppliers_follow"
                           :section="section"
                           :members="members"
                           :administrator="administrator"
                           :questions_enabled="questions_enabled"/>
        </div>

        <div v-if="data.newsfeed_type === 'share'">
            <newsfeed-share-post  :data="data"
                                  :section="section"
                                  :members="members"
                                  :groups_follow="groups_follow"
                                  :suppliers_follow="suppliers_follow"
                                  :administrator="administrator"/>
        </div>

    </div>
</template>

<script>
import Share from '../../mixins/newsfeed/Share'

export default {
    name: "Post",
    mixins: [
        Share
    ],
    props: ['data', 'members', 'administrator', 'section', 'groups_follow', 'suppliers_follow', 'questions_enabled'],
}

</script>

