<template>
    <div>
        <div v-if="data.newsfeed_type === 'post'">
            <newsfeed-job :data="data" :groups_follow="groups_follow" :suppliers_follow="suppliers_follow" />
        </div>
        <div v-if="data.newsfeed_type === 'share'">
            <newsfeed-share-job :data="data" :members="members"/>
        </div>

    </div>
</template>

<script>
import Share from '../../mixins/newsfeed/Share'

export default {
    name: "Job",
    mixins: [
        Share
    ],
    props: ['data', 'members', 'administrator', 'groups_follow', 'suppliers_follow'],
}

</script>

