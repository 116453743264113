<template>
    <div :class="'p-05 col-12 col-sm-6 col-md-4' + (service_settings.max_column_count === 4 ? ' col-lg-3' : '')">
        <div class="card pb-5">
            <div class=" shadow" v-if="resource.cover !== null">
                <a :href="'/'+resource.type+'/'+resource.slug">
                    <img class="img-fluid card-img-top " :src="resource.cover.url" style="object-fit: cover;">
                </a>
            </div>
            <div class="card-content pl-05 pt-1 pr-1 pb-0">
                <div class="d-flex flex-row justify-content-between">
                    <div class="flex-fill">
                        <a :href="'/'+resource.type+'/'+resource.slug">
                            <div v-if="resource.cover === null">
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'doc'"><img src="/images/icons/doc.png"
                                                                                       width="25" alt="Document"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'docx'"><img src="/images/icons/doc.png"
                                                                                        width="25" alt="Document"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'txt'"><img src="/images/icons/doc.png"
                                                                                       width="25" alt="Text file"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'pdf'"><img src="/images/icons/pdf.png"
                                                                                       width="25" alt="PDF document"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'xls'"><img src="/images/icons/xls.png"
                                                                                       width="25" alt="Spreadsheet"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'xlsx'"><img src="/images/icons/xls.png"
                                                                                        width="25" alt="Spreadsheet"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'ppt'"><img src="/images/icons/ppt.png"
                                                                                       width="25" alt="Powerpoint presentation"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="resource.resource_file_type === 'pptx'"><img src="/images/icons/ppt.png"
                                                                                        width="25" alt="Powerpoint presentation"></div>
                            </div>
                            <div class="h5 text-bold-700 pl-05">{{ resource.title.substring(0, 70) }}</div>
                        </a>

                        <div v-if="resource.disabled === 1">
                            <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2">
                            <i class="feather icon-alert-circle"></i> {{ __(resource.type.replace('-', '_') + '.title') }} Inactive
                            </span>
                        </div>

                        <div class="d-flex justify-content-between align-items-center w-100 ">
                            <div class="mt-05  mb-05" v-if="this.rating !== null && this.rating !== 0">
                                <span :class="'rating-static rating-'+this.rating * 10">  </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-content resource-description font-small-4 mt-1 px-1" v-if="service_settings.description_display" v-html="description"></div>
            <div class="card-bottom m-05 p-0 d-flex align-items-center justify-content-between" style="width: 96%;">
                  <button class="btn-sm btn-flat-light ml-auto" style="border:none;"><a :href="'/resource/'+resource.slug">{{ __('globals.view') }} <i class="feather icon-chevron-right"></i></a></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GuestResourceCard",
    props: ['resource', 'service_settings'],
    data() {
        return {
            rating: null,
            site_tax: window.App.site_tax,
        }
    },
    methods: {
        average_rating(){
            let ratings_array = [];
            if(this.resource.ratings.length > 0){
                this.resource.ratings.forEach(rating => {
                    ratings_array.push(rating.rating);
                })
            }
            let sum = ratings_array.reduce((a, b) => a + b, 0);
            this.rating = Math.ceil((sum / ratings_array.length) || 0);
        },
    },
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.resource.resource_description.split(' ').length > 50 ? this.resource.resource_description.split(' ', 50).join(' ') + '...' : this.resource.resource_description) : '';
        }
    },
    beforeMount() {
        this.average_rating();
    }
}
</script>
