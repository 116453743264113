<template>
    <div>

        <!-- Internal Name -->
        <div class="mb-2">
            <label for="exampleFormControlInput1" class="form-label">Internal Name</label>
            <input
                v-model="name"
                type="text"
                class="form-control has-validation"
                id="internal_name"
                placeholder="Welcome Email - Free members"
                required
            >
            <small v-if="errors && errors.name" id="error_name" class="form-text text-danger">* {{ errors.name[0] }}</small>
        </div>

        <!-- Enabled -->
        <div class="form-check mb-1">
            <input
                v-model="email_enabled"
                class="form-check-input"
                type="checkbox"
                id="check-enabled"
                required
            >
            <label class="form-check-label" for="flexCheckDefault">
                Enable this email
            </label>
        </div>
        <small v-if="errors && errors.enabled" id="error_name" class="form-text text-danger">* {{ errors.enabled[0] }}</small>

        <!-- Delay -->
        <div class="mb-2">
            <label for="exampleFormControlInput1" class="form-label">Delay Mins</label>
            <input
                v-model="delay"
                class="form-control"
                type="number"
                id="delay_mins"
                min="0"
                max="10080"
                required
            />
            <small id="delayHelp" class="form-text text-muted">The email will be sent X minutes after the trigger occurred. 0 will send immediately</small>
            <small v-if="errors && errors.delay" id="error_name" class="form-text text-danger">* {{ errors.delay[0] }}</small>
        </div>

        <!-- Email Template -->
        <div class="mb-2">
            <label for="filter_option">Email Template</label>
            <select
                v-model="email_template_id"
                class="custom-select"
                id="template_options"
                required
            >
                <option v-for="template in available_email_templates" :value="template.id" :key="template.id">{{ template.name }}</option>
            </select>
            <small id="filtersHelp" class="form-text text-muted">The email colour theme.</small>
            <small v-if="errors && errors.email_template_id" id="error_name" class="form-text text-danger">* {{ errors.email_template_id[0] }}</small>
        </div>

        <!-- Triggers -->
        <div class="mb-2">
            <label for="trigger_select">Trigger</label>
            <select
                v-model="trigger_id"
                class="custom-select"
                id="trigger_select"
                required
            >
                <option v-for="trigger in available_trigger_options" :value="trigger.id" :key="trigger.id">{{ trigger.name }}</option>
            </select>
            <small id="filtersHelp" class="form-text text-muted">When this action occurs it triggers an email to the user that performed the action.</small>
            <small v-if="errors && errors.trigger_id" id="error_trigger" class="form-text text-danger">* {{ errors.trigger_id[0] }}</small>
        </div>


        <!-- Filters -->
        <div class="mb-2">
            <label for="filter_option">Audience Filters</label>
            <select
                v-model="filter_id"
                class="custom-select"
                id="filter_option"
                required
            >
                <option v-for="filter in available_filter_options" :value="filter.id" :key="filter.id">{{ filter.name }}</option>
            </select>
            <small id="filtersHelp" class="form-text text-muted">Will send to the user if they match the filter criteria</small>
            <small v-if="errors && errors.filter_id" id="error_name" class="form-text text-danger">* {{ errors.filter_id[0] }}</small>
        </div>

        <!-- Subject -->
        <div class="mb-2">
            <label for="email_subject" class="form-label">Email Subject</label>
            <input
                v-model="email_subject"
                type="text"
                class="form-control"
                id="email_subject"
                placeholder="Welcome to the community"
                required
            >
            <small v-if="errors && errors.subject" id="error_name" class="form-text text-danger">* {{ errors.subject[0] }}</small>
        </div>

        <!-- TinyMCE -->
        <div class="mb-05">
            <label for="email_content" class="required mt-1">Email Content</label>
            <div class="card my-0">
                <editor
                    v-model="email_content"
                    api-key="no-api-key"
                    :init='{
                        skin: "trybz-member",
                        skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                        content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                        external_plugins: {
                            customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                            loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                        },
                        // valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li],hr",
                        plugins: "table lists link image media code wordcount customtags loadingbar",
                        toolbar: "undo redo | styles fontsize hr | alignleft aligncenter alignright alignjustify | forecolor backcolor | bullist numlist | removeformat | link table | a11ycheck code",
                        toolbar_mode: "wrap",
                        image_title: true,
                        link_context_toolbar: true,
                        link_assume_external_targets: "https",
                        link_target_list: false,
                        link_title: false,
                        branding: false,
                        elementpath: false,
                        menubar: false,
                        file_picker_types: "",
                        height: 500,
                        content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                        relative_urls : false,
                        remove_script_host : false,
                    }'
                />
            </div>
            <small id="filtersHelp" class="form-text text-muted">Available tags [first_name], [last_name], [email]</small>
            <small v-if="errors && errors.content" id="error_name" class="form-text text-danger">* {{ errors.content[0] }}</small>
          <small id="error_name" class="form-text">*If you are using modules and all modules have no content, the email wont send.</small>


        </div>


        <!-- Buttons -->
        <div class="d-flex flex-row-reverse">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-primary waves-effect waves-light" @click.prevent="saveAndSendTest">Save & Send a copy to yourself</button>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-primary waves-effect waves-light mr-1" @click.prevent="save">Save</button>
            </div>
        </div>


    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

    export default {

        name: "CreateBehaviouralTrigger",
        components: {
            'editor': Editor
        },

        props: ['triggerId'],
        data() {
            return {
                available_trigger_options: null,
                available_filter_options: null,
                available_email_templates: null,
                name: null,
                delay: 1,
                email_template_id: null,
                trigger_id: null,
                filter_id: null,
                email_subject: null,
                email_content: null,
                email_enabled: true,
                send_at: null,
                errors: null,
                error_message: null,
            }
        },
        methods: {

            init(){

                console.log(this.emailBehaviouralTriggerId)

                axios.post('/admin/email-behavioural-trigger-create-init', {
                    update_id: this.triggerId === 0 ? null : this.triggerId,
                })
                .then(response => {
                    this.available_trigger_options = response.data.data.available_trigger_options;
                    this.available_filter_options = response.data.data.available_filter_options;
                    this.available_email_templates = response.data.data.available_email_templates;

                    // populate update fields
                    if(response.data.data.current_record){
                        this.name              = response.data.data.current_record.name;
                        this.delay             = response.data.data.current_record.delay;
                        this.email_enabled     = response.data.data.current_record.enabled;
                        this.email_template_id = response.data.data.current_record.email_template_id;
                        this.trigger_id        = response.data.data.current_record.trigger_id;
                        this.filter_id         = response.data.data.current_record.filter_id;
                        this.email_subject     = response.data.data.current_record.subject;
                        this.email_content     = response.data.data.current_record.content;
                        this.send_at           = response.data.data.current_record.send_at;
                    } else {
                        this.trigger_id        = response.data.data.default_trigger.id ?? null;
                        this.filter_id         = response.data.data.default_filter.id ?? null;
                        this.email_template_id = response.data.data.default_email_template.id ?? null;
                    }
                });
            },

            save() {
                axios.post('/admin/email-behavioural-trigger-create-save', {
                    update_id: this.triggerId === 0 ? null : this.triggerId,
                    name: this.name,
                    delay: this.delay,
                    enabled: this.email_enabled,
                    email_template_id: this.email_template_id,
                    trigger_id: this.trigger_id,
                    filter_id: this.filter_id,
                    subject: this.email_subject,
                    content: this.email_content,
                    send_at: this.send_at,
                })
                    .then(response => {
                        if (response.data.success === true) {
                          // update the id so we can save next time without creating a new record
                          this.triggerId = response.data.data.behavioural_trigger.id

                          alert("Email saved");
                          this.error_message = null;
                        }
                    }).catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.error_message = error.response.data.message;
                    }
                });
            },

            saveAndSendTest() {
                axios.post('/admin/email-behavioural-trigger-save-and-send-test', {
                    update_id: this.triggerId === 0 ? null : this.triggerId,
                    name: this.name,
                    delay: this.delay,
                    enabled: this.email_enabled,
                    email_template_id: this.email_template_id,
                    trigger_id: this.trigger_id,
                    filter_id: this.filter_id,
                    subject: this.email_subject,
                    content: this.email_content,
                    send_at: this.send_at,
                })
                    .then(response => {
                        if (response.data.success === true) {
                          // update the id so we can save next time without creating a new record
                          this.triggerId = response.data.data.behavioural_trigger.id

                            alert("A test email has been sent to your logged in email address");
                            this.error_message = null;
                        }
                    }).catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        this.error_message = error.response.data.message;
                    }
                });
            },
        },
        mounted() {
            this.init();
        }
    }
</script>
