<template>
    <div>
        <div class="card">
            <div class="d-flex">
                <div v-if="data.suppliers !== undefined && data.suppliers.length === 0">
                    <div class="d-flex justify-content-start mb-05">
                        <div class="p-05">
                            <img class="round"
                                 :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                                 alt="avatar"
                                 height="30"
                                 width="30"/>
                        </div>
                        <div class="p-0  pt-05 justify-content-start">
                            <div class="user-name text-bold-600">
                                <div class="dropdown dropdown-user nav-item">
                                    <a class="dropdown-toggle dropdown-user-link"
                                       href="#"
                                       data-toggle="dropdown"
                                       v-text="data.module.owner.first_name+' '+data.module.owner.last_name">
                                    </a>
                                    <span v-for="badge in data.module.owner.badges">
                                    <img :src="badge.image" style="margin-top: -3px;  height: 15px;"
                                         data-toggle="tooltip"
                                         data-placement="bottom"
                                         :title="badge.name">
                                </span>
                                    <span v-if="data.module.owner.user_badge_id !== null">
                            <img :src="data.module.owner.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                                 data-toggle="tooltip"
                                 data-placement="bottom"
                                 :title="data.module.owner.user_badge_name">
                        </span>
                                    <div class="dropdown-menu dropdown-menu-center">
                                        <profile :data="data"></profile>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: -3px; line-height: 1;"><span v-text="data.module.owner.position"
                                                                                 class="font-small-2 text-light"></span><span
                                class="font-small-2 text-light"> at </span><span v-text="data.module.owner.organisation"
                                                                                 class="font-small-2 text-light"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="data.suppliers !== undefined && data.suppliers.length === 1">
                    <div class="d-flex justify-content-start mb-05">
                        <div class="p-05">
                            <img class="round"
                                 :src="data.suppliers[0].supplier_avatar !== null ? data.suppliers[0].supplier_avatar.url :  '/images/avatar.png'"
                                 alt="avatar"
                                 height="30"
                                 width="30"/>
                        </div>
                        <div class="p-0  pt-05 justify-content-start">
                            <div class="user-name text-bold-600 text-dark">
                                <a href="#" class="text-dark" v-text="data.suppliers[0].title"></a>
                            </div>
                            <div style="margin-top: -5px" class="font-small-2 text-primary">@{{
                                    data.suppliers[0].slug
                                }}
                            </div>
                        </div>
                    </div>
                </div>


                <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                    <span v-text="ago(data.module.created_at)" class="font-small-1 text-light "
                          style="margin-top: 4px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                            <div v-if="canUpdate || isAdmin === true">
                                <a class="dropdown-item" :href="'/member/product/'+data.module.slug+'/edit'">
                                    <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                </a>
                                <a class="dropdown-item" href="#" @click="destroy">
                                    <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                </a>
                            </div>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">
                <img :class="'img-fluid product-image-'+data.id" v-if="data.module.product_image.length > 0"
                     :src="data.module.product_image[0].url">
                <div class=" mt-1 ml-1 mr-1 mb-05">
                    <span class="h3"><a :href="'/member/product/'+data.module.slug"
                                        v-text="data.module.title" class="text-dark"></a></span>
                </div>
                <div class="mt-05 ml-1 mr-1 mb-05" v-if="rating !== null">
                    <span :class="'rating-static rating-'+rating * 10">  </span>
                </div>
                <div class="ml-1 mr-1 mt-1 mb-0 pb-1 breakAll postarea" v-linkified
                     v-html="data.module.product_description.substring(0,550), true"></div>
                <div><a :href="'/member/product/'+data.module.slug" class="font-small-3 ml-1">{{ __('globals.read_more') }} <i
                    class="feather icon-chevron-right"></i></a></div>
                <div class="m-05 border-bottom text-right"></div>
                <div class="d-flex pl-05 pr-05 pt-0 pb-05 justify-content-between align-items-center ">
                    <div class="font-small-2 ml-05" v-if="this.price !== 0 && this.price !== null"
                         v-text="this.$options.filters.currency(this.price, site_tax)"></div>
                    <div class="ml-auto">
                        <a :href="'/member/product/'+data.module.slug"
                           class="btn btn-sm btn-flat-primary text-bold-700 ">View
                            {{ __('globals.view_product') }} <i class="feather icon-chevron-right"></i></a>
                        <a :href="'/member/product/'+data.module.slug" v-if="data.module.external_link !== null">
                            <button class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('globals.more_info') }} <i class="feather icon-chevron-right"></i>
                            </button>
                        </a>

                        <a :href="'/member/product/'+data.module.slug+'/add_to_cart'" v-if="data.module.external_link === null">
                            <button class="btn btn-sm btn-primary waves-effect waves-light"
                                    v-if="this.price !== 0 && this.price !== null">
                                {{ __('globals.buy_now') }} <i class="fa fa-tag"></i>
                            </button>
                        </a>
                    </div>
                </div>
                <div class=" m-05" v-if="data.groups !== undefined && data.groups.length > 0">
                    <a :href="'/member/group/'+data.groups[0].slug">
                        <div class="card text-white">
                            <img class="card-img img-fluid" style="height: 3rem;"
                                 :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                            <div
                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                    {{ data.groups[0].title }} </p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                    class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
                <div class=" m-05"
                     v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                    <a :href="'/member/supplier/'+data.suppliers[0].slug">
                        <div class="card text-white">
                            <img class="card-img img-fluid" style="height: 3rem;"
                                 :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                            <div
                                class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong>
                                    {{ data.suppliers[0].title }} </p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                    class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>

                </div>

                <div class="d-flex justify-content-between border-top ml-05 mr-05">
                    <div class="p-05">
                        <like :post="data.module" type="product"></like>
                    </div>
                    <div class="p-05" style="margin-top: 1px;">
                        <a href="javascript:void(0)"
                           class="font-small-3 text-bold-700"
                           @click="showComments(data.id)">
                            <i class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span>
                        </a>
                    </div>
                    <social-share :id="data.id" :social="this.data.module.socialShare"/>
                </div>

            </div>

        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  :module="data.type"
                  :slug="data.module.slug"
                  :members="members"
        ></comments>

        <div class="modal fade" :id="'shareModal'+data.id" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ __('globals.share_product') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="p-1 card border-light">
                            <div class="">
                                <label :for="'comment_body'+data.id">{{ __('globals.enter_comment') }}</label>
                                <textarea :name="'comment_body'+data.id" :id="'comment_body'+data.id" cols="30" rows="3"
                                          class="form-control"></textarea>
                            </div>
                            <div class="pb-1">
                                <label :for="'feed'+data.id">{{ __('globals.select_share') }}</label>
                                <select :name="'feed'+data.id" :id="'feed'+data.id" class="form-control">
                                    <option value="profile">{{ __('globals.your_newsfeed') }}</option>
                                    <optgroup :label="__('globals.share_group')" v-if="groups_follow !== undefined">
                                        <option :value="'group_'+group.id" v-for="group in groups_follow">{{
                                                group.title
                                            }}
                                        </option>
                                    </optgroup>
                                    <optgroup :label="__('globals.share_supplier')"
                                              v-if="suppliers_follow !== undefined">
                                        <option :value="'supplier_'+supplier.id"
                                                v-for="supplier in suppliers_follow">{{ supplier.title }}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="card border-light w-100">
                                <div class="d-flex justify-content-start mb-05 ">
                                    <div class="p-05">
                                        <img class="round"
                                             :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                                             alt="avatar"
                                             height="30"
                                             width="30"/>
                                    </div>
                                    <div class="p-0  pt-05 justify-content-start">
                                        <div class="user-name text-bold-600">
                                            <div class="dropdown dropdown-user nav-item">
                                                <a class="dropdown-toggle dropdown-user-link"
                                                   href="#"
                                                   data-toggle="dropdown"
                                                   v-text="data.module.owner.first_name+' '+data.module.owner.last_name">
                                                </a>
                                                <span v-for="badge in data.module.owner.badges">
                                                    <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                                                         data-placement="bottom"
                                                         :title="badge.name">
                                                </span>
                                                <span v-if="data.module.owner.user_badge_id !== null">
                                                    <img :src="data.module.owner.user_badge_image.url" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                                                         data-placement="bottom"
                                                         :title="data.module.owner.user_badge_name">
                                                </span>
                                                <div class="dropdown-menu dropdown-menu-center">
                                                    <profile :data="data"></profile>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-top: -3px; line-height: 1;"><span
                                            v-text="data.module.owner.position"
                                            class="font-small-2 text-light"></span><span
                                            class="font-small-2 text-light"> at </span><span
                                            v-text="data.module.owner.organisation"
                                            class="font-small-2 text-light"></span></div>
                                    </div>

                                    <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                        <span v-text="ago(data.module.created_at)" class="font-small-1 text-light "
                                              style="margin-top: 4px;"></span>
                                        <div class="dropdown">
                                            <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                    style="border:none">
                                                <i class="fa fa-chevron-circle-down mr-0"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                                                <div v-if="canUpdate || isAdmin === true">
                                                    <a class="dropdown-item"
                                                       :href="'/member/product/'+data.module.slug+'/edit'">
                                                        <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                                    </a>
                                                    <a class="dropdown-item" href="#" @click="destroy">
                                                        <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                                    </a>
                                                </div>
                                                <a class="dropdown-item danger" @click.prevent="report">
                                                    <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-content">
                                    <div class=" mt-1 ml-1 mr-1 mb-05">
                    <span class="h3"><a :href="'/member/product/'+data.module.slug"
                                        v-text="data.module.title" class="text-dark"></a></span>
                                    </div>
                                    <div class="mt-05 ml-1 mr-1 mb-05" v-if="rating !== null">
                                        <span :class="'rating-static rating-'+rating * 10">  </span>
                                    </div>
                                    <div class="ml-1 mr-1 mt-1 mb-0 pb-1 breakAll postarea" v-linkified
                                         v-html="data.module.product_description"></div>
                                    <div class="m-05 border-bottom text-right"><a
                                        :href="'/member/product/'+data.module.slug"
                                        class="btn btn-sm btn-flat-primary text-bold-700 ">{{ __('globals.view_product') }} <i
                                        class="feather icon-chevron-right"></i></a></div>
                                    <div
                                        class="d-flex pl-05 pr-05 pt-0 pb-05 justify-content-between align-items-center ">
                                        <div class="ml-1 text-bold-700" v-if="this.price !== 0 && this.price !== null"
                                             v-text="this.$options.filters.currency(this.price, site_tax)"></div>
                                        <div class="">
                                            <a :href="'/member/product/'+data.module.slug"
                                               v-if="data.module.external_link != null">
                                                <button class="btn btn-sm btn-primary waves-effect waves-light">
                                                    View External Site <i class="feather icon-chevron-right"></i>
                                                </button>
                                            </a>

                                            <a :href="'/member/product/'+data.module.slug+'/add_to_cart'" v-else>
                                                <button class="btn btn-sm btn-primary waves-effect waves-light"
                                                        v-if="this.price !== 0 && this.price !== null">
                                                    {{ __('globals.buy_now') }} <i class="fa fa-tag"></i>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div class=" m-05" v-if="data.groups !== undefined && data.groups.length > 0">
                                        <a :href="'/member/group/'+data.groups[0].slug">
                                            <div class="card text-white">
                                                <img class="card-img img-fluid" style="height: 3rem;"
                                                     :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                                <div
                                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong> {{ data.groups[0].title }} </p>
                                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                                        class="feather icon-chevron-right"></i></button>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class=" m-05"
                                         v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                                        <a :href="'/member/supplier/'+data.suppliers[0].slug">
                                            <div class="card text-white">
                                                <img class="card-img img-fluid" style="height: 3rem;"
                                                     :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                                <div
                                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                    <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{ data.suppliers[0].title }} </p>
                                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                                        class="feather icon-chevron-right"></i></button>
                                                </div>
                                            </div>
                                        </a>

                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevent="sharePost(data.id)">{{ __('globals.share') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Share from '../../../mixins/newsfeed/Share'
import Product from '../../../mixins/newsfeed/Product'
import DateTime from '../../../mixins/newsfeed/DateTime'

export default {
    name: "NewsfeedProduct",
    props: ['groups_follow', 'suppliers_follow', 'members'],
    data() {
        return {
            user_badge_icon: '',
            user_badge_name: '',
        }
    },
    mixins: [
        Share, Product, DateTime
    ],
}
</script>
