<template>
    <div>
        <a  @click.prevent="toggle"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            :class="classes"
            class="font-small-3 text-bold-700 ">
            <i class="fa fa-thumbs-o-up"></i>
            <span v-text="likesCount"></span>
        </a>
        <div v-if="hover && likedByUsers.length > 0" class="likes-dropdown dropdown-menu-center"
             @mouseover="hover = true"
             @mouseleave="hover = false"
             aria-labelledby="dropdownLikesList">
            <LikedByList :likedByUsers="likedByUsers"></LikedByList>
        </div>

    </div>

</template>

<script>
    import LikedByList from "./post/LikedByList";

    export default {
        props: ['post', 'type'],
        components: {LikedByList},
        data() {
            return {
                likesCount: this.likes_count(),
                isLiked: this.post.isLiked,
                module: this.type,
                user: window.App.user,
                likedByUsers: [],
                hover : false,
                confirmed_user_like_message: this.__('globals.confirmed_user_like')
            }
        },
        computed: {
            classes() {
                return [this.isLiked ? 'text-secondary' : ''];
            }
        },
        mounted() {
            this.fetchLikesFromPost();
        },
        methods: {
            likes_count() {
                return this.post.likesCount !== undefined ? this.post.likesCount : this.post.likes_count;
            },
            onLikesHover() {
                this.fetchLikesFromPost();
                this.$refs.likesList.visible = true;
            },
            onLikesHoverLeave() {
                this.$refs.likesList.visible = false;
            },
            fetchLikesFromPost() {
                let url = '';
                if (this.module === 'post' || this.module === 'comment')
                {
                    url = '/member/'+this.module+'/'+ this.post.id+'/likes';
                }
                else if (this.module === 'zoom-webinar' || this.module === 'zoom')
                {
                    url = '/member/'+this.module+'/'+ this.post.zoom_id+'/likes';
                }
                else
                {
                    url = '/member/'+this.module+'/'+ this.post.slug+'/likes';
                }

                axios.get(url)
                    .then(response => {
                        this.likedByUsers = response.data;
                    });

            },
            toggle() {
                 this.isLiked ? this.unlike() : this.like();
            },
            endpoint() {
                let slug = '';
                if (this.module === 'post' || this.module === 'comment')
                {
                    slug = this.post.id;
                }
                else if (this.module === 'zoom-webinar' || this.module === 'zoom')
                {
                    slug = this.post.zoom_id;
                }
                else
                {
                    slug = this.post.slug;
                }


                return '/member/'+this.module+'/' + slug +'/likes';
            },
            like() {
                if (this.user.confirmed === 0){
                    let data = {
                        'message': this.confirmed_user_like_message,
                        'level': 'danger'
                    }
                    flash(data);
                    return;
                }
                axios.post(this.endpoint());
                this.isLiked = true;
                this.likesCount++;
            },
            unlike() {
                axios.delete(this.endpoint());
                this.isLiked = false;
                this.likesCount--;
            }
        }
    }
</script>

<style scoped>
  .likes-dropdown {
      z-index: 9;
      background-color: #fff;
      padding-top: 5px;
      position:absolute;
      margin-bottom: 1rem;
      border: none;
      border-radius: 0.5rem;
      box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.4);
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }
</style>
