
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)
export default {
    name: "Event",
    props: ['event', 'subscription',],
    components: {},
    data() {
        return {
        }
    },
    methods: {
        editPost(e) {
            e.stopPropagation();
            this.editing = true;
        },
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/event/' + this.event.slug);
                if(window.location.href.includes('/member/event')){
                    document.location.href='/member';
                }
                this.$parent.$emit('deleted', this.event.id);
            }
        },
        report() {
            $('#report_id').val(this.event.id);
            $('#report_type').val('App\\Models\\Event');
            $('#post_title').text(this.event.title);
            $('#reportModal').modal('toggle');
        },
    },
}
