<template>
    <div>
         <div class="d-flex justify-content-between border-bottom">
             <div class="flex-grow-1">
                 <newsfeed-owner-info
                            :user_data="data.module.owner"
                            :supplier_data="data.supplier"
                            :shared="false"
                            :type="data.type"
                            :time="data.published_at">
                 </newsfeed-owner-info>
             </div>
             <div class="btn-group mt-05 mr-05">
                 <div class="dropdown">
                            <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                                <i class="fa fa-chevron-circle-down mr-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                        <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                            <a class="dropdown-item" :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/edit'"><i
                                class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                        </span>
                                <span
                                    v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                            <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                                class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                        </span>
                                <a class="dropdown-item danger" @click.prevent="report">
                                    <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                            </div>
                 </div>
            </div>
         </div>

        <div class="shadow" v-if="data.module.cover !== null">
            <img class="img-fluid" :src="data.module.cover.url" style="object-fit: cover;">
        </div>

            <div class="card-content pt-1 pb-1">
                <div class="col-md-12">
                    <div class="d-flex">
                        <div class="w-100">
                            <div v-if="data.module.cover === null">
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'doc'"><img src="/images/icons/doc.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'docx'"><img src="/images/icons/doc.png"
                                                                                           width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'txt'"><img src="/images/icons/doc.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'pdf'"><img src="/images/icons/pdf.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'xls'"><img src="/images/icons/xls.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'xlsx'"><img src="/images/icons/xls.png"
                                                                                           width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'ppt'"><img src="/images/icons/ppt.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'pptx'"><img src="/images/icons/ppt.png"
                                                                                           width="25px"></div>
                            </div>
                            <span class="h3">
                                <a :href="'/member/' + this.data.module.type + '/'+data.module.slug" style="color: inherit">{{ data.module.title }}</a>
                            </span>
                             <div class="mt-05  mb-05" v-if="rating !== null && rating !== 0">
                                <span :class="'rating-static rating-'+rating * 10">  </span>
                            </div>
                        </div>
                    </div>
                    <div class="pt-05 pb-05">
                        <div v-if="collapse_body" class="breakAll image-resize " v-linkified v-html="data.module.resource_description"></div>
                        <div v-else :class="class_body+' image-resize '" ref="textBox" v-linkified v-html="data.module.resource_description"></div>

                        <div v-if="enable_read_more">
                             <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                            </button>
                            <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="pb-1 pt-05 text-bold-700" v-if="data.module.can_be_downloaded === false && data.module.price > 0">
                            <span class="font-small-2 pt-1"> {{ __('globals.form.paragraph.your_price') }} {{ data.module.price | currency(site_tax) }} {{ __('globals.all_inclusive') }}</span>
                        </div>
                    </div>

                </div>

                <div class="border-top" v-if="user.confirmed">
                    <div class="pl-1 pr-1 pt-05">
                        <span v-if="data.module.price === null && this.lowest_plan_price !== null && data.module.can_be_downloaded === false" class="font-small-2">
                            {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('plan.upgrade_plan_message') }}
                        </span>
                        <span class="border-bottom" v-else-if="data.module.can_be_downloaded">
                                <div v-if="data.module.external_url !== null">
                                    <a :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/access-external-link'" target="_blank"
                                       class="download-button btn btn-primary waves-effect waves-light form-control">
                                            <i class="fa fa-link"></i> {{ __('resource.form.buttons.access_external_resource') }}
                                    </a>
                                </div>
                                <div v-else>
                                    <a :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/download-file'"
                                       class="download-button btn btn-primary waves-effect waves-light form-control">
                                            <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                                    </a>
                                </div>
                        </span>
                        <span class="border-bottom" v-else-if="data.module.price > 0 && data.module.can_be_downloaded === false">
                                <a :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/add_to_cart'"
                                   class="download-button btn btn-primary waves-effect waves-light form-control"><i
                                    class="fa fa-download"></i> {{ __('resource.form.buttons.buy') }} </a>
                        </span>
                    </div>
                </div>
                <div id="progress" class="p-2" style="display: none"></div>
            </div>
    </div>
</template>

<script>
import Share from "../../../../mixins/newsfeed/Share";
import Resource from "../../../../mixins/newsfeed/Resource";

export default {
    props: ['data'],
    name: "NewsfeedResource",
    data() {
        return {
        }
    },
    mixins: [
        Share, Resource
    ],
    mounted() {
        this.average_rating();
        this.planPrices(this.data.module.resource_plan_prices);
        if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>

<style scoped>

</style>
