export default {
    name: "tickets",
    data() {
        return {
            user: window.App.user,
            can_buy_tickets: false,
            price: null,
            sold_out: null,
            lowest_plan_name: null,
            lowest_plan_price: null,
            price_setting: window.App.price_setting,
        }
    },
    methods: {
        ticketsPrice(tickets) {

            if(tickets.length > 0){
                this.sold_out =  true;
            }

            if (this.price_setting === 'one-price' && tickets.length > 0) {
                tickets.forEach(ticket => {
                    if(ticket.sold_out !== 1){
                        if(ticket.ticket_one_price !== null) {
                            this.can_buy_tickets = true;
                            if (this.price === null) {
                                this.price = ticket.ticket_one_price;
                            } else if (ticket.ticket_one_price < this.price) {
                                this.price = ticket.ticket_one_price;
                            }
                        }
                        this.sold_out = false;
                    }
                })
            }

            if (this.price_setting === 'plan-price' && tickets.length > 0) {
                tickets.forEach(ticket => {
                    if(ticket.sold_out !== 1) {
                        ticket.ticket_plan_prices.forEach(plan_price => {
                            // if the user is on the right plan that allows them to buy tickets
                            if (this.user.user_plan_id === plan_price.plan_id && plan_price.price !== null) {
                                this.can_buy_tickets = true;
                                if (this.price === null) {
                                    this.price = plan_price.price;
                                } else if (plan_price.price < this.price) {
                                    this.price = plan_price.price;
                                }
                            }
                            // This is used to set the price of the available ticket based on lowest plan price if the user is not on a plan where price is available
                            if (this.lowest_plan_price === null && plan_price.price !== null) {
                                this.lowest_plan_price = plan_price.price;
                                this.lowest_plan_name = plan_price.plan.title;
                            } else if (plan_price.price < this.lowest_plan_price && plan_price.price !== null) {
                                this.lowest_plan_price = plan_price.price;
                                this.lowest_plan_name = plan_price.plan.title;
                            }
                        })
                        this.sold_out = false;
                    }
                })
            }
        }
    }
}
