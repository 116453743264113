<template>
    <div class="d-flex justify-content-start" style="margin-bottom: 5px;">
        <div class="p-05">
            <div v-if="user_data !== null && supplier_data.length === 0">
                <img class="round" :src="user_data !== null ? user_data.avatar.url :  '/images/avatar.png'"
                     alt="avatar"
                     height="30"
                     width="30"/>
                </div>
            <div v-else-if="supplier_data.length > 0">
                 <img class="round" :src="supplier_data[0].supplier_avatar !== null ? supplier_data[0].supplier_avatar.url :  '/images/avatar.png'"
                 alt="avatar"
                 height="30"
                 width="30"/>
            </div>
        </div>

        <div class="p-0 pt-05 justify-content-start">
            <div class="user-name text-bold-600 text-dark">

                <div class="dropdown dropdown-user nav-item" v-if="supplier_data.length === 0">
                    <a class="dropdown-toggle dropdown-user-link" :href="postOwnerLink" data-toggle="dropdown" v-text="postOwnerName" @click="show_profile = true"></a>
                    <div class="dropdown-menu dropdown-menu-center">
                        <div v-if="show_profile">
                            <profile :owner="user_data"></profile>
                        </div>
                    </div>
                    <span  v-for="badge in user_data.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="badge.name">
                    </span>
                    <span v-if="user_data.user_badge_id !== null">
                        <img :src="user_data.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                                                   data-toggle="tooltip"
                                                   data-placement="bottom"
                                                   :title="user_data.user_badge_name">
                    </span>
                    <span class="text-bold-400" v-if="shared === true" >{{ __('globals.shared') }} {{ type === 'event' ? 'an '+ type : 'a '+ type }}</span>
                </div>

                <div class="nav-item" v-else>
                    <a class="text-dark" :href="postOwnerLink" v-text="postOwnerName"></a>
                </div>
            </div>

            <div  style="margin-top: -5px;" v-if="supplier_data.length > 0">
                <span v-text="'@'+supplier_data[0].slug" class="font-small-2 text-primary"></span>
            </div>

            <div  style="margin-top: -3px; line-height: 1;" class="mr-2"  v-else>
                <span v-text="user_data.position" class="font-small-2 text-light"></span>
                <span class="font-small-2 text-light"> {{ __('globals.at') }} </span> <span v-text="user_data.organisation" class="font-small-2 text-light"></span>
            </div>
        </div>
    </div>
</template>

<script>
import Profile from "./Profile";

export default {
    name: "SharedPostOwnerInfo",
    props: ['user_data', 'supplier_data' , 'isServiceAdmin', 'shared', 'type', 'user_id'],
    components: {Profile},
    computed: {
        postOwnerName: function () {
            return (this.supplier_data.length > 0)
                ? this.supplier_data[0].title
                : this.user_data.first_name+' '+this.user_data.last_name

        },
        postOwnerLink: function () {
            return (this.supplier_data.length > 0)
                ? `/member/supplier/${this.supplier_data[0].slug}`
                : '#'
        },
    },
    data(){
        return {
            show_profile: false
        }
    }

}
</script>
