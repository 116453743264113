<template>
    <div class="">
        <div class="card mb-0 ml-1 mr-1 mb-1 d-flex align-items-top">
            <div class="d-flex border-bottom  pt-0 p-05">
                <comment-owner-info :user_data="this.comment.owner"></comment-owner-info>
                <div class="ml-auto" >
                    <span v-text="ago" class="text-light font-small-1 d-block"></span>
                    <div v-if="this.comment.user_id === this.user_id || this.is_admin === true">
                        <button class="btn-sm btn-flat-dark float-right" style="border:none;" @click="editing = !editing"><i class="fa fa-edit"></i></button>
                        <button class="btn-sm btn-flat-danger float-right" style="border:none;" @click="destroy"><i class="fa fa-trash-o"></i></button>
                    </div>
                </div>
            </div>

            <div class="d-flex ml-1 mr-1 mb-1 mt-05">
                <div v-if="editing" class="form-group w-100 mb-0">
                    <div class="card mb-1 shared-post-comment">
                        <editor
                            v-model="comment_text"
                            api-key="no-api-key"
                            :init='{
                                skin: "trybz-member",
                                skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                external_plugins: {
                                    customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                    loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                },
                                valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                plugins: "link quickbars autolink lists customtags loadingbar",
                                link_context_toolbar: true,
                                quickbars_selection_toolbar: "bold italic link bullist",
                                quickbars_image_toolbar: false,
                                quickbars_insert_toolbar: false,
                                link_assume_external_targets: "https",
                                link_target_list: false,
                                link_title: false,
                                toolbar: false,
                                branding: false,
                                elementpath: false,
                                menubar: false,
                                file_picker_types: "",
                                height: 100,
                                content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                relative_urls : false,
                                remove_script_host : false,
                            }'
                        />
                    </div>

                    <button class="btn btn-sm btn-primary" @click="update">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click="cancelEditing">{{ __('globals.cancel') }}</button>
                </div>

                <div v-else v-html="comment_body" class="col-12"></div>

            </div>

            <div class="card-body border-top p-0 ">
                <div class="d-flex justify-items-across">
                    <div class="pr-1 pl-1">
                        <likes :id="this.comment.id" :likesCount="this.comment.likesCount" :isLiked="this.comment.isLiked" type="comment" :likedByUsers="this.comment.userLikes" class="font-small-3 text-bold-700"></likes>
                    </div>
                    <div class="ml-auto mr-1">
                        <a @click.prevent="showReplies()" class="font-small-3 text-bold-700 d-block p-05">
                            <i class="fa fa-comment-o"></i>
                            <span v-if="replieslength === 1"><span v-text="replieslength"></span> {{ __('globals.reply') }}</span>
                            <span v-else><span v-text="replieslength === 0 ? '': replieslength"></span> {{ __('globals.replies') }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="show_replies">
            <child-comments :comment_id="this.comment.id" :parent_type="this.type" @repliesLength="updateCommentsCount"></child-comments>
        </div>
    </div>
</template>

<script>
    import moment from 'moment-timezone';
    import CommentOwnerInfo from './CommentOwnerInfo'
    import ChildComments from "./ChildComments";
    import Editor from '@tinymce/tinymce-vue'
    import Likes from './Likes';
    import axios from 'axios'

    export default {
        props: ['comment', 'type'],
        name: 'comment',
        components: {
            ChildComments,
            'editor': Editor,
            CommentOwnerInfo,
            Likes
        },
        data() {
            return {
                editing: false,
                comment_text: this.comment.comment_text,
                comment_body: this.comment.comment_text,
                comment_updated_message: this.__('comment.comment_updated'),
                id: this.comment.id,
                user_id: window.App.user.id,
                is_admin: window.App.user.isAdmin,
                show_replies: false,
                replieslength: this.comment.hasOwnProperty('repliesCount') ? this.comment.repliesCount : 0,
            };
        },

        computed: {
            canUpdate() {
                return this.authorize(user => this.comment.user_id === user.id);
            },
            ago() {
                return moment(this.comment.updated_at).format('DD-MM-YY');
            }
        },
        methods: {
            updateCommentsCount(count){
                this.replieslength = count;
            },
            showReplies() {
                this.show_replies = !this.show_replies;
            },
            update() {
                axios.patch('/member/comment/' + this.comment.id, {
                   comment_text: this.comment_text
                });
                this.comment_body = this.comment_text;
                this.editing = false;
                let data = {
                    'message': this.comment_updated_message,
                    'level': 'success'
                }
                flash(data);
            },
            destroy() {
                axios.delete('/member/comment/' + this.comment.id)
                this.$emit('deleted', this.comment.id);
            },
            cancelEditing(){
                this.editing = false;
                this.comment_text = this.comment.comment_text;
            }
        }
    }
</script>

<style scoped>

</style>
