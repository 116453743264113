<template>
    <div class="card">
        <div class="row">
            <div class="col-12 col-md-9 border-right">
                <div class="mt-05 ml-1">
                    <span class="font-large-2 primary" v-html="showFileTypeIcon()"></span>
                </div>
                <div>
                    <h4 class="ml-1"  v-text="item.cart.title"></h4>
                    <p class="ml-1"  v-html="item.cart.resource_description.substring(0,250)+'...'"></p>
                </div>
            </div>
            <div class="col-12 col-md-3 pt-05 text-center">
                <span class="font-large-1" v-text="this.$options.filters.currency(item.price, site_tax)"></span>
                <span class="font-small-1 text-muted d-block" style="margin-top: -5px;">inc {{ site_tax.name}}</span>

                <div class=" mt-05">
                <span class="font-small-2 text-center">{{ __('globals.quantity') }}:</span>
                <div class="d-flex mb-05 mt-05">
                <div class="m-auto">
                    <NumberInputSpinner
                        :min="1"
                        :max="5000"
                        :step="1"
                        :integerOnly="true"
                        v-model="qty"
                    />
                    </div>
                    </div>
                </div>
                <div class="text-center mb-05">
                <button class="btn-sm btn-flat-danger" @click="destroy" style="border:none;"><i class="feather icon-x"></i> {{ __('globals.remove') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NumberInputSpinner from 'vue-number-input-spinner'

    export default {
        name: "resource",
        props: ['data', 'index'],
        components: {
            NumberInputSpinner,
        },
        data() {
            return {
                item: this.data,
                qty: this.data.qty,
                site_tax: window.App.site_tax,
                icons: {
                    'pdf': '<i class="fas fa-file-pdf"></i>',
                    'txt': '<i class="fas fa-file-alt"></i>',
                    'doc': '<i class="fas fa-file-word"></i>',
                    'docx': '<i class="fas fa-file-word"></i>',
                    'xls': '<i class="fas fa-file-excel"></i>',
                    'xlsx': '<i class="fas fa-file-excel"></i>',
                    'ppt': '<i class="fas fa-file-powerpoint"></i>',
                    'pptx': '<i class="fas fa-file-powerpoint"></i>',
                    'zip': '<i class="fas fa-file-archive"></i>'
                }
            }
        },
        methods: {
            destroy() {
                this.$emit('deleted', {index: this.index});
            },
            showFileTypeIcon(){
                return this.icons[this.data.cart.resource_file_type];
            },
        },
        watch: {
            qty: function (val) {
                axios
                    .patch('/member/cart/'+this.data.id, {
                        qty: val
                    })
                    .then((response) => {
                        this.$emit('updated', {qty: val, index: this.index});
                    })
                    .catch((error) => {})
            }
        },
    }
</script>

<style scoped>

</style>
