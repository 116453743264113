<template>
    <div class="card p-1">
        <h3 class="primary">{{ __('globals.this_invoice')}}:</h3>

        <strong>{{ __('globals.purchase_details')}}</strong>
        <div class="d-flex border-top pt-05 pb-05">
            <div>{{ __('globals.total_cart_price')}}</div>
            <div class="ml-auto">{{ grossTotal | currency(siteTax)}}</div>
        </div>
        <div class="d-flex border-bottom pb-05">
            <div>{{ __('globals.tax')}} ({{ siteTax.name}})</div>
            <div class="ml-auto">{{ grossTotal | tax(siteTax)}}</div>
        </div>
        <div class="d-flex border-bottom pt-05 pb-05">
            <div>{{ __('globals.total_due')}}:</div>
            <div class="ml-auto">{{ totalOwing | currency(siteTax)}}</div>
        </div>
        <div class="mt-1">
            <span v-if="is_guest === true">
                <a :href="'/free-charge'" v-if="totalOwing === 0 && cart_item_count > 0"
                   class="btn btn-primary form-control">{{ __('globals.place_order')}}</a>

                <a :href="'cart/checkout'" v-else-if="cart_item_count > 0"
                   class="btn btn-primary form-control">{{ __('globals.place_order')}}</a>
            </span>
            <span v-else>
                <a :href="'/member/free-charge'" v-if="totalOwing === 0 && cart_item_count > 0"
                   class="btn btn-primary form-control">{{ __('globals.place_order')}}</a>

                <a :href="'/member/cart/checkout'" v-else-if="cart_item_count > 0"
                   class="btn btn-primary form-control">{{ __('globals.place_order')}}</a>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "invoice",
        props:['data', 'cart_item_count', 'is_guest'],
        data() {
            return {
                totalCartPrice: 0,
                discounts: 0,
                totalOwing: 0,
                gst: 0,
                grossTotal: 0,
                netTotal: 0,
                discountTotal: 0,
                siteTax: window.App.site_tax,
            }
        },
        methods: {
            calculateGrossTotal(){
                let price = 0;
                for (let i = 0; i < this.data.length; i++){
                    price += this.data[i].qty * this.data[i].price;
                }
                this.grossTotal = price;
            },
            calculateTotalOwing(){
                this.totalOwing = this.netTotal + this.gst;
            },
            calculateGST(){
                this.gst = this.grossTotal * (this.siteTax.rate + 1);
            },
            calculateNetTotal(){
                this.netTotal = this.grossTotal - this.gst;
            },
        },
        mounted() {
            this.calculateGrossTotal();
            this.calculateGST();
            this.calculateNetTotal();
            this.calculateTotalOwing();
        },
    }
</script>

<style scoped>

</style>
