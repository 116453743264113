<template>
    <div class="col-xl-4 col-md-6 col-sm-12 profile-card-2">
        <div class="card shadow-none" style="width: 240px; border: none;" >
            <div class="card-header mx-auto pb-0">
                <div class="row m-0">
                    <div class="col-sm-12 text-center">
                        <div class="avatar avatar-xl">
                            <img class="img-fluid"
                                 :src="owner.avatar !== null ? owner.avatar.url :  '/images/avatar.png'"
                                 alt="">
                        </div>
                        <a :href="'/member/profiles/'+owner.username">
                            <h4>
                                <span v-text="owner.first_name+' '+owner.last_name"></span>
                                <img
                                    v-if="owner.roles.hasOwnProperty('title') && owner.roles[0].title === 'Admin'"
                                    src="/images/badges/admin.png"
                                    style="margin-top: -5px;" data-toggle="tooltip" data-placement="bottom"
                                    title="Administrator">
                            </h4>
                        </a>
                    </div>
                    <div class="col-sm-12 text-center">
                        <div style="margin-top: -5px;"><span v-text="owner.position" class="font-small-2 text-light"></span>
                            <span class="font-small-2 text-light"> at </span><span v-text="owner.organisation" class="font-small-2 text-light"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-content">
                <div class="card-body text-center mx-auto p-0">

                    <div class="d-flex justify-content-between mt-2">

                        <div class="followers">
                            <p class="font-weight-bold font-medium-2 mb-0" v-text="followers"></p>
                            <span class="">{{ __('globals.contacts') }}</span>
                        </div>
                        <div>
                            <follow :data="owner"></follow>

                        </div>
                    </div>
                    <div class="mt-05">
                        <a :href="'/member/profiles/'+owner.username">
                            <button class="btn btn-sm btn-outline-light mt-1">{{ __('globals.view_profile_button') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Profile",
    props: ['owner'],
    data() {
        return {
            followers: 0,
            following: 0,
            followingOwner: '',
            username: this.owner.username
        }
    },
    methods: {
        // showFollowers and showFollowing has been replaced by the getProfileDetails function
        showFollowers() {
            axios
                .get('/member/user/followers/' + this.username)
                .then(response => {
                    this.followers = response.data.followers;
                });
        },
        showFollowing() {
            axios
                .get('/member/user/following/' + this.username)
                .then(response => {
                    this.following = response.data.following;
                });
        },
        getProfileDetails(){
            axios
                .get('/member/user/profileDetails/' + this.username)
                .then(response => {
                    this.followers = response.data.followers;
                    this.following = response.data.following;
                });
        }
    },
    beforeMount() {
        this.getProfileDetails();
    }
}
</script>

<style scoped>

</style>
