<template>
    <div>
        <div class="card" style="width: 100%;">
            <div class="d-flex justify-content-start mb-05">
                <div class="p-05">
                    <img class="round"
                         :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                         alt="avatar"
                         height="30"
                         width="30"/>
                </div>
                <div class="p-0  pt-05 justify-content-start">
                    <div class="user-name text-bold-600">
                        <div class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle dropdown-user-link"
                               href="#"
                               data-toggle="dropdown"
                               v-text="data.module.owner.first_name+' '+data.module.owner.last_name">
                            </a>
                            <span v-for="badge in data.module.owner.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name">
                    </span>
                            <span v-if="data.module.owner.user_badge_id !== null">
                            <img :src="data.module.owner.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                                 data-toggle="tooltip"
                                 data-placement="bottom"
                                 :title="data.module.owner.user_badge_name">
                        </span>
                            <div class="dropdown-menu dropdown-menu-center">
                                <profile :data="data"></profile>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: -3px; line-height: 1;"><span v-text="data.module.owner.position"
                                                                         class="font-small-2 text-light"></span><span
                        class="font-small-2 text-light"> at </span><span v-text="data.module.owner.organisation"
                                                                         class="font-small-2 text-light"></span></div>
                </div>
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                    <span v-text="ago(data.module.created_at)" class="font-small-1 text-light " style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                            <div v-if="canUpdate || isAdmin === true">
                                <a class="dropdown-item" href="#" :href="'/member/job/'+data.module.slug+'/edit'"><i
                                    class="feather icon-settings"></i>{{ __('globals.edit_post')}}</a>
                                <a class="dropdown-item" href="#" @click="destroy"><i class="feather icon-trash-2"></i>{{ __('globals.delete_post')}}</a>
                            </div>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post')}}</a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="bg-white text-white pt-2 pb-2 pl-1 pr-1 mb-0 d-flex justify-content-between">
                <div>
                    <span class="font-medium-3 text-light">{{ __('job.new_position_open') }}:</span><br>
                    <a :href="'/member/job/'+data.module.slug" style="color: #000000"><span
                        class="h3"
                        v-text="data.module.title"></span></a>
                    <div>
                        <i class="feather icon-briefcase text bold-700" style="color: #3399fe !important"></i> <span
                        style="color: #3399fe !important" class="text-bold-700"
                        v-text="data.module.employment_type"></span>
                    </div>
                </div>
                <div v-if="data.module.organisation_logo !== null">
                    <img :src="data.module.organisation_logo.url"
                         width="75">

                </div>
            </div>

            <div class="card-content">
                <div class="card-body p-0">

                </div>
                <div class=" m-05 pt-1 pl-1 pr-1 border-top"
                     v-html="data.module.position_description.substring(0, 550)"></div>
                <div class="d-flex ml-1 mr-1 mt-05 mb-0 pb-05 border-bottom align-items-center justify-content-start">
                    <span v-if="data.module.hide_salary === 0" class="font-small-3 text-bold-700" style=" color: black">New Job Opening: {{ data.module.custom_salary }}</span>
                    <div class="ml-auto" v-if="user.confirmed === true">
                        <span v-if=" data.module.isPositionClosed === false">
                                <span class="ml-auto" v-if="data.module.external_link !== null">
                                    <a :href="data.module.external_link"
                                       target="_blank"
                                       class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.view_job_site') }} <i
                                        class="feather icon-chevron-right"></i></a>
                                </span>
                                <span v-else>
                                    <a :href="'/member/job-applies?apply='+data.module.slug"
                                       class="btn btn-primary waves-effect waves-light">{{ __('job.form.buttons.apply') }} <i
                                        class="fas fa-user-check"></i></a>
                                </span>

                        </span>
                        <div class="btn btn-sm btn-outline-danger p-05 rounded" v-else>
                            <strong>{{ __('job.headers.member.title_position_closed') }}</strong>
                        </div>

                    </div>
                </div>
                <div class="d-flex justify-content-between ml-05 mr-05" style="color: black">
                    <div class="p-05">
                        <like :post="data.module" type="job"></like>
                    </div>
                    <div class="p-05" style="margin-top: 1px;">
                        <a href="javascript:void(0)"
                           class="font-small-3 text-bold-700"
                           @click="showComments(data.id)">
                            <i class="fa fa-comment-o"></i> Comment <span v-text="commentsCount"></span>
                        </a>
                    </div>
                    <social-share :id="data.id" :social="this.data.module.socialShare"/>
                </div>

            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  :module="data.type"
                  :slug="data.module.slug"
                  :members="members"
        ></comments>

        <div class="modal fade" :id="'shareModal'+data.id" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ __('job.share_job') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="p-1 card border-light">
                            <div class="">
                                <label :for="'comment_body'+data.id">{{ __('globals.enter_comment') }}</label>
                                <textarea :name="'comment_body'+data.id" :id="'comment_body'+data.id" cols="30" rows="3"
                                          class="form-control"></textarea>
                            </div>
                            <div class="pb-1">
                                <label :for="'feed'+data.id">{{ __('globals.select_share') }}</label>
                                <select :name="'feed'+data.id" :id="'feed'+data.id" class="form-control">
                                    <option value="profile">{{ __('globals.your_newsfeed') }}</option>
                                    <optgroup :label="__('globals.share_group')" v-if="groups_follow !== undefined">
                                        <option :value="'group_'+group.id" v-for="group in groups_follow">{{
                                                group.title }}
                                        </option>
                                    </optgroup>
                                    <optgroup :label="__('globals.share_supplier')"
                                              v-if="suppliers_follow !== undefined">
                                        <option :value="'supplier_'+supplier.id"
                                                v-for="supplier in suppliers_follow">{{ supplier.title }}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="card border-light w-100">
                                <div class="d-flex justify-content-start mb-05">
                                    <div class="p-05">
                                        <img class="round"
                                             :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                                             alt="avatar"
                                             height="30"
                                             width="30"/>
                                    </div>
                                    <div class="p-0  pt-05 justify-content-start">
                                        <div class="user-name text-bold-600">
                                            <div class="dropdown dropdown-user nav-item">
                                                <a class="dropdown-toggle dropdown-user-link"
                                                   href="#"
                                                   data-toggle="dropdown"
                                                   v-text="data.module.owner.first_name+' '+data.module.owner.last_name">
                                                </a>
                                                <span v-for="badge in data.module.owner.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name">
                    </span>
                                                <div class="dropdown-menu dropdown-menu-center">
                                                    <profile :data="data"></profile>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-top: -3px; line-height: 1;"><span v-text="data.module.owner.position"
                                                                                             class="font-small-2 text-light"></span><span
                                            class="font-small-2 text-light"> at </span><span v-text="data.module.owner.organisation"
                                                                                             class="font-small-2 text-light"></span></div>
                                    </div>
                                    <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                                        <span v-text="ago(data.module.created_at)" class="font-small-1 text-light " style="margin-top: 3px;"></span>
                                        <div class="dropdown">
                                            <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                    style="border:none">
                                                <i class="fa fa-chevron-circle-down mr-0"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                                                <div v-if="canUpdate || isAdmin === true">
                                                    <a class="dropdown-item" href="#" :href="'/member/job/'+data.module.slug+'/edit'"><i
                                                        class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                                                    <a class="dropdown-item" href="#" @click="destroy"><i class="feather icon-trash-2"></i>{{ __('globalsdelete_post') }}</a>
                                                </div>
                                                <a class="dropdown-item danger" @click.prevent="report">
                                                    <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="bg-white text-white pt-2 pb-2 pl-1 pr-1 mb-0 d-flex justify-content-between">
                                    <div>
                                        <span class="font-medium-3 text-light">{{ __('job.new_position_open') }}:</span><br>


                                        <a :href="'/member/job/'+data.module.slug" style="color: #000000"><span
                                            class="font-medium-1 text-black text-bold-700"
                                            v-text="data.module.title"></span></a>
                                        <div>
                                            <i class="feather icon-briefcase text bold-700" style="color: #3399fe !important"></i> <span
                                            style="color: #3399fe !important" class="text-bold-700"
                                            v-text="data.module.employment_type"></span>
                                        </div>
                                    </div>
                                    <div v-if="data.module.organisation_logo !== null">
                                        <img :src="data.module.organisation_logo.url"
                                             width="75">

                                    </div>
                                </div>

                                <div class="card-content">
                                    <div class="card-body p-0">
                                    </div>
                                    <div class="breakAll ml-1 mt-1 mr-1 mb-05 pb-1 border-bottom"
                                         v-html="data.module.position_description.substring(0, 300)"></div>
                                    <div class="d-flex ml-1 mr-1 mt-05 mb-0 pb-05 border-bottom align-items-center justify-content-start">
                                        <span v-if="data.module.hide_salary === 0" class="font-small-3 text-bold-700" style=" color: black">{{ __('job.new_job_opening') }}: {{ data.module.custom_salary }}</span>
                                        <div class="ml-auto w-30" v-if="user.confirmed === true">
                        <span v-if=" data.module.isPositionClosed === false">
                                <span class="ml-auto" v-if="data.module.external_link !== null">
                                    <a :href="data.module.external_link"
                                       target="_blank"
                                       class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.view_job_site') }} <i
                                        class="feather icon-chevron-right"></i></a>
                                </span>
                                <span v-else>
                                    <a :href="'/member/job-applies?apply='+data.module.slug"
                                       class="btn btn-primary waves-effect waves-light">{{ __('job.form.buttons.apply') }} <i
                                        class="fas fa-user-check"></i></a>
                                </span>

                        </span>
                                            <div class="btn btn-sm btn-outline-danger p-05 rounded" v-else>
                                                <strong>{{ __('job.application_closed') }}</strong>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevent="sharePost(data.id)">{{ __('globals.share') }}
                        </button>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import Share from '../../../mixins/newsfeed/Share'
import Job from '../../../mixins/newsfeed/Job'
import DateTime from '../../../mixins/newsfeed/DateTime'

export default {
    name: "NewsfeedJob",
    props: ['groups_follow', 'suppliers_follow', 'members'],
    mixins: [
        Share, Job, DateTime
    ],
}
</script>

<style scoped>

</style>
