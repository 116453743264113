<template>
    <div class="card">
        <div class="row border-bottom ml-1 mr-1">
            <div class="col-12 col-md-9 border-right">
                <div class="mt-1">
                    <h4 class="m-1" v-text="ticket.cart.title"></h4>
                    <p class="m-1" v-text="ticket.cart.description"></p>
                </div>
            </div>
            <div class="col-12 col-md-3 pt-05 text-center">
                <span class="font-large-1" v-text="this.$options.filters.currency(ticket.price, this.siteTax)"></span>
                <span class="font-small-1 text-muted d-block" style="margin-top: -5px;">
                    inc {{ this.siteTax.name }}
                </span>

                <div class=" mt-05">
                    <span class="font-small-2 text-center">
                        Quantity:
                    </span>
                    <div class="d-flex mb-05 mt-05">
                        <div class="m-auto">
                            <NumberInputSpinner
                                :min="1"
                                :max="ticket.cart.quantity_available"
                                :step="1"
                                :integerOnly="true"
                                v-model="ticketsCount"
                            />
                        </div>
                    </div>
                </div>
                <div class="text-center mb-05">
                    <button class="btn-sm btn-flat-danger" @click="destroy" style="border:none;"><i
                        class="feather icon-x"></i> {{ __('globals.remove') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-1" v-if="attendeeCount > 0">
            <h3><i class="feather icon-info"></i> {{ __('event.headers.member.assign')}}</h3>
        </div>

        <div class="ml-1 mr-1" v-if="attendeeCount > 0 && !isLoading" id="attendee_form">

            <form :class="'row '+ (index === 0 ? '' : 'border-top pt-1') " v-for="(attendee, index) in this.attendeeCount" :key="index">
                <div class="ml-1">
                    <strong>
                        {{ __('event.search.attendee')}} {{ index + 1 }}
                    </strong>

                    <button v-if="attendee === 1 && is_guest !== true" class="btn btn-link btn-lg p-0" @click.prevent="assignTicketToMe(index)">
                        ({{ __('globals.assign_to_me') }})
                    </button>
                </div>

                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="required">{{ __('globals.name')}}</label>
                        <input class="form-control" type="text" v-model="attendees[index].name" />
                        <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors[index] && errors[index].name">
                            <i class="feather icon-x-circle"></i> {{ errors[index].name[0] }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="required">{{ __('globals.email')}}</label>
                        <input class="form-control" type="email" v-model="attendees[index].email" />
                        <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors[index] && errors[index].email">
                            <i class="feather icon-x-circle"></i> {{ errors[index].email[0] }}
                        </div>
                    </div>

                    <div class="form-group" v-if="allowed_fields.mobile === '1'">
                        <label class="required">{{ __('globals.phone')}}</label>
                        <input class="form-control" type="text" v-model="attendees[index].phone" />
                        <div class="text-left text-danger help-block font-small-1" role="alert" v-if="errors[index] && errors[index].phone">
                            <i class="feather icon-x-circle"></i> {{ errors[index].phone[0] }}
                        </div>
                    </div>

                    <div class="form-group" v-if="allowed_fields.organisation === '1'">
                        <label>{{ __('globals.organisation')}}</label>
                        <input class="form-control" type="text" v-model="attendees[index].organisation" />
                        <span class="help-block text-muted font-small-1"></span>
                    </div>

                    <div class="form-group" v-if="allowed_fields.organisation_suburb === '1'">
                        <label>{{ __('globals.organisation_suburb')}}</label>
                        <input class="form-control" type="text" v-model="attendees[index].organisationSuburb" />
                        <span class="help-block text-muted font-small-1"></span>
                    </div>

                     <div class="form-group" v-if="allowed_fields.position === '1'">
                        <label class="">{{ __('globals.position')}}</label>
                        <input class="form-control" type="text" v-model="attendees[index].position" />
                        <span class="help-block text-muted font-small-1"></span>
                    </div>

                    <template v-if="allowed_fields.ethnicity === '1'">
                        <div class="form-group" v-if="ethnicity_values === null">
                            <label class="">{{ __('globals.ethnicity')}}</label>
                            <input class="form-control" type="text" v-model="attendees[index].ethnicity" />
                            <span class="help-block text-muted font-small-1"></span>
                        </div>

                        <div class="form-group" v-else-if="ethnicity_values.length > 0">
                            <label class="">{{ __('globals.ethnicity')}}</label>
                            <select class="form-control" v-model="attendees[index].ethnicity">
                                <option value="">Select Ethnicity</option>
                                <option :value="values" v-for="values in ethnicity_values">{{ values }}</option>
                            </select>
                            <span class="help-block text-muted font-small-1"></span>
                        </div>
                    </template>

                    <div class="form-group">
                        <label>{{ __('globals.dietary_requirements')}}</label>
                        <textarea class="form-control" v-model="attendees[index].dietaryRequirements" >
                        </textarea>
                        <span class="help-block text-muted font-small-1"></span>
                    </div>

                    <div class="mb-1 text-center">
                        <button class="btn btn-primary w-50" type="button" @click.prevent="attendees[index].id === '' ? storeAttendeeDetails(index) : updateAttendeeDetails(index)">Save Attendee Details</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import NumberInputSpinner from "vue-number-input-spinner";

    export default {
        name: "Event",
        props: ['ticket', 'allowed_fields', 'ethnicity_values', 'is_guest', 'index'],
        components: {
            NumberInputSpinner,
        },
        data() {
            return {
                isLoading: true,
                item: this.ticket,
                ticketsCount: this.ticket.qty,
                attendeeCount: this.ticket.qty,
                siteTax: window.App.site_tax,
                attendees: [],
                errors: [],
            }
        },
        watch: {
            ticketsCount: function (val) {
                axios
                     .patch('/member/cart/' + this.ticket.id, {
                            qty: val
                     })
                     .then((response) => {
                         if(this.attendeeCount > val){
                             this.removeAttendee(this.attendeeCount-1);
                         }
                         if(this.attendeeCount < val){
                             this.addAttendee();
                         }
                         this.$emit('updated', {qty: val, index: this.index});
                         this.flashMessage(response.data.message);
                     })
                     .catch((error) => {})
            },
        },
        methods: {
            initialiseAttendees(){
                // Based on the quantity add attendees
                for(let i = 0; i < this.attendeeCount; i++ ){
                    this.attendees.push({
                        id: '',
                        name: '',
                        email: '',
                        phone: '',
                        organisation: '',
                        organisationSuburb : '',
                        position : ''  ,
                        ethnicity: '',
                        dietaryRequirements: '',
                    })
                }
                this.getAttendeeInfo();
            },
            getAttendeeInfo(){
                axios
                    .get(`/member/ticket/${this.ticket.cart_id}/attendees`)
                    .then((response) => {
                        if (response.data.attendees.length > 0){
                            response.data.attendees.forEach((attendee, index) => {
                                this.assignAttendeeValue(index, attendee);
                            })
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                    })
            },
            assignAttendeeValue(index, attendee){
                this.attendees[index].id = attendee.id
                this.attendees[index].name = attendee.name;
                this.attendees[index].email = attendee.email;
                this.attendees[index].phone = attendee.phone;
                this.attendees[index].organisation = attendee.organisation;
                this.attendees[index].organisationSuburb = attendee.organisation_suburb;
                this.attendees[index].position = attendee.position;
                this.attendees[index].ethnicity = attendee.ethnicity;
                this.attendees[index].dietaryRequirements = attendee.dietaryrequirements;
            },
            addAttendee(){
                this.attendeeCount++;
                this.attendees.push({
                    id: '',
                    name: '',
                    email: '',
                    phone: '',
                    organisation: '',
                    organisationSuburb : '',
                    position : ''  ,
                    ethnicity: '',
                    dietaryRequirements: '',
                });
            },
            storeAttendeeDetails(index) {
                this.$set(this.errors, index, []);
                axios
                    .post(`/member/ticket/${this.ticket.cart_id}/attendee`, {
                        name: this.attendees[index].name,
                        email: this.attendees[index].email,
                        phone: this.attendees[index].phone,
                        organisation:  this.attendees[index].organisation,
                        organisation_suburb: this.attendees[index].organisationSuburb,
                        position:  this.attendees[index].position,
                        ethnicity:  this.attendees[index].ethnicity,
                        dietaryrequirements: this.attendees[index].dietaryRequirements,
                    })
                    .then((response) => {
                        this.assignAttendeeValue(index, response.data.attendee);
                        this.flashMessage(response.data.message);
                    })
                    .catch((error) => {
                        this.$set(this.errors, index, error.response.data.errors);
                    })
            },
            assignTicketToMe(index) {
                this.$set(this.errors, index, []);
                axios
                    .post(`/member/ticket/${this.ticket.cart_id}/assign-self`, {})
                    .then((response) => {
                        this.assignAttendeeValue(index, response.data.attendee);
                        this.flashMessage(response.data.message);
                    })
                    .catch((error) => {});
            },
            updateAttendeeDetails(index) {
                this.$set(this.errors, index, []);
                axios
                    .post(`/member/ticket/${this.ticket.cart_id}/attendee/${this.attendees[index].id}`, {
                        _method: 'PUT',
                        name: this.attendees[index].name,
                        email: this.attendees[index].email,
                        phone: this.attendees[index].phone,
                        organisation:  this.attendees[index].organisation,
                        organisation_suburb: this.attendees[index].organisationSuburb,
                        position:  this.attendees[index].position,
                        ethnicity:  this.attendees[index].ethnicity,
                        dietaryrequirements: this.attendees[index].dietaryRequirements,
                    })
                    .then((response) => {
                        this.assignAttendeeValue(index, response.data.attendee);
                        this.flashMessage(response.data.message);
                    })
                    .catch((error) => {
                        this.$set(this.errors, index, error.response.data.errors);
                    })
            },
            removeAttendee(index){
                // Index here can also be the last attendee
                // remove attendee and update quantity as well
                if(this.attendees[index].id !== ''){
                    axios
                        .delete(`/member/ticket/${this.ticket.cart_id}/attendee/${this.attendees[index].id}`)
                        .then((response) => {
                            this.$set(this.errors, index, []);
                            this.attendeeCount--;
                            this.attendees.splice(index, 1);
                            this.flashMessage(response.data.message);
                        })
                        .catch(() => {})

                } else {
                    this.$set(this.errors, index, []);
                    this.attendeeCount--;
                    this.attendees.splice(index, 1);
                }
            },
            destroy() {
                this.$emit('deleted', {index: this.index});
            },
            showFileTypeIcon() {
                return this.icons[this.data.cart.resource_file_type];
            },
            flashMessage(message){
                let flash_data = {
                    'message': message,
                    'level': 'primary'
                }
                flash(flash_data);
            }
        },
        beforeMount() {
            this.initialiseAttendees();
        }
    }
</script>

<style scoped>

</style>
