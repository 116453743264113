<template>
    <div class="card mt-1 p-1 mb-3">
        <span class="font-small-3">{{ __('globals.post_rating') }}: </span>
            <div class="pt-1">
                <div class="font-small-3">{{ __('globals.post_rating_paragraph') }}</div>
                <div class="rating">
                    <input type="radio" name="rating" id="star5" value="5" v-model="rating"/><label class="full" for="star5"
                                                                     :title="__('globals.5_starts')"></label>
                    <input type="radio" name="rating" id="star4half" v-model="rating" value="4.5"/><label class="half" for="star4half"
                                                                                    :title="__('globals.4_5_stars')"></label>
                    <input type="radio" name="rating" id="star4" v-model="rating" value="4"/><label class="full" for="star4"
                                                                     :title="__('globals.4_stars')"></label>
                    <input type="radio" name="rating" id="star3half" v-model="rating" value="3.5"/><label class="half" for="star3half"
                                                                                    :title="__('globals.3_5_stars')"></label>
                    <input type="radio" name="rating" id="star3" v-model="rating" value="3"/><label class="full" for="star3"
                                                                     :title="__('globals.3_stars')"></label>
                    <input type="radio" name="rating" id="star2half" v-model="rating" value="2.5"/><label class="half" for="star2half"
                                                                                    :title="__('globals.2_5_stars')"></label>
                    <input type="radio" name="rating" id="star2" v-model="rating" value="2"/><label class="full" for="star2"
                                                                     :title="__('globals.2_stars')"></label>
                    <input type="radio" name="rating" id="star1half" v-model="rating" value="1.5"/><label class="half" for="star1half"
                                                                                    :title="__('globals.1_5_stars')"></label>
                    <input type="radio" name="rating" id="star1" v-model="rating" value="1"/><label class="full" for="star1"
                                                                     :title="__('globals.1_star')"></label>
                    <input type="radio" name="rating" id="starhalf" v-model="rating" value="0.5"/><label class="half" for="starhalf"
                                                                           :title="__('globals.0_5_star')"></label>
                </div>
            </div>


        <div class="d-flex form-group mb-05">
                <textarea name="rating_text"
                          id="rating_text"
                          class="form-control"
                          placeholder="Please provide your feedback?"
                          required
                          v-model="rating_text"></textarea>
        </div>
        <div class="d-flex justify-content-end">
            <button type="submit"
                    class="btn btn-primary btn-doc-header waves-effect waves-light"
                    @click="addRating">{{ __('globals.submit')}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NewRating",
        props: ['data', 'slug'],
        data() {
            return {
                rating_text: '',
                rating: '',
                endpoint: '/member/'+this.data+'/'+this.slug+'/ratings',
                user: window.App.user,
                rating_submitted_message: this.__('globals.rating_submitted'),
            }
        },
        methods: {
            addRating(){
                if (this.user.confirmed == 0){
                    let data = {
                        'message': __('globals.confirmed_rating'),
                        'level': 'danger'
                    }
                    flash(data);
                    return;
                }

                axios
                    .post(this.endpoint, {rating_text: this.rating_text, rating: this.rating})
                    .then(response => {
                        if (response.data.error != null){
                            let flash_data = {
                                    'message': response.data.error,
                                    'level': 'danger'
                                }
                            flash(flash_data);
                        }
                        else {
                            let flash_data = {
                                    'message': this.rating_submitted_message,
                                    'level': 'success'
                                }
                            flash(flash_data);
                            this.$emit('created', response.data)
                        }
                        this.rating_text = '';
                        this.rating = '';

                    });
            }
        }
    }
</script>

<style scoped>

</style>
