<template>
    <div class="row justify-content-center">
        <div v-if="!friend_id && currentGroupChat === null" class="col-md-12">
            <div class="card card-default">
                <div class="pb-1 d-flex align-items-center border-bottom p-0 m-1 card-header">
                    <div>
                        <h3 class="text-bold-700 mb-0"><i class="feather icon-send"></i> {{ __('chat.compose_new_message') }}</h3>
                    </div>
                    <div class="ml-auto">
                        <a href="/member/messages" class="btn-sm btn-outline-primary"><i
                            class="feather icon-arrow-left"></i> {{ __('chat.form.links.back_inbox') }} </a>
                    </div>

                </div>
                <span class="pl-2 pr-2">
                    <input v-if="!startGroupChat" class="mb-05 form-control" placeholder="Search all contacts to chat"
                           type="text" v-model="search" v-on:input="debounceInput" autofocus>
                </span>
<!--                <span class="pl-2 pr-2 text-right">-->
<!--                    <a class="btn btn-sm btn-primary text-white" v-if="!startGroupChat" @click="startGroupChat = 1"><i-->
<!--                        class="feather icon-users"></i> {{ __('chat.form.links.create_group_chat') }}</a>-->
<!--                </span>-->
                <!-- group chat -->
                <div v-if="startGroupChat" class="row pl-2 pr-2">
                    <div class="col-12">
                        <input class="mb-05 form-control" placeholder="Search for contacts to add to your group" type="text"
                               v-model="searchForGroup" v-on:input="debounceInput">
                        <div v-if="selectedGroupMembers.length > 0" class="pb-05 selected-members-list">
                            <span class="font-small-3 text-bold-700"> {{ __('chat.group_participants') }}</span> <br>
                            <span class="badge p-1 mr-05 badge-blue" v-for="member in selectedGroupMembers" :key="member.id+Math.random()">
                                {{ member.first_name }}
                            </span>
                        </div>
                        <a @click="cancelGroup" class="btn btn-sm btn-outline-danger text-danger">{{ __('chat.form.links.cancel_group') }}</a>
                        <a v-if="selectedGroupMembers.length > 0" @click.prevent="createGroupSession"
                           class="pl-05 btn btn-sm btn-success text-white">{{ __('chat.form.links.start_group_chat') }} <i
                            class="feather icon-chevron-right"></i></a>
                    </div>


                    <div class="col-12 group-chat-members card-body">

                        <span v-if="this.searchForGroup !== null && friends.length > 0">
                            <h3>{{ __('chat.add_contact_to_group') }}</h3>
                            <ul class="list-group">
                            <li class="list-group-item" @click.prevent="addToGroup(friend)"
                                v-for="friend in friends" :key="friend.id+Math.random()">

                                <a href="" style="color:#212529"> </a>
                                <div class="d-flex align-items-center">
                                    <div class="">
                                        <img class="round"
                                             :src="friend.avatar !== null ? friend.avatar.url :  '/images/avatar.png'"
                                             alt="avatar"
                                             height="30"
                                             width="30"/>
                                    </div>
                                    <div class="ml-05">
                                        <span class="h5">  {{ friend.first_name }} {{ friend.last_name }}<i
                                            v-if="friendExistsInGroup(friend)"
                                            class="fa pl-1 text-success fa-check-circle"></i></span>
                                        <span class="text-danger"
                                              v-if="(friend.session && friend.session.unreadCount > 0)">{{
                                                friend.session.unreadCount
                                            }}</span>
                                        <span class="text-light text-bold-700  font-small-2 d-block"
                                        > {{ friend.position }} / {{
                                                friend.organisation
                                            }} </span>
                                        <i class="fas fa-circle float-right success" v-if="friend.online"
                                           style="position: absolute; top: 15px; right: 15px;"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>

                            <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
                                <div slot="no-more" class="text-light"> </div>
                                <div slot="no-results"></div>
                            </infinite-loading>
                        </span>

                    </div>
                </div>

                <!--EOF group chat-->


                <div v-if="!startGroupChat" class="card-body">
                    <h3 id="search_heading">{{ __('chat.your_contacts') }}</h3>
                    <ul class="list-group">

<!--                        <span v-if="this.search === null || this.search === ''">-->
<!--                            <li class="list-group-item" @click.prevent="openGroupChat(groupSession)"-->
<!--                                v-for="groupSession in groupSessions" :key="groupSession.id+Math.random()">-->
<!--                            <a href="" style="color:#212529">-->
<!--                                <i class="far fa-comments"></i>-->
<!--                                {{ groupSession.name }}</a>-->
<!--                        </li>-->
<!--                        </span>-->


                            <span v-for="friend in friends" :key="'friend'+friend.id+Math.random()">
                                <li class="list-group-item" @click.prevent="openChat(friend)"
                                    v-if="friend.contact === 1 || (search !== null && search !== '')">

                                    <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                    <div class="">
                                    <img class="round"
                                         :src="friend.avatar !== null ? friend.avatar.url :  '/images/avatar.png'"
                                         alt="avatar"
                                         height="30"
                                         width="30"/>
                                         </div>
                                         <div class="ml-05">
                                      <span class="h5">  {{ friend.first_name }} {{ friend.last_name }}</span>
                                        <span class="badge badge-pill badge-primary badge-sm" v-if="(friend.session && friend.session.unreadCount > 0)">{{
                                                friend.session.unreadCount
                                            }}</span>
                                          <span class="text-light font-small-2 d-block"
                                          > {{ friend.position }} | {{
                                                  friend.organisation
                                              }} </span>
                                    <i class="fas fa-circle float-right success" v-if="friend.online"
                                       style="position: absolute; top: 15px; right: 15px; width: 15px; height: 15px; background-color: green"></i>
                                    </div>
                                        </div>
                                        <div> <i class="feather icon-chevron-right h3"></i> </div>
                                    </div>
                                </li>
                            </span>
                            <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler" v-if="this.search">
                                <div slot="no-more" class="text-light"></div>
                                <div slot="no-results">No results</div>
                            </infinite-loading>
                    </ul>

                </div>
            </div>
        </div>

        <div v-if="currentGroupChat === null" class="col-md-8">
            <span v-for="friend in friends" :key="friend.id+Math.random()" v-if="friend.session">
                <message v-if="friend.session.open" @close="close(friend)" :friend=friend></message>
            </span>
        </div>

        <div v-if="currentGroupChat != null" class="col-md-8">
            <span v-for="groupSession in groupSessions" :key="groupSession.id+Math.random()">
                <group-message :group-session="groupSession" v-if="currentGroupChat.id === groupSession.id"
                               @close="closeGroupChat(groupSession)"></group-message>
            </span>
        </div>

        <div v-if="! chatAvailable" class="col-md-12">
            <div class="card card-default">
                <div class="pb-1 d-flex align-items-center border-bottom p-0 m-1 card-header">
                    <div class="ml-auto">
                        <a href="/member/messages" class="btn-sm btn-outline-primary"><i
                            class="feather icon-arrow-left"></i> {{ __('chat.form.links.back_inbox') }} </a>
                    </div>
                </div>
                <div class="col-md-12">
                    <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-important">
                        <div class="alert-body">
                            <span>This chat is not available.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Message from './Message';
import InfiniteLoading from "vue-infinite-loading";

export default {
    props: ['friendId', 'groupId'],
    components: {InfiniteLoading, Message},
    data() {
        return {
            friends: [],
            search: null,
            page: 1,
            startGroupChat: false,
            searchForGroup: null,
            selectedGroupMembers: [],
            currentGroupChat: null,
            groupSessions: [],
            infiniteId: +new Date(),
            infiniteId2: +new Date(),
            friend_id: this.friendId,
            chatAvailable: true,
        }
    },
    computed: {
        /*filteredGroupFriends() {
            if (this.searchForGroup === null) {
                return this.friends;
            }
            return this.friends.filter(friend => {
                return friend.first_name.toLowerCase().indexOf(this.searchForGroup.toLowerCase()) > -1
            })
        },
        filteredFriends() {
            if (this.search === null) {
                return this.friends;
            }
            return this.friends.filter(friend => {
                return friend.first_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            })
        },*/

    },
    methods: {
        infiniteHandler($state) {

            let vm = this;
            axios
                .get(this.getUrl())
                .then(response => {
                    if (response.data.data.length > 0) {
                        this.friends = [];
                        $.each(response.data.data, function (key, value) {
                            vm.friends.push(value);
                        });
                        /*this.friends.forEach(
                            friend => friend.session ? this.listenForEverySession(friend) : ""
                        );
                        if (this.friendId) {
                            let friend = self.friends.find(friend => friend.id == this.friendId);
                            self.openChat(friend);
                        }*/

                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },

        debounceInput: _.debounce(function (e) {

            if (e.target.value === '' || e.target.value === null) {
                this.getFriends();
            }
            else {
                this.friends = [];
                this.changeFilter();
            }


        }, 500),

        getUrl() {
            if (this.search !== null) {
                return '/member/search-contacts?search=' + this.search + '&page=' + this.page
            } else if (this.searchForGroup !== null) {
                return '/member/search-contacts?search=' + this.searchForGroup + '&page=' + this.page
            }
        },
        changeFilter() {
            this.page = 1;
            this.friends = [];
            this.infiniteId += 1;

        },
        close(friend) {
            friend.session.open = false;
        },
        closeGroupChat(groupSession) {
            this.currentGroupChat = null;
        },
        friendExistsInGroup(friend) {
            return this.selectedGroupMembers.some((item) => {
                return item.id === friend.id
            });
        },
        cancelGroup() {
            this.selectedGroupMembers = [];
            this.startGroupChat = false;

            this.getFriends();
        },
        addToGroup(friend) {
            if (!this.friendExistsInGroup(friend)) {
                this.selectedGroupMembers.push(friend);
            } else {
                this.selectedGroupMembers.splice(this.selectedGroupMembers.indexOf(friend), 1);
            }
            this.searchForGroup = null;
        },
        getGroupSessions() {
            let self = this;
            axios.get('/member/group-sessions').then(res => {
                this.groupSessions = res.data;
                if (this.groupSessions.length === 0) {
                    return;
                }

                this.groupSessions.forEach(
                    groupSession => this.listenForEveryGroupSession(groupSession)
                );

                if (this.groupId) {
                    let groupSession = this.groupSessions.find(groupSession => groupSession.id == self.groupId);
                    this.openGroupChat(groupSession);
                }
            });
        },
        getFriend() {
            let self = this;
            axios.post('/member/get-friend', {id: this.friend_id}).then(res => {
                this.friends = res.data.data
                this.friends.forEach(
                    friend => friend.session ? this.listenForEverySession(friend) : ""
                );
                if (this.friend_id) {
                    let friend = self.friends.find(friend => friend.id == this.friend_id);
                    self.openChat(friend);
                }
            });
        },
        getFriends() {
            let self = this;
            axios.post('/member/get-friends').then(res => {
                this.friends = res.data.data
                this.friends.forEach(
                    friend => friend.session ? this.listenForEverySession(friend) : ""
                );
                if (this.friend_id) {
                    let friend = self.friends.find(friend => friend.id == this.friend_id);
                    self.openChat(friend);
                }
            });
        },
        openChat(friend) {
            if (! friend || friend.id === window.App.user.id) {
                this.chatAvailable = false;
                return;
            }
            this.friend_id = friend.id;
            history.pushState(
                {},
                null,
                `/member/chat?friend=`+this.friend_id
            );
            if (friend.session) {
                this.friends.forEach(
                    friend => friend.session ? friend.session.open = false : ''
                );
                friend.session.open = true;
                friend.session.unreadCount = 0;
            } else {
                this.friends.forEach(friend => {
                    if (friend.session !== null)
                        friend.session.open = false;
                });

                //create session
                this.createSession(friend);
            }

        },
        openGroupChat(groupSession) {
            this.currentGroupChat = groupSession;
        },
        createGroupSession() {
            let self = this;
            // get member ids
            let members = this.selectedGroupMembers.map((friend) => {
                return friend.id;
            });

            axios
                .post('/member/group-session/create', {members: members}).then(res => {
                // self.getGroupSessions();
                self.groupSessions.push(res.data);
                self.cancelGroup();
                let newGroupSessionId = res.data.id;
                let groupSession = self.groupSessions.find(groupSession => newGroupSessionId == groupSession.id);
                console.log(groupSession);
                self.openGroupChat(groupSession);
            });
        },
        createSession(friend) {
            axios
                .post('/member/session/create', {friend_id: friend.id}).then(res => {
                (friend.session = res.data.data), (friend.session.open = true);
            });
        },
        listenForEverySession(friend) {
            Echo.private(`chat.${friend.session.id}`).listen(
                "PrivateChatEvent",
                e => (friend.session.open ? "" : friend.session.unreadCount++)
            );
        },
        listenForEveryGroupSession(groupSession) {
            // Echo.private(`group_chat`).listen(
            //     "GroupChatSessionCreated",
            //     (e) => {
            //         console.log(e);
            //     }
            // );
        }

    },
    mounted() {
        //
    },
    created() {
        if (this.friend_id) {
            this.getFriend();
        } else {
            this.getFriends();
        }

        this.getGroupSessions();

        // Echo.channel('chat').listen('SessionEvent', e => {
        //     let friend = this.friends.find(friend => friend.id == e.session_by);
        //     friend.session = e.session;
        //     this.listenForEverySession(friend);
        // });
        //
        // Echo.private(`group_chat`).listen(
        //     "GroupChatSessionCreated",
        //     (e) => {
        //         console.log(e);
        //     }
        // );
        //
        // Echo.join('chat')
        //     .here((users) => {
        //         this.friends.forEach(friend => {
        //             users.forEach(user => {
        //                 if (user.id == friend.id) {
        //                     friend.online = true
        //                 }
        //             })
        //
        //         })
        //     })
        //     .joining((user) => {
        //         this.friends.forEach(friend => user.id == friend.id ? friend.online = true : '')
        //     })
        //     .leaving((user) => {
        //         this.friends.forEach(friend => user.id == friend.id ? friend.online = false : '')
        //     })
    },
}
</script>

<style scoped>
.list-group-item {
    cursor: pointer;
}
</style>
