<template>
    <div class="d-flex justify-content-start align-items-center px-2">
<!--        <img class="round" :src="user.avatar !== null ? user.avatar.url : '/images/avatar.png'"-->
<!--                             alt="avatar"-->
<!--                             height="40"-->
<!--                             width="40"/>-->
        <div class="mt-05 mb-05 d-flex justify-content-between align-items-center font-weight-bold flex-grow-1">
            <a class="ml-05 m-0 text-left text-capitalize" :href="'/member/profiles/'+user.username" target="_blank">
                {{ user.first_name }} {{ user.last_name }}
            </a>
            <button type="button" class="btn btn-link text-capitalize" :id="user.id + 'popover-engagement'" data-toggle="popover" data-trigger="focus" :data-html="true"
                    :data-content="'Posts Made: ' + user.posts_count + '<br> Questions Asked: '+ user.questions_count + '<br> Likes Given: '+ user.likes_count + '<br> Comments Made: '+user.comments_count"
                    :data-title="user.first_name + ' '+ user.last_name + ' ' + this.__('globals.engagement')">
                <i class="feather icon-chevron-down" style="font-size: 16px;"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserEngagementCard",
    props: ['user'],
    data() {
        return {
        }
    },
    methods: {

    },
    mounted() {
        this.$nextTick(() => {
            $('#' + this.user.id + 'popover-engagement').popover();
        });
    }
}
</script>

<style scoped>

</style>
