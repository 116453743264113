<template>
    <div class="modal fade" ref="inviteModal" :id='"inviteModal"+this._uid' tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ __('question.invite.body.invite_users') }}</h5>
                    <button type="button" class="close" @click.prevent="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-05">
                        <label for="member_invite">{{ __('question.invite.body.select_members') }}</label>
                        <select :id='"member_invite"+this._uid' v-model="members" class="form-control" multiple="multiple" >
                        </select>
                        <label for="member_invite" class="help-block text-muted font-small-1">{{ __('question.invite.body.members_helper') }}</label>
                        <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.members != null">
                            <i class="feather icon-x-circle"></i> {{this.errors.members[0]}}
                        </div>
                    </div>
                    <div class="mb-05">
                        <label for="external_users">{{ __('question.invite.body.select_external_members') }}</label>
                        <select :id='"external_members"+this._uid' v-model="external_members" class="form-control" multiple="multiple" >
                        </select>
                        <label for="external_users" class="help-block text-muted font-small-1">{{ __('question.invite.body.external_members_helper') }}</label>
                        <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.external_members != null">
                            <i class="feather icon-x-circle"></i> {{this.errors.external_members[0]}}
                        </div>
                    </div>
                    <div class="">
                        <label for="comment_body" class="d-block">{{ __('question.invite.body.add_message') }}</label>
                        <div id="loader" class="card p-1">
                            <div class="spinner-border spinner-border-sm text-secondary m-auto" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div id="editor" class="card my-0 d-none">
                            <div>
                                <editor
                                    v-model="message"
                                    api-key="no-api-key"
                                    :init='{
                                        skin: "trybz-member",
                                        skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                        content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                        external_plugins: {
                                            customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                            loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                        },
                                        valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                        plugins: "link quickbars autolink lists customtags loadingbar autoresize",
                                        link_context_toolbar: true,
                                        quickbars_selection_toolbar: "bold italic link bullist",
                                        quickbars_image_toolbar: false,
                                        quickbars_insert_toolbar: false,
                                        link_assume_external_targets: "https",
                                        link_target_list: false,
                                        link_title: false,
                                        toolbar: false,
                                        branding: false,
                                        elementpath: false,
                                        menubar: false,
                                        max_height: 300,
                                        file_picker_types: "",
                                        content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                        relative_urls : false,
                                        remove_script_host : false,
                                        init_instance_callback: this.loader,
                                    }'
                                />
                            </div>
                        </div>
                        <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.message != null">
                            <i class="feather icon-x-circle"></i> {{this.errors.message[0]}}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="close">{{ __('globals.close') }}</button>
                    <button type="button" class="btn btn-primary" @click.prevevnt="inviteUsers">{{ __('globals.invite') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import TomSelect from 'tom-select';

    export default {
        name: 'InviteUsersModal',
        components: {
            'editor': Editor,
            TomSelect
        },
        props: ['module_id'],
        data(){
          return {
              members: [],
              external_members: [],
              message: '',
              contacts: [],
              errors: {
                'members': null,
                'external_members': null,
                'message': null,

            },
          }
        },
        mounted(){
            let vm = this;
            new TomSelect('#member_invite'+this._uid, {
                        valueField: 'id',
                        labelField: 'full_name',
                        searchField: ['full_name', 'organisation', 'username', 'position', 'email'],
                        plugins: {
                            'clear_button':{
                                'title':'Remove all selected options',
                            },
                            'remove_button':{
                                'title':'Remove this item',
                            }
                        },
                        closeAfterSelect: true,
                        persist: false,
                        onClear(){
                            this.close();
                        },
                        load: function(query, callback) {
                            var url = '/member/search-contacts?search=' + encodeURIComponent(query);
                            fetch(url)
                                .then(response => response.json())
                                .then(json => {
                                    callback(json.data)
                                }).catch(()=>{
                                    callback();
                                });

                        },
                        render: {
                            option: function(data, escape) {
                                return '<div>' + escape(data.full_name) + '</div>';
                            },
                            item: function(data, escape) {
                                return '<div>' + escape(data.full_name) + '</div>';
                            }
                        },
                    });
            new TomSelect('#external_members'+this._uid,
                    {
                        create: true,
                        plugins: {
                            'clear_button':{
                                'title':'Remove all selected options',
                            },
                            'remove_button':{
                                'title':'Remove this item',
                            }
                        },
                        persist: false,
                        createOnBlur: true,
                    });
            $('#inviteModal'+this._uid).modal('show');
            $('#inviteModal'+this._uid).on('shown.bs.modal', function() {
                $(document).off('focusin.modal');
            });

        },
        methods: {
             errorsReset(){
                this.errors = {
                    'members': null,
                    'external_members': null,
                    'message': null,
                };
            },
            inviteUsers(){
                this.errorsReset()
                axios
                      .post('/member/question/invite', {
                          module_id: this.module_id,
                          members: this.members,
                          external_members: this.external_members,
                          message: this.message
                      })
                      .then((response) => {
                            this.close();
                            flash(response.data);
                        })
                        .catch(error => {
                            this.errors = error.response.data.errors;
                        });
            },
            loader(){
                $('#editor').removeClass('d-none');
                $('#loader').addClass('d-none');
            },
            close() {
                this.$emit('close-modal');
                $('#inviteModal'+this._uid).modal('hide');
            },
        }
    }
</script>
