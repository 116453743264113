<template>
    <div class="p-05 col-xl-3 col-md-3 col-sm-6">
        <div class="card pb-2" >
            <div class="">
                <a :href="'/video/'+video.slug">
                    <img class="card-img-top img-fluid"
                         :src="video.cover !== null ? video.cover.url :  '/images/cover.jpg'" alt="Cover image">
                </a>
            </div>
            <div class="card-content">
                <div class="card-body pt-05 p-1">
                    <a :href="'/video/'+video.slug">
                        <h6 class="text-bold-700">{{ video.title }}</h6>
                    </a>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="badge badge-pill bg-gradient-secondary  text-bold-700" v-if="video.free_download === 'no'"><i class="feather icon-award"></i> {{ __('globals.premium_only') }}</div>

                        <div class="d-flex justify-content-between align-items-center w-100 ">
                            <div class="mt-05 mb-05" v-if="video.average_rating !== null">
                                <span :class="'rating-static rating-'+video.average_rating * 10">  </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="video.disabled === 1">
                      <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2 ">
                        <i class="feather icon-alert-circle"></i> {{ __('video.video_inactive') }}
                      </span>
                    </div>
                    <div class="card-content video-description font-small-4 mt-1" v-if="service_settings.description_display" v-html="description"></div>
                    <div class="card-bottom mt-1 p-05 d-flex font-small-3 align-items-center">
                        <div class="btn-sm btn-flat-secondary static video-view"><i class="feather icon-eye"></i> {{ video.views }}</div>
                        <div class="ml-auto">
                            <a :href="'/video/'+video.slug" class="btn-sm btn-flat-light" style="border:none;">
                                {{ __('globals.view') }} <i class="feather icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GuestVideoCard",
    props: ['video', 'service_settings'],
    data() {
        return {
            site_tax: window.App.site_tax,
            rating: null,
        }
    },
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.video.description.split(' ').length > 50 ? this.video.description.split(' ', 50).join(' ') + '...' : this.video.description) : '';
        }
    },
}
</script>
