<template>

<!--    James - NEW LANG MANAGER-->
    <div class="language-manager col-12">

        <div>
            <h3>Options</h3>
            <p>Display edit options for:</p>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="check-english" v-model="display_english" value="" :checked="display_english" @click="toggleEnglish"/>
                <label class="form-check-label" for="check-english">
                    English
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="check-chinese" v-model="display_chinese" value="" :checked="display_chinese" @click="toggleChinese"/>
                <label class="form-check-label" for="check-chinese">
                    Chinese
                </label>
            </div>
        </div>

        <hr />

        <div>
            <h3>Search</h3>
            <div class="input-group mb-3">
                <input v-model="search_string" type="text" class="form-control" placeholder="Search for the key or the displayed text" aria-label="Search for the key or the displayed text" aria-describedby="search-button">
                <button @click="searchLangItems()" class="btn btn-outline-primary" type="button" id="search-button">Search</button>
            </div>
            <p>Displaying {{ pagination_meta.total }} items</p>
        </div>

        <hr />

        <div class="mt-3">
            <h3>Language Editor</h3>
            <div class="container-fluid">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="col-sm-3">Key</th>
                        <th class="col-sm-9">Value</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="lang_item in displayed_lang_items">
                        <th >{{ lang_item.key }}</th>
                        <td>
                            <div v-if="display_english">
                                <small>English</small>
                                <div class="input-group mb-1">
                                    <input :id="'en-'+lang_item.key" type="text" class="form-control" placeholder="empty" aria-label="Enter your new language value" aria-describedby="search-button" :value="lang_item.en">
                                    <button @click="saveLangItem('en-'+lang_item.key)" class="btn btn-outline-primary" type="button" :id="'save-button-' + 'en-' +lang_item.key">Save</button>
                                    <button v-if="lang_item.is_override" @click="resetLangItem('en-'+lang_item.key)" class="btn btn-outline-danger" type="button" :id="'reset-button-' + 'en-' +lang_item.key">Reset</button>
                                </div>
                            </div>

                            <div v-if="display_chinese">
                                <small>Chinese</small>
                                <div class="input-group mb-1">
                                    <input :id="'cn-'+lang_item.key" type="text" class="form-control" placeholder="empty" aria-label="Enter your new language value" aria-describedby="search-button" :value="lang_item.cn">
                                    <button @click="saveLangItem('cn-'+lang_item.key)" class="btn btn-outline-primary" type="button" :id="'save-button-' + 'cn-' +lang_item.key">Save</button>
                                    <button v-if="lang_item.is_override" @click="resetLangItem('cn-'+lang_item.key)" class="btn btn-outline-danger" type="button" :id="'reset-button-' + 'cn-' +lang_item.key">Reset</button>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr v-if="displayed_lang_items.length === 0">
                        <th>-- No Language items --</th>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Pagination-->
            <nav aria-label="Next Page">
                <ul class="pagination">
                    <li v-for="link in pagination_meta.links" class="page-item" :class="[link.active ? 'active' : '', link.url ? '' : 'disabled']" v-on:click="paginationLinkClick(link.url)"><a class="page-link" href="#"><span v-html="link.label"></span></a></li>
                </ul>
            </nav>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'LanguageManager',
        data() {
            return {
                displayed_lang_items: [],
                pagination_meta:[],
                search_string: '',
                display_english: true,
                display_chinese: false,
            }
        },
        methods: {

            // Used to toggle the language visibility
            toggleEnglish(){
                this.display_english = !this.display_english;
                console.log('show/hide english');
            },
            toggleChinese(){
                this.display_chinese = !this.display_chinese;
                console.log('show/hide chinese');
            },

            // Pagination Links
            paginationLinkClick(url){
                axios.get(url)
                    .then(response => {
                            this.displayed_lang_items = response.data.data;
                            this.pagination_meta = response.data.meta;
                        }
                    );
            },

            // Handles the initial full index and searches
            searchLangItems(){
                axios.get("lang-editor/search?search="+this.search_string)
                .then(response => {
                        this.displayed_lang_items = response.data.data;
                        this.pagination_meta = response.data.meta;
                        // console.log(response.data);
                    }
                );
            },

            // Handles lang updates when the save button is clicked
            saveLangItem(element_id){
                const lang = element_id.substring(0, 2); // get first 2 chars (en / cn)
                const key = element_id.substring(3); // gets everything after the 1st 3 chars (strips lang key)
                let value = document.getElementById(element_id).value; // Value of the input

                // Find the record in displayed_lang_items
                function searchKey(nameKey, myArray){
                    for (let i=0; i < myArray.length; i++) {
                        if (myArray[i].key === nameKey) {
                            return myArray[i];
                        }
                    }
                }

                console.log('attempt to save value:"' + value + '"');


                axios.post("/admin/lang-editor/update", {
                    'lang': lang,
                    'key': key,
                    'value': value
                })
                .then(response => {

                    if(response.data.success === false){
                        console.log('Validation Failed', response.data);

                        this.saveLangButtonDisplay(element_id, 'error');

                        // Resets text to original text which is the new value
                        const lang_item = searchKey(key, this.displayed_lang_items);
                        document.getElementById(element_id).value = lang_item[lang]

                    } else {
                        console.log('Saved key', response.data);

                        // search again (with pagination & search_string) and replace all values
                        this.searchLangItems();
                        this.saveLangButtonDisplay(element_id, 'success');
                    }
                }
                ).catch(error => {
                    this.saveLangButtonDisplay(element_id, 'error');

                    // Resets text to original text which is the new value
                    const lang_item = searchKey(key, this.displayed_lang_items);
                    document.getElementById(element_id).value = lang_item[lang]
                })
            },

            resetLangItem(element_id){
                const lang = element_id.substring(0, 2); // get first 2 chars (en / cn)
                const key = element_id.substring(3); // gets everything after the 1st 3 chars (strips lang key)
                axios.post("/admin/lang-editor/reset-key?lang="+lang+"&key="+key)
                    .then(response => {
                            // search again and replace all values
                            this.searchLangItems();
                        }
                    ).catch(error => {
                        this.saveLangButtonDisplay(element_id, 'reset-error');
                })
            },

            // Handles the button styles on SAVE and ERROR
            saveLangButtonDisplay(element_id, status){

                let button = null;
                if(status === 'reset-error') {
                    button = document.getElementById('reset-button-' + element_id);
                } else {
                    button = document.getElementById('save-button-' + element_id);
                }

                if(status === 'success'){
                    button.innerText = 'SAVED';
                    button.className="btn btn-success disabled"
                    button.disabled = true;
                } else if (status === 'error'){
                    button.innerText = 'ERROR';
                    button.className="btn btn-danger disabled"
                    button.disabled = true;
                } else if (status === 'reset-error'){
                    button.innerText = 'ERROR';
                    button.className="btn btn-danger disabled"
                    button.disabled = true;
                } else if (status === 'reset-success'){
                    button.innerText = 'RESET';
                    button.className="btn btn-success disabled"
                    button.disabled = true;
                }

                // Return button back to normal
                setTimeout(() => {
                    if(status === 'reset-error'){
                        button.innerText = 'Reset';
                    } else {
                        button.innerText = 'Save';
                    }
                    button.className="btn btn-outline-primary"
                    button.disabled = false;
                }, 4000);
            },
        },
        mounted() {
            // Get initial full index
            this.searchLangItems();
        }
    }
</script>
<style scoped>

</style>
