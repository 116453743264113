<template>
    <div>

        <!-- Internal Name -->
        <div class="mb-2">
            <label for="exampleFormControlInput1" class="form-label">Internal Name</label>
            <input
                v-model="name"
                type="text"
                class="form-control has-validation"
                id="internal_name"
                placeholder="Welcome Email - Free members"
                required
            >
            <small v-if="errors && errors.name" id="error_name" class="form-text text-danger">* {{ errors.name[0] }}</small>
        </div>

        <!-- Enabled -->
        <div class="form-check mb-1">
            <input
                v-model="email_enabled"
                class="form-check-input"
                type="checkbox"
                value="true"
                id="check-enabled"
                required
            >
            <label class="form-check-label" for="flexCheckDefault">
                Enable this email
            </label>
        </div>
        <small v-if="errors && errors.enabled" id="error_name" class="form-text text-danger">* {{ errors.enabled[0] }}</small>

        <!-- Send at -->
        <div class="mb-2">
            <label for="exampleFormControlInput1" class="form-label">Send at</label>
            <input
                v-model="send_at"
                type="text"
                class="form-control has-validation"
                id="send_at"
                placeholder="Format: 2023-12-28 21:00:00"
                required
            >
            <small id="nameHelp" class="form-text text-muted">When to send this email. Format: 2023-12-28 21:00:00</small>
            <small v-if="errors && errors.send_at" id="error_name" class="form-text text-danger">* {{ errors.send_at[0] }}</small>
        </div>

        <!-- Filters -->
        <div class="mb-2">
            <label for="filter_option">Audience Filters</label>
            <select
                v-model="filter_id"
                class="custom-select"
                id="filter_option"
                required
            >
                <option v-for="filter in available_filter_options" :value="filter.id" :key="filter.id">{{ filter.name }}</option>
            </select>
            <small id="filtersHelp" class="form-text text-muted">Limit the audience based on this filter. No filter will send to all.</small>
            <small id="filtersHelp" class="form-text text-muted">Available tags [first_name], [last_name], [email]</small>
            <small v-if="errors && errors.filter_id" id="error_name" class="form-text text-danger">* {{ errors.filter_id[0] }}</small>
        </div>

        <!-- Email Template -->
        <div class="mb-2">
            <label for="filter_option">Email Template</label>
            <select
                v-model="email_template_id"
                class="custom-select"
                id="template_options"
                required
            >
                <option v-for="template in available_email_templates" :value="template.id" :key="template.id">{{ template.name }}</option>
            </select>
            <small id="filtersHelp" class="form-text text-muted">The email colour theme.</small>
            <small v-if="errors && errors.email_template_id" id="error_name" class="form-text text-danger">* {{ errors.email_template_id[0] }}</small>
        </div>

        <!-- Subject -->
        <div class="mb-2">
            <label for="email_subject" class="form-label">Email Subject</label>
            <input
                v-model="email_subject"
                type="text"
                class="form-control"
                id="email_subject"
                placeholder="Welcome to the community"
                required
            >
            <small v-if="errors && errors.subject" id="error_name" class="form-text text-danger">* {{ errors.subject[0] }}</small>
        </div>

        <!-- TinyMCE -->
        <div class="mb-05">
            <label for="email_content" class="required mt-1">Email Content</label>
            <div class="card my-0">
                <editor
                    v-model="email_content"
                    api-key="no-api-key"
                    :init='{
                        skin: "trybz-member",
                        skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                        content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                        external_plugins: {
                            customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                            loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                        },
                        // valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li],hr",
                        plugins: "table lists link image media code wordcount customtags loadingbar",
                        toolbar: "undo redo | styles fontsize hr | alignleft aligncenter alignright alignjustify | forecolor backcolor | bullist numlist | removeformat | link table | a11ycheck code",
                        toolbar_mode: "wrap",
                        image_title: true,
                        link_context_toolbar: true,
                        link_assume_external_targets: "https",
                        link_target_list: false,
                        link_title: false,
                        branding: false,
                        elementpath: false,
                        menubar: false,
                        file_picker_types: "",
                        height: 500,
                        content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                        relative_urls : false,
                        remove_script_host : false,
                    }'
                />
            </div>
            <small id="contentHelp" class="form-text text-muted">*If you are using modules and all modules have no content, the email wont send.</small>
            <small v-if="errors && errors.content" id="error_name" class="form-text text-danger">* {{ errors.content[0] }}</small>
        </div>

        <!-- Buttons -->
        <div class="d-flex flex-row-reverse">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-primary waves-effect waves-light" @click.prevent="saveAndSendTest">Save & Send a copy to yourself</button>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-primary waves-effect waves-light mr-1" @click.prevent="save">Save</button>
            </div>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-4">
            <small v-if="error_message" id="error_name" class="form-text text-danger">* {{ error_message }}</small>
        </div>

        <!-- Tag reference -->
      <!--
        <h2>Tag Reference</h2>
        <table class="table table-hover">
            <thead>
            <tr>
              <th scope="col">Tag</th>
              <th scope="col">Supported Options</th>
              <th scope="col">Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">[first_name]</th>
                <td>NA</td>
                <td>Recipient's first name</td>
            </tr>
            <tr>
                <th scope="row">[last_name]</th>
                <td>NA</td>
                <td>Recipient's first name</td>
            </tr>
            <tr>
                <th scope="row">[email]</th>
                <td>NA</td>
                <td colspan="2">Recipient's email address</td>
            </tr>
            </tbody>
        </table>
      -->
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {

    name: "EmailMarketingCreate",
    components: {
        'editor': Editor
    },

    props: ['emailMarketingId'],
    data() {
        return {
            available_filter_options: null,
            available_email_templates: null,
            name: null,
            email_template_id: null,
            filter_id: null,
            email_subject: null,
            email_content: null,
            email_enabled: true,
            send_at: null,
            errors: null,
            error_message: null,
        }
    },
    methods: {

        init(){
            axios.post('/admin/email-marketing-create-init', {
                update_id: this.emailMarketingId === 0 ? null : this.emailMarketingId,
            })
            .then(response => {
                this.available_filter_options = response.data.data.available_filter_options;
                this.available_email_templates = response.data.data.available_email_templates;

                // populate update fields
                if(response.data.data.current_record){
                    this.name              = response.data.data.current_record.name;
                    this.email_enabled     = response.data.data.current_record.enabled;
                    this.email_template_id = response.data.data.current_record.email_template_id;
                    this.filter_id         = response.data.data.current_record.filter_id;
                    this.email_subject     = response.data.data.current_record.subject;
                    this.email_content     = response.data.data.current_record.content;
                    this.send_at           = response.data.data.current_record.send_at;
                } else {
                    this.filter_id         = response.data.data.default_filter.id ?? null;
                    this.email_template_id = response.data.data.default_email_template.id ?? null;
                }
            });
        },

        save() {
            axios.post('/admin/email-marketing-create-save', {
                update_id: this.emailMarketingId === 0 ? null : this.emailMarketingId,
                name: this.name,
                enabled: this.email_enabled,
                email_template_id: this.email_template_id,
                filter_id: this.filter_id,
                subject: this.email_subject,
                content: this.email_content,
                send_at: this.send_at,
            })
            .then(response => {
                if (response.data.success === true) {

                    // update the ID so we can save next time without creating a new record
                    this.emailMarketingId = response.data.data.email_marketing.id

                    alert("Email Saved");
                    this.error_message = null;
                }
            }).catch((error) => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.error_message = error.response.data.message;
                }
            });
        },

        saveAndSendTest() {
          axios.post('/admin/email-marketing-save-and-send-test', {
              update_id: this.emailMarketingId === 0 ? null : this.emailMarketingId,
              name: this.name,
              enabled: this.email_enabled,
              email_template_id: this.email_template_id,
              filter_id: this.filter_id,
              subject: this.email_subject,
              content: this.email_content,
              send_at: this.send_at,
            })
            .then(response => {
                if (response.data.success === true) {

                    // update the id so we can save next time without creating a new record
                    this.emailMarketingId = response.data.data.email_marketing.id

                    alert("A test email has been sent to your logged in email address");
                    this.error_message = null;
                }
            }).catch((error) => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.error_message = error.response.data.message;
                }
            });
        },
    },
    mounted() {
        this.init();
    }
}
</script>
