<template>
    <div class="d-flex justify-content-start " style="margin-bottom: 5px;">
        <div class="p-05">
            <img v-if="data.post_as !== 'supplier'" class="round"
                 :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                 alt="avatar"
                 height="30"
                 width="30"/>

            <img v-if="data.post_as === 'supplier'" class="round"
                 :src="data.suppliers[0].supplier_avatar !== null ? data.suppliers[0].supplier_avatar.url :  '/images/avatar.png'"
                 alt="avatar"
                 height="30"
                 width="30"/>
        </div>

        <div class="p-0 pt-05 justify-content-start">
            <div class="user-name text-bold-600 text-dark">
                <div class="dropdown dropdown-user nav-item" v-if="data.post_as !== 'supplier'">
                    <a class="dropdown-toggle dropdown-user-link"
                       :href="postOwnerLink"
                       data-toggle="dropdown"
                       v-text="postOwnerName" @click="show_profile = true"></a>

                    <div v-if="data.post_as !== 'supplier'" class="dropdown-menu dropdown-menu-center">
                        <div v-if="show_profile">
                            <profile :data="data"></profile>
                        </div>
                    </div>
                    <span v-if="data.post_as !== 'supplier'" v-for="badge in data.module.owner.badges">
                        <img :src="badge.image" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip"
                             data-placement="bottom"
                             :title="badge.name">
                    </span>
                    <span v-if="data.module.owner.user_badge_id !== null">
                            <img :src="data.module.owner.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                                 data-toggle="tooltip"
                                 data-placement="bottom"
                                 :title="data.module.owner.user_badge_name">
                        </span>
                    <span class="font-small-2 text-dark" v-if="newsfeed_type === 'share'">{{ __('globals.shared') }} {{ data.type }}</span>
                </div>
                <div class="nav-item" v-else>
                    <a class="text-dark"
                       :href="postOwnerLink"
                       v-text="postOwnerName"></a>
                </div>
            </div>


            <div  style="margin-top: -5px;" v-if="data.post_as === 'supplier'">
                <span v-text="'@'+this.data.suppliers[0].slug" class="font-small-2 text-primary"></span>

            </div>
            <div  style="margin-top: -3px; line-height: 1;" class="mr-2"  v-else>

                <span v-text="data.module.owner.position"
                      class="font-small-2 text-light"></span> <span
                class="font-small-2 text-light"> {{ __('globals.at') }} </span> <span v-text="data.module.owner.organisation" class="font-small-2 text-light"></span>
            </div>

        </div>


    </div>
</template>

<script>
    import Profile from "./Profile";

    export default {
        name: "PostOwnerInfo",
        props: ['data', 'user_id', 'newsfeed_type'],
        components: {Profile},
        computed: {
            ago() {
                return moment(this.data.created_at).format('DD-MM-YY');
            },
            postOwnerName: function () {
                return (this.data.post_as === 'supplier')
                    ? this.data.suppliers[0].title
                    : this.data.module.owner.first_name+' '+this.data.module.owner.last_name

            },
            postOwnerLink: function () {
                return (this.data.post_as === 'supplier')
                    ? `/member/supplier/${this.data.suppliers[0].slug}`
                    : '#'
            },
        },
        methods: {
            report(){
                this.$parent.report();
            },
            destroy() {
                if (confirm('Are you sure?')) {
                    axios.delete('/member/post/' + this.data.module.id);
                    this.$emit('deleted', this.data.module.id);
                }
            },
        },
        data(){
            return {
                show_profile: false
            }
        }
    }
</script>
