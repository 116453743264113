<template>
    <div>
        <div class="mt-0 mb-05">
            <div class=" pt-1 pb-1 col-12" v-if="this.data.post_text !== null && this.data.post_text !== ''">
                <div class="breakAll image-resize" v-linkified v-html="this.data.post_text"></div>
            </div>
            <div class="col-12 p-0">
                <div class="text-center" v-if="this.data.video_link !== null">
                    <video-player :options="videoOptions"/>
                </div>
                <div class="video_container" v-else-if="this.data.video_external_url !== null">
                    <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="this.data.video_external_url"></iframe>
                </div>
                <div class="gallery-row" v-if="images.length > 1">
                    <div class="gallery-container">
                        <lightbox :items="images"></lightbox>
                    </div>
                </div>
                <div v-else-if="this.data.post_image.length === 1">
                    <div class="card-img-top text-center ">
                        <img class="mw-100" :src="this.data.post_image[0].url">
                    </div>
                </div>
                <div class="pr-05 pl-05 pb-05 mt-1" v-if="this.data.post_file.length > 0">
                    <a @click.prevent="downloadFile" v-for="(file, index) in this.data.post_file">
                        <button data-toggle="modal" data-target="#registerModal" class="download-button btn btn-primary waves-effect waves-light form-control">
                            <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import lightbox from "../../post/lightbox/lightbox.vue"
import "../../post/lightbox/lightbox.css"

export default {
    name: "GuestNewsfeedPost",
    components: {lightbox},
    props: ['data'],
    data() {
        return {
            images: [],
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: typeof this.data.video_link !== undefined ? this.data.video_link : '',
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    mixins: [],
    methods: {
        init(){
            let mime_type = ['image/gif', 'image/jpeg', 'image/png'];
            if (this.data.media !== undefined && this.data.media.length > 0) {
                for (let i = 0; i < this.data.media.length; i++) {
                    if (this.data.media[i] !== undefined) {
                        if (mime_type.includes(this.data.media[i].mime_type)) {
                            this.images.push(window.App.siteUrl+'/storage/' + this.data.media[i].id + '/' + this.data.media[i].file_name);
                        }
                    }
                }
            }
        },
        downloadFile() {
        },
    },
    beforeMount(){
        this.init();
    },

}
</script>

<style>

</style>
