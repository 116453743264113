<template>
    <div class="card p-2">
        <table class="table table-bordered">
            <thead class="thead-light">
                <tr class="bg-primary">
                    <th>
                        #
                    </th>
                    <th>
                        {{ __('globals.name') }}
                    </th>
                    <th>
                        {{ __('globals.slug') }}
                    </th>
                    <th class="w-25">

                    </th>
                </tr>
            </thead>
            <tbody>
                <transition name="row" v-for="(tag, index) in tags" :key="index">
                    <tr>
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td class="w-25">
                            <p :id="'title-field'+tag.id" class=" ">{{ tag.title }}</p>
                            <input type="text" :id="'title-input-box'+tag.id" class="form-control hidden" :value="tag.title" />
                        </td>
                        <td>
                            {{ tag.slug }}
                        </td>
                        <td class="text-center w-25">
                            <button class="btn-sm btn-outline-info" :id="'edit-button'+tag.id" @click.prevent="editTag(tag.id, index)">Edit</button>
                            <button class="btn-sm btn-outline-danger" :id="'delete-button'+tag.id" @click.prevent="destroyTag(tag.slug, index)">Delete</button>
                            <button class="btn-sm btn-outline-success hidden" :id="'save-button'+tag.id" @click.prevent="saveTag(tag.id, index)">Save</button>
                            <button class="btn-sm btn-outline-warning hidden" :id="'cancel-button'+tag.id" @click.prevent="cancelEdit(tag.id, index)">Cancel</button>
                        </td>

                    </tr>
                </transition>
            </tbody>
        </table>

        <div>
            <button class="btn btn-primary" @click.prevent="create_form = !create_form">
                <i v-if="create_form === false" class="feather icon-plus"></i>
                <i v-if="create_form === true" class="feather icon-minus"></i>
                Create New Tag
            </button>

            <transition name="fade">
                <div class="mt-2 card p-1 w-50" v-if="create_form">
                    <form>
                        <div class="form-group">
                            <label for="tag_name"><span class="text-capitalize">{{ tag }}</span> Tag Name</label>
                            <div class="d-flex">
                                <input id="tag_name" v-model="tag_name" class="form-control mr-1" type="text"/>
                                <button class="btn btn-success" type="submit" @click.prevent="createTag()">Create</button>
                            </div>
                            <div class="text-left text-danger help-block font-small-1" role="alert" v-if="error_create">
                                <i class="feather icon-x-circle"></i> {{error_create}}
                            </div>
                        </div>
                    </form>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TypeComponent',
        props: ['tag'],
        data() {
            return {
                editing: false,
                tags: [],
                create_form: false,
                submitting: false,
                tag_name: null,
                error_create: null,
                field_id: []
            }
        },
        methods: {
            async getTags(){
                await axios
                    .get('/admin/'+this.tag+'-tags')
                    .then(response => {
                        console.log(response.data);
                        this.tags = response.data;
                    })
            },

            async updateTag(id, title, index){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;

                return await axios
                    .put('/admin/'+this.tag+'-tags', {
                        id: id,
                        title: title,
                    })
                    .then((response)=>{
                        let data = {
                            'message': 'Tag Updated',
                            'level': 'success'
                        }
                        flash(data);
                        this.tags[index].title = title;
                        this.submitting = false;
                    })
                    .catch(error => {
                        let data = {
                            'message': error.response.data.errors.title[0],
                            'level': 'danger'
                        };
                        flash(data);
                        this.submitting = false;
                    });

            },

            async createTag(){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                this.error_create = null

                return await axios
                    .post('/admin/'+this.tag+'-tags', {
                        title: this.tag_name,
                    })
                    .then((response)=>{
                        this.tags.push(response.data);
                        this.tag_name = null;

                        let data = {
                            'message': 'Tag Added',
                            'level': 'success'
                        }
                        flash(data);

                        this.submitting = false;
                    })
                    .catch(error => {
                        console.log(error.response.data.errors.title)
                        this.error_create = error.response.data.errors.title[0];
                        this.submitting = false;
                    });
            },

            async destroyTag(slug, index){
                if(confirm(this.__('globals.delete_tag')
            )){
                    if(this.submitting){
                        return false;
                    }
                    this.submitting = true;

                    await axios
                        .delete('/admin/'+this.tag+'-tags/'+slug)
                        .then((response)=>{

                            this.tags.splice(index, 1);

                            let data = {
                                'message': 'Tag Deleted',
                                'level': 'danger'
                            }
                            flash(data);
                            this.submitting = false;
                        })
                        .catch(error => {
                            console.log(error)
                            this.submitting = false;
                        });
                }
            },

            editTag(id){
                document.getElementById('title-field'+id).classList.add('hidden');
                document.getElementById('title-input-box'+id).classList.remove('hidden');
                this.hideButtons(id);
            },

            cancelEdit(id, index){
                document.getElementById("title-input-box"+id).value = this.tags[index].title;
                document.getElementById('title-input-box'+id).classList.add('hidden');
                document.getElementById('title-field'+id).classList.remove('hidden');
                this.showButtons(id);
            },

            saveTag(id, index){

                if(document.getElementById('title-input-box'+id).value === this.tags[index].title){

                } else if(confirm(this.__('globals.update_tag'))){
                    let title = document.getElementById('title-input-box'+id).value;
                    this.updateTag(id, title, index);
                }

                this.cancelEdit(id, index)
                this.showButtons(id);
            },

            hideButtons(id){
                document.getElementById('edit-button'+id).classList.add('hidden');
                document.getElementById('delete-button'+id).classList.add('hidden');
                document.getElementById('save-button'+id).classList.remove('hidden');
                document.getElementById('cancel-button'+id).classList.remove('hidden');
            },
            showButtons(id){
                document.getElementById('edit-button'+id).classList.remove('hidden');
                document.getElementById('delete-button'+id).classList.remove('hidden');
                document.getElementById('save-button'+id).classList.add('hidden');
                document.getElementById('cancel-button'+id).classList.add('hidden');
            }
        },
        beforeMount() {
            this.getTags()
        }
    }
</script>


<style>

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.row-enter-active, .row-leave-active {
    transition: opacity 1s
}
.row-enter, .row-leave-to  {
    opacity: 0
}

.table tbody tr:hover {
    background: #f5f5f5;
}

</style>
