<template>
    <div>
        <search :categories="this.category" :tags="this.tags" :filters="this.search_filters" v-on:searchType="changeFilter($event)"></search>
        <div v-if="this.user.user_categories.length === 0 && this.search_filters.default === 'user'">
            <div class="alert alert-danger" role="alert">
                {{ __('search.paragraph.no_interests') }} <a :href="'/member/profiles/'+this.user.username+'/edit'">{{ __('globals.click_here') }}</a>
            </div>
        </div>
        <div class="row match-height pl-05 pr-05">
            <div class="col-md-3 col-sm-4 col-6 p-05" v-for="(supplier, index) in suppliers" :key="infiniteId+index">
                <suppliers-infinite-scroll-card :supplier="supplier" :service_settings="service_settings" />
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results">{{ __('globals.no') }} {{ this.segment }} {{ __('globals.found') }}.</div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import moment from "moment-timezone";
import Search from '../Search'

export default {
    name: "SupplierInfiniteScroll",
    components: {InfiniteLoading, Search},
    props: ['category', 'filters', 'tag', 'segment', 'service_settings'],
    data() {
        return {
            suppliers: [],
            page: 1,
            user: window.App.user,
            search: this.filters.default,
            infiniteId: +new Date(),
            categoryId: '',
            keyword: '',
            search_filters: this.filters,
            tags: this.tag,
            request: this.segment,
            type: '',
        }
    },
    methods: {
        ago(date) {
            return moment(date).fromNow();
        },
        infiniteHandler($state) {
            let vm = this;
            axios
                .get(this.getUrl())
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        $.each(response.data.data, function (key, value) {
                            vm.suppliers.push(value);
                        });
                        this.matchHeight();
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getUrl(){
            if (this.search === "my") {
                return "/member/supplier/my?page=" + this.page + '&segment=' + this.request;
            }

            if (this.search === "category"){
                return "/member/supplier/search/"+this.search+"?categoryId="+this.categoryId+"&page=" + this.page+'&segment='+this.request;
            }

            if (this.search === "tag"){
                return "/member/supplier/search/"+this.search+"?tagId="+this.tagId+"&page=" + this.page+'&segment='+this.request;
            }

            if (this.search === "type") {
                return "/member/supplier/search/" + this.search + "?type=" + this.type + "&page=" + this.page;
            }

            if (this.search === "keyword"){
                return "/member/supplier/search/"+this.search+"?keyword="+this.keyword+"&page=" + this.page+'&segment='+this.request;
            }

            return "/member/supplier/search/"+this.search+"?page=" + this.page+'&segment='+this.request;
        },
        changeFilter(args){
            if (args.type === "category")
            {
                this.categoryId = args.categoryId
            }

            if (args.type === "tag")
            {
                this.tagId = args.tagId
            }

            if (args.type === "type") {
                this.type = args.typeId
            }

            if (args.type === "keyword")
            {
                this.keyword = args.keyword
            }

            this.search = args.type;
            this.page = 1;
            this.suppliers = [];
            this.infiniteId += 1;
        },
        matchHeight() {
          let iteration = 0;
          let intervalId = setInterval(function () {
            if (iteration < 10) {
              $(".row.match-height").each(function () {
                $(this).find(".card").not(".card .card").matchHeight(); // Not .card .card prevents collapsible cards from taking height
              });
              iteration++;
            } else {
              clearInterval(intervalId);
            }
          }, 100);
        }
    },
    mounted() {
        if (this.user.user_categories.length === 0 && this.search_filters.default !== 'my'){
            this.search = "all";
        }

        if (this.search_filters.default === 'my') {
            this.search = "my";
        }
    }
}
</script>
