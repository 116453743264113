<template>
    <div>
        <div class="d-flex mb-1">
            <div class="font-large-1  d-inline-block pr-1 text-bold-700">
                {{ !show_product_plans ? "Select Options" : __('plan.headers.member.upgrade_subscription') }}
            </div>
            <div class="ml-auto">
                <button v-if="(!show_product_plans && !show_company_sub_plans) || (show_company_sub_plans && this.selected_product_option.plans.length === 1)" class="btn btn-outline-primary btn-default waves-effect waves-light" @click.prevent="showProductOptions()">
                    <i class="feather icon-arrow-left"></i> Show All Plan Options
                </button>
                <button v-if="show_company_sub_plans && this.selected_product_option.plans.length > 1" class="btn btn-outline-primary btn-default waves-effect waves-light" @click.prevent="showCompanyOptions()">
                    <i class="feather icon-arrow-left"></i> Show All Company Plan Options
                </button>
            </div>
        </div>

        <div v-if="!is_loading">
            <p class="alert alert-primary" role="alert" v-if="user_part_of_company">
                {{ __('plan.user_part_of_company') }}
            </p>
            <p class="alert alert-danger" role="alert" v-else-if="user_current_plan === null">
                {{ __('plan.user_on_no_plan') }}
            </p>
            <p class="alert alert-primary" role="alert" v-else-if="user_current_plan.price === 0">
                {{ __('plan.form.paragraph.on_free_plan') }}
            </p>
            <p v-else>
                {{ __('plan.form.paragraph.expand_subscription') }}
            </p>
        </div>

        <div v-if="is_loading" class="text-center">
            <span class="spinner-border spinner-border-md text-primary"></span>
        </div>

        <template v-else>

            <div class="row">
                <div class="col-12">
                    <div class="row card-match-height">

                        <template v-if="show_product_plans">

                            <div class="col-12 col-md-4" v-for="(plan_product, index) in plan_products" :key="index">

                                <div class="card p-05" v-if="plan_product.plans.length > 1 || (plan_product.type === 'company' && plan_product.plans[0].sub_plans.length > 1)">
                                    <div class="card-container">
                                        <div class="d-inline-flex p-2">
                                            <div>
                                                  <div style="">
                                                      <h3 >{{ plan_product.title }}</h3>
                                                  </div>

                                                  <div v-if="plan_product.type === 'company' && plan_product.plans.length === 1">
                                                    <p><i class="feather icon-users"></i> {{ plan_product.plans[0].num_individuals === 0 ? "Unlimited" : plan_product.plans[0].num_individuals }} Users</p>
                                                  </div>

                                                  <div class="font-weight-bolder font-size-medium" v-if="plan_product.lowest_price !== null && plan_product.lowest_price !== 0">
                                                    From {{ plan_product.lowest_price | currency(site_tax)}} / {{ plan_product.lowest_price_interval }}
                                                  </div>

                                                  <div class="mt-05" v-html="plan_product.description"></div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="card-body" v-if="plan_product.plans[0].plan_features.length > 0">
                                        <plan-features :plan_features="plan_product.plans[0].plan_features"></plan-features>
                                    </div>

                                    <div class="card-footer border-0 bg-white mt-auto">

                                        <button :class="'btn mt-1 btn-block mt-auto '+ (checkProductPlan(plan_product) ? 'btn-success' : 'btn-primary')" @click.prevent="showPlanOptions(index)">
                                          {{ checkProductPlan(plan_product) ? 'Show Current Plan Options' : 'Select this Plan' }}
                                        </button>

                                    </div>
                                </div>

                                <plan-card :plan="plan_product.plans[0]" :user_subscription="user_subscription" :user_current_plan="user_current_plan" :company_owner="company_owner" :show_features="true" :user_part_of_company="user_part_of_company" v-else-if="plan_product.plans.length === 1"></plan-card>
                            </div>

                        </template>

                        <template v-else-if="show_individuals_plans">
                            <div class="col-12 col-md-4" v-for="(plan, index) in this.selected_product_option.plans" :key="index">
                                <plan-card :plan="plan" :user_subscription="user_subscription" :user_current_plan="user_current_plan" :company_owner="company_owner" :show_features="true" :user_part_of_company="user_part_of_company"></plan-card>
                            </div>
                        </template>

                        <template v-else-if="show_company_plans">

                             <div class="col-12 col-md-4" v-for="(plan, index) in this.selected_product_option.plans" :key="index">

                                 <div class="card p-05" v-if="plan.sub_plans.length > 1">
                                     <div class="card-container">
                                         <div class="d-inline-flex p-2">
                                             <div>
                                                 <div style="">
                                                     <h3>{{ plan.title }}</h3>
                                                 </div>

                                                 <div v-if="plan.type === 'company'">
                                                    <p><i class="feather icon-users"></i> {{ plan.num_individuals === 0 ? "Unlimited" : plan.num_individuals }} Users</p>
                                                 </div>

                                                 <div class="font-weight-bolder font-size-medium" v-if="plan.lowest_company_price !== null && plan.lowest_company_price !== 0">
                                                    From {{ plan.lowest_company_price | currency(site_tax)}} / {{ plan.lowest_company_price_interval }}
                                                 </div>

                                                 <div class="mt-05" v-html="plan.description"></div>
                                            </div>

                                        </div>
                                     </div>

                                    <div class="card-body" v-if="plan.sub_plans[0].plan_features.length > 0">
                                        <plan-features :plan_features="plan.sub_plans[0].plan_features"></plan-features>
                                    </div>

                                    <div class="card-footer border-0 bg-white mt-auto">

                                        <button :class="'btn mt-1 btn-block mt-auto ' + (checkCurrentPlan(plan) ? 'btn-success' : 'btn-primary')" @click.prevent="showCompanyPlans(index)">
                                            {{ checkCurrentPlan(plan) ? 'Show Current Plan Options': 'Show Further Options'}}
                                        </button>

                                    </div>
                                 </div>

                                 <template v-else>
                                     <plan-card :plan="plan.sub_plans[0]" :user_subscription="user_subscription" :user_current_plan="user_current_plan" :company_owner="company_owner" :show_features="true" :user_part_of_company="user_part_of_company"></plan-card>
                                 </template>

                             </div>
                        </template>

                        <template v-if="show_company_sub_plans">
                             <div class="col-12 col-md-4" v-for="(plan, index) in this.selected_company_sub_plan.sub_plans" :key="index">
                                <plan-card :plan="plan" :user_subscription="user_subscription" :user_current_plan="user_current_plan" :company_owner="company_owner" :show_features="true" :user_part_of_company="user_part_of_company"></plan-card>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </template>


        <div class="d-flex m-1 align-items-center">
            <p class="font-small-3 mr-1 mb-0">
                Please note: All prices are listed in {{ site_tax.currency }} and are inclusive of {{ site_tax.name }}.
            </p>
            <div class="ml-auto">
                <img src="/images/stripe.png" alt="stripe image" />
            </div>
        </div>
    </div>
</template>

<script>


    import PlanCard from "./PlanCard.vue";
    import PlanFeatures from "./PlanFeatures.vue";

    export default {
        props: ['event'],
        name: "ManageSubscription",
        components: {PlanCard, PlanFeatures},
        data() {
             return {
                 user: window.App.user,
                 site_tax: window.App.site_tax,
                 is_loading: true,
                 user_current_plan: null,
                 user_subscription: null,
                 company_owner: false,
                 user_part_of_company: false,
                 plan_products: [],
                 selected_product_option: [],
                 selected_company_sub_plan: [],
                 show_product_plans: true,
                 show_individuals_plans: false,
                 show_company_plans: false,
                 show_company_sub_plans: false,
                 show_company_options: false,
                 has_company_options: false,
             }
        },
        mounted() {
            // Get all the plan products
            axios
                .get(`/member/plan-products`)
                .then((response) => {
                    this.is_loading = false;
                    this.plan_products = response.data.plan_products;
                    this.user_subscription = response.data.user_subscription;
                    this.user_current_plan = response.data.user_current_plan;
                    this.company_owner = response.data.company_owner;
                    this.user_part_of_company =  response.data.user_part_of_company;
                    this.matchCardHeights();
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
        methods: {
            showProductOptions() {
                this.show_product_plans = true;
                this.show_company_plans = false;
                this.show_individuals_plans = false;
                this.show_company_sub_plans = false;
                this.matchCardHeights();
            },
            showCompanyOptions(){
              this.show_company_plans = true;
              this.show_company_sub_plans = false;
              this.matchCardHeights();
            },
            showPlanOptions(index){
                this.selected_product_option = this.plan_products[index];
                this.show_product_plans = false;
                if(this.selected_product_option.type === 'company'){
                    if(this.selected_product_option.plans.length === 1){
                        this.showCompanyPlans(0);
                    } else {
                        this.show_company_plans = true;
                    }
                } else {
                    this.show_individuals_plans = true;
                }
                this.matchCardHeights();
            },
            showCompanyPlans(index){
                this.show_company_plans = false;
                this.show_company_sub_plans = true;
                this.selected_company_sub_plan = this.selected_product_option.plans[index];
                this.matchCardHeights();
            },
            checkProductPlan(plan_product){
                if(this.user_part_of_company){
                    return false;
                } else if (this.user_current_plan !== null && this.user_current_plan.plan_product_id === plan_product.id){
                    return true;
                } else {
                    return false;
                }
            },
            checkCurrentPlan(plan){
                if(this.user_part_of_company){
                    return false;
                }
                let index = plan.sub_plans.findIndex(plan => plan.stripe_plan_id === this.user.plan_id);
                if(index >= 0){
                    return true;
                } else {
                    return false;
                }
            },
            matchCardHeights() {
                setTimeout(() => {
                    const rows = document.querySelectorAll('.row.card-match-height');

                    rows.forEach((row) => {
                      const cards = row.querySelectorAll('.card:not(.card .card)');
                      let maxHeight = 0;
                      cards.forEach((card) => {
                        const cardHeight = card.offsetHeight;
                        maxHeight = Math.max(maxHeight, cardHeight);
                      });

                      cards.forEach((card) => {
                        card.style.height = `${maxHeight}px`;
                      });
                    });
                  }, 100);
            },
        }
    }

</script>


<style scoped>

</style>
