import moment from "moment-timezone";

export default {
    name: "Webinar",
    props: ['zoom', 'subscription'],
    data() {
        return {
            member: this.subscription,
        }
    },
    methods: {
        editPost(e) {
            e.stopPropagation();
            this.editing = true;
        },
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/zoom/' + this.data.module.zoom_id);
                if(window.location.href.includes('/member/zoom')){
                    document.location.href='/member';
                }
                this.$parent.$emit('deleted', this.data.module.id);
            }
        },
        report() {
            $('#report_id').val(this.data.module.id);
            $('#report_type').val('App\\Models\\Zoom');
            $('#post_title').text(this.data.module.title);
            $('#reportModal').modal('toggle');
        },
    },
    computed: {
        showDateTime() {
            return moment(this.data.module.meeting_at).format('LLL');
        },
    }
}
