<template>
    <div>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <h4>Scheduled Marketing Emails</h4>
            <p>When you want to send a unique email to a group of users once.</p>
          </div>
          <div class="col-6">
            <div class="w-100 mb-1">
              <div class="d-flex align-items-center">
                <div class="ml-auto mb-05">
                  <a class="btn btn-success ml-05" href="/admin/email-marketing-create"><i class="feather icon-plus"></i>Create Marketing Email</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        <div class="panel-body card p-1">

            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Enabled</th>
                    <th scope="col">Send At</th>
                    <th scope="col">Filter</th>
                    <th scope="col">Last Sent</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="email_marketing in available_email_marketings">

                        <th scope="row">{{ email_marketing.name }}</th>
                        <td>{{ email_marketing.enabled ? 'Yes' : 'No' }}</td>
                        <td>{{ email_marketing.send_at }}</td>
                        <td>{{ email_marketing.filter.name }}</td>
                        <td>{{ !email_marketing.last_executed ? 'Never' : email_marketing.last_executed }}</td>
                        <td>
                            <a :href="'/admin/email-marketing-edit/' + email_marketing.id">
                                <button type="button" class="btn-sm btn-outline-warning">EDIT</button>
                            </a>
                            <button type="button" class="btn-sm btn-outline-danger" @click.prevent="deleteMarketingEmail(email_marketing.id)">DELETE</button>
                        </td>
                    </tr>

                    <tr v-if="!available_email_marketings || available_email_marketings.length === 0">
                        <th>-- No Marketing Emails --</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>



<script>
export default {
    name: "EmailMarketingIndex",

    data() {
        return {
            available_email_marketings: [],
        }
    },
    methods: {

        init(){
            axios.post('/admin/email-marketing-index-init')
            .then(response => {
                this.available_email_marketings = response.data.data.available_email_marketings;
            });
        },

        deleteMarketingEmail(id){
            if(confirm('Are you sure you want to delete this marketing email?')){
                axios.post('/admin/email-marketing-index-delete', {
                    'email_marketing_id': id,
                })
                .then(response => {
                    this.init();
                });
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>
