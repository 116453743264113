<template>
    <div>
        <div class="d-flex justify-content-between border-bottom">
            <div class="flex-grow-1">
                <newsfeed-owner-info
                        :user_data="data.module.owner"
                        :supplier_data="data.supplier"
                        :shared="false"
                        :type="data.type"
                        :time="data.published_at">
                </newsfeed-owner-info>
            </div>
            <div class="btn-group mt-05 mr-05">
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                            <div v-if="canUpdate || isAdmin === true">
                                <a class="dropdown-item" :href="'/member/product/'+data.module.slug+'/edit'">
                                    <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                </a>
                                <a class="dropdown-item" href="#" @click="destroy">
                                    <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                </a>
                            </div>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                            </a>
                        </div>
                    </div>
            </div>
        </div>

            <div class="card-content">
                <img :class="'img-fluid product-image-'+data.id" v-if="data.module.product_image.length > 0"
                     :src="data.module.product_image[0].url">
                <div class=" mt-1 ml-1 mr-1 mb-05">
                    <span class="h3"><a :href="'/member/product/'+data.module.slug"
                                        v-text="data.module.title" class="text-dark"></a></span>
                </div>
                <div class="mt-05 ml-1 mr-1 mb-05" v-if="rating !== null && rating !== 0">
                    <span :class="'rating-static rating-'+rating * 10">  </span>
                </div>

                <div class="pt-05 pl-1 pr-1 pb-05">
                        <div v-if="collapse_body" class="breakAll image-resize postarea" v-linkified v-html="data.module.product_description"></div>
                        <div v-else :class="class_body+' image-resize postarea'" ref="textBox" v-linkified v-html="data.module.product_description"></div>

                        <div v-if="enable_read_more">
                             <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                            </button>
                            <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>
                </div>

                <div class="d-flex p-05 justify-content-between align-items-center border-top" v-if="user.confirmed">
                    <div class="font-small-2 ml-05" v-if="data.module.price !== null">
                        {{ __('globals.form.paragraph.your_price') }} {{ data.module.price | currency(site_tax) }}
                    </div>
                    <span v-else-if="this.lowest_plan_price !== null" class="font-small-2 pl-05 pr-05">
                        {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('plan.upgrade_plan_message') }}
                    </span>
                    <div class="ml-auto">
                        <a :href="'/member/product/'+data.module.slug"
                           class="btn btn-sm btn-flat-primary text-bold-700 ">
                            {{ __('globals.view_product') }} <i class="feather icon-chevron-right"></i></a>
                        <a :href="'/member/product/'+data.module.slug" v-if="data.module.external_link !== null">
                            <button class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('globals.more_info') }} <i class="feather icon-chevron-right"></i>
                            </button>
                        </a>
                        <a :href="'/member/product/'+data.module.slug+'/add_to_cart'" v-if="data.module.external_link === null">
                            <button class="btn btn-sm btn-primary waves-effect waves-light"
                                    v-if="data.module.price !== null">
                                {{ __('globals.buy_now') }} <i class="fa fa-tag"></i>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import Share from '../../../../mixins/newsfeed/Share'
import Product from '../../../../mixins/newsfeed/Product'

export default {
    name: "NewsfeedProduct",
    props: ['data'],
    data() {
        return {
        }
    },
    mounted() {
        this.average_rating();
        this.planPrices(this.data.module.product_plan_prices);
        if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
    methods: {
    },
    mixins: [
        Share, Product
    ],
}
</script>
