<template>
    <div>
        <div class="card-content">
            <img :class="'img-fluid product-image'" v-if="this.data.product_image.length > 0"
                     :src="this.data.product_image[0].url">
            <div class=" mt-1 ml-1 mr-1 mb-05">
                    <span class="h3"><a :href="'/product/'+this.data.slug"
                                        v-text="this.data.title" class="text-dark"></a></span>
            </div>

            <div class="mt-05 ml-1 mr-1 mb-05" v-if="average_rating !== null">
                <span :class="'rating-static rating-'+average_rating * 10">  </span>
            </div>

            <div class="ml-1 mr-1 mt-1 mb-0 pb-1 breakAll postarea image-resize" v-linkified
                     v-html="this.data.product_description"></div>
            <div><a :href="'/product/'+this.data.slug" class="font-small-3 ml-1">{{ __('globals.read_more') }} <i
                    class="feather icon-chevron-right"></i></a></div>
            <div class="m-05 border-bottom text-right"></div>
            <div class="d-flex pl-05 pr-05 pt-0 pb-05 justify-content-between align-items-center ">
                <div v-if="this.data.product_price !== 0 && this.data.product_price !== null">
                    {{ this.data.product_price | currency(site_tax) }}
                </div>
                <div class="ml-auto">
                    <a :href="'/product/'+this.data.slug"
                           class="btn btn-sm btn-flat-primary text-bold-700 ">View
                            {{ __('globals.view_product') }} <i class="feather icon-chevron-right"></i></a>
                    <a :href="'/product/'+this.data.slug" v-if="this.data.external_link !== null">
                            <button class="btn btn-sm btn-primary waves-effect waves-light">
                                {{ __('globals.more_info') }} <i class="feather icon-chevron-right"></i>
                            </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "GuestNewsfeedProduct",
    props: ['data'],
    data() {
        return {
            prices_array: [],
            average_rating: null,
            site_tax: window.App.site_tax
        }
    },
    methods: {
        init() {
            let ratings_array = [];
            if(this.data.ratings.length > 0){
                this.data.ratings.forEach(rating => {
                    ratings_array.push(rating.rating);
                })
            }
            let sum = ratings_array.reduce((a, b) => a + b, 0);
            this.average_rating = Math.ceil((sum / ratings_array.length) || 0);
        },
    },
    beforeMount() {
        this.init();
    }
}
</script>
