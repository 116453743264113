<template>
    <div class="d-flex justify-content-between w-100 owner-info">
        <div class="d-flex justify-content-start">
            <div class="p-05">
                <div v-if="supplier_data != null && supplier_data.pivot.post_as === 'supplier'">
                     <img class="round" :src="supplier_data.supplier_avatar !== null ? supplier_data.supplier_avatar.url :  '/images/avatar.png'"
                     alt="avatar"
                     height="40"
                     width="40"/>
                </div>
                <div v-else>
                    <img class="round" :src="(user_data !== null && user_data.avatar !== null) ? user_data.avatar.url : '/images/avatar.png'"
                         alt="avatar"
                         height="40"
                         width="40"/>
                </div>
            </div>
            <div class="mt-05 mb-05">
                <div class="user-name text-bold-600 text-dark">
                    <div class="dropdown dropdown-user nav-item" v-if="supplier_data !== null && supplier_data.pivot.post_as === 'supplier'">
                        <a class="text-dark" :href="postOwnerLink" v-text="postOwnerName">
                        </a>
                    </div>
                    <div class="nav-item" v-else>
                        <div class="nav-item">
                             <a class="dropdown-toggle dropdown-user-link " :href="postOwnerLink"
                               data-toggle="dropdown" v-text="postOwnerName" @click="show_profile = true">
                             </a>

                            <div v-if="show_profile" class="dropdown-menu dropdown-menu-center">
                                <profile :data="user_data"></profile>
                            </div>
                            <span v-if="user_data.roles[0].title === 'Admin'">
                                <img src="/images/badges/admin.png" alt="user role badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" title="Administrator">
                            </span>
                            <span v-for="badge in user_data.badges">
                                    <img :src="badge.image" alt="user custom badge" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="badge.name">
                            </span>
                            <span v-if="user_data.user_badge_id !== null && user_data.user_badge_image != null">
                                <img :src="user_data.user_badge_image.url" alt="user plan badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="user_data.user_badge_name">
                            </span>

                            <span class="mt-05 mb-05" v-if="profile_data !== undefined && profile_data !== null">
                                <span class="font-weight-normal">{{ __('newsfeed.posted_to') }}</span> <a class="text-bold-600 text-dark" :href="'/member/profiles/'+profile_data.username" target="_blank">{{profile_data.name}}</a>
                                <span v-if="profile_data.roles[0].title === 'Admin'">
                                    <img src="/images/badges/admin.png" alt="user role badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" title="Administrator">
                                </span>
                                <span v-for="badge in profile_data.badges">
                                    <img :src="badge.image" alt="user custom badge" style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="badge.name">
                                </span>
                                <span v-if="profile_data.user_badge_id !== null && profile_data.user_badge_image != null">
                                    <img :src="profile_data.user_badge_image.url" alt="user plan badge"  style="margin-top: -3px;  height: 15px;" data-toggle="tooltip" data-placement="bottom" :title="profile_data.user_badge_name">
                                </span>
                            </span>

                            <span class="text-bold-400" v-if="shared === true" >{{ __('globals.shared') }} {{ type === 'event' ? 'an '+ type : 'a '+ type }}</span>
                        </div>
                    </div>
                </div>

                <div v-if="supplier_data != null && supplier_data.pivot.post_as === 'supplier'"  style="margin-top: -5px;">
                    <span v-text="'@'+supplier_data.slug" class="font-small-2 text-light"></span>
                </div>

                <div v-else style="margin-top: -3px; line-height: 1;" class="mr-2">
                    <span v-text="user_data.position" class="font-small-2 text-light"></span>
                    <span class="font-small-2 text-light"> {{ __('globals.at') }} </span> <span v-text="user_data.organisation" class="font-small-2 text-light"></span>
                </div>
            </div>
        </div>

        <div :class="this.shared===true ? 'btn-group mt-05 ml-05':'btn-group mt-05 ml-05'" style="min-width: 45px">
            <span v-if="time !== null" v-text="ago" class="font-small-1 text-light" style="margin-top: 4px;"></span>
        </div>
    </div>
</template>

<script>

import moment from 'moment-timezone';
import Profile from './Profile';

export default {
    name: "NewsfeedOwnerInfo",
    props: ['user_data', 'profile_data', 'supplier_data', 'shared', 'type', 'time'],
    components: {
        Profile
    },
    data(){
      return {
          show_profile: false,
      }
    },
    computed: {
        postOwnerName: function () {
            return (this.supplier_data != null && this.supplier_data.pivot.post_as === 'supplier')
                ? this.supplier_data.title
                : this.user_data.full_name

        },
        postOwnerLink: function () {
            return (this.supplier_data != null && this.supplier_data.pivot.post_as === 'supplier')
                ? `/member/supplier/${this.supplier_data.slug}`
                : '#'
        },
        ago() {
            return moment(this.time).format('DD-MM-YY');
        },
    },
}
</script>
