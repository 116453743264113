<template>
    <div>
        <div class="d-flex mb-1">
            <button v-if="(!show_product_plans && !show_company_sub_plans) || (show_company_sub_plans && this.selected_product_option.plans.length === 1)" class="btn btn-primary btn-default waves-effect waves-light" @click.prevent="showProductOptions()">
                <i class="feather icon-arrow-left"></i> Show All Plan Options
            </button>
            <button v-if="show_company_sub_plans && this.selected_product_option.plans.length > 1" class="btn btn-primary btn-default waves-effect waves-light" @click.prevent="showCompanyOptions()">
                <i class="feather icon-arrow-left"></i> Show All Company Plan Options
            </button>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="row card-match-height">

                    <template v-if="show_product_plans">

                        <div class="col-12 col-md-6" v-for="(plan_product, index) in plan_products" :key="index">

                            <div class="card p-05" v-if="plan_product.plans.length > 1 || (plan_product.type === 'company' && plan_product.plans[0].sub_plans.length > 1)">
                                <div class="card-container">
                                    <div class="d-inline-flex p-2">
                                        <div>
                                              <div style="">
                                                  <h3 >{{ plan_product.title }}</h3>
                                              </div>

                                              <div v-if="plan_product.type === 'company' && plan_product.plans.length === 1">
                                                <p><i class="feather icon-users"></i> {{ plan_product.plans[0].num_individuals === 0 ? "Unlimited" : plan_product.plans[0].num_individuals }} Users</p>
                                              </div>

                                              <div class="font-weight-bolder font-size-medium" v-if="plan_product.lowest_price !== null && plan_product.lowest_price !== 0">
                                                From {{ plan_product.lowest_price | currency(site_tax)}} / {{ plan_product.lowest_price_interval }}
                                              </div>

                                              <div class="mt-05" v-html="plan_product.description"></div>
                                        </div>

                                    </div>
                                </div>

                                <div class="card-body" v-if="plan_product.plans[0].plan_features.length > 0">
                                    <plan-features :plan_features="plan_product.plans[0].plan_features"></plan-features>
                                </div>

                                <div class="card-footer border-0 bg-white mt-auto">

                                    <button :class="'btn mt-1 btn-block mt-auto '+ (selected_product_id === plan_product.id ? 'btn-success' : 'btn-primary')" @click.prevent="showPlanOptions(index)">
                                      {{ selected_product_id === plan_product.id ? 'Show Selected Plan Options' : 'Show Plan Options' }}
                                    </button>

                                </div>
                            </div>

                            <register-plan-card :plan="plan_product.plans[0]" :selected_plan_id="selected_plan_id" v-else-if="plan_product.plans.length === 1" @plan-select="selectPlan"></register-plan-card>
                        </div>

                    </template>

                    <template v-else-if="show_individuals_plans">
                        <div class="col-12 col-md-6" v-for="(plan, index) in this.selected_product_option.plans" :key="index">
                            <register-plan-card :plan="plan" :selected_plan_id="selected_plan_id" @plan-select="selectPlan"></register-plan-card>
                        </div>
                    </template>

                    <template v-else-if="show_company_plans">

                         <div class="col-12 col-md-6" v-for="(plan, index) in this.selected_product_option.plans" :key="index">

                             <div class="card p-05" v-if="plan.sub_plans.length > 1">
                                 <div class="card-container">
                                     <div class="d-inline-flex p-2">
                                         <div>
                                             <div style="">
                                                 <h3>{{ plan.title }}</h3>
                                             </div>

                                             <div v-if="plan.type === 'company'">
                                                <p><i class="feather icon-users"></i> {{ plan.num_individuals === 0 ? "Unlimited" : plan.num_individuals }} Users</p>
                                             </div>

                                             <div class="font-weight-bolder font-size-medium" v-if="plan.lowest_company_price !== null && plan.lowest_company_price !== 0">
                                                From {{ plan.lowest_company_price | currency(site_tax)}} / {{ plan.lowest_company_price_interval }}
                                             </div>

                                             <div class="mt-05" v-html="plan.description"></div>
                                        </div>

                                    </div>
                                 </div>

                                <div class="card-body" v-if="plan.sub_plans[0].plan_features.length > 0">
                                    <plan-features :plan_features="plan.sub_plans[0].plan_features"></plan-features>
                                </div>

                                <div class="card-footer border-0 bg-white mt-auto">

                                    <button :class="'btn mt-1 btn-block mt-auto ' + (checkCurrentPlan(plan) ? 'btn-success' : 'btn-primary')" @click.prevent="showCompanyPlans(index)">
                                        {{ checkCurrentPlan(plan) ? 'Show Selected Plan Options': 'Show Further Options'}}
                                    </button>

                                </div>
                             </div>

                             <template v-else>
                                 <register-plan-card :plan="plan.sub_plans[0]" :selected_plan_id="selected_plan_id" @plan-select="selectPlan"></register-plan-card>
                             </template>

                         </div>
                    </template>

                    <template v-if="show_company_sub_plans">
                         <div class="col-12 col-md-6" v-for="(plan, index) in this.selected_company_sub_plan.sub_plans" :key="index">
                            <register-plan-card :plan="plan" :selected_plan_id="selected_plan_id" @plan-select="selectPlan"></register-plan-card>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import RegisterPlanCard from "./RegisterPlanCard.vue";
    import PlanFeatures from "../../plans/PlanFeatures.vue";

    export default {
        props: ['plan_products'],
        name: "RegisterPlans",
        components: {RegisterPlanCard, PlanFeatures},
        data() {
             return {
                 site_tax: window.App.site_tax,
                 selected_product_option: [],
                 selected_company_sub_plan: [],
                 show_product_plans: true,
                 show_individuals_plans: false,
                 show_company_plans: false,
                 show_company_sub_plans: false,
                 selected_product_id: null,
                 selected_plan_id: null,
             }
        },
        mounted() {
            this.matchCardHeights();
        },
        methods: {
            selectPlan(event){
                if(event.type === 'select'){
                    this.selected_product_id = event.plan_product_id;
                    this.selected_plan_id = event.plan_stripe_id;
                } else {
                    this.selected_product_id = null;
                    this.selected_plan_id = null;
                }
                this.$emit('plan-select',{value: event.plan_id});
            },
            showProductOptions() {
                this.show_product_plans = true;
                this.show_company_plans = false;
                this.show_individuals_plans = false;
                this.show_company_sub_plans = false;
                this.matchCardHeights();
            },
            showCompanyOptions(){
              this.show_company_plans = true;
              this.show_company_sub_plans = false;
              this.matchCardHeights();
            },
            showPlanOptions(index){
                this.selected_product_option = this.plan_products[index];
                this.show_product_plans = false;
                if(this.selected_product_option.type === 'company'){
                    if(this.selected_product_option.plans.length === 1){
                        this.showCompanyPlans(0);
                    } else {
                        this.show_company_plans = true;
                    }
                } else {
                    this.show_individuals_plans = true;
                }
                this.matchCardHeights();
            },
            showCompanyPlans(index){
                this.show_company_plans = false;
                this.show_company_sub_plans = true;
                this.selected_company_sub_plan = this.selected_product_option.plans[index];
                this.matchCardHeights();
            },
            checkCurrentPlan(plan){
                let index = plan.sub_plans.findIndex(plan => plan.stripe_plan_id === this.selected_plan_id);
                if(index >= 0){
                    return true;
                } else {
                    return false;
                }
            },
            matchCardHeights() {
                setTimeout(() => {
                    const rows = document.querySelectorAll('.row.card-match-height');

                    rows.forEach((row) => {
                      const cards = row.querySelectorAll('.card:not(.card .card)');
                      let maxHeight = 0;
                      cards.forEach((card) => {
                        const cardHeight = card.offsetHeight;
                        maxHeight = Math.max(maxHeight, cardHeight);
                      });

                      cards.forEach((card) => {
                        card.style.height = `${maxHeight}px`;
                      });
                    });
                  }, 100);
            },
        }
    }

</script>


<style scoped>

</style>
