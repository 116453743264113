<template>
    <div>
        <div class="card">
            <a :href="previous_page"
               class="btn btn-outline-white text-white btn-default waves-effect waves-light position-absolute m-05">
                <i class="feather icon-arrow-left"></i> {{ __('event.form.buttons.back') }}
            </a>
            <div class="shadow">

                <img class="card-img-top img-fluid"
                     :src="event.event_image == null ? '/images/cover.jpg' : event.event_image.url"
                     style="object-fit: cover;">

            </div>
            <div class="card-content">

                <div class="card-body pt-1 pb-0">
                    <div class="mb-2">
                        <div class="">
                            <div class="d-flex align-items-center justify-content-between">
                                <h1 class="font-large-1" v-text="event.title"></h1>

                                <div>
                                    <span v-if="event.access === 'Offline Event'" class="mb-05 badge badge-pill bg-gradient-secondary d-block text-bold-700 font-small-2 ">
                                        <i class="feather icon-user-plus"></i> {{ __('event.cards.offline') }}
                                    </span>
                                    <span v-if="event.access === 'Online Event'" class="mb-05 badge badge-pill badge-success d-block text-bold-700 font-small-2 ">
                                        <i class="feather icon-globe"></i> {{ __('event.cards.online') }}
                                    </span>
                                    <span v-if="event.access === 'Hybrid Event'" class="mb-05 badge badge-pill badge-warning d-block text-bold-700 font-small-2 ">
                                        <i class="feather icon-calendar"></i> {{ __('event.cards.hybrid') }}
                                    </span>
                                    <span v-if="this.sold_out === true" class="mb-05 badge badge-pill badge-danger d-block font-small-2 text-bold-700">
                                        <i class="feather icon-alert-circle"></i> {{ __('event.sold_out') }}
                                    </span>
                                    <span v-if="event.event_privacy === 1" class="mb-05 badge badge-pill  d-block font-small-2 text-bold-700">
                                        <i class="feather icon-award"></i>  {{ __('event.cards.premium') }}
                                    </span>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between">
                                <span class="primary font-medium-3"><i class="feather icon-calendar"></i>
                                    <span v-text="event.event_start_end_date"></span>
                                </span>
                                <div class="text-right" v-if="event.tickets.length > 0 && this.can_buy_tickets === true && this.sold_out !== true && this.price !== null">
                                    <span>
                                        <strong>{{ __('globals.from') }} {{ this.price | currency(site_tax) }}</strong>
                                    </span>
                                    <a :href="'/member/event/'+event.slug+'/tickets'" class="btn btn-primary waves-effect waves-light">
                                        {{ __('event.form.buttons.buy_tickets') }} <i class="fa fa-tag"></i>
                                    </a>
                                </div>
                            </div>

                            <div id="single-normal">
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-outline-success btn-default waves-effect waves-light mobile-hide dropdown-toggle"
                                        type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        {{ __('event.form.buttons.add_to_calendar')}}
                                    </button>
                                    <div class="dropdown-menu ml-05 pb-0" aria-labelledby="dropdownMenuButton">
                                        <div class="m-05 pb-05 text-center border-bottom">
                                            <a :href="getGoogleCalendarLink" style="color: inherit"><i
                                                class="fa fa-google text-danger" aria-hidden="true"></i> {{ __('event.form.links.gmail') }}</a>
                                        </div>
                                        <div class="m-05 pb-05 text-center border-bottom">
                                            <a :href="getIcalCalendarLink" style="color: inherit"><i
                                                class="fa fa-apple text-dark" aria-hidden="true"></i> {{ __('event.form.links.icalendar') }}</a>
                                        </div>
                                        <div class="m-05 text-center ">
                                            <a :href="getOutlookCalendarLink" style="color: inherit"><i
                                                class="fa fa-calendar text-primary" aria-hidden="true"></i> {{ __('event.form.links.outlook') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p class="pt-1" v-if="event.event_summary != null">
                                <span id="tinymce" class="breakAll" v-html="event.event_summary"></span>
                            </p>
                        </div>
                    </div>

                    <div class="row match-height">
                        <div class="col-12 col-md-8">
                            <h5 class="border-bottom pb-05 mb-1"><i class="feather icon-book-open"></i> {{ __('event.headers.admin.event_details') }}
                            </h5>
                            <div class="d-flex justify-content-start">
                                <p><span class="user-name text-bold-600"><i
                                    class="fa fa-map-marker"></i> {{ __('event.fields.text.location') }}: </span><br>{{ event.location }}
                                </p>
                                <p class="ml-1"><span class="user-name text-bold-600"><i class="fa fa-clock"></i> {{ __('event.fields.text.venue') }}: </span><br>
                                    {{ event.venue }}
                                </p>
                            </div>
                            <p>
                                <span class="user-name text-bold-600"><i class="fas fa-file-alt"></i> {{ __('globals.description')}}: </span><br>
                                <span id="tinymce" class="breakAll image-resize" v-html="event.event_description"></span>
                            </p>

                            <div class="p-1" v-if="event.disabled === 1">
                                <div class="card bg-outline-gray">
                                    <div class="card-header">
                                        <h4 class="card-title danger">
                                            {{ __('globals.event_inactive_title') }}
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        {{ __('globals.event_inactive_content') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <h5 class="border-bottom pb-05 mb-1"><i class="feather icon-user-plus"></i> {{ __('event.headers.admin.event_organiser') }}
                            </h5>
                            <p><span class="user-name text-bold-600"><i
                                class="fa fa-building"></i> {{ __('event.fields.text.organisation_name') }}: </span><br>{{ event.organisation_name }}</p>
                            <p><span class="user-name text-bold-600"><i class="fa fa-user"></i> {{ __('globals.name')}}: </span><br>
                                {{ event.contact_name }}
                            </p>
                            <p v-if="event.contact_phone">
                                <span class="user-name text-bold-600"><i class="fa fa-phone"></i> {{ __('globals.phone') }}: </span><br>
                                {{ event.contact_phone }}
                            </p>
                            <p v-if="event.contact_email">
                                <span class="user-name text-bold-600"><i class="fa fa-envelope"></i> {{ __('globals.email') }}: </span><br>
                                {{ event.contact_email }}
                            </p>
                            <div class="text-right mb-1">
                                <button class="btn btn-sm btn-outline-danger" @click.prevent="report">{{ __('event.form.buttons.report') }}</button>
                            </div>
                            <div class="" v-if="event.group !== null">
                                <a :href="'/member/group/'+event.group.slug">
                                    <div class="card text-white">
                                        <img class="card-img img-fluid" style="height: 3rem;"
                                             :src="event.group.group_cover == null ? '/images/cover.jpg' : event.group.group_cover.url">
                                        <div
                                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong> {{ event.group.title }} </p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('event.form.buttons.view_group') }} <i
                                                class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="" v-if="event.supplier !== null">
                                <a :href="'/member/'+event.supplier.type+'/'+event.supplier.slug">
                                    <div class="card text-white">
                                        <img class="card-img img-fluid" style="height: 3rem;"
                                             :src="event.supplier.supplier_cover_image == null ? '/images/cover.jpg' : event.supplier.supplier_cover_image.url">
                                        <div
                                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{ event.supplier.title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                                class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="event.tickets.length > 0 ">
                        <div class="d-flex align-items-center pt-05 pb-05 border-top" v-if="this.can_buy_tickets === true">
                            <div class="text-bold-700">
                                <span v-if="this.price !== null">
                                    <span><strong>{{ __('globals.from') }} {{ this.price | currency(site_tax) }}</strong></span>
                                </span>
                            </div>
                            <div class="ml-auto" v-if="this.sold_out !== true && this.price !== null">
                                <a :href="'/member/event/'+event.slug+'/tickets'"
                                       class="btn btn-primary waves-effect waves-light">{{ __('event.form.buttons.buy_tickets') }} <i
                                        class="fa fa-tag"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex align-items-center pt-05 pb-05 border-top" v-else-if="this.can_buy_tickets === false && this.lowest_plan_name !== null && this.lowest_plan_price !== null">
                            <strong>
                                <span>
                                    <a href="/member/plans" style="text-decoration: underline" class="text-dark">{{ __('globals.upgrade') }}</a>
                                    {{ __('globals.to_get_it_for') }} {{ this.lowest_plan_price | currency(site_tax) }} {{ __('globals.on_a') }}
                                    {{ this.lowest_plan_name }} {{ __('globals.plan') }}
                                </span>
                            </strong>
                        </div>
                    </div>

                    <div class="d-flex align-items-center border-top" v-else-if="event.contact_link != null && (event.event_privacy === 0 || event.event_privacy === 1)">
                        <div class="ml-auto pt-1 pb-1 white">
                            <a :href="event.contact_link" target="_blank"
                               class="btn btn-sm btn-primary waves-effect waves-light">{{ __('event.form.buttons.view_event_site') }} <i
                                class="feather icon-chevron-right"></i></a>
                        </div>
                    </div>
                </div>


            </div>
            <div class="d-flex border-top justify-content-between">
                <div class="font-small-3 text-bold-700">
                    <likes :id="this.event.slug" :likes-count="this.event.likesCount" :is-liked="this.event.isLiked" :liked-by-users="this.event.userLikes" type="event"></likes>
                </div>
                <div class="font-small-3 text-bold-700">
                        <a class="p-05 d-block" @click.prevent="commentsShow = !commentsShow"><i
                           class="fa fa-comment-o"></i>
                            <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                            <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                        </a>
                </div>
                <div class="font-small-3 text-bold-700">
                    <social-share :id="this.event.id" :social="this.event.socialShare" @shareModal="triggerShareModal"></social-share>
                </div>
            </div>
        </div>

        <div v-if="commentsShow">
            <comments :newsfeed_uuid="this.event.newsfeed_uuid" :id="this.event.slug" type="event" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <share-modal :original_newsfeed_id="this.event.newsfeed_id" :module="event" type="event" @closeModal="share_newsfeed = false" ></share-modal>
        </div>
    </div>
</template>


<script>
import moment from "moment-timezone";
import linkify from 'vue-linkify'
import Share from '../../mixins/newsfeed/Share'
import * as Add2Calendar from "add2calendar"
import Tickets from "../../mixins/tickets";

import ShareModal from "../newsfeed/components/ShareModal.vue";
import Likes from "../newsfeed/components/Likes.vue";
import Comments from "../newsfeed/components/Comments.vue";
import Event from "../../mixins/newsfeed/Event";


Vue.directive('linkified', linkify)

export default {
    name: "Event",
    mixins: [
        Share, Event, Tickets
    ],
    components: {Likes, Comments, ShareModal},
    props: ['event', 'previous_page'],
    data() {
        return {
            commentsShow: false,
            commentsCount: this.event.commentsCount,
            dateRange: '',
            site: window.App.siteUrl,
            share_newsfeed: false,
        }
    },
    methods: {
        updateCommentsCount(count){
            this.commentsCount = count;
        },
        triggerShareModal(){
            this.share_newsfeed = !this.share_newsfeed;
        },

        changeLocationFormat() {
            if (this.event.venue.includes('/')) {
                let location = this.event.venue.split('/')
                return location[1] + ', ' + location[0]
            }

            return this.event.venue
        },
        addToCalendarStartDate() {

            return moment(this.event.event_start_date).format("MMMM") + ' ' + moment(this.event.event_start_date).format("DD") + ', ' +
                moment(this.event.event_start_date).format("YYYY") + ' ' + moment(this.event.event_start_time, 'HH:mm:ss').format('HH:mm');

        },
        addToCalendarEndDate() {
            return moment(this.event.event_end_date).format("MMMM") + ' ' + moment(this.event.event_end_date).format("DD") + ', ' +
                moment(this.event.event_end_date).format("YYYY") + ' ' + moment(this.event.event_end_time, 'HH:mm:ss').format('HH:mm')
        },
        getCalendarData() {
            let regex = /(<([^>]+)>)/ig;
            return {
                title: this.event.title,
                start: this.addToCalendarStartDate(),
                end: this.addToCalendarEndDate(),
                timezone: this.event.venue,
                location: this.changeLocationFormat(),
                description: this.event.event_description.replace(regex, ""),
                isAllDay: false,
            };
        }
    },
    computed: {
        getGoogleCalendarLink() {
            var singleEventArgs = this.getCalendarData();
            let singleEvent = new Add2Calendar(singleEventArgs);

            return singleEvent.getGoogleUrl();

        },
        getIcalCalendarLink() {
            var singleEventArgs = this.getCalendarData();
            let singleEvent = new Add2Calendar(singleEventArgs);

            return singleEvent.getICalUrl();

        },
        getOutlookCalendarLink() {
            var singleEventArgs = this.getCalendarData();

            let singleEvent = new Add2Calendar(singleEventArgs);
            console.log(singleEvent);
            return singleEvent.getOutlookUrl();

        },

        canUpdate() {
            return this.event.owner.id === this.user_id || this.checkServiceAdmin()
        },
        ago() {
            return moment(this.event.published_at).format('DD-MM-YY');
        },
    },
    beforeMount() {
        this.ticketsPrice(this.event.tickets);
    }
}
</script>


