<template>
    <div class="card" style="padding-bottom:30px;min-height: 235px;">
        <div class="cover-img-lg  shadow">
            <a :href="'/member/product/'+product.slug">
                <img class="card-img-top cover-img-lg img-fluid" :src="product.product_image.length > 0 && product.product_image[0] !== null ? product.product_image[0].url :  '/images/cover.jpg'" style="object-fit: cover;" alt="Cover image">
            </a>
        </div>

        <div class="card-content p-1 pt-05">
            <a :href="'/member/product/'+product.slug">
                <h6 class="text-bold-700">{{ product.title}}</h6>
            </a>

            <span :class="'rating-static rating-'+product.average_rating * 10"
                  v-if="product.average_rating !== null"></span>

            <div v-if="product.disabled === 1">
                <span class="badge badge-pill bg-danger mb-05 text-bold-700 font-small-2 ">
                    <i class="feather icon-alert-circle"></i> {{ __('product.product_inactive') }}</span>
            </div>
            <div class="card-content product-description font-small-4 mt-1" v-if="service_settings.description_display" v-html="description"></div>
            <div class="card-bottom d-flex align-items-center"
                 v-if="confirmed_user === true">
                <div class="font-small-2 text-light">
                    <span v-if="product.price > 0">{{ product.price | currency(site_tax)}}</span>
                </div>
                <div class="ml-auto">
                    <a :href="'/member/product/'+product.slug+'/add_to_cart'">
                        <button class="btn-sm btn-primary waves-effect waves-light" style="border:none;">{{ __('globals.buy') }}
                            <i class="fa fa-tag"></i></button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card.Product",
    props: ['product', 'service_settings'],
    data() {
        return {
            user: window.App.user,
            site_tax: window.App.site_tax,
            confirmed_user: window.App.user.confirmed,
        }
    },
    methods: {
        formatRating(average_rating) {
            if (average_rating === null) return null;

            if (!Number.isInteger(average_rating)) {
                let rate = parseInt(average_rating);
                average_rating = (rate + 0.5);
            }

            return average_rating * 10;
        },
        formatPrice(number) {
            return new Intl.NumberFormat('en-AU', {
                style: 'currency',
                currency: this.site_tax.currency_code
            }).format(number)
        },
    },
    computed: {
        description: function() {
            return this.service_settings.description_display ? (this.product.product_description.split(' ').length > 50 ? this.product.product_description.split(' ', 50).join(' ') + '...' : this.product.product_description) : '';
        }
    }
}
</script>
