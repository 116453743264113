<template>
    <div>
        <div class="card p-2 mt-1">
            <div class="d-flex border-bottom mb-1">
                <div>
                    <h4 class="">
                        <i class="feather icon-users"></i>
                        {{ __('event.tickets.fields.text.attendees') }}
                    </h4>
                </div>
                <div class="ml-auto" id="attendee-list-toolbar" v-if="attendees.length > 0">
                    <span class="mr-05">
                        <a :href="'/member/event/'+event.slug+'/attendees/export'" :title="__('event.tickets.form.buttons.download_attendees_report')">
                            <i class="feather icon-download"></i>
                        </a>
                    </span>
                </div>
            </div>

             <div>
                 <h5 class="">{{ __('event.form.buttons.attendees_total') }}: {{ attendees.length }}</h5>
             </div>

            <ul class="list-group">
                <li class="list-group-item">
                    <div class="d-flex">
                        <div class="width-20-per pr-1"><strong>{{ __('event.tickets.table.attendees.name') }}</strong></div>
                        <div class="width-20-per pr-1"><strong>{{ __('event.tickets.table.attendees.email') }}</strong></div>
                        <div class="width-20-per pr-1"><strong>{{ __('globals.organisation') }}</strong></div>
                        <div class="width-20-per pr-1"><strong>{{ __('globals.position') }}</strong></div>
                        <div class="width-20-per"></div>
                    </div>
                </li>
                <li class="list-group-item" v-for="(attendee, index) in attendees" :key="index">
                    <div class="d-flex align-items-center">
                        <div class="width-20-per pr-1">{{ attendee.name }}</div>
                        <div class="text-left width-20-per pr-1">{{ attendee.email }}</div>
                        <div class="text-left width-20-per pr-1">{{ attendee.organisation }}</div>
                        <div class="text-left width-20-per pr-1">{{ attendee.position }}</div>
                        <div class="text-center width-20-per">
                            <button class="btn-sm btn-outline-info" @click.prevent="editAttendee(index)">
                                {{ __('globals.edit') }}
                            </button>
                            <button class="btn-sm btn-outline-danger" @click.prevent="deleteAttendee(attendee.id, index)">
                                {{ __('globals.delete') }}
                            </button>
                        </div>
                    </div>
                </li>
                <li class="list-group-item" v-if="attendees.length === 0">{{ __('event.tickets.table.attendees.no_records') }}</li>
            </ul>

            <button class="col-2 btn btn-success mt-05 mb-05" type="button" @click.prevent="add_attendee = !add_attendee" v-if="Object.keys(tickets).length > 0">
                <i class="feather icon-plus" v-if="!add_attendee"></i>
                <i class="feather icon-minus" v-else></i>
                {{ __('event.tickets.table.attendees.add_new') }}
            </button>
        </div>

        <div v-if="add_attendee">
            <attendee-modal :event_slug="event.slug" :attendee="null" :tickets="this.tickets" @closeModal="closeAttendeeModal" @newAttendee="appendAttendee"></attendee-modal>
        </div>

        <div v-if="edit_attendee">
            <attendee-modal :event_slug="event.slug" :attendee="this.attendees[this.edit_attendee_index]" :tickets="null" @closeModal="closeAttendeeModal" @updateAttendee="updateAttendee"></attendee-modal>
        </div>
    </div>
</template>

<script>
    import AttendeeModal from "./AttendeeModal";

    export default {
        props: ['event'],
        name: "EventAttendee",
        components: {AttendeeModal},
        data() {
            return {
                add_attendee: false,
                edit_attendee: false,
                attendees: [],
                tickets: [],
                submitting: false,
                name: '',
                phone: '',
                email: '',
                organisation: '',
                position: '',
                suburb: '',
                ticket: '',
                dietary: '',
                errors: '',
                edit_attendee_index: '',
            }
        },
        mounted() {
            axios
                .get(`/member/event/${this.event.slug}/attendee`)
                .then((response) => {
                    this.attendees = response.data.data.attendees;
                    this.tickets = response.data.data.tickets;
                });
        },
        methods: {
            addAttendee(){
                this.add_attendee = true;
            },
            editAttendee(index){
                this.edit_attendee_index = index;
                this.edit_attendee = true;
            },
            deleteAttendee(attendee_id, index){
                if (window.confirm(this.__('attendee.delete_message'))) {
                    axios   .delete(`/member/event/${this.event.slug}/attendee/${attendee_id}`)
                            .then((response) => {
                                this.attendees.splice(index, 1);
                                let data = {
                                'message': this.__('attendee.deleted'),
                                'level': 'danger'
                                }
                                flash(data);
                            })
                  } else {}
            },
            appendAttendee(attendee){
                this.attendees.push(attendee)
            },
            updateAttendee(updated_attendee){
                this.attendees[this.edit_attendee_index] = updated_attendee;
            },
            closeAttendeeModal(){
                this.add_attendee = false;
                this.edit_attendee = false;
            },
        }
    }
</script>

<style>

</style>
