<template>
    <div>
            <div class="bg-white text-white pt-2 pb-2 pl-1 pr-1 mb-0 d-flex justify-content-between">
                <div>
                    <span class="font-medium-3 text-light">{{ __('job.new_position_open') }}:</span><br>
                    <a :href="'/member/job/'+this.data.slug" style="color: #000000"><span
                        class="h3"
                        v-text="this.data.title"></span></a>
                    <div>
                        <i class="feather icon-briefcase text bold-700" style="color: #3399fe !important"></i> <span
                        style="color: #3399fe !important" class="text-bold-700"
                        v-text="this.data.employment_type"></span>
                    </div>
                </div>
                <div v-if="this.data.organisation_logo !== null">
                    <img :src="this.data.organisation_logo.url"
                         width="75">
                </div>
            </div>

            <div class="card-content">
                <div class=" m-05 pt-1 pl-1 pr-1 border-top image-resize"
                     v-html="this.data.position_description"></div>
                <div class="d-flex ml-1 mr-1 mt-05 mb-0 pb-05 align-items-center justify-content-start">
                    <span v-if="this.data.hide_salary === 0" class="font-small-3 text-bold-700" style=" color: black">New Job Opening: {{ this.data.custom_salary }}</span>
                    <span v-if=" this.data.isPositionClosed === false">
                        <span class="ml-auto" v-if="this.data.external_link !== null">
                            <a :href="this.data.external_link" target="_blank" class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.view_job_site') }} <i class="feather icon-chevron-right"></i>
                            </a>
                        </span>
                    </span>
                    <div class="btn btn-sm btn-outline-danger p-05 rounded ml-auto" v-else>
                        <strong>{{ __('job.headers.member.title_position_closed') }}</strong>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

export default {
    name: "GuestNewsfeedJob",
    props: ['data'],
    mixins: [],
}
</script>

<style scoped>

</style>
