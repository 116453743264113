<template>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label>{{ __('video.assign_video_to') }}</label>
                <select name="video_owner_type" v-model="selectedOption" class="form-control">
                    <option value="">{{ __('globals.select_type') }}</option>
                    <option value="supplier">{{ __('globals.supplier') }}</option>
                    <option value="group">{{ __('globals_group') }}</option>
                </select>
            </div>
        </div>

        <div v-if="selectedOption == 'supplier'" class="col-6">
            <div class="form-group">
                <label>Select Supplier</label>
                <select v-model="selectedOwner" name="supplier_id" class="form-control">
                    <option v-for="supplier in suppliers"
                            :key="supplier.id"
                            :value="supplier.id">
                    {{supplier.title}}
                    </option>
                </select>
            </div>
        </div>

        <div v-if="selectedOption == 'group'"  class="col-6">
            <div class="form-group">
                <label>Select Group</label>
                <select v-model="selectedOwner" name="group_id" class="form-control">
                    <option v-for="group in groups"
                            :value="group.id"
                            :key="group.id">
                        {{group.title}}
                    </option>
                </select>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'videoOwnerSelector',
        props: ['suppliers','groups','selectedOption','selectedOwner'],
        data() {
            return {
                //
            }
        }
    }
</script>
