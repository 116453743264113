    import axios from 'axios'

    export default {
        data() {
            return {
                username: window.App.user.username,
                user_id: window.App.user.id,
                user: window.App.user,
                isAdmin: window.App.user.isAdmin,
                site_tax: window.App.site_tax,
                price_setting: window.App.price_setting,
                isServiceAdmin: false,
                editing: false,
                lowest_plan_name: null,
                lowest_plan_price: null,
                enable_read_more: false,
                collapse_body: false,
                class_body: 'breakAll',
            }
        },
        computed: {
            canUpdate() {
                return this.data.owner.id === this.user_id || this.checkServiceAdmin() || this.isAdmin
            },
        },
        methods: {
            formatDate(date) {
                return moment(date).format('DD MMM YY');
            },
            sharePost(data_id, newsfeed_id) {

                if(newsfeed_id == null){
                    newsfeed_id = data_id;
                }

                axios.post('/member/newsfeed/share', {
                    body: $('#comment_body'+data_id).val(),
                    feed: $('#feed'+newsfeed_id).val(),
                    id: newsfeed_id,
                })
                    .then(response => {
                        if ($('#feed'+newsfeed_id).val() === 'profile') {
                            document.location.href = '/member'
                        }

                        if ($('#feed'+newsfeed_id).val().includes('group')) {
                            document.location.href = '/member/group/' + response.data.slug
                        }

                        if ($('#feed'+newsfeed_id).val().includes('supplier')) {
                            document.location.href = '/member/supplier/' +response.data.slug
                        }
                    });
            },
            checkServiceAdmin() {
                if (this.data.group !== null && this.data.group.hasOwnProperty('admins')) {
                    for (var i = 0; i < this.data.group.admins.length; i++) {
                        if (this.data.group.admins[i].id === this.user_id) {
                            this.isServiceAdmin = true;
                        }
                    }

                    if (this.data.group.user_id === this.user_id) {
                        this.isServiceAdmin = true;
                    }
                }

                 if (this.data.supplier !== null && this.data.supplier.hasOwnProperty('admins')) {
                    for (var i = 0; i < this.data.supplier.admins.length; i++) {
                        if (this.data.supplier.admins[i].id === this.user_id) {
                            this.isServiceAdmin = true;
                        }
                    }

                    if (this.data.supplier.user_id === this.user_id) {
                        this.isServiceAdmin = true;
                    }
                }
            },
            planPrices(plan_prices) {
                if (this.price_setting === 'plan-price' && plan_prices.length > 0) {
                    plan_prices.forEach(plan => {
                        if(plan.price !== null) {
                            // This is used to set the price based on lowest plan price if the user is not on a plan where price is available
                            if (this.lowest_plan_price === null) {
                                this.lowest_plan_price = plan.price;
                                this.lowest_plan_name = plan.plan.title;
                            } else if (plan.price < this.lowest_plan_price) {
                                this.lowest_plan_price = plan.price;
                                this.lowest_plan_name = plan.plan.title;
                            }
                        }
                    })
                }
            },
        }
    }

