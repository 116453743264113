<template>
    <div>
        <div class="d-flex justify-content-between border-bottom">
            <div class="flex-grow-1">
                <newsfeed-owner-info
                        :user_data="data.module.owner"
                        :supplier_data="data.supplier"
                        :shared="false"
                        :type="data.type"
                        :time="data.published_at">
                </newsfeed-owner-info>
            </div>
            <div class="btn-group mt-05 mr-05">
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                            <div v-if="canUpdate || isAdmin === true">
                                <a class="dropdown-item" href="#" :href="'/member/job/'+data.module.slug+'/edit'"><i
                                    class="feather icon-settings"></i>{{ __('globals.edit_post')}}</a>
                                <a class="dropdown-item" href="#" @click="destroy"><i class="feather icon-trash-2"></i>{{ __('globals.delete_post')}}</a>
                            </div>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post')}}</a>
                        </div>
                    </div>
            </div>
        </div>

            <div class="bg-white text-white pt-2 pb-2 pl-1 pr-1 mb-0 d-flex justify-content-between">
                <div>
                    <span class="font-medium-3 text-light">{{ __('job.new_position_open') }}:</span><br>
                    <a :href="'/member/job/'+data.module.slug" style="color: #000000"><span
                        class="h3"
                        v-text="data.module.title"></span></a>
                    <div>
                        <i class="feather icon-briefcase text bold-700" style="color: #3399fe !important"></i> <span
                        style="color: #3399fe !important" class="text-bold-700"
                        v-text="data.module.employment_type"></span>
                    </div>
                </div>
                <div v-if="data.module.organisation_logo !== null">
                    <img :src="data.module.organisation_logo.url"
                         width="75">
                </div>
            </div>

            <div class="card-content">
                <div class="pt-1 pl-1 pr-1 pb-05 border-top">
                    <div v-if="collapse_body" class="breakAll image-resize textBox" v-linkified v-html="data.module.position_description"></div>
                    <div v-else :class="class_body+' image-resize textBox'" ref="textBox" v-linkified v-html="data.module.position_description"></div>

                    <div v-if="enable_read_more">
                        <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                            {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                        </button>
                        <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                            {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                        </button>
                    </div>
                </div>

                <div class="d-flex ml-1 mr-1 mt-05 mb-0 pb-05 align-items-center justify-content-start">
                    <span v-if="data.module.hide_salary === 0" class="font-small-3 text-bold-700" style=" color: black">New Job Opening: {{ data.module.custom_salary }}</span>
                    <div class="ml-auto" v-if="user.confirmed === true">
                        <span v-if=" data.module.isPositionClosed === false">
                                <span class="ml-auto" v-if="data.module.external_link !== null">
                                    <a :href="data.module.external_link"
                                       target="_blank"
                                       class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.view_job_site') }} <i
                                        class="feather icon-chevron-right"></i></a>
                                </span>
                                <span v-else>
                                    <a :href="'/member/job-applies?apply='+data.module.slug"
                                       class="btn btn-sm btn-primary waves-effect waves-light">{{ __('job.form.buttons.apply') }} <i
                                        class="fas fa-user-check"></i></a>
                                </span>
                        </span>
                        <div class="btn btn-sm btn-outline-danger p-05 rounded" v-else>
                            <strong>{{ __('job.headers.member.title_position_closed') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import Share from '../../../../mixins/newsfeed/Share'
import Job from '../../../../mixins/newsfeed/Job'

export default {
    name: "NewsfeedJob",
    props: ['data'],
    mixins: [
        Share, Job
    ],
    mounted() {
        if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>

<style>

</style>
