<template>
    <div>
        <div class="card" v-if="this.shared_user">
            <div class="d-flex justify-content-start border-bottom">
                <shared-post-owner-info
                    :user_data="this.shared_user"
                    :supplier_data="[]"
                    :isServiceAdmin="isServiceAdmin"
                    :shared="true"
                    :type="this.data.type"
                    :user_id="user_id" />
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                    <span v-text="ago(this.data.created_at)" class="font-small-1 text-light "
                          style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || this.data.user_id === user_id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="editPost"><i
                            class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                    </span>
                            <span
                                v-if="isServiceAdmin === true || this.data.user_id === user_id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="deleteNewsfeed"><i
                            class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pl-05 pr-05 mt-05">
                <div v-if="editing" class="form-group pr-05 pl-05">
                    <div class="mb-05 mx-05">
                        <div class="card my-0">
                            <editor
                                v-model="shared_text"
                                api-key="no-api-key"
                                :init='{
                                    skin: "trybz-member",
                                    skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                    content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                    external_plugins: {
                                        customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                        loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                    },
                                    valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                    plugins: "link quickbars autolink lists customtags loadingbar",
                                    link_context_toolbar: true,
                                    quickbars_selection_toolbar: "bold italic link bullist",
                                    quickbars_image_toolbar: false,
                                    quickbars_insert_toolbar: false,
                                    link_assume_external_targets: "https",
                                    link_target_list: false,
                                    link_title: false,
                                    toolbar: false,
                                    branding: false,
                                    elementpath: false,
                                    menubar: false,
                                    file_picker_types: "",
                                    height: 100,
                                    content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                    relative_urls : false,
                                    remove_script_host : false,
                                }'
                            />
                        </div>
                    </div>
                    <button class="btn btn-sm btn-primary" @click.prevent="updateSharedNewsfeed">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click.prevent="cancelEditing">{{ __('globals.cancel') }}</button>
                </div>
                <div v-else-if="body !== null">
                    <div class="breakAll pr-05 pl-05 mb-05" v-linkified v-html="this.$options.filters.nl2br(body, true)">
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center pr-1 pl-1">
                <div class="card border-light w-100">
                    <div class="d-flex justify-content-start border-bottom">
                        <shared-post-owner-info
                                :user_data="this.data.module.owner"
                                :supplier_data="this.shared_supplier.length > 0 ? this.shared_supplier : []"
                                :isServiceAdmin="isServiceAdmin"
                                :shared="false"
                                type="event"
                                :user_id="user_id"
                        />
                        <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                            <span v-text="ago(this.data.module.created_at)" class="font-small-1 text-light " style="margin-top: 4px;"></span>
                        </div>
                    </div>
                    <div class="card-content">
                        <div :id="'gallery-'+data.id" class="card-img-top img-fluid">
                            <img class=" img-fluid"
                                 :src="data.module.event_image === null ? '/images/cover.jpg' : data.module.event_image.url">
                        </div>
                        <div class="card-body p-0">

                            <div class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                                <span class="h3">
                                    <a :href="'/member/event/'+data.module.slug" v-text="data.module.title" style="color:inherit;"></a>
                                </span>
                            </div>
                            <div class="d-flex mt-0 ml-1 mr-1 mb-0 align-items-center justify-content-start">
                                <span v-if="data.module.event_privacy === 1" class="badge badge-pill bg-gradient-secondary font-small-2 text-bold-700 mb-05 mr-05" style="padding: 0.2rem 0.5rem;"><i class="feather icon-award"></i> {{ __('globals.premium_only') }}</span>
                                <span v-if="data.module.sold_out === 1" class="badge font-small-2 text-bold-700 badge-pill badge-danger mb-05" style="padding: 0.2rem 0.5rem;"><i class="feather icon-alert-circle"></i> {{ __('event.sold_out') }}</span>
                            </div>
                            <div class="d-flex ml-1 mr-1 mt-0">
                                <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span class="h6 primary" v-text="showDateRange"></span>
                            </div>
                            <div
                                class="d-flex ml-1 mt-0 mr-1 mb-1 align-items-center justify-content-start text-light font-small-2">
                                <i class="fa fa-map-marker"></i> <span class="pl-05" v-text="data.module.location"></span>
                            </div>
                            <div class=" m-05 pt-1 pl-05 pr-0 border-top">
                                <div class="breakAll mb-0" v-linkified
                                     v-html="data.module.event_description.substring(0,550)"></div>
                                <div><a :href="'/member/event/'+data.module.slug" class="font-small-3">{{ __('globals.read_more') }} <i
                                    class="feather icon-chevron-right"></i></a></div>

                            </div>

                            <div class="p-1" v-if="this.shared_group.length > 0">
                                <a :href="'/member/group/'+this.shared_group[0].slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;"
                                             :src="this.shared_group[0].group_cover == null ? '/images/cover.jpg' : this.shared_group[0].group_cover.url">
                                        <div
                                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ this.shared_group[0].title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                                class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="p-1" v-if="this.shared_supplier.length > 0">
                                <a :href="'/member/supplier/'+this.shared_supplier[0].slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;"
                                             :src="this.shared_supplier[0].supplier_cover_image == null ? '/images/cover.jpg' : this.shared_supplier[0].supplier_cover_image.url">
                                        <div
                                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ this.shared_supplier[0].title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                                class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div class="card-body border-top ml-05 mr-05 p-0">
                            <div class="d-flex justify-content-between">
                                <div class=" p-05">
                                    <like :post="data.module" type="post"></like>
                                </div>
                                <div class="p-05 font-small-3 text-bold-700"><a
                                    @click.prevent="showComments(data.id)"><i
                                    class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pl-1 pr-1 pb-05" v-if="data.groups !== undefined && data.groups.length > 0">
                <a :href="'/member/group/'+data.groups[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.groups[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pl-1 pr-1 pb-05" v-if="data.suppliers !== undefined && data.suppliers.length > 0">
                <a :href="'/member/supplier/'+data.suppliers[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.suppliers[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  :module="data.type"
                  :slug="data.module.id"
                  :members="members"
        ></comments>
    </div>
</template>

<script>
import Event from '../../../mixins/newsfeed/Event'
import DateTime from '../../../mixins/newsfeed/DateTime'
import SharedFunctions from "../../../mixins/newsfeed/SharedFunctions";

export default {
    props: ['members'],
    name: "SharedEvent",
    mixins: [
        SharedFunctions, Event, DateTime
    ],
    mounted() {
        this.getSharedPost()
    }
}
</script>

