<template>
    <div>
        <div v-bind:class="'card '+ pinStyle">
            <div v-if="data.module_type === 'App\\Models\\Post'">
                <post :data="data" :post="data.module" :questions_enabled="questions_enabled" :pin="pinned" :section="section"></post>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\Video'">
                <newsfeed-video :data="data" :video="data.module"></newsfeed-video>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\Event'">
                <event :data="data" :event="data.module"></event>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\Product'">
                <newsfeed-product :data="data" :product="data.module"></newsfeed-product>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\Resource'">
                <newsfeed-resource :data="data" :resource="data.module"></newsfeed-resource>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\Job'">
                <job :data="data" :job="data.module"></job>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\Stack\\Question'">
                <question :data="data" :question="data.module"></question>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\Zoom'">
                <newsfeed-zoom-meeting :data="data" :zoom="data.module"></newsfeed-zoom-meeting>
            </div>

            <div v-else-if="data.module_type === 'App\\Models\\ZoomWebinar'">
                <newsfeed-zoom-webinar :data="data" :webinar="data.module" ></newsfeed-zoom-webinar>
            </div>

            <div v-if="data.group != null || data.supplier != null" class="pl-1 pr-1 pt-05 pb-05">
                <div v-if="data.group !== null && this.section !== 'group'">
                    <a :href="'/member/group/'+data.group.slug">
                        <div class="card text-white mb-0 border-0">
                            <img class="card-img img-fluid" style="height: 3rem;" :src="data.group.group_cover == null ? '/images/cover.jpg' : data.group.group_cover.url">
                            <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                    {{ data.group.title }}</p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
                <div v-if="data.supplier !== null && this.section !== 'supplier'">
                    <a :href="'/member/supplier/'+data.supplier.slug">
                        <div class="card text-white mb-0 border-0">
                            <img class="card-img img-fluid" style="height: 3rem;" :src="data.supplier.supplier_cover_image == null ? '/images/cover.jpg' : data.supplier.supplier_cover_image.url">
                            <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                    {{ data.supplier.title }}</p>
                                <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div v-if="this.data.type === 'question'"  class=" border-top text-right p-05">
                <a :href="'/member/question/'+data.module.slug" class="ml-auto text-bold-700 font-small-3">{{ __('question.view_this_question') }} <i class="feather icon-chevron-right"></i> </a>
            </div>

            <div v-if="this.data.type === 'post' && section === 'sidebar'" class="border-top p-05 text-right">
                <a :href="'/member/post/'+data.module.id" style="color:inherit"
                   class="btn-sm btn-flat-light text-bold-700">{{ __('globals.view_post') }} <i
                    class="feather icon-arrow-right"></i></a>
            </div>


            <div v-if="this.data.type !== 'question' && section !== 'sidebar'" class="d-flex justify-content-between border-top pl-05 pr-05">
                <div class="font-small-3 text-bold-700">
                    <likes :id="this.data.module.slug !== undefined ? this.data.module.slug : (this.data.type === 'zoom' || this.data.type === 'zoom-webinar'? this.data.module.zoom_id : this.data.module.id)" :likes-count="this.data.likesCount" :is-liked="this.data.isLiked" :liked-by-users="this.data.userLikes" :type="this.data.type === 'resource' ? this.data.module.type : this.data.type"></likes>
                </div>
                <div class="font-small-3 text-bold-700">
                    <a class="d-block p-05" @click.prevent="commentsShow = !commentsShow"><i
                        class="fa fa-comment-o"></i>
                        <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                        <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                    </a>
                </div>
                <div class="font-small-3 text-bold-700">
                    <social-share :id="this.data.id" :social="this.data.module.socialShare" @shareModal="triggerShareModal"></social-share>
                </div>
            </div>
        </div>

        <div v-if="commentsShow">
            <comments :newsfeed_uuid="this.data.uuid" :id="this.data.module.slug !== undefined ? this.data.module.slug : (this.data.type === 'zoom' || this.data.type === 'zoom-webinar'? this.data.module.zoom_id : this.data.module.id)" :type="this.data.type === 'resource' ? this.data.module.type : this.data.type" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <newsfeed-share-modal :original_newsfeed_id="this.data.id" :data="this.data" :type="this.data.type" @closeModal="share_newsfeed = false" ></newsfeed-share-modal>
        </div>
    </div>
</template>

<script>

// All the Sub-modules
import Post from './post/Post.vue';
import NewsfeedVideo from './post/Video.vue';
import Event from "./post/Event.vue";
import NewsfeedProduct from "./post/Product.vue";
import NewsfeedResource from "./post/Resource.vue";
import Job from "./post/Job.vue";
import Question from './post/Question.vue';
import NewsfeedZoomMeeting from './post/Zoom.vue';
import NewsfeedZoomWebinar from './post/ZoomWebinar.vue';

// The shared elements
import Likes from "../components/Likes.vue";
import Comments from "../components/Comments.vue";
import NewsfeedShareModal from "../components/NewsfeedShareModal.vue";

export default {
    name: "Newsfeed",
    props: ['data', 'section', 'questions_enabled'],
    components: {Post, NewsfeedVideo, Event, NewsfeedProduct, NewsfeedResource, Job, Question, NewsfeedZoomMeeting, NewsfeedZoomWebinar, Likes, Comments, NewsfeedShareModal},
    mixins: [],
    data(){
        return {
            commentsShow: false,
            commentsCount: this.data.commentsCount,
            pinned: this.isPin,
            share_newsfeed: false,
        }
    },
    computed: {
        pinStyle() {
            if (this.data.section === this.section){
                return this.data.pin_to_top === 1 ? 'border border-primary' : ''
            }
            return ''
        },
        isPin() {
            if(this.data.section === this.section && this.data.pin_to_top === 1){
                this.pinned =  true;
            } else {
                this.pinned =  false;
            }
        },
    },
    methods: {
        updateCommentsCount(count){
            this.commentsCount = count;
        },
        triggerShareModal(){
            this.share_newsfeed = !this.share_newsfeed;
        }
    },
}
</script>

<style scoped>

</style>
