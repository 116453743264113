<template>
    <div class="card">
        <div class="row">
            <div class="col-12 col-md-9 border-right">
                <div>
                    <h4 class="pt-1 ml-1" v-text="'Service: '+ item.cart.title"> </h4>
                    <p class="p-1">This is a once off purchase of a single listing of this service. After your purchase you will be able to create a new listing within the service.</p>
                </div>
            </div>
            <div class="col-12 col-md-3 pt-05 text-center">
                <span class="font-large-1" v-text="this.$options.filters.currency(item.price, site_tax)"></span>
                <span class="font-small-1 text-muted d-block" style="margin-top: -5px;">inc {{ site_tax.name}}</span>
                <div class=" mt-05">
                    <span class="font-small-2 text-center">{{ __('globals.quantity') }}:</span>
                    <div class="d-flex mb-05 mt-05">
                        <div class="m-auto">
                            <NumberInputSpinner
                                :min="1"
                                :max="5000"
                                :step="1"
                                :integerOnly="true"
                                v-model="qty"
                            />
                        </div>
                    </div>
                </div>
                <div class="text-center mb-05">
                    <button class="btn-sm btn-flat-danger" @click="destroy" style="border:none;"><i class="feather icon-x"></i> {{ __('globals.remove') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NumberInputSpinner from 'vue-number-input-spinner'

    export default {
        name: "product",
        props: ['data', 'index'],
        components: {
            NumberInputSpinner,
        },
        data() {
            return {
                item: this.data,
                qty: this.data.qty,
                site_tax: window.App.site_tax
            }
        },
        methods: {
            destroy() {
                this.$emit('deleted', {index: this.index});
            }
        },
        watch: {
            qty: function (val) {
                axios
                    .patch('/member/cart/'+this.data.id, {
                        qty: val
                    })
                    .then((response) => {
                        this.$emit('updated', {qty: val, index: this.index});
                    })
                    .catch((error) => {})
            }
        },
    }
</script>

<style scoped>

</style>
