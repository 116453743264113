<template>
    <div>
        <div class="card" v-if="this.shared_user">
            <div class="d-flex justify-content-start mb-05 border-bottom">
                <shared-post-owner-info
                    :user_data="this.shared_user"
                    :supplier_data="[]"
                    :isServiceAdmin="isServiceAdmin"
                    :shared="true"
                    :type="this.data.type"
                    :user_id="user_id">
                </shared-post-owner-info>
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                    <span v-text="ago(this.data.created_at)" class="font-small-1 text-light " style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="editPost"><i
                            class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                    </span>
                            <span
                                v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="deleteNewsfeed"><i
                            class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="reportNewsfeed">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="editing" class="form-group m-05">
                <textarea class="form-control mb-05" v-model="body"></textarea>
                <button class="btn btn-sm btn-primary" @click.prevent="updateSharedNewsfeed">{{ __('globals.update') }}</button>
                <button class="btn btn-sm btn-link" @click.prevent="cancelEditing">{{ __('globals.cancel') }}</button>
            </div>
            <div class="" v-else-if="body !== null">
                <div class="breakAll m-05" v-linkified v-html="this.$options.filters.nl2br(body, true)">
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="card m-05 border-light w-100">
                    <div class="d-flex justify-content-start mb-05 border-bottom">
                        <shared-post-owner-info
                                    :user_data="this.data.module.owner"
                                    :supplier_data="post_as_who === 'supplier' ? this.shared_supplier : []"
                                    :isServiceAdmin="isServiceAdmin"
                                    :shared="false"
                                    type="post"
                                    :user_id="user_id"
                        ></shared-post-owner-info>

                        <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                            <span v-text="ago(this.data.module.created_at)" class="font-small-1 text-light " style="margin-top: 4px;"></span>
                        </div>
                    </div>
                    <div class="card-content">
                        <span v-if="this.data.module.can_view_video === true">
                            <div class="text-center" v-if="this.data.module.external_url === null">
                                <video-player :options="videoOptions" v-if="videoOptions.sources[0].src"/>
                            </div>
                            <div class="video_container" v-else>
                                <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="this.data.module.external_url"></iframe>
                            </div>
                        </span>
                        <span v-else>
                            <img class="img-fluid"
                                 :src="this.data.module.cover == null ? this.data.module.thumbnail : this.data.module.cover.url">
                        </span>
                        <div class="card-body py-1 pl-1 pr-1">
                            <div class="d-flex mt-0 mb-0 align-items-center justify-content-start">
                            <span class="h3"><a :href="'/member/video/'+data.module.slug" v-text="data.module.title" style="color:inherit;"></a></span>
                            </div>
                            <div>
                                <div class="breakAll pt-1" v-linkified style="max-height: 300px; overflow: hidden; text-overflow: ellipsis">
                                    <span class="breakAll" v-html="data.module.description"></span>
                                </div>
                                <div class="text-right">
                                    <a :href="'/member/video/'+data.module.slug" class="font-small-3">{{ __('globals.read_more') }}<i class="feather icon-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div v-if="data.module.can_view_video === false" class="mt-0 ml-1 mr-1 mb-1 pt-1">
                            <div class="d-flex">
                                <span class="font-small-2 pt-1"> {{ this.price | currency(site_tax)}} {{ __('globals.all_inclusive') }}</span>

                                <div class="ml-auto " v-if="data.module.can_view_video === false">
                                    <a :href="'/member/video/'+data.module.slug+'/add_to_cart'"
                                       class="btn btn-primary waves-effect waves-light">{{ __('video.form.links/pay_for_view') }} <i
                                        class="fa fa-tag"></i></a>
                                </div>
                            </div>
                            <div id="progress" class="p-2" style="display: none"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body border-top pl-05 pr-05 pb-0 pt-0">
                <div class="d-flex justify-content-between">
                    <div class=" p-05">
                        <likes :id="this.data.uuid" :likes-count="this.data.likesCount"  :is-liked="this.data.isLiked" :liked-by-users="this.data.userLikes" type="newsfeed"></likes>
                    </div>
                    <div class="p-05 font-small-3 text-bold-700 mr-1"><a
                       @click.prevent="commentsShow = !commentsShow"><i
                       class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount !== 0 ? '('+commentsCount+')': ''"></span></a>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="commentsShow">
            <newsfeed-comments :uuid="this.data.uuid" type="newsfeed" @length="updateCommentsCount"></newsfeed-comments>
        </div>
    </div>
</template>

<script>
import DateTime from '../../../mixins/newsfeed/DateTime'
import SharedFunctions from "../../../mixins/newsfeed/SharedFunctions";
import VideoPlayer from "../../VideoPlayer.vue";

export default {
    props: ['members'],
    name: "SharedVideo",
    components: {VideoPlayer},
    data () {
        return {
            shared_user: null,
            price_setting: window.App.price_setting,
            price: '',
            plan_prices: '',
            user: window.App.user,
            site_tax: window.App.site_tax,
            commentsShow: false,
            commentsCount: this.data.comments.length,
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: typeof this.data.module.video_link !== undefined ? this.data.module.video_link : '',
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    mixins: [
        DateTime, SharedFunctions
    ],
    mounted () {
        this.getSharedPost()

        axios
            .get('/member/video/'+this.data.module.slug+'/get_price')
            .then(response => {
                this.price = response.data.price;
            });

        axios
            .get('/member/video/'+this.data.module.slug+'/get_all_price_plans')
            .then(response => {
                this.plan_prices = response.data.plan_prices;
            });
    },
    methods: {
        updateCommentsCount(count){
            this.commentsCount = count;
        },
    }
}
</script>

