<template>
    <div class="card">

        <div class="d-flex justify-content-between p-1">

            <div class="d-flex justify-content-start align-items-center">
                <div class="avatar avatar-sm bg-rgba-warning p-50 m-0" style="width:30px;height:30px;">
                    <div class="avatar-content">
                        <i class="feather icon-user-check text-warning font-medium-2"></i>
                    </div>
                </div>
                <div class="pl-05">
                    <h5 class="mb-0">{{ __('admin_dashboard.top_engaged_users') }}</h5>
                </div>
            </div>

            <div class="pl-05">
                <button type="button" class="btn btn-outline-primary" @click.prevent="show_filters = !show_filters">
                    {{ __('globals.filter_options') }}
                    <i class="feather icon-chevron-down" v-if="!show_filters"></i>
                    <i class="feather icon-chevron-up" v-else></i>
                </button>
            </div>
        </div>

        <div v-if="show_filters" class="p-1 text-capitalize">
            <div class="form-group">
                <label for="month" class="required">{{ __('globals.time') }}</label>
                <select id="month" class="form-control" v-model="month">
                    <option :value="month.value" v-for="(month, index) in months_array" :key="index">
                        {{ month.name }}
                    </option>
                </select>
            </div>

            <div class="text-center">
                <button class="btn btn-primary w-50" @click.prevent="getUserEngagement()">{{ __('globals.search') }}</button>
            </div>
        </div>

        <div class="text-center" v-else>
            <div v-if="is_loading">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div class="ml-05 mr-05" v-else-if="!is_loading && users.length > 0">
                <template v-for="(user, index) in users">
                    <user-engagement-card  :user="user" :key="index">
                    </user-engagement-card>
                </template>
            </div>
            <div v-else>
                <p>{{ __('globals.no_users_yet') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import UserEngagementCard from "./UserEngagementCard";
    import moment from "moment-timezone";

    export default {
        name: "UserEngagement",
        props: [],
        components: {UserEngagementCard},
        data() {
            return {
                months_array: [],
                month: '',
                is_loading: false,
                show_filters: false,
                users: [],
            }
        },
        methods: {
            getUserEngagement(){
                if(this.is_loading){
                    return false;
                }
                this.show_filters = false;
                this.is_loading = true;
                axios.post('/admin/user-engagement', {time: this.month})
                     .then((response) => {
                         this.users = [];
                         this.show_filters = false;
                         response.data.users.forEach(user => {
                             if (user.total_content !== 0) {
                                this.users.push(user);
                             }
                         });
                         this.is_loading = false;
                     }).catch(()=>{
                         this.is_loading = false;
                     })
            },
            getMonthsRange(){
                const currentMonth = moment();
                const last12Months = [];

                for (let i = 0; i < 12; i++) {
                    const month = {
                        name: currentMonth.format('MMMM YYYY'),
                        value: currentMonth.format('Y-MM'),
                    };

                    last12Months.unshift(month);
                    currentMonth.subtract(1, 'month');
                  }

                this.months_array = last12Months;
            }
        },
        // mounted() {
        //     this.getUserEngagement();
        // },
        beforeMount() {
            this.getMonthsRange();
            this.month = moment().format('Y-MM');
        }
    }
</script>

<style scoped>

</style>
