<template>
    <div>
        <div class="card">
            <div style="display: flex; flex: 1 1 auto;" class="row">
                <div class="col-12 col-md-6">
                    <div class="img-square-wrapper">

                        <img :class="'card-img img-fluid m-05 product-image-'+product.id"
                             :src="product.media.length > 0 ? product.media[0].url : 'images/cover.png'">
                        <span v-if="product.media.length > 1">

                                <span class="ml-05 mb-2" v-for="media in product.media">
                                    <a @click.prevent="changeImage(product.id, media.url)">
                                    <img class="rounded" :src="media.thumbnail" height="50" width="50">
                                </a>
                                </span>

                        </span>

                    </div>
                </div>
                <div class="col-12 col-md-6 pl-05 ">
                    <div class="card-body p-1">
                        <h1 class="font-large-1" v-text="product.title"></h1>
                        <div class="d-flex align-items-center">
                            <span v-if="product.average_rating !== null">
                                <span :class="'rating-static rating-'+product.average_rating * 10">  </span>
                            </span>
                            <div class="ml-auto">
                                <button class="btn-sm btn-outline-warning product-rating" @click.prevent="showRatings()">
                                <span v-if="product.ratings.length === 0">
                                    {{ __('product.form.buttons.add_rating') }}
                                </span>
                                    <span v-if="product.ratings.length === 1">
                                    {{ __('product.form.buttons.view_rating') }}
                                </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-text p-1 breakAll">
                        <span class="breakAll image-resize" v-html="product.product_description"></span>
                    </div>

                     <div v-if="product.group !== null || product.supplier !== null" class="pl-1 pr-1 pt-05 pb-05">
                        <div v-if="product.group !== null">
                                <a :href="'/member/group/'+product.group.slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;" :src="product.group.group_cover == null ? '/images/cover.jpg' : product.group.group_cover.url">
                                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                    {{ product.group.title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                        </div>
                        <div v-if="product.supplier !== null">
                                <a :href="'/member/supplier/'+product.supplier.slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;" :src="product.supplier.supplier_cover_image == null ? '/images/cover.jpg' : product.supplier.supplier_cover_image.url">
                                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ product.supplier.title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                        </div>
                     </div>

                    <div class="p-1" v-if="product.disabled === 1">
                        <div class="card bg-outline-gray">
                            <div class="card-header">
                                <h4 class="card-title danger">{{ __('globals.disabled_product_box_header') }}</h4>
                            </div>
                            <div class="card-body">{{ __('globals.disabled_product_box_content') }}</div>
                        </div>
                    </div>
                    <div class="d-flex pr-05 p-1 align-items-center justify-content-between border-top border-bottom mr-05">
                        <div v-if="product.price !== null ">
                            <strong>{{ __('globals.form.paragraph.your_price') }} {{ product.price | currency(site_tax) }}
                            </strong>
                        </div>
                        <div class="d-flex" v-if="user.confirmed === true">
                            <div v-if="product.external_link !== null">
                                <a :href="product.external_link" target="_blank">
                                    <button class="btn btn-primary waves-effect waves-light">
                                        {{ __('product.form.buttons.view_product_site') }} <i
                                        class="feather icon-chevron-right"></i>
                                    </button>
                                </a>
                            </div>
                            <div v-else>
                                <div v-if="product.price === null && this.lowest_plan_price !== null">
                                    <strong>
                                        <span>
                                            <a href="/member/plans" style="text-decoration: underline" class="text-dark">{{ __('globals.upgrade') }}</a>
                                            {{ __('globals.to_get_it_for') }} {{ this.lowest_plan_price | currency(site_tax) }} {{ __('globals.on_a') }}
                                            {{ this.lowest_plan_name }} {{ __('globals.plan') }}
                                        </span>
                                    </strong>
                                </div>
                                <div v-if="product.price !== null">
                                    <a :href="'/member/product/'+product.slug+'/add_to_cart'">
                                        <button class="btn btn-primary waves-effect waves-light">
                                            {{ __('globals.buy_now') }} <i class="fa fa-tag"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

                        <div class="d-flex align-items-center justify-content-between pl-1 pr-1">
                            <newsfeed-owner-info
                                :user_data="product.owner"
                                :supplier_data="null"
                                :shared="false"
                                type="product"
                                :time="null">
                            </newsfeed-owner-info>
                            <div class="ml-auto">
                                <button class="btn btn-sm btn-outline-danger" @click.prevent="report">
                                    {{ __('globals.report') }}
                                </button>
                            </div>
                        </div>

                </div>

            </div>

            <div class="d-flex justify-content-between mt-1 mb-0 border-top">
                 <div class="font-small-3 text-bold-700">
                        <likes :id="this.product.slug" :likes-count="this.product.likesCount" :is-liked="this.product.isLiked" :liked-by-users="this.product.userLikes" type="product"></likes>
                </div>
                    <div class="font-small-3 text-bold-700">
                            <a class="p-05 d-block" @click.prevent="commentsShow = !commentsShow"><i
                               class="fa fa-comment-o"></i>
                                <span v-if="commentsCount === 1"><span v-text="commentsCount"></span> {{ __('globals.comment') }}</span>
                                <span v-else><span v-text="commentsCount === 0 ? ' ': commentsCount"></span> {{ __('globals.comments') }}</span>
                            </a>
                    </div>
                    <div class="font-small-3 text-bold-700">
                        <social-share :id="this.product.id" :social="this.product.socialShare" @shareModal="triggerShareModal"></social-share>
                    </div>
            </div>
        </div>

        <div v-if="commentsShow">
            <comments :newsfeed_uuid="this.product.newsfeed_uuid" :id="this.product.slug" type="product" @length="updateCommentsCount"></comments>
        </div>
        <div v-if="share_newsfeed">
            <share-modal :original_newsfeed_id="this.product.newsfeed_id" :module="this.product" type="product" @closeModal="share_newsfeed = false" ></share-modal>
        </div>

        <ratings
            :data="product"
            module="product"
            :slug="product.slug"
            :count="product.ratings.length"
            :enableRating="true"
        ></ratings>
    </div>
</template>

<script>
import moment from "moment-timezone";
import linkify from 'vue-linkify'

import Product from "../../mixins/newsfeed/Product";
import Share from "../../mixins/newsfeed/Share";
import Likes from "../newsfeed/components/Likes.vue";
import Comments from "../newsfeed/components/Comments.vue";
import ShareModal from "../newsfeed/components/ShareModal.vue";

Vue.directive('linkified', linkify)

export default {
    name: "Product",
    props: ['product',],
    mixins: [Product, Share],
    components: {Likes, Comments, ShareModal},
    data() {
        return {
            translations: window._translations,
            commentsShow: false,
            commentsCount: this.product.commentsCount,
            share_newsfeed: false,
        }
    },
    methods: {
        changeImage(id, url) {
            $('.product-image-' + id).attr("src", url);
        },
        showRatings() {
            $('#rating').toggle();
        },
        updateCommentsCount(count){
            this.commentsCount = count;
        },
        triggerShareModal(){
            this.share_newsfeed = !this.share_newsfeed;
        }
    },
    beforeMount() {
        this.planPrices(this.product.product_plan_prices);
    }
}
</script>



