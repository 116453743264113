<template>
    <div>
        <div class="card" style="width: 100%;">
            <post-owner-info :data="data"
                             :isServiceAdmin="isServiceAdmin"
                             :user_id="user_id"
            ></post-owner-info>

            <div class="btn-group mt-05" style="right:5px; position: absolute;">
                <span v-text="ago" class="font-small-1 text-light" style="margin-top: 4px;"></span>
                <div class="dropdown">
                    <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                        <i class="fa fa-chevron-circle-down mr-0"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                        <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                            class="feather icon-trash-2"></i>{{ __('question.form.links.delete_question') }}</a>
                    </span>
                        <a class="dropdown-item danger" @click.prevent="report">
                            <i class="feather icon-alert-triangle"></i>{{ __('question.form.links.report_question') }}</a>
                    </div>
                </div>
            </div>
            <div class="ml-1 mr-1 pt-1 pb-1 mb-0 border-top border-bottom">
                <div class="breakAll"><a :href="'/member/question/'+data.module.slug" class="font-medium-3 text-dark text-bold-700"> {{this.$options.filters.nl2br(title, true)}}</a></div>
                <div class="breakAll pt-05 pb-05" v-linkified v-html="this.$options.filters.nl2br(body, true)"></div>
            </div>
            <div class="p-1" v-if="data.groups !== undefined && data.groups.length > 0">
                <a :href="'/member/group/'+data.groups[0].slug">
                    <div class="card text-white mb-0">
                        <img class="card-img img-fluid" style="height: 3rem;" :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong> {{ data.groups[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>

            </div>
            <div class="p-1" v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                <a :href="'/member/supplier/'+data.suppliers[0].slug">
                    <div class="card text-white mb-0">
                        <img class="card-img img-fluid" style="height: 3rem;" :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{ data.suppliers[0].title }} </p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>


            <div class="ml-auto p-05">
                <a :href="'/member/question/'+data.module.slug" class="ml-auto text-bold-700 font-small-3">{{ __('question.view_this_question') }} <i class="feather icon-chevron-right"></i> </a>
            </div>
        </div>

    </div>

</template>

<script>
import moment from "moment-timezone";
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)
import PostOwnerInfo from './PostOwnerInfo'

export default {
    name: "Question",
    props: ['data', 'members', 'administrator'],
    components: {PostOwnerInfo},
    data() {
        return {
            editing: false,
            item: this.data,
            body: this.data.module.body === null ? '' : this.data.module.body,
            title: this.data.module.title === null ? '' : this.data.module.title,
            user_id: window.App.user.id,
            user: window.App.user,
            isServiceAdmin: false,
            social: '',
            isAdmin: this.administrator
        }
    },
    methods: {
        showComments(id) {
            $('#comment-' + id).toggle();
        },
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/question/' + this.data.module.slug);
                this.$emit('deleted', this.data.module.id);
            }
        },
        report() {
            $('#report_id').val(this.data.module.id);
            $('#report_type').val('App\\Models\\Stack\\Question');
            $('#post_title').text(this.data.module.title);
            $('#reportModal').modal('toggle');
        },
        getSocialLinks(){
            axios.get('/member/question/'+ this.data.module.slug+'/social')
                .then(response => {
                    this.social = response.data;
                });
        }
    },
    computed: {
        ago() {
            return moment(this.data.created_at).format('DD-MM-YY');
        },
        commentsCount() {
            if (this.data.module.comments.length) {
                return `(${this.data.module.comments.length})`;
            }
        }
    },
    beforeMount() {
        this.getSocialLinks();
    }
}
</script>

