<template>
    <div class="card">
        <div class="cover-img-top shadow">
            <img class="img-fluid card-img-top cover-img-top" alt="Cover Image" :src="supplier.supplier_cover_image === null ? '/images/cover.jpg' : supplier.supplier_cover_image.url"  style="object-fit: cover;" />
        </div>
        <div class="card-content">
            <div class="card-header pt-05 mb-1">
                <div class="col-12 col-md-3 col-lg-2 pr-0 pl-0 text-center">
                    <div class="avatar avatar-xxl" style="margin-top: -90px;">
                        <img class="rounded-circle shadow" alt="Avatar Image" :src="supplier.supplier_avatar === null ? '/images/avatar.png' : supplier.supplier_avatar.url" />
                    </div>
                </div>
                <div class="col-12 col-md-9 col-lg-10 pl-0 pr-0">
                    <h1 class="pb-0 mb-0 font-large-2">{{ supplier.title }}</h1>
                    <span class="pb-0 mb-0 text-bold-700 text-primary" v-if="supplier.supplier_tag_name !== null">{{ supplier.supplier_tag_name }} </span>
                </div>
            </div>

            <div class="card-body row pb-1">
                <div class="col-12 col-md-12 col-lg-4">
                    <div class="d-inline-block text-dark font-medium-3">
                        <span class="badge badge-pill badge-light pr-05" v-if="supplier.privacy === '1'">
                            <i class="fa fa-shield-alt" data-toggle="tooltip" data-placement="bottom" title="Private Page"></i> Private
                        </span>
                        <p class="badge badge-pill primary">
                            <i class="fa fa-users text-white"></i>
                            <span class=" text-white"> {{ supplier.follower_count }} {{ supplier.follower_count === 1 ? 'Follower' : __('supplier.title_followers') }}</span>
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-12 col-lg-8 float-right text-right pr-05 pl-05">
                    <div class="d-flex justify-content-around">
                        <a href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary waves-light mr-05 profile-menu">
                            <i class="feather icon-send mobile-hide"></i> Posts
                        </a>
                        <a href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary waves-light mr-05 profile-menu">
                            <i class="feather icon-calendar mobile-hide"></i> Events
                        </a>
                        <a href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary waves-light mr-05 profile-menu">
                            <i class="feather icon-shopping-cart mobile-hide"></i> Store
                        </a>
                        <a href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary profile-menu mr-05">
                            <i class="feather icon-users mobile-hide"></i> Resources
                        </a>
                        <a href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary waves-light mr-05 profile-menu">
                            <i class="feather icon-video mobile-hide"></i> Videos
                        </a>
                        <a href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary waves-light profile-menu">
                            <i class="feather icon-users mobile-hide"></i> Followers
                        </a>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12 col-md-12">
            <div class="card p-1">
                <div class="card-title">
                    <h4 class="mb-1 pb-05 border-bottom">
                        <i class="feather icon-users"></i> {{ supplier.title }}
                    </h4>
                </div>
                <div class="card-body p-0">
                    <span class="breakAll image-resize" v-html="supplier.description"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['supplier'],
        name: "Supplier",
    }
</script>

<style scoped>

</style>
