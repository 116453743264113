<template>
    <div>
        <div class="card">
            <div class="cover-img-top  shadow">
                <img class="card-img-top img-fluid cover-img-top"
                     :src="group.group_cover == null ? '/images/cover.png' : group.group_cover.url" alt="Group Cover">
            </div>
            <div class="card-content">
                <div class="card-header">
                    <div class="col-12 pl-0 pr-0">
                        <h1 class="pb-0 mb-0 font-large-2">{{group.title}}</h1>
<!--                        <span class="pb-0 mb-0 h4 text-muted">&#64;{{group.slug}}</span>-->
                    </div>
                </div>

                <div class="card-body row pb-0">

                    <div class="col-12 col-md-4 mb-1">

                        <div class="d-inline-block text-dark font-medium-3">
                            <span class="badge badge-pill badge-light pr-1" v-if="group.group_privacy == '1'">
                                <i class="fa fa-shield-alt mr-05" data-toggle="tooltip" data-placement="bottom" title="Private Group"></i> Private
                            </span>

                            <div class="badge badge-pill ">
                                <i class="fa fa-users mr-05"></i> {{group.total_members}} {{ __('group.title_members') }}
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-8 col-md-8 col-lg-8 float-right text-right pr-05 pl-05 pb-1">
                        <div class="d-flex justify-content-around">

                            <button href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary waves-effect waves-light mr-05 profile-menu" @click.prevent="active_tab = null">
                                <i class="feather icon-send mobile-hide"></i> {{ __('group.form.links.posts') }}
                            </button>

                            <button class="btn btn-primary waves-effect waves-light  mr-05 profile-menu" @click.prevent="active_tab = 'events'; loadEvents()">
                                <i class="feather icon-calendar mobile-hide"></i> {{ __('group.form.links.events') }}
                            </button>

                            <button class="btn btn-primary waves-effect waves-light  mr-05 profile-menu" @click.prevent="active_tab = 'products'; loadProducts()">
                                <i class="feather icon-shopping-cart mobile-hide"></i> {{ __('group.form.links.products') }}
                            </button>

                            <button class="btn btn-primary waves-effect waves-light  mr-05 profile-menu" @click.prevent="active_tab = 'resources' ; loadResources()">
                                <i class="feather icon-shopping-cart mobile-hide"></i> {{ __('group.form.links.resources') }}
                            </button>

                            <button class="btn btn-primary waves-effect waves-light  mr-05 profile-menu" @click.prevent="active_tab = 'videos' ; loadVideos()">
                                <i class="feather icon-video mobile-hide"></i> {{ __('group.form.links.videos') }}
                            </button>

                            <button href="#" data-toggle="modal" data-target="#registerModal" class="btn btn-primary waves-effect waves-light profile-menu" @click.prevent="active_tab = null">
                                <i class="feather icon-user mobile-hide"></i> {{ __('group.title_members') }}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <div class="card p-1">
                    <div class="card-title"><h4 class="mb-1 pb-05 border-bottom">
                        <i class="feather icon-users"></i> {{ group.title}}</h4>
                    </div>
                    <div class="card-body p-0">
                        <span class="breakAll image-resize" v-html="group.group_description"></span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isLoading">
            <infinite-loading>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
        </div>

        <div v-else>
            <div v-if="this.active_tab === 'events' && events.length > 0" class="row p-05">
                <guest-event-card v-for="(event, index) in events" :event="event" :key="index" :service_settings="service_settings.event"></guest-event-card>
            </div>

            <div v-else-if="this.active_tab === 'products' && products.length > 0" class="row p-05">
                <guest-product-card v-for="(product, index) in products" :product="product" :key="index" :service_settings="service_settings.product"></guest-product-card>
            </div>

            <div v-else-if="this.active_tab === 'resources' && resources.length > 0 " class="row p-05">
                <guest-resource-card v-for="(resource, index) in resources" :resource="resource" :key="index" :service_settings="service_settings.resource"></guest-resource-card>
            </div>

            <div v-else-if="this.active_tab === 'videos' && videos.length > 0" class="row p-05">
                <guest-video-card v-for="(video, index) in videos" :video="video" :key="index" :service_settings="service_settings.video"></guest-video-card>
            </div>
        </div>
    </div>
</template>

<script>

    import GuestEventCard from './cards/Event.vue';
    import GuestProductCard from './cards/Product.vue';
    import GuestResourceCard from './cards/Resource.vue';
    import GuestVideoCard from './cards/Video.vue';

    export default {
        props: ['data', 'service_settings'],
        name: "Group",
        data() {
            return {
                group: this.data,
                active_tab: null,
                events: [],
                products: [],
                resources: [],
                videos: [],
                isLoading: false,
            }
        },

        components: {
            GuestEventCard,
            GuestProductCard,
            GuestResourceCard,
            GuestVideoCard
        },

        methods:{
            loadEvents(){
                if(this.isLoading){
                    return false;
                }
                this.isLoading = true;
                axios
                    .get("/group/"+this.data.slug+"/events")
                    .then(response => {
                        this.events = response.data.data;
                        this.isLoading = false;
                    });
            },
            loadProducts(){
                if(this.isLoading){
                    return false;
                }
                this.isLoading = true;
                axios
                    .get("/group/"+this.data.slug+"/products")
                    .then(response => {
                        this.products = response.data.data;
                        this.isLoading = false;
                    });
            },
            loadResources(){
                if(this.isLoading){
                    return false;
                }
                this.isLoading = true;
                axios
                    .get("/group/"+this.data.slug+"/resources")
                    .then(response => {
                        this.resources = response.data.data;
                        this.isLoading = false;
                    });
            },
            loadVideos(){
                if(this.isLoading){
                    return false;
                }
                this.isLoading = true;
                axios
                    .get("/group/"+this.data.slug+"/videos")
                    .then(response => {
                        this.videos = response.data.data;
                        this.isLoading = false;
                    });
            },
        },

        beforeMount(){
            let url = window.location.href;
            let currentUrlTab = url.substring(url.indexOf("#") + 1);

            switch (currentUrlTab){
                case 'events':
                    this.loadEvents();
                    break;
                case 'products':
                    this.loadProducts();
                    break;
                case 'resources':
                    this.loadResources();
                    break;
                case 'videos':
                    this.loadVideos();
                    break;
            }

            this.active_tab =  currentUrlTab;
        }
    }
</script>

<style scoped>

</style>
