<template>
    <div>
        <a  @click.prevent="toggle"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            :class="classes"
            class="font-small-3 text-bold-700 p-05 d-block">
            <i class="fa fa-thumbs-o-up"></i>
            <span v-text="count === 0 ? '' : count"></span>
        </a>

        <div v-if="hover && likedUsersList.length > 0" class="likes-dropdown dropdown-menu-center"
             @mouseover="hover = true"
             @mouseleave="hover = false"
             aria-labelledby="dropdownLikesList">
            <LikedByList :likedByUsers="likedUsersList"></LikedByList>
        </div>
    </div>
</template>

<script>
    import LikedByList from "./LikedByList";

    export default {
        name: "Likes",
        props: ['id', 'likesCount', 'isLiked', 'type', 'likedByUsers'],
        components: {LikedByList},
        data() {
            return {
                count: this.likesCount,
                likedUsersList: this.likedByUsers,
                liked: this.isLiked,
                user: window.App.user,
                hover : false,
                confirmed_user_like_message: this.__('globals.confirmed_user_like')
            }
        },
        computed: {
            classes() {
                return [this.liked ? 'text-secondary' : ''];
            }
        },
        methods: {
            onLikesHover() {
                this.$refs.likesList.visible = true;
            },
            onLikesHoverLeave() {
                this.$refs.likesList.visible = false;
            },
            toggle() {
                 this.liked ? this.unlike() : this.like();
            },
            endpoint() {
                return '/member/'+ this.type +'/' + this.id +'/likes';
            },
            like() {
                if (this.user.confirmed === 0){
                    let data = {
                        'message': this.confirmed_user_like_message,
                        'level': 'danger'
                    }
                    flash(data);
                    return;
                }
                axios.post(this.endpoint());
                this.liked = true;
                this.likedUsersList.push({'full_name': this.user.name, 'user_id': this.user.id, 'user_image': this.user.avatar.original_url, 'deleted_at': null});
                this.count++;
            },
            unlike() {
                axios.delete(this.endpoint());
                let index = this.likedUsersList.findIndex((user) => user.user_id === this.user.id);
                this.likedUsersList.splice(index, 1);
                this.liked = false;
                this.count--;
            }
        }
    }
</script>

<style scoped>
  .likes-dropdown {
      z-index: 9;
      background-color: #fff;
      padding-top: 5px;
      position:absolute;
      margin-bottom: 1rem;
      border: none;
      border-radius: 0.5rem;
      box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.4);
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }
</style>
