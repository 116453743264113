
export default {
    name: "Job",
    props: ['job'],
    components: { },
    data() {
        return {
        };
    },
    methods: {
        editPost(e) {
            e.stopPropagation();
            this.editing = true;
        },
        destroy() {
            if (confirm("Are you sure?")) {
                axios.delete("/member/job/" + this.data.module.slug);
                if(window.location.href.includes('/member/job')){
                    document.location.href='/member';
                }
                this.$parent.$emit("deleted", this.data.module.id);
            }
        },
        report() {
            $("#report_id").val(this.data.module.id);
            $("#report_type").val("App\\Models\\Job");
            $("#post_title").text(this.data.module.title);
            $("#reportModal").modal("toggle");
        },
    },
    computed: {
    },
    beforeMount() {
    },
}
