<template>
    <div>
        <div class="card-content">
                <div :id="'gallery-'+this.data.id" class="card-img-top img-fluid">
                    <img class=" img-fluid"
                         :src="this.data.event_image === null ? '/images/cover.jpg' : this.data.event_image.url">
                </div>
                <div class="card-body p-0">

                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 pt-1 border-top align-items-center justify-content-start">
                            <span class="h3"><a :href="'/member/event/'+this.data.slug" v-text="this.data.title"
                                                style="color:inherit;"></a></span>


                    </div>
                    <div class="d-flex mt-0 ml-1 mr-1 mb-0 align-items-center justify-content-start">
                            <span v-if="this.data.event_privacy === 1"
                                  class="badge badge-pill bg-gradient-secondary font-small-2 text-bold-700 mb-05 mr-05"
                                  style="padding: 0.2rem 0.5rem;"><i class="feather icon-award"></i> {{ __('globals.premium_only') }}</span>
                        <span v-if="this.sold_out === true"
                              class="badge font-small-2 text-bold-700 badge-pill badge-danger mb-05"
                              style="padding: 0.2rem 0.5rem;"><i class="feather icon-alert-circle"></i> {{ __('event.sold_out') }}</span>
                    </div>
                    <div class="d-flex ml-1 mr-1 mt-0">
                        <h6 class="primary mr-05"><i class="feather icon-calendar"></i></h6><span
                        class="h6 primary" v-text="showDateRange"></span>

                    </div>
                    <div class="d-flex ml-1 mt-0 mr-1 align-items-center justify-content-start text-light font-small-2">
                        <i class="fa fa-map-marker"></i> <span class="pl-05" v-text="this.data.location"></span>

                    </div>

                    <div class="pb-05 pt-1 pl-1 pr-1 border-top">
                        <div v-if="collapse_body" class="breakAll image-resize" v-linkified v-html="data.event_description"></div>
                        <div v-else :class="class_body+' image-resize'" ref="textBox" v-linkified v-html="data.event_description"></div>

                        <div v-if="enable_read_more">
                             <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                                {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                            </button>
                            <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                                {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>

                    <div class="d-flex p-05 align-items-center justify-content-start border-top" v-if="">
                         <span class="font-small-2 ml-05" v-if="this.can_buy_tickets === true && this.price !== null">
                             {{ __('globals.from') }} {{ this.price | currency(site_tax)}} <!--{{ __('globals.all_inclusive') }}-->
                         </span>
                         <span class="font-small-2 ml-05" v-if="this.can_buy_tickets === false && this.lowest_plan_name !== null && this.lowest_plan_price !== null">
                             {{ __('globals.from') }} {{ this.lowest_plan_price | currency(site_tax)}} {{ __('globals.for') }} {{ this.lowest_plan_name }} {{ __('ticket.upgrade_plan_message') }}
                         </span>
                         <div class="ml-auto">
                             <a v-if="this.sold_out !== true && this.can_buy_tickets === true && this.price !== null"  :href="'/member/event/'+data.slug+'/tickets'"
                                   class="btn btn-sm btn-primary waves-effect waves-light">
                                    {{ __('event.form.buttons.buy_tickets') }} <i class="fa fa-ticket"></i>
                             </a>
                             <a v-if="this.data.contact_link !== null" :href="this.data.contact_link" style="color: #fff;" target="_blank"
                                       class="btn btn-sm btn-primary waves-effect waves-light ml-auto">{{ __('event.form.buttons.view_event_site') }} <i
                                        class="feather icon-chevron-right"></i>
                            </a>
                             <a v-else :href="'/member/event/'+data.slug"
                                       class="btn btn-sm btn-flat-primary text-primary text-bold-700 waves-effect waves-light">
                                        {{ __('globals.view_event_page') }} <i class="feather icon-chevron-right"></i>
                             </a>
                         </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment-timezone";
    import tickets from "../../../../mixins/tickets";

    export default {
        name: "NewsfeedSharedEvent",
        props: ['data', 'plans'],
        mixins: [tickets],
        data() {
            return {
                site_tax: window.App.site_tax,
                price_setting: window.App.price_setting,
                enable_read_more: false,
                collapse_body: false,
                class_body: 'breakAll',
            }
        },
        computed: {
            showDateRange() {
                    let start_date = this.data.event_start_date;
                    let end_date = this.data.event_end_date;

                    let start_time = this.data.event_start_time;
                    let end_time = this.data.event_end_time;

                    start_time = moment(start_time, 'HH:mm:ss').format('h:mm A');
                    end_time = moment(end_time, 'HH:mm:ss').format('h:mm A');

                    let start_month = moment(start_date).format("MM");
                    let end_month = moment(end_date).format("MM");

                    let start_month_word = moment(start_date).format("MMMM");
                    let end_month_word = moment(end_date).format("MMMM");

                    let start_year = moment(start_date).format("YYYY");
                    let end_year = moment(end_date).format("YYYY");

                    let start_day = moment(start_date).format("Do");
                    let end_day = moment(end_date).format("Do");

                    if (start_month === end_month && start_year === end_year) {
                        if (start_day === end_day)
                            return start_day + " " + end_month_word + " " + end_year + " " + start_time + " - " + end_time;
                        else
                            return start_day + " - " + end_day + " " + end_month_word + " " + end_year + " " + start_time + " - " + end_time;
                    }

                    if (start_month !== end_month && start_year === end_year) {
                        return start_day + " " + start_month_word + " - " + end_day + " " + end_month_word + " " + end_year + " " + start_time + " - " + end_time;
                    }

                    if (start_year !== end_year) {
                        return start_day + " " + start_month_word + " " + start_year + " - " + end_day + " " + end_month_word + " " + end_year + " " + start_time + " - " + end_time;
                    }
            },
        },
        mounted(){
            if (this.$refs.textBox.clientHeight >= 300) {
                this.enable_read_more = true;
                this.class_body = 'breakAll overflow-fade';
            }
        },
        beforeMount() {
            this.ticketsPrice(this.data.tickets);
        }
    }
</script>

<style scoped>

</style>
