<template>
    <div>
        <div class="d-flex justify-content-between border-bottom">
            <div class="flex-grow-1">
                <newsfeed-owner-info
                        :user_data="data.module.owner"
                        :supplier_data="null"
                        :shared="false"
                        :type="data.type"
                        :time="data.published_at">
                </newsfeed-owner-info>
            </div>

            <div class="btn-group mt-05 mr-05">
                <div class="dropdown">
                    <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                        <i class="fa fa-chevron-circle-down mr-0"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                        <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                            class="feather icon-trash-2"></i>{{ __('question.form.links.delete_question') }}</a>
                    </span>
                        <a class="dropdown-item danger" @click.prevent="report">
                            <i class="feather icon-alert-triangle"></i>{{ __('question.form.links.report_question') }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-1 pr-1 pl-1">
            <div class="breakAll"><a :href="'/member/question/'+data.module.slug" class="font-medium-3 text-dark text-bold-700">{{ title }}</a></div>
            <div class="pt-05 pb-05">
                 <div v-if="collapse_body" class="breakAll" v-linkified v-html="body"></div>
                 <div v-else :class="class_body" ref="textBox" v-linkified v-html="body"></div>
                 <div v-if="enable_read_more">
                     <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                         {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                     </button>
                     <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                         {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                     </button>
                 </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)

export default {
    name: "NewsfeedQuestion",
    props: ['data'],
    components: {},
    data() {
        return {
            editing: false,
            item: this.data,
            body: this.data.module.body === null ? '' : this.data.module.body,
            title: this.data.module.title === null ? '' : this.data.module.title,
            user_id: window.App.user.id,
            user: window.App.user,
            isServiceAdmin: false,
            isAdmin: window.App.user.isAdmin,
            enable_read_more: false,
            collapse_body: false,
            class_body: 'breakAll',
        }
    },
    methods: {
        destroy() {
            if (confirm('Are you sure?')) {
                axios.delete('/member/question/' + this.data.module.slug);
                this.$parent.$emit('deleted', this.data.id);
            }
        },
        report() {
            $('#report_id').val(this.data.module.id);
            $('#report_type').val('App\\Models\\Stack\\Question');
            $('#post_title').text(this.data.module.title);
            $('#reportModal').modal('toggle');
        },
        checkServiceAdmin() {
                if (this.data.group !== null && this.data.group.hasOwnProperty('admins')) {
                    for (var i = 0; i < this.data.group.admins.length; i++) {
                        if (this.data.group.admins[i].id === this.user_id) {
                            this.isServiceAdmin = true;
                        }
                    }

                    if (this.data.group.user_id === this.user_id) {
                        this.isServiceAdmin = true;
                    }
                }

                 if (this.data.supplier !== null && this.data.supplier.hasOwnProperty('admins')) {
                    for (var i = 0; i < this.data.supplier.admins.length; i++) {
                        if (this.data.supplier.admins[i].id === this.user_id) {
                            this.isServiceAdmin = true;
                        }
                    }

                    if (this.data.supplier.user_id === this.user_id) {
                        this.isServiceAdmin = true;
                    }
                }
        },
    },
    beforeMount() {
        this.checkServiceAdmin();
    },
    mounted(){
         if (this.$refs.textBox.clientHeight >= 300) {
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
}
</script>

