<template>
    <div>
        <div class="card" v-if="this.shared_user">
            <div class="d-flex justify-content-start border-bottom">
                <shared-post-owner-info
                    :user_data="this.shared_user"
                    :supplier_data="[]"
                    :isServiceAdmin="isServiceAdmin"
                    :shared="true"
                    :type="this.data.type"
                    :user_id="user_id" />
                <div class="btn-group mt-05" style="right:0.5rem; position: absolute;">
                    <span v-text="ago(this.data.created_at)" class="font-small-1 text-light "
                          style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || this.data.user_id === user_id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="editPost"><i
                            class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                    </span>
                            <span
                                v-if="isServiceAdmin === true || this.data.user_id === user_id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="deleteNewsfeed"><i
                            class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pl-05 pr-05 mt-05">
                <div v-if="editing" class="form-group pr-05 pl-05">
                    <div class="mb-05 mx-05">
                        <div class="card my-0">
                            <editor
                                v-model="shared_text"
                                api-key="no-api-key"
                                :init='{
                                        skin: "trybz-member",
                                        skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                        content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                        external_plugins: {
                                            customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                            loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                        },
                                        valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                        plugins: "link quickbars autolink lists customtags loadingbar",
                                        link_context_toolbar: true,
                                        quickbars_selection_toolbar: "bold italic link bullist",
                                        quickbars_image_toolbar: false,
                                        quickbars_insert_toolbar: false,
                                        link_assume_external_targets: "https",
                                        link_target_list: false,
                                        link_title: false,
                                        toolbar: false,
                                        branding: false,
                                        elementpath: false,
                                        menubar: false,
                                        file_picker_types: "",
                                        height: 100,
                                        content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                        relative_urls : false,
                                        remove_script_host : false,
                                    }'
                            />
                        </div>
                    </div>
                    <button class="btn btn-sm btn-primary" @click.prevent="updateSharedNewsfeed">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click.prevent="cancelEditing">{{ __('globals.cancel') }}</button>
                </div>
                <div class="pt-05" v-else-if="body !== null">
                    <div class="breakAll pr-05 pl-05" v-linkified
                         v-html="this.$options.filters.nl2br(body, true)">
                    </div>
                </div>

                <div class="d-flex justify-content-center pr-05 pl-05 pt-05">
                    <div class="card border-light w-100">
                        <shared-post-owner-info
                                    :user_data="this.data.module.owner"
                                    :supplier_data="this.shared_supplier.length > 0 ? this.shared_supplier : []"
                                    :isServiceAdmin="isServiceAdmin"
                                    :shared="false"
                                    type="post"
                                    :user_id="user_id"
                        />
                        <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                            <span v-text="ago(this.data.module.created_at)" class="font-small-1 text-light " style="margin-top: 4px;"></span>
                        </div>

                        <div class="card-content">
                            <img :class="'img-fluid product-image-'+data.id"
                                 :src="data.module.media[0].url">
                            <div v-if="data.module.media.length > 1">
                            <span v-for="media in data.module.media">
                                <a @click.prevent="changeImage(data.id, media.url)">
                                    <img class="rounded ml-05 mt-05 mr-0 mb-0" :src="media.thumbnail" height="50" width="50">
                                </a>
                            </span>
                            </div>
                            <div class="card-body p-0">
                            </div>
                            <div class=" mt-1 ml-1 mr-1 mb-05">
                            <span class="h3"><a :href="'/member/product/'+data.module.slug"
                                                v-text="data.module.title" class="text-dark"></a></span>
                            </div>
                            <div class="mt-05 ml-1 mr-1 mb-05" v-if="rating !== null">
                                <span :class="'rating-static rating-'+rating * 10">  </span>
                            </div>
                            <div class="ml-1 mr-1 mt-1 mb-0 pb-1 breakAll postarea" v-linkified
                                 v-html="data.module.product_description"></div>
                            <div class="m-05 text-right"><a :href="'/member/product/'+data.module.slug"
                                                            class="btn btn-sm btn-flat-primary text-bold-700 ">{{ __('globals.view_product') }}
                                <i class="feather icon-chevron-right"></i></a></div>
                            <div class="d-flex pl-05 pr-05 pt-0 pb-05 justify-content-between align-items-center ">
                                <div class="ml-1 text-bold-700" v-if="this.price !== 0 && this.price !== null"
                                     v-text="this.$options.filters.currency(this.price, site_tax)"></div>
                                <div class="">
                                    <a :href="'/member/product/'+data.module.slug" v-if="data.module.external_link != null">
                                        <button class="btn btn-sm btn-primary waves-effect waves-light">
                                            {{ __('globals.more_info') }} <i class="feather icon-chevron-right"></i>
                                        </button>
                                    </a>

                                    <a :href="'/member/product/'+data.module.slug+'/add_to_cart'" v-else>
                                        <button class="btn btn-sm btn-primary waves-effect waves-light"
                                                v-if="this.price !== 0 && this.price !== null">
                                            {{ __('globals.buy_now') }} <i class="fa fa-tag"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div class="pl-1 pr-1 pt-05 mb-05" v-if="this.shared_group.length > 0">
                                <a :href="'/member/group/'+this.shared_group[0].slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;"
                                             :src="this.shared_group[0].group_cover == null ? '/images/cover.jpg' : this.shared_group[0].group_cover.url">
                                        <div
                                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ this.shared_group[0].title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                                class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="pl-1 pr-1 pt-05 mb-05" v-if="this.shared_supplier.length > 0">
                                <a :href="'/member/supplier/'+this.shared_supplier[0].slug">
                                    <div class="card text-white mb-0 border-0">
                                        <img class="card-img img-fluid" style="height: 3rem;"
                                             :src="this.shared_supplier[0].supplier_cover_image == null ? '/images/cover.jpg' : this.shared_supplier[0].supplier_cover_image.url">
                                        <div
                                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                                {{ this.shared_supplier[0].title }}</p>
                                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                                class="feather icon-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="card-body border-top ml-05 mr-05 p-0">
                                <div class="d-flex justify-content-between">
                                    <div class=" p-05">
                                        <like :post="data.module" type="post"></like>
                                    </div>
                                    <div class="p-05 font-small-3 text-bold-700"><a
                                        @click.prevent="showComments(data.id)"><i
                                        class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pl-1 pr-1 pb-05" v-if="data.groups !== undefined && data.groups.length > 0">
                <a :href="'/member/group/'+data.groups[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.groups[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pl-1 pr-1 pb-05" v-if="data.suppliers !== undefined && data.suppliers.length > 0">
                <a :href="'/member/supplier/'+data.suppliers[0].slug">
                    <div class="card text-white mb-0 border-0">
                        <img class="card-img img-fluid" style="height: 3rem;"
                             :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                        <div
                            class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }} </strong>
                                {{ data.suppliers[0].title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i
                                class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  :module="data.type"
                  :slug="data.module.id"
                  :members="members"
        ></comments>
    </div>
</template>

<script>
import Product from '../../../mixins/newsfeed/Product'
import DateTime from '../../../mixins/newsfeed/DateTime'
import Post from '../../../mixins/newsfeed/Post'
import SharedFunctions from "../../../mixins/newsfeed/SharedFunctions";

export default {
    props: ['members'],
    name: "SharedProduct",
    data() {
        return {
        }
    },
    mixins: [
        SharedFunctions, Product, DateTime, Post
    ],
    mounted() {
        this.getSharedPost()
    }
}
</script>
