<template>
    <div class="col-md-12 profile-card-2">
        <div class="card p-05 shadow-none" style="width: 100%; border: none;">
            <div class="card-content p-0">
                <div v-for="(member, index) in likedByUsers" :key="member.id" v-if="index < 10 && member.deleted_at === null">
                    <div class="card shadow-none p-0" style="border: none;">
                        <div class="d-flex align-items-center">
                                <div class="pr-1"><img class="round"
                                                      :src="member.user_image !== null ? member.user_image :  '/images/avatar.png'"
                                                      alt="avatar"
                                                      height="20"
                                                      width="20"/>
                                </div>
                                <div style="color:inherit;" class="text-bold-600">
                                    {{member.full_name}}
                                </div>
                        </div>
                    </div>
                </div>
                <span v-if="likedByUsers.length > 10">And {{ likedByUsers.length - 10 }} {{ __('globals.more') }}...</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LikedByList",
    props: ['likedByUsers'],
}
</script>
