<template>
    <li class="list-supplier-item "  style="list-style-type: none;">
        <div class="d-flex align-items-center">
            <div>
                <img class="round"
                     :src="member.avatar !== null ? member.avatar.url :  '/images/avatar.png'"
                     alt="avatar"
                     height="25"
                     width="25"
                />
            </div>
            <div>
                <span class="font-weight-bold ml-05 mb-0 mt-1">{{ member.first_name }} {{ member.last_name }}</span>

                <span v-for="badge in member.badges">
                    <img :src="badge.image" style="margin-top: -3px;  height: 12px;" data-toggle="tooltip" data-placement="bottom"
                         :title="badge.name" alt="badge image" />
                </span>

                <img :src="member.user_badge_image.url" style="margin-top: -3px;  height: 12px;" data-toggle="tooltip" data-placement="bottom"
                     :title="member.user_badge_name" v-if="member.user_badge_name !== null && member.user_badge_icon !== null" alt="badge image"
                />
            </div>
            <div class="ml-auto">
                <div class="d-flex" v-if="siteAdmin || admins.includes(userId)">
                    <div class="pr-05">
                        <admin :type="type"
                               :slug="slug"
                               :member="member"
                               :is_admin="admins.includes(member.id)"
                        >
                            <div slot="buttons_make_admin">
                                <slot name="buttons_make_admin"></slot>
                            </div>

                            <div slot="buttons_admin">
                                <slot name="buttons_admin"></slot>
                            </div>
                        </admin>
                    </div>
                    <div>
                        <manage-followers :type="type"
                                          :slug="slug"
                                          :member="member"
                        >
                        </manage-followers>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>

import ManageFollowers from './ManageFollowers'
import ServiceAdmin from "./ServiceAdmin";

export default {
    name: "Followers",
    components: {ManageFollowers, ServiceAdmin},
    props: ['type', 'slug', 'member', 'admins'],
    data(){
        return {
            siteAdmin: window.App.user.isAdmin,
            userId: window.App.user.id
        }
    },
}
</script>

<style scoped>

</style>
