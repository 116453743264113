<template>
    <div class="d-flex align-items-top ml-3 mr-1">
        <div class="card  mb-1 d-inline-block ml-05 w-100">
            <div class="d-flex border-bottom pt-05 p-05">
                <comment-owner-info :user_data="this.data.owner"></comment-owner-info>
                <div class="ml-auto">
                    <span v-text="ago" class="text-light font-small-1 d-block"></span>
                    <div v-if="data.owner.id === this.user_id || this.is_admin === true">
                        <button class="btn-sm btn-flat-dark float-right" style="border:none;" @click="editing = !editing"><i class="fa fa-edit"></i></button>
                        <button class="btn-sm btn-flat-danger float-right" style="border:none;" @click="destroy"><i class="fa fa-trash-o"></i></button>
                    </div>
                </div>
            </div>

            <div class="d-flex ml-1 mr-1 mb-1 mt-05">
                <div v-if="editing" class="form-group w-100 mb-0">

                    <div class="card mb-1 shared-post-reply">
                        <editor
                            v-model="comment"
                            api-key="no-api-key"
                            :init='{
                                skin: "trybz-member",
                                skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                external_plugins: {
                                    customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                    loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                },
                                valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                plugins: "link quickbars autolink lists customtags loadingbar",
                                link_context_toolbar: true,
                                quickbars_selection_toolbar: "bold italic link bullist",
                                quickbars_image_toolbar: false,
                                quickbars_insert_toolbar: false,
                                link_assume_external_targets: "https",
                                link_target_list: false,
                                link_title: false,
                                toolbar: false,
                                branding: false,
                                elementpath: false,
                                menubar: false,
                                file_picker_types: "",
                                height: 100,
                                content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                relative_urls : false,
                                remove_script_host : false,
                            }'
                        />
                    </div>

                    <button class="btn btn-sm btn-primary" @click="update">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click="cancelEditing">{{ __('globals.cancel') }}</button>
                </div>

                <div v-else v-html="comment_body" class="col-12">
                </div>
                <div class="p-05">

                </div>
            </div>

            <div class="card-body p-0 border-top w-100">
                <div class="d-flex">
                    <div class="pr-1 pl-1">
                         <likes :id="this.data.id" :likesCount="this.data.likesCount" :isLiked="this.data.isLiked" type="comment" :likedByUsers="this.data.userLikes" ></likes>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment-timezone';
    import Editor from '@tinymce/tinymce-vue'
    import CommentOwnerInfo from './CommentOwnerInfo';
    import Likes from './Likes';
    import axios from 'axios'

    export default {
        props: ['data'],
        name: 'ChildComment',
        components: {
            'editor': Editor,
            CommentOwnerInfo,
            Likes
        },
        data() {
            return {
                editing: false,
                comment: this.data.comment_text,
                comment_body: this.data.comment_text,
                comment_updated_message: this.__('comment.comment_updated'),
                id: this.data.id,
                user_id: window.App.user.id,
                is_admin: window.App.user.isAdmin,
            };
        },
        computed: {
            canUpdate() {
                return this.authorize(user => (this.data.user_id === user.id) || this.is_admin === true)
            },
            ago() {
                return moment(this.data.updated_at).format('DD-MM-YY');
            },
            commentsCount() {
                if (this.data.commentsCount > 0) {
                    return `(${this.data.commentsCount})`;
                }
            }
        },
        methods: {
            update() {
                axios.patch('/member/comment/' + this.data.id, {
                    comment_text: this.comment
                });
                this.comment_body = this.comment;
                this.editing = false;
                let data = {
                    'message': this.comment_updated_message,
                    'level': 'success'
                }
                flash(data);
            },
            destroy() {
                axios.delete('/member/comment/' + this.data.id)

                this.$emit('deleted', this.data.id);

            },
            cancelEditing(){
                this.editing = false;
                this.comment= this.data.comment_text;
            }
        }
    }
</script>

<style scoped>

</style>
