<template>
    <div class="px-2">
        <div class="px-auto">
            <h5>{{ __('question.new') }}</h5>
        </div>

        <div class="mt-1 form-group">
            <div class="mb-05">
                <label for="question_title" class="required">Question Title</label>
                <input  v-model="question_title" type="text" class="form-control"/>
                <div class="ml-auto text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.question_title != null">
                    <i class="feather icon-x-circle"></i> {{this.errors.question_title[0]}}
                </div>
            </div>

            <div class="mb-05">
                <label for="question_body mt-1" class="required">Question Text</label>
                <div class="card my-0">
                    <editor
                        v-model="question_text"
                        api-key="no-api-key"
                        :init='{
                            skin: "trybz-member",
                            skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                            content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                            external_plugins: {
                                customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                            },
                            valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                            plugins: "link quickbars autolink lists customtags loadingbar",
                            link_context_toolbar: true,
                            quickbars_selection_toolbar: "bold italic link bullist",
                            quickbars_image_toolbar: false,
                            quickbars_insert_toolbar: false,
                            link_assume_external_targets: "https",
                            link_target_list: false,
                            link_title: false,
                            toolbar: false,
                            branding: false,
                            elementpath: false,
                            menubar: false,
                            file_picker_types: "",
                            height: 100,
                            content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                            relative_urls : false,
                            remove_script_host : false,
                        }'
                    />
                </div>
                <div class="ml-auto text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.question_body != null">
                    <i class="feather icon-x-circle"></i> {{this.errors.question_body[0]}}
                </div>
            </div>

             <div v-if="question_tags.length > 0" class="mb-05 w-100">
                 <label class="">{{ __('question.field.text.tags') }}</label>
                 <select id='tags' class="form-control" multiple="multiple" v-model="selected_question_tags">
                     <option :value="tag.id" v-for="tag in question_tags">{{tag.name}}</option>
                 </select>
                 <div class="text-left text-danger help-block font-small-1 " role="alert" v-if="this.errors.question_tags != null">
                     <i class="feather icon-x-circle"></i> {{this.errors.question_tags[0]}}
                 </div>
             </div>

            <div class="w-100">
                <label class="required">{{ __('question.field.text.service_label') }}</label>
                <select id="service" class="form-control" v-model="service">

                    <option value="profile" >{{ __('globals.home_newsfeed') }}</option>
                    <option v-if="default_group" :value="'group_'+ default_group.id">{{ default_group.title }}</option>

                    <optgroup :label="__('globals.share_group')">
                        <option :value="'group_'+group.id" v-for="group in groups_followed">{{group.title}}</option>
                    </optgroup>
                    <optgroup :label="__('globals.share_supplier')">
                        <option :value="'supplier_'+supplier.id" v-for="supplier in suppliers_followed" v-if="supplier.enable_faqs === 1">{{supplier.title}}</option>
                    </optgroup>
                </select>
                <div class="text-left text-danger help-block font-small-1" role="alert" v-if="this.errors.service != null">
                    <i class="feather icon-x-circle"></i> {{this.errors.service[0]}}
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mb-05">
                <button type="submit" class="btn btn-primary" @click.prevent="createQuestion">Create</button>
        </div>
    </div>

</template>

<script>

import lightbox from "./post/lightbox/lightbox";
import Editor from '@tinymce/tinymce-vue';
import 'vue-select/dist/vue-select.css';
import TomSelect from 'tom-select';

export default {
    name: "Question",
    props: ['default_group'],
    components: {
        lightbox,
        'editor': Editor
    },
    data(){
        return {
            question_title: '',
            question_text: this.question_body,
            question_tags: '',
            service: '',
            groups_followed: window.App.groupsFollowed,
            suppliers_followed: window.App.suppliersFollowed,
            selected_question_tags: [],
            submitting: false,
            errors: {
                'question_title': null,
                'question_body': null,
                'question_tags': null,
                'service': null
            },
        }
    },
    mounted() {
        new TomSelect('#service', {});
    },
    methods: {
        init(){
            this.getTags();
        },
        errorsReset(){
            this.errors = {
                'question_title': null,
                'question_body': null,
                'question_tags': null,
                'service': null
            };
        },
        async createQuestion(){
            if(this.submitting){
                return false;
            }
            this.submitting = true;
            this.errorsReset();

            return await axios
                .post('/member/question', {
                    title: this.question_title,
                    body: this.question_text,
                    service: this.service,
                    question_tags: this.selected_question_tags,
                    type: 'new'
                })
                .then((response)=>{
                    flash(response.data.flash);
                    window.location.href='/member/question/'+response.data.slug;
                })
                .catch(err => {
                    this.errors = {
                        'question_title': err.response.data.errors.title,
                        'question_body': err.response.data.errors.body,
                        'question_tags': err.response.data.errors.question_tags,
                        'service': err.response.data.errors.service
                    };
                    this.submitting = false;
                });
        },
        async getTags(){
           await  axios
                .get('/member/question/tags')
                .then(response => {
                    this.question_tags = response.data;
                })

           if(this.question_tags.length > 0){
                new TomSelect('#tags',
                    {
                            plugins: {
                                'clear_button':{
                                    'title':'Remove all selected options',
                                },
                                'remove_button':{
                                    'title':'Remove this item',
                                }
                            },
                    }
                );
           }
        },
    },
    beforeMount(){
        if(this.default_group){
            this.service = 'group_'+this.default_group.id;
        } else {
            this.service = 'profile';
        }

        this.init();
    },

}

</script>

<style scoped>

</style>
