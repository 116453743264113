<template>
    <div>
        <div class="card">

            <div class="d-flex border-bottom">
                <div v-if="data.suppliers !== undefined && data.suppliers.length === 0">
                    <div class="d-flex justify-content-start mb-05">
                        <div class="p-05">
                            <img class="round"
                                 :src="data.module.owner.avatar !== null ? data.module.owner.avatar.url :  '/images/avatar.png'"
                                 alt="avatar"
                                 height="30"
                                 width="30"/>
                        </div>
                        <div class="p-0  pt-05 justify-content-start">
                            <div class="user-name text-bold-600">
                                <div class="dropdown dropdown-user nav-item">
                                    <a class="dropdown-toggle dropdown-user-link"
                                       href="#"
                                       data-toggle="dropdown"
                                       v-text="data.module.owner.first_name+' '+data.module.owner.last_name">
                                    </a>
                                    <span v-for="badge in data.module.owner.badges">
                                    <img :src="badge.image" style="margin-top: -3px;  height: 15px;"
                                         data-toggle="tooltip"
                                         data-placement="bottom"
                                         :title="badge.name">
                                </span>
                                    <span v-if="data.module.owner.user_badge_id !== null">
                            <img :src="data.module.owner.user_badge_image.url" style="margin-top: -3px;  height: 15px;"
                                 data-toggle="tooltip"
                                 data-placement="bottom"
                                 :title="data.module.owner.user_badge_name">
                        </span>
                                     <div class="dropdown-menu dropdown-menu-center">
                                        <profile :data="data"></profile>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: -3px; line-height: 1;"><span v-text="data.module.owner.position"
                                                                                 class="font-small-2 text-light"></span><span
                                class="font-small-2 text-light"> at </span><span v-text="data.module.owner.organisation"
                                                                                 class="font-small-2 text-light"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="data.suppliers !== undefined && data.suppliers.length === 1">
                    <div class="d-flex justify-content-start mb-05">
                        <div class="p-05">
                            <img class="round"
                                 :src="data.suppliers[0].supplier_avatar !== null ? data.suppliers[0].supplier_avatar.url :  '/images/avatar.png'"
                                 alt="avatar"
                                 height="30"
                                 width="30"/>
                        </div>
                        <div class="p-0  pt-05 justify-content-start">
                            <div class="user-name text-bold-600 text-dark">
                                <a :href="'member/supplier/'+data.suppliers[0].slug" class="text-dark" v-text="data.suppliers[0].title"></a>
                            </div>
                            <div style="margin-top: -5px" class="font-small-2 text-primary">@{{
                                    data.suppliers[0].slug
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                <span v-text="ago(data.module.created_at)" class="font-small-1 text-light "
                      style="margin-top: 4px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                    <span v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/edit'"><i
                            class="feather icon-settings"></i>{{ __('globals.edit_post') }}</a>
                    </span>
                            <span
                                v-if="isServiceAdmin === true || user_id === data.module.owner.id || isAdmin === true">
                        <a class="dropdown-item" href="#" @click.prevent="destroy"><i
                            class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}</a>
                    </span>
                            <a class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shadow" v-if="data.module.cover !== null">

                <img class="img-fluid"
                     :src="data.module.cover.url" style="object-fit: cover;">

            </div>
            <div class="card-content pt-1 pb-1 border-bottom">

                <div class="col-md-12">
                    <div class="d-flex">
                        <div class="w-100">
                            <div v-if="data.module.cover === null">
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'doc'"><img src="/images/icons/doc.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'docx'"><img src="/images/icons/doc.png"
                                                                                           width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'txt'"><img src="/images/icons/doc.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'pdf'"><img src="/images/icons/pdf.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'xls'"><img src="/images/icons/xls.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'xlsx'"><img src="/images/icons/xls.png"
                                                                                           width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'ppt'"><img src="/images/icons/ppt.png"
                                                                                          width="25px"></div>
                                <div class="d-inline-block mr-05 mb-05 float-left"
                                     v-if="data.module.resource_file_type === 'pptx'"><img src="/images/icons/ppt.png"
                                                                                           width="25px"></div>
                            </div>
                            <span class="h3"><a :href="'/member/' + this.data.module.type + '/'+data.module.slug"
                                                style="color: inherit">{{ data.module.title }}</a></span>

                        </div>
                    </div>
                    <div>
                        <div class="breakAll pt-1" v-linkified style="max-height: 300px; overflow: hidden; text-overflow: ellipsis;">
                            <span class="breakAll" v-html="data.module.resource_description"></span>
                        </div>
                        <div class="text-right">
                            <a :href="'/member/'+data.module.type+'/'+data.module.slug" class="font-small-3">{{ __('globals.read_more') }}<i class="feather icon-chevron-right"></i></a>
                        </div>
                    </div>
                    <div>
                        <div class="pb-1 pt-05 text-bold-700" v-if="data.module.canBeDownloaded === false && data.module.resource_price > 0">
                            <span class="font-small-2 pt-1"> {{ data.module.resource_price | currency(site_tax) }} {{ __('globals.all_inclusive') }}</span>
                        </div>

                    </div>

                </div>

                <div>
                    <div class="p-1" v-if="user.confirmed">
                            <span class="border-bottom" v-if="data.module.canBeDownloaded">
                                <div v-if="data.module.external_url !== null">
                                    <a :href="data.module.external_url" target="_blank"
                                       class="download-button btn btn-primary waves-effect waves-light form-control">
                                            <i class="fa fa-link"></i> {{ __('resource.form.buttons.access_external_resource') }}
                                    </a>
                                </div>
                                <div v-else>
                                    <a :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/download-file'"
                                       class="download-button btn btn-primary waves-effect waves-light form-control">
                                            <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                                    </a>
                                </div>

                            </span>
                        <span class="border-bottom"
                              v-else-if="data.module.resource_price > 0 && data.module.canBeDownloaded === false">
                                <a :href="'/member/' + this.data.module.type + '/'+data.module.slug+'/add_to_cart'"
                                   class="download-button btn btn-primary waves-effect waves-light form-control"><i
                                    class="fa fa-download"></i> {{ __('resource.form.buttons.buy') }} </a>
                            </span>
                    </div>
                </div>

                <div id="progress" class="p-2" style="display: none"></div>
                <div class="col-12 p-0">
                    <div v-if="data.groups !== undefined && data.groups.length > 0">
                        <a :href="'/member/group/'+data.groups[0].slug">
                            <div class="card text-white mb-0 mx-1">
                                <img class="card-img img-fluid" style="height: 3rem;"
                                     :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                <div
                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;">
                                        <strong>{{ __('globals.posted_in') }} </strong> {{ data.groups[0].title }}</p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }}
                                        <i
                                            class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div
                        v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                        <a :href="'/member/supplier/'+data.suppliers[0].slug">
                            <div class="card text-white mb-0 mx-1">
                                <img class="card-img img-fluid" style="height: 3rem;"
                                     :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                <div
                                    class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                    <p class="text-white mb-0" style="line-height: 1rem;">
                                        <strong>{{ __('globals.posted_to') }} </strong> {{ data.suppliers[0].title }} </p>
                                    <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }}
                                        <i
                                            class="feather icon-chevron-right"></i></button>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div class="d-flex justify-content-between">
                <div class="p-05">
                    <like :post="data.module" :type="data.module.type"></like>
                </div>
                <div class="p-05">
                    <a @click.prevent="showComments(data.id)" class="font-small-3 text-bold-700">
                        <i class="fa fa-comment-o"></i> {{ __('globals.comment') }} <span v-text="commentsCount"></span>
                    </a>
                </div>
                <social-share :id="data.id" :social="this.data.module.socialShare"/>
            </div>
        </div>
        <comments :data="data.module.comments"
                  :newsfeed_id="data.id"
                  module="resource"
                  :slug="data.module.slug"
                  :members="members"
        ></comments>
        <div class="modal fade" :id="'shareModal'+data.id" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ __('globals.share_resource') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="p-1 card border-light">
                            <div class="">
                                <label :for="'comment_body'+data.id">{{
                                        __('globals.enter_comment')
                                    }}</label>
                                <textarea :name="'comment_body'+data.id" :id="'comment_body'+data.id" cols="30" rows="3"
                                          class="form-control"></textarea>
                            </div>
                            <div class="pb-1">
                                <label :for="'feed'+data.id">{{ __('globals.select_share') }}</label>
                                <select :name="'feed'+data.id" :id="'feed'+data.id" class="form-control">
                                    <option value="profile">{{ __('globals.your_newsfeed') }}</option>
                                    <optgroup :label="__('globals.share_group')" v-if="groups_follow !== undefined">
                                        <option :value="'group_'+group.id" v-for="group in groups_follow">{{
                                                group.title
                                            }}
                                        </option>
                                    </optgroup>
                                    <optgroup :label="__('globals.share_supplier')"
                                              v-if="suppliers_follow !== undefined">
                                        <option :value="'supplier_'+supplier.id"
                                                v-for="supplier in suppliers_follow">{{ supplier.title }}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>

                            <div class="p-1 card border-light">
                                <post-owner-info :data="data"
                                                 :isServiceAdmin="isServiceAdmin"
                                                 :user_id="user_id"
                                />
                                <div class="btn-group mt-05" style="right: 0.5rem; position: absolute;">
                                               <span v-text="ago(data.published_at)" class="font-small-1 text-light "
                                                     style="margin-top: 4px;"></span>
                                </div>

                                <div class=" mt-0 mb-0 border-top border-bottom">
                                    <div class=" pt-1 pb-1 col-12">
                                        <div class="text-center rounded" style="background-color: #000;">
                <span v-if="data.module.cover !== null">
                    <img class="img-fluid"
                         :src="data.module.cover == null ? data.module.thumbnail : data.module.cover.url">
                </span>


                                        </div>
                                        <div v-if="data.module.cover === null">
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'doc'"><img
                                                src="/images/icons/doc.png"
                                                width="25px"></div>
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'docx'"><img
                                                src="/images/icons/doc.png"
                                                width="25px"></div>
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'txt'"><img
                                                src="/images/icons/doc.png"
                                                width="25px"></div>
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'pdf'"><img
                                                src="/images/icons/pdf.png"
                                                width="25px"></div>
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'xls'"><img
                                                src="/images/icons/xls.png"
                                                width="25px"></div>
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'xlsx'"><img
                                                src="/images/icons/xls.png"
                                                width="25px"></div>
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'ppt'"><img
                                                src="/images/icons/ppt.png"
                                                width="25px"></div>
                                            <div class="d-inline-block mr-05 mb-05 float-left"
                                                 v-if="data.module.resource_file_type === 'pptx'"><img
                                                src="/images/icons/ppt.png"
                                                width="25px"></div>
                                        </div>
                                        <span class="h3">{{ data.module.title }}</span>

                                        <div class="breakAll pt-2" v-linkified
                                             v-html="this.$options.filters.nl2br(data.module.resource_description.substring(0,400), true)"></div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div v-if="data.groups !== undefined && data.groups.length > 0">
                                            <a :href="'/member/group/'+data.groups[0].slug">
                                                <div class="card text-white mb-0">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.groups[0].group_cover == null ? '/images/cover.jpg' : data.groups[0].group_cover.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;">
                                                            <strong>{{ __('globals.posted_in') }} </strong> {{ data.groups[0].title }}</p>
                                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }}
                                                            <i
                                                                class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            v-else-if="data.suppliers !== undefined && data.suppliers.length > 0">
                                            <a :href="'/member/supplier/'+data.suppliers[0].slug">
                                                <div class="card text-white mb-0">
                                                    <img class="card-img img-fluid" style="height: 3rem;"
                                                         :src="data.suppliers[0].supplier_cover_image == null ? '/images/cover.jpg' : data.suppliers[0].supplier_cover_image.url">
                                                    <div
                                                        class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                                                        <p class="text-white mb-0" style="line-height: 1rem;">
                                                            <strong>{{ __('globals.posted_to') }} </strong> {{ data.suppliers[0].title }} </p>
                                                        <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }}
                                                            <i
                                                                class="feather icon-chevron-right"></i></button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('globals.close') }}</button>
                        <button type="button" class="btn btn-primary" @click.prevent="sharePost(data.id)">{{ __('globals.share') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Share from "../../../mixins/newsfeed/Share";
import Resource from "../../../mixins/newsfeed/Resource";
import DateTime from "../../../mixins/newsfeed/DateTime";

export default {
    props: ['members'],
    name: "NewsfeedResource",
    data() {
        return {
            price_setting: window.App.price_setting,
            price: '',
            plan_prices: '',
            user: window.App.user,
            site_tax: window.App.site_tax,
        }
    },
    mixins: [
        Share, Resource, DateTime
    ],
    mounted() {
        axios
            .get('/member/' + this.data.module.type + '/' + this.data.module.slug + '/get_price')
            .then(response => {
                this.price = response.data.price;
            });

        axios
            .get('/member/' + this.data.module.type + '/' + this.data.module.slug + '/get_all_price_plans')
            .then(response => {
                this.plan_prices = response.data.plan_prices;
            });
    },

}
</script>

<style scoped>

</style>
