<template>
    <div>
        <div class="card h-auto" style="width: 100%;">
            <div class="d-flex justify-content-start border-bottom">
                <div class="flex-grow-1">
                    <newsfeed-owner-info
                            :user_data="data.module.owner"
                            :profile_data="null"
                            :supplier_data="data.supplier"
                            :shared="false"
                            :type="data.type"
                            :time="null">
                    </newsfeed-owner-info>
                </div>

                 <div class="btn-group mt-1" style="right:10px; position: absolute;">
                    <span v-text="this.publish_at" :title="this.publish_at_full" class="font-small-2 text-muted " style="margin-top: 3px;"></span>
                    <div class="dropdown">
                        <button class="btn-sm btn-outline-light dropdown-toggle " type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border:none">
                            <i class="fa fa-chevron-circle-down mr-0"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200">
                            <span v-if="canUpdate">
                                <button class="dropdown-item" @click="editing = true">
                                    <i class="feather icon-settings"></i>{{ __('globals.edit_post') }}
                                </button>
                            </span>
                            <span v-if="canUpdate">
                                <button class="dropdown-item danger" @click="destroy">
                                    <i class="feather icon-trash-2"></i>{{ __('globals.delete_post') }}
                                </button>
                            </span>
                            <button v-if="user_id !== data.module.user_id" class="dropdown-item danger" @click.prevent="report">
                                <i class="feather icon-alert-triangle"></i>{{ __('globals.report_post') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex ml-1 mr-1 mt-0 mb-0">
                <div v-if="editing" class="form-group w-100">
                    <div class="card mb-1 mt-1">
                        <editor
                            v-model="post_text"
                            api-key="no-api-key"
                            :init='{
                                skin: "trybz-member",
                                skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                                content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                                external_plugins: {
                                    customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                                    loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                                },
                                valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                                plugins: "link quickbars autolink lists customtags loadingbar",
                                link_context_toolbar: true,
                                quickbars_selection_toolbar: "bold italic link bullist",
                                quickbars_image_toolbar: false,
                                quickbars_insert_toolbar: false,
                                link_assume_external_targets: "https",
                                link_target_list: false,
                                link_title: false,
                                toolbar: false,
                                branding: false,
                                elementpath: false,
                                menubar: false,
                                file_picker_types: "",
                                height: 100,
                                content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                                relative_urls : false,
                                remove_script_host : false,
                            }'
                        />
                    </div>

                    <div class="form-group" v-if="external_video_link !== null">
                        <label for="external_video_link">{{ __('video.field.text.external_url') }}:</label>
                        <input class="form-control" type="text" name="external_video_link" id="external_video_link" v-model="external_video_link"/>
                    </div>

                    <div class="form-group" id="date_time">
                        <label class="required" for="published_at">{{ __('post.post_published_on') }}:</label>
                        <input class="form-control" type="datetime-local" name="published_at" id="published_at"
                            v-model="date_time">
                        <span class="help-block" role="alert"></span><strong>({{ siteTimezone }})</strong>
                    </div>
                    <button class="btn btn-sm btn-primary" @click="update()">{{ __('globals.update') }}</button>
                    <button class="btn btn-sm btn-link" @click="cancel()">{{ __('globals.cancel') }}</button>
                </div>
                <div class="d-flex pt-1 pb-1" v-else-if="post_text !== null">
                    <div class="breakAll" v-linkified v-html="this.$options.filters.nl2br(post_text, true)"></div>
                </div>
            </div>

            <div class="mb-05" v-if="data.module.video_link !== null">
                <video-player :options="videoOptions" />
            </div>

            <div class="video_container mb-05" v-else-if="external_video_link !== null && external_video_link !== '' && !editing">
                <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                        mozallowfullscreen allowfullscreen :src="external_video_link"></iframe>
            </div>

            <div class="gallery-row mb-05" v-if="data.module.post_image.length > 1">
                <div class="gallery-container">
                    <lightbox :items="images"></lightbox>
                </div>
            </div>

            <div class="mb-05" v-else-if="data.module.post_image.length === 1">
                <div class="card-img-top img-fluid">
                    <img class=" img-fluid"
                         :src="data.module.post_image[0].url" />
                </div>
            </div>

            <div class="m-1" v-if="!editing && data.module.post_file !== null">
                <a  @click.prevent="downloadFile(file.id, index)" v-for="(file, index) in data.module.post_file">
                    <div class="card bg-outline-light text-center m-0">
                        <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <strong class="text-center m-auto"><i class="feather icon-download"></i> {{ __('post.form.buttons.download_file') }} ...{{ file.file_name.slice(-20) }}</strong>
                        </div>
                    </div>
                </a>
            </div>

            <div v-if="editing">
                <div class="m-1" v-if="data.module.post_file !== null">
                    <div class="d-flex" v-for="(file, index) in data.module.post_file">
                        <a class="flex-grow-1 pr-1" @click.prevent="downloadFile(file.id, index)" >
                            <div class="card bg-outline-light text-center m-0">
                                <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                                    <strong class="text-center m-auto"><i class="feather icon-download"></i> {{ __('post.form.buttons.download_file') }} </strong>
                                </div>
                            </div>
                        </a>
                        <a class="ml-auto" href="#" @click.prevent="deleteAttachment(file.id, index)">
                            <div class="btn btn-danger text-center" >
                                <span v-if="canUpdate">
                                    <strong class="w-100 text-center"><i class="feather icon-trash-2"></i> {{ __('globals.delete') }} </strong>
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="mx-1 mt-1 mb-05 border-top" v-if="data.group !== null">
                <a :href="'/member/group/'+data.group.slug">
                    <div class="card text-white">
                        <img class="card-img img-fluid" style="height: 3rem;" :src="data.group.group_cover == null ? '/images/cover.jpg' : data.group.group_cover.url">
                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_in') }}: </strong> {{ data.group.title }}</p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_group') }} <i class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
            <div class="mx-1 mt-1 mb-05 border-top" v-else-if="data.supplier !== null">
                <a :href="'/member/supplier/'+data.supplier.slug">
                    <div class="card text-white">
                        <img class="card-img img-fluid" style="height: 3rem;" :src="data.supplier.supplier_cover_image == null ? '/images/cover.jpg' : data.supplier.supplier_cover_image.url">
                        <div class="card-img-overlay overflow-hidden overlay-dark d-flex align-items-center pt-05 pb-05 pl-1 pr-05">
                            <p class="text-white mb-0" style="line-height: 1rem;"><strong>{{ __('globals.posted_to') }} </strong> {{ data.supplier.title }} </p>
                            <button class="btn-sm btn-outline-white ml-auto">{{ __('globals.view_page') }} <i class="feather icon-chevron-right"></i></button>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import linkify from 'vue-linkify'
import VideoPlayer from "../VideoPlayer";
import Editor from '@tinymce/tinymce-vue'
import lightbox from "./lightbox/lightbox";

Vue.directive('linkified', linkify)

export default {
    name: "ScheduledPostDisplay",
    props: ['data'],
    components: {
        lightbox, VideoPlayer, 'editor': Editor
    },
    data() {
        return {
            editing: false,
            post: this.data,
            images: [],
            publish_at: '',
            publish_at_full: '',
            post_text: this.data.module.post_text,
            external_video_link: this.data.module.video_external_url,
            user_id: window.App.user.id,
            user: window.App.user,
            siteTimezone: window.App.siteTimezone,
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                  {
                    src: this.data.module.video_link && this.data.module.video_link.includes('trybz_pending_video') ? '/videos/pending.mp4' : this.data.module.video_link,
                    type: 'video/mp4'
                  }
                ]
            }
        }
    },
    computed: {
        canUpdate(){
            // if the user is a site admin or is the owner of schedule post
            if(this.user.isAdmin || this.user_id === this.data.module.user_id){
                return true;
            }
            // check if the user is service admin of the group the post is made in
            if (this.data.group !== null){
               if(this.data.group.admins.length > 0){
                   return this.data.group.admins.includes(this.user_id);
               }
            }
            return false;
        },
    },
    methods: {
        destroy() {
            if (confirm(this.__('globals.are_you_sure'))) {
                axios.delete('/member/post/' + this.data.module.id);
                this.$emit('deleted', this.data.module.id);
            }
        },
        report() {
            $('#report_id').val(this.data.module.id);
            $('#report_type').val('App\\Models\\Post');
            let text = this.post_text.replaceAll(/<\/?[^>]+(>|$)/gi, "");
            $('#post_title').text(text);
            $('#reportModal').modal('toggle');
        },
        generate_date_time() {
            return moment(this.data.published_at, 'YYYY-MM-DD HH:mm:ss', window.App.timezone).tz(window.App.siteTimezone).format(moment.HTML5_FMT.DATETIME_LOCAL);
        },
        cancel() {
            this.editing = false;
            this.post_text = this.data.module.post_text;
            this.external_video_link = this.data.module.video_external_url;
        },
        update() {
            axios.patch('/member/post/' + this.data.module.id, {
                post_text: this.post_text,
                video_external_url: this.external_video_link
            });

            if (moment(this.date_time) < moment(new Date())){
                if (confirm(this.__('post.form.validation.past_date'))){
                    this.update_published_at()
                    this.$emit('deleted', this.data.module.id);
                }
            }
            else {
                this.update_published_at()
            }

            this.editing = false;

            let data = {
                'message': this.__('post.post_updated'),
                'level': 'success'
            }
            flash(data);

        },
        update_published_at() {
            axios.patch('/member/newsfeed/' + this.data.id, {
                published_at: this.date_time,
            });
            this.publish_at = ! window.App.timeFormat ? moment.tz(this.date_time, window.App.timezone).local() : moment.tz(this.date_time, window.App.timezone).local().fromNow();
            this.publish_at_full = moment.tz(this.date_time, window.App.timezone).local();
        },
        downloadFile(file_id, index) {
            this.$http.get('/member/post/' + this.data.module.id + '/download-file/'+file_id, {responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], { type: this.data.module.post_file.mime_type })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = this.data.module.post_file[index].file_name
                    link.click()
                })
        },

        deleteAttachment(file_id, index){
            if (confirm(this.__('post.form.delete_attachment'))) {
                axios.delete('/member/post/' + this.data.module.id + '/delete-attachment/'+file_id);
                this.data.module.post_file.splice(index, 1);
            }
        },
    },
    mounted() {
        this.date_time = this.generate_date_time();
        this.publish_at = ! window.App.timeFormat ? moment.tz(this.data.published_at, window.App.timezone).local() : moment.tz(this.data.published_at, window.App.timezone).local().fromNow();
        this.publish_at_full = moment.tz(this.data.published_at, window.App.timezone).local();

        let mime_type = ['image/gif', 'image/jpeg', 'image/png'];
        if (this.data.module.post_image.length > 0) {
            for (let i = 0; i < this.data.module.post_image.length; i++) {
                if (this.data.module.post_image[i] !== undefined) {
                    if (mime_type.includes(this.data.module.post_image[i].mime_type)) {
                        this.images.push(window.App.siteUrl+'/storage/' + this.data.module.post_image[i].id + '/' + this.data.module.post_image[i].file_name);
                    }
                }
            }
        }
    },

    beforeMount(){

        if(typeof(this.data.video_link) === 'undefined'){
            this.data.video_link = null;
        }
        if(this.data.module.post_file.length === 0){
            this.data.module.post_file = null;
        }
    }
}
</script>

<style scoped>

</style>
