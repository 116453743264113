<template>
    <div>
        <div class="mt-0 mb-05">
            <div class=" pt-1 pb-1 col-12" v-if="this.data.post_text !== null && this.data.post_text !== ''">
                <div v-if="collapse_body">
                    <div class="breakAll image-resize" v-linkified v-html="this.data.post_text"></div>
                </div>
                <div v-else>
                    <div v-bind:class="class_body+ ' image-resize'" ref="textBox" v-linkified v-html="this.data.post_text"></div>
                </div>
                <div v-if="enable_read_more">
                    <button v-if="collapse_body" type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = false">
                        {{ __('globals.read_less') }} <i class="fa fa-chevron-up"></i>
                    </button>
                    <button v-else type="button" class="btn btn-link font-small-3 mt-05 mb-05 p-0" @click.prevent="collapse_body = true">
                        {{ __('globals.read_more') }} <i class="fa fa-chevron-down"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 p-0">
                <div class="text-center" v-if="this.data.video_link !== null">
                    <video-player :options="videoOptions"/>
                </div>
                <div class="video_container" v-else-if="this.data.video_external_url !== null">
                    <iframe style="background-color: #000" class="responsive-iframe" webkitallowfullscreen
                                        mozallowfullscreen allowfullscreen :src="this.data.video_external_url"></iframe>
                </div>
                <div class="gallery-row" v-if="images.length > 1">
                    <div class="gallery-container">
                        <lightbox :items="images"></lightbox>
                    </div>
                </div>
                <div v-else-if="this.data.post_image.length === 1">
                    <div class="card-img-top text-center ">
                        <img class="mw-100" :src="this.data.post_image[0].url">
                    </div>
                </div>
                <div class="pr-05 pl-05 pb-05 mt-1" v-if="this.data.post_file.length > 0">
                    <a @click.prevent="downloadFile(file.id, index)" v-for="(file, index) in this.data.post_file">
                        <div class="card bg-outline-light text-center mb-05">
                            <div class="d-flex align-items-center pt-05 pb-05 pl-1 pr-05 ">
                                <strong class="text-center"><i class="feather icon-download"></i>
                                    {{ __('post.form.buttons.download_file') }} ...{{ file.file_name.slice(-20) }} </strong>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import lightbox from "../../../post/lightbox/lightbox.vue"
import "../../../post/lightbox/lightbox.css"
import VideoPlayer from "../../../VideoPlayer.vue";

export default {
    name: "NewsfeedSharedPost",
    components: {lightbox, VideoPlayer},
    props: ['data'],
    data() {

        // When uploading a post video a 'trybz_pending_video' placeholder is added then replaced when the upload completes.
        let vid_src = '';
        if(this.data.video_link !== undefined){
            if(this.data.video_link && this.data.video_link.includes('trybz_pending_video')){
                // vid_src = window.location.origin + '/videos/pending.mp4';
                vid_src = '/videos/pending.mp4';
            } else {
                vid_src = this.data.video_link;
            }
        }

        return {
            images: [],
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: vid_src,
                        type: 'video/mp4'
                    }
                ]
            },
            enable_read_more: false,
            collapse_body: false,
            class_body: 'breakAll',
        }
    },
    mounted(){
        if(this.$refs.textBox.clientHeight >= 300){
            this.enable_read_more = true;
            this.class_body = 'breakAll overflow-fade';
        }
    },
    methods: {
        init(){
            let mime_type = ['image/gif', 'image/jpeg', 'image/png'];
            if (this.data.media !== undefined && this.data.media.length > 0) {
                for (let i = 0; i < this.data.media.length; i++) {
                    if (this.data.media[i] !== undefined) {
                        if (mime_type.includes(this.data.media[i].mime_type)) {
                            this.images.push(window.App.siteUrl+'/storage/' + this.data.media[i].id + '/' + this.data.media[i].file_name);
                        }
                    }
                }
            }
        },
        downloadFile(file_id, index) {
            this.$http.get('/member/post/' + this.data.id + '/download-file/'+file_id, {responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], {type: this.data.post_file.mime_type})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = this.data.post_file[index].file_name
                    link.click()
                })
        },
    },
    beforeMount(){
        this.init();
    },

}
</script>

<style>

</style>
