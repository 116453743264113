var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:this.mainclass},[_c('span',{staticClass:"font-small-2 text-bold-600"},[_vm._v("Post your "),(_vm.parent === true)?_c('span',[_vm._v("Comment:")]):(_vm.parent === false)?_c('span',[_vm._v(_vm._s(_vm.__('comment.reply'))+":")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card mb-1 shared-post-comment"},[_c('editor',{attrs:{"api-key":"no-api-key","init":{
                    skin: "trybz-member",
                    skin_url: "/plugins/tiny-mce-skin/ui/trybz-member",
                    content_css: "/plugins/tiny-mce-skin/content/trybz-member/content.min.css",
                    external_plugins: {
                        customtags: "/plugins/tiny-mce-plugins/customtags/plugin.min.js",
                        loadingbar: "/plugins/tiny-mce-plugins/loadingbar/plugin.min.js",
                    },
                    valid_elements : "p,br,b,i,b/strong,i/em,a[href|target=_blank],li[a|b|i|u|ol|ul],ol[li],ul[li]",
                    plugins: "link quickbars autolink lists customtags loadingbar",
                    link_context_toolbar: true,
                    quickbars_selection_toolbar: "bold italic link bullist",
                    quickbars_image_toolbar: false,
                    quickbars_insert_toolbar: false,
                    link_assume_external_targets: "https",
                    link_target_list: false,
                    link_title: false,
                    toolbar: false,
                    branding: false,
                    elementpath: false,
                    menubar: false,
                    file_picker_types: "",
                    height: 100,
                    content_style: "body { font-family: Montserrat, Helvetica, Arial, serif; font-size: 0.86rem; font-weight: 400; line-height: 1.45; color: #1d2124; }",
                    relative_urls : false,
                    remove_script_host : false,
                }},model:{value:(_vm.comment_text),callback:function ($$v) {_vm.comment_text=$$v},expression:"comment_text"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary btn-doc-header waves-effect waves-light text-bold-700",attrs:{"id":"comment_post_submit","type":"submit","disabled":_vm.isActive},on:{"click":_vm.addComment}},[_vm._v(_vm._s(_vm.__('comment.post'))),_c('i',{staticClass:"feather icon-arrow-right"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }