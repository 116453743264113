<template>
    <div>
        <div class="card w-100">
            <div>
                <img class="img-fluid"
                     :src="resource.cover == null ? '/images/cover.png' : resource.cover.url" v-if="resource.cover">
            </div>
            <div class="card-content p-1">
                <div class="row">
                    <div class="col-12">
                        <span class="font-large-1">{{ resource.title }}</span>
                    </div>
                    <div class="col-12 d-flex align-items-center">
                        <div class="ml-auto">
                            <button class="btn-sm btn-outline-warning resource-rating" data-toggle="modal" data-target="#registerModal">
                                <span>
                                {{ __('resource.form.buttons.view_rating') }}
                            </span>
                            </button>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="breakAll pt-1">
                            <span class="breakAll image-resize" v-html="resource.resource_description"></span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex border-top border-bottom align-items-center mt-1 owner-info">
                            <div id="pt-1">
                                <img
                                    :src="resource.owner.avatar !== null ? resource.owner.avatar.url :  '/images/avatar.png'"
                                    alt="avatar" height="40" width="40" class="round">
                            </div>
                            <div class="p-1">
                                <span data-toggle="modal" data-target="#registerModal" class="user-name text-bold-600">{{ resource.owner.first_name}} {{ resource.owner.last_name}}</span>
                                <br><span class="font-size-3 text-muted">{{ resource.owner.position}}</span>
                            </div>

                        </div>
                    </div>
                    <div class="col-12">

                            <button data-toggle="modal" data-target="#registerModal"
                               class="download-button btn btn-primary waves-effect waves-light form-control mt-05">
                                    <i class="fa fa-download"></i> {{ __('resource.form.buttons.download') }}
                            </button>

                            <div id="progress" class="p-2" style="display: none"></div>
                    </div>
                </div>
            </div>
             <div class="d-flex justify-content-between border-top pl-05 pr-05">
                <div class="p-05">
                    <i class="fa fa-thumbs-o-up" data-toggle="modal" data-target="#registerModal"></i>
                     {{ resource.likes === 0 ? '' : resource.likes }}
                </div>
                <div class="p-05">
                    <span data-toggle="modal" data-target="#registerModal" class="comment-button">
                        <i class="fa fa-comment-o"></i>
                        {{ resource.comments === 0 ? '' : resource.comments }}
                        {{ resource.comments === 1 ? __('globals.comment') : __('globals.comments') }}
                    </span>
                </div>
                <div>
                    <guest-social-share :social="resource.social"></guest-social-share>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import linkify from 'vue-linkify'

    Vue.directive('linkified', linkify)

    export default {
        name: "Resource",
        props: ['resource'],
        data() {
            return {}
        },
    }
</script>

<style scoped>

</style>
