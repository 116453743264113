<template>
    <div>
        <search :filters="filters" :tags="tags" v-on:searchType="changeFilter($event)"></search>

        <div class="row p-1 bg-white border-bottom d-flex align-items-center" v-for="(question, index) in questions" :key="index" >
            <guest-question-card :question="question"></guest-question-card>
        </div>

        <infinite-loading :identifier="infiniteId" @distance="1" @infinite="infiniteHandler">
           <div slot="no-more"></div>
            <div slot="no-results">{{ __('globals.no_questions_found_header') }}</div>
        </infinite-loading>
   </div>

</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Search from "../../Search.vue";
import GuestQuestionCard from "../cards/Question.vue";

export default {
    name: "GuestQuestionsInfiniteScroll",
    components: {InfiniteLoading, Search, GuestQuestionCard},
    props: ['filters', 'tags'],
    data() {
        return {
            questions: [],
            page: 1,
            search: '',
            infiniteId: +new Date(),
            keyword: '',
            tagId: '',
        }
    },
    methods: {
        infiniteHandler($state) {
            if(this.search === 'custom'){
                this.getFilteredQuestions($state);
            } else {
                this.getQuestions($state);
            }
        },
        getQuestions($state){
            let vm = this;
            axios
                .get("/question/search/"+this.search+"?page="+this.page)
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.questions.push(value);
                        });
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        getFilteredQuestions($state){
            let vm = this;
            axios
                .get("/question/search/custom?tagId=" + this.tagId + "&keyword=" + this.keyword + "&page=" + this.page)
                .then(response => {
                    if (response.data.data.length > 0){
                        $.each(response.data.data, function(key, value){
                            vm.questions.push(value);
                        });
                        $state.loaded();
                    }
                    else {
                        $state.complete();
                    }
                });
            vm.page++;
        },
        changeFilter(args){
            if (args.type === "tag" || args.type === "keyword") {
                if(args.tagId !== undefined) {
                    this.tagId = args.tagId
                }
                if(args.keyword !== undefined) {
                    this.keyword = args.keyword
                }
                this.search = 'custom';
            } else {
                this.keyword = '';
                this.tagId = '';
                this.search = args.type;
            }

            this.page = 1;
            this.questions = [];
            this.infiniteId += 1;
        }
    },
    beforeMount() {
        switch (this.filters.default) {
            case 'popular':
                this.search = 'popular';
                break;
            case 'latest':
                this.search = 'latest';
                break;
            default:
                this.search = 'all';
                break;
        }
    }
}
</script>

<style scoped>

</style>
