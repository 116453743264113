import { render, staticRenderFns } from "./NewRating.vue?vue&type=template&id=7bd29f80&scoped=true"
import script from "./NewRating.vue?vue&type=script&lang=js"
export * from "./NewRating.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bd29f80",
  null
  
)

export default component.exports