<template>
    <div>
        <div style="display: inline-block">
            <button :class="'btn btn-primary '+ this.size" v-if="currentStatus === false" @click.prevent="join()">
                {{type === 'group' ? __('globals.join') : __('globals.follow') }} <i class="feather icon-user-plus"></i>
            </button>
            <button :class="'btn btn-primary '+ this.size" v-if="currentStatus === true" @click.prevent="leaveQuestionCheck()">
                {{ type === 'group' ? __('globals.leave') : __('globals.unfollow') }} <i class="feather icon-log-out"></i>
            </button>
            <button :class="'btn btn-primary '+ this.size" v-if="currentStatus === 'pending'" @click.prevent="leave()">
                {{ __('globals.requested') }} <i class="feather icon-log-in"></i>
            </button>
        </div>

        <div v-if="leave_modal">
            <leave-service-modal type="group" :slug="slug" :leave_question="leave_question" @question-complete="questionComplete()" @close-modal="triggerModal()"></leave-service-modal>
        </div>
    </div>
</template>

<script>
    import LeaveServiceModal from "./LeaveServiceModal";

    export default {
        props: ['type', 'slug', 'status', 'ask_question', 'leave_question', 'button_size'],
        name: "JoinService",
        components: {LeaveServiceModal},
        data () {
            return {
                currentStatus: this.status,
                submitting: false,
                leave_modal: false,
                size: ''
            }
        },
        beforeMount() {
            switch (this.button_size){
                case 'small':
                    this.size = 'btn-sm';
                    break;
                case 'large':
                    this.size = 'btn-lg';
                    break;
                default:
                    this.size = '';
                    break;
            }
        },
        methods: {
            join() {
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .post('/member/'+this.type+'/'+this.slug + (this.type === 'group' ? '/join' : '/follow'))
                    .then(response => {
                        let flash_data = {
                            'message': response.data.message,
                            'level': 'success'
                        }
                        flash(flash_data);
                        if(response.data.status === 'accepted'){
                          this.currentStatus = true;
                        } else {
                          this.currentStatus = 'pending';
                        }
                        this.submitting = false;
                    })
                    .catch(() => {
                        this.submitting = false;
                    });
            },
            leave(){
                if(this.submitting){
                    return false;
                }
                this.submitting = true;
                axios
                    .delete('/member/'+this.type+'/'+this.slug + (this.type === 'group' ? '/leave' : '/unfollow'))
                    .then(response => {
                        let flash_data = {
                            'message': this.currentStatus === 'pending' ? 'Request Cancelled' : response.data.message,
                            'level': 'primary'
                        }
                        flash(flash_data);
                        this.currentStatus = false;
                        this.submitting = false;
                    })
                    .catch(() => {
                        this.submitting = false;
                    });
            },
            leaveQuestionCheck(){
                if(this.ask_question){
                    this.triggerModal();
                    return false;
                }
                this.leave();
            },
            questionComplete(){
              this.leave_modal = !this.leave_modal;
              this.leave();
            },
            triggerModal(){
                this.leave_modal = !this.leave_modal;
            }
        },
    }
</script>

<style scoped>

</style>
